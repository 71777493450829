export function findOrThrow<T>(
  array: readonly T[],
  predicate: (value: T, index: number, obj: readonly T[]) => boolean,
): T {
  const foundItem = array.find(predicate)
  if (foundItem === undefined) {
    throw new Error(`Cannot find element in array.`)
  }

  return foundItem
}
