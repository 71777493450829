import type { ObjectPosition } from '@/shared/types/document'

export const relativeToScaled = (val: number, scale: number): number => {
  return val * scale
}

export const scaledToRelative = (val: number, scale: number): number => {
  return val / scale
}

type Keys = keyof ObjectPosition
export const relativeToScaledObject = (
  val: Partial<ObjectPosition>,
  scale: number,
): Partial<ObjectPosition> => {
  const keys = Object.keys(val) as Keys[]
  return keys.reduce((acc, key: Keys) => {
    if (key === 'x' || key === 'y' || key === 'w' || key === 'h') {
      acc[key] = relativeToScaled(val[key] ?? 0, scale)
    } else {
      acc[key] = val[key] ?? 0
    }
    return acc
  }, {} as Partial<ObjectPosition>)
}

export const scaledToRelativeObject = (
  val: Partial<ObjectPosition>,
  scale: number,
): Partial<ObjectPosition> => {
  const keys = Object.keys(val) as Keys[]
  return keys.reduce((acc, key: Keys) => {
    if (key === 'x' || key === 'y' || key === 'w' || key === 'h') {
      acc[key] = scaledToRelative(val[key] ?? 0, scale)
    } else {
      acc[key] = val[key] ?? 0
    }
    return acc
  }, {} as Partial<ObjectPosition>)
}
