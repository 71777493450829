interface IPlusLogoWithTextProps {
  height?: string
  width?: string
  fillColor?: string
}

const PlusLogoWithText = (props: IPlusLogoWithTextProps): JSX.Element => {
  const { height, width, fillColor } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 294 96`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96 48C96 78.5097 78.5097 96 48 96C17.4903 96 0 78.5097 0 48C0 17.4903 17.4903 0 48 0C78.5097 0 96 17.4903 96 48ZM80 56V48L48 48L48 16L40 16L40 20C40 33 33 40 20 40H16L16 48L48 48L48 80H56V76C56 63 63 56 76 56H80ZM32 77H40V56H19V64H27C30 64 32 66 32 69V77ZM56 19H64V27C64 30 66 32 69 32H77V40H56V19Z"
        fill={fillColor}
      />
      <path
        d="M120 81.0014H133.715V59.5152H146.058C159.955 59.5152 169.19 51.195 169.19 38.3033C169.19 25.3202 159.955 17 146.058 17H120V81.0014ZM144.778 28.4288C151.178 28.4288 155.292 32.1775 155.292 38.3033C155.292 44.3378 151.178 48.0864 144.778 48.0864H133.715V28.4288H144.778Z"
        fill={fillColor}
      />
      <path d="M175.804 81.0014H188.695V17H175.804V81.0014Z" fill={fillColor} />
      <path
        d="M229.887 57.5038C229.887 66.1897 226.138 70.8526 219.738 70.8526C213.338 70.8526 210.595 66.7382 210.595 58.3266V34.0061H197.703V60.0638C197.703 76.7042 206.664 81.55 216.355 81.55C223.03 81.55 227.144 78.9899 230.161 75.3327L231.35 81.0014H242.779V34.0061H229.887V57.5038Z"
        fill={fillColor}
      />
      <path
        d="M249.395 65.3668C249.943 75.4242 258.812 81.55 271.887 81.55C284.504 81.55 293.281 75.607 293.281 66.2811C293.281 55.6751 284.321 53.2065 273.532 52.1094C266.767 51.2865 262.744 50.9208 262.744 47.355C262.744 44.3378 266.035 42.5091 271.064 42.5091C276.275 42.5091 279.841 44.7949 280.207 48.5436H292.55C291.91 38.9433 283.224 33.3661 270.607 33.3661C258.538 33.2747 250.401 39.4005 250.401 48.7264C250.401 58.4181 258.904 60.8867 269.875 62.1667C277.464 63.1725 280.664 63.4467 280.664 67.2868C280.664 70.5783 277.373 72.3155 271.978 72.3155C265.669 72.3155 262.104 69.4812 261.646 65.3668H249.395Z"
        fill={fillColor}
      />
    </svg>
  )
}

PlusLogoWithText.defaultProps = {
  fillColor: '#100F19',
  height: '52',
  width: '160',
}

export default PlusLogoWithText
