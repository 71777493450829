import cx from 'clsx'
import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

type Props = {
  tooltip?: string
  error?: boolean
} & React.TextareaHTMLAttributes<HTMLTextAreaElement>

// TODO: This is basically just an Input but with a rows prop... should be merged ideally
const TextArea = (props: Props): JSX.Element => {
  const { className = '', error, ...rest } = props

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'top',
    })

  return (
    <div className="relative flex flex-1 rounded-md">
      <textarea
        ref={setTriggerRef}
        className={cx(
          'border-divider-light-gray placeholder-copy-secondary block w-full rounded text-base',
          'hover:bg-interactive-secondary-hover',
          'focus:bg-background-white',
          'focus:ring-interactive-primary',
          'focus:border-divider-light-blue',
          'focus:ring-0',
          'focus:ring-offset-0',

          'disabled:opacity-50',
          'disabled:cursor-not-allowed',
          error && 'border-interactive-destructive',
          className,
        )}
        {...rest}
      />
      {visible && props.tooltip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.tooltip}
        </div>
      )}
    </div>
  )
}

export default TextArea
