import { Transition } from 'framer-motion'
import { createContext, createElement, useCallback, useContext, useRef, useState } from 'react'
import {
  assign,
  createMachine,
  interpret,
  InterpreterFrom,
  StateFrom,
  StateMachine,
  StateSchema,
} from 'xstate'

interface ScreenAnchor {
  readonly type: 'screen'
  readonly horizontalAlign?: 'start' | 'center' | 'end'
  readonly verticalAlign?: 'start' | 'center' | 'end'
}

interface SpecifiedElementAnchor {
  readonly type: 'element'
  readonly selectorId: string
  readonly useHighlightedElement?: never
}

interface HighlightElementAnchor {
  readonly type: 'element'
  readonly selectorId?: never
  readonly useHighlightedElement: boolean
}

type Anchor = ScreenAnchor | SpecifiedElementAnchor | HighlightElementAnchor

interface Highlight {
  readonly selectorId: string
  readonly padding?: number
}

export interface ComponentProps {
  readonly nextStep: () => void
  readonly previousStep: () => void
  readonly endTour: () => void
  readonly hasPreviousStep: boolean
  readonly hasNextStep: boolean
  // eslint-disable-next-line no-use-before-define
  readonly currentStep: TourStep
  readonly currentStepIndex: number
  readonly totalSteps: number
  readonly animating: boolean
}

export interface TourStep {
  readonly component: React.FunctionComponent<ComponentProps>
  readonly anchor: Anchor
  readonly highlight?: Highlight
}

type TourOptions = {
  readonly layout: boolean | 'size' | 'position'
  readonly transition: Transition | null
}

interface TourContext {
  readonly activeTourSteps: readonly TourStep[]
  readonly currentIndex: number
  readonly currentStep: TourStep | undefined
  readonly options: TourOptions
}

type TourEvents =
  | {
      readonly type: 'NEXT_STEP'
    }
  | { readonly type: 'PREVIOUS_STEP' }
  | { readonly type: `SET_STEP_${number}` }
  | { readonly type: 'FINISH_TOUR' }
  | { readonly type: 'SET_TOUR_OPTIONS'; readonly options: Partial<TourOptions> }

type TourTypeState =
  | {
      readonly value: `step_${number}`
      readonly context: TourContext
    }
  | {
      readonly value: 'done'
      readonly context: TourContext
    }

type TourSchema = StateSchema<TourContext>
export type TourStateMachine = StateMachine<TourContext, TourSchema, TourEvents, TourTypeState>

export type ToursConfig<T extends string = string> = {
  readonly [key in T]: {
    readonly tour: TourStateMachine
    readonly options?: TourOptions
  }
}

export const buildTour = (steps: readonly TourStep[]) => {
  if (steps.length === 0) throw new Error('A tour must have at least one step to work correctly.')

  return createMachine<TourContext, TourEvents, TourTypeState>({
    context: {
      activeTourSteps: steps,
      currentIndex: 0,
      currentStep: steps[0],
      options: {
        layout: true,
        transition: null,
      },
    },
    initial: 'step_0',
    on: {
      ...steps.reduce((obj, _, i) => {
        return {
          ...obj,
          [`SET_STEP_${i}`]: {
            actions: assign((context: TourContext) => ({
              ...context,
              currentIndex: i,
              currentStep: steps[i],
            })),
            target: `step_${i}`,
          },
        }
      }, {}),
      FINISH_TOUR: 'done',
      SET_TOUR_OPTIONS: {
        actions: assign((context, event) => ({
          ...context,
          options: { ...context.options, ...event.options },
        })),
      },
    },
    predictableActionArguments: true,
    states: {
      ...steps.reduce((obj, _, i) => {
        return {
          ...obj,
          [`step_${i}`]: {
            on: {
              NEXT_STEP: {
                actions: steps[i + 1]
                  ? assign((context) => ({
                      ...context,
                      currentIndex: i + 1,
                      currentStep: steps[i + 1],
                    }))
                  : undefined,
                target: steps[i + 1] ? `step_${i + 1}` : 'done',
              },
              PREVIOUS_STEP: {
                actions: steps[i - 1]
                  ? assign((context) => ({
                      ...context,
                      currentIndex: i - 1,
                      currentStep: steps[i - 1],
                    }))
                  : undefined,
                target: steps[i - 1] ? `step_${i - 1}` : undefined,
              },
            },
          },
        }
      }, {}),
      done: {
        type: 'final',
      },
    },
  })
}

const useStarTourProvider = (tours: ToursConfig) => {
  const activeMachine = useRef<InterpreterFrom<TourStateMachine> | null>(null)
  const [machineState, setMachineState] = useState<StateFrom<TourStateMachine> | null>(null)

  const stop = useCallback(() => {
    if (activeMachine.current) {
      activeMachine.current.stop()
      // eslint-disable-next-line functional/immutable-data
      activeMachine.current = null
      setMachineState(null)
    }
  }, [])

  const start = useCallback(
    (tourId: string, step?: number) => {
      if (!tours[tourId]) {
        // eslint-disable-next-line no-console
        console.error(
          `No tour with ID "${tourId}" was registered. Check your StarTourProvider and ensure the tour is registered correctly. Available tours: ${Object.keys(
            tours,
          ).join(', ')}`,
        )
        return
      }
      // eslint-disable-next-line functional/immutable-data
      activeMachine.current = interpret(tours[tourId].tour)
      activeMachine.current.subscribe((state) => {
        if (state.done) {
          stop()
        } else {
          setMachineState(state)
        }
      })
      if (tours[tourId].options) {
        activeMachine.current.send('SET_TOUR_OPTIONS', { options: tours[tourId].options })
      }
      if (step) {
        activeMachine.current.send(`SET_STEP_${step}`)
      }
      activeMachine.current.start()
    },
    [stop, tours],
  )

  const transitionTo = useCallback((step: number) => {
    if (!activeMachine.current) return

    return activeMachine.current.send(`SET_STEP_${step}`)
  }, [])

  const next = useCallback(() => {
    if (!activeMachine.current) return

    return activeMachine.current.send('NEXT_STEP')
  }, [])

  const previous = useCallback(() => {
    if (!activeMachine.current) return

    return activeMachine.current.send('PREVIOUS_STEP')
  }, [])

  return {
    activeTourSteps: machineState?.context?.activeTourSteps ?? null,
    currentStep: machineState?.context?.currentStep ?? null,
    currentStepIndex: machineState?.context?.currentIndex ?? -1,
    isTourActive: !!activeMachine.current,
    next,
    options: machineState?.context?.options,
    previous,
    start,
    stop,
    transitionTo,
  }
}

const noop = (): void => {
  // noop
}
interface ProviderProps {
  readonly children: React.ReactNode
  readonly tours: ToursConfig
}

type StarTourProviderValue = ReturnType<typeof useStarTourProvider>
export const StarTourContext = createContext<StarTourProviderValue>({
  activeTourSteps: null,
  currentStep: null,
  currentStepIndex: -1,
  isTourActive: false,
  next: noop as StarTourProviderValue['next'],
  options: undefined,
  previous: noop as StarTourProviderValue['previous'],
  start: noop,
  stop: noop,
  transitionTo: noop as StarTourProviderValue['transitionTo'],
})
export const StarTourProvider = ({ children, tours }: ProviderProps) => {
  const starTour = useStarTourProvider(tours)

  return createElement(StarTourContext.Provider, { value: starTour }, children)
}

export const useStarTour = () => useContext(StarTourContext)
