import { useStarTour } from '@plusdocs/star-tours'
import { Loader } from 'react-feather'

import { Snapshot } from '@/generated/graphql'
import Button, { ButtonProps } from '@/shared/components/Button/Button'
import { ButtonTestId } from '@/shared/types/testIds'
import { openLink } from '@/util'

export type RefreshOrCaptureState = 'refreshing' | 'capturing' | 'none'

export const RefreshButton = ({
  onClickRefreshSnapshot,
  isLoggedOut,
  needsRepair,
  label = 'Refresh Snapshot',
  refreshingLabel = 'Refreshing…',
  refreshOrCaptureState,
  variant = 'primary',
  snapshot,
}: {
  readonly onClickRefreshSnapshot: () => void
  readonly isLoggedOut?: boolean
  readonly label?: string
  readonly needsRepair?: boolean
  readonly refreshingLabel?: string
  readonly refreshOrCaptureState: RefreshOrCaptureState
  readonly variant?: ButtonProps['variant']
  readonly snapshot?: Snapshot
}): JSX.Element => {
  const { isTourActive } = useStarTour()

  const isRefreshingOrCapturing = refreshOrCaptureState !== 'none'

  const getButtonState = () => {
    if (isTourActive) {
      return <span>{label}</span>
    }

    if (isRefreshingOrCapturing) {
      return (
        <div className="flex items-center space-x-2">
          <Loader size={16} className="motion-safe:animate-spin-slow" />
          <span>{refreshOrCaptureState === 'capturing' ? 'Capturing…' : refreshingLabel}</span>
        </div>
      )
    }

    return <span>{label}</span>
  }

  return (
    <div className="space-y-2">
      <Button
        variant={variant}
        className="w-full"
        data-star-tours-id="refresh-button"
        data-testid={ButtonTestId.RefreshSnapshot}
        onClick={onClickRefreshSnapshot}
        disabled={!isTourActive && (isLoggedOut || isRefreshingOrCapturing || needsRepair)}
        {...(isLoggedOut
          ? {
              tooltip: (
                <span className="inline-block">
                  This Snapshot is logged out. <br />
                  Log back in to refresh it.
                </span>
              ),
              tooltipOffset: [0, 9],
              tooltipPlacement: 'bottom',
            }
          : {})}
      >
        {getButtonState()}
      </Button>
      {snapshot && (
        <>
          <a
            href={snapshot.instructions.url}
            className="flex w-full"
            target="_blank"
            rel="noreferrer"
          >
            <Button variant="secondary" className="w-full">
              Visit source
            </Button>
          </a>
          <Button
            variant="secondary"
            className="w-full"
            onClick={() =>
              openLink(
                `${import.meta.env.WEB_APP_HOST}/${snapshot.organization.slug}/snapshot/${
                  snapshot.id
                }`,
              )
            }
          >
            Go to Snapshot
          </Button>
        </>
      )}
    </div>
  )
}
