import { createAsyncThunk, createNextState, createSlice, PayloadAction } from '@reduxjs/toolkit'
import WebFont from 'webfontloader'

import {
  ContentType,
  Document,
  PagesEditorQueryDocument,
  SnapshotDocument,
  SnapshotFieldsFragment,
  SnapshotQuery,
  SnapshotQueryResult,
  TrackContentViewsDocument,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import { generateViewPayload } from '@/shared/hooks/useTrackContentView'
import documentSync from '@/util/documentSync'

import { initializeSnapshots } from './snapshots'
import { AppThunkOptions, RootState } from './types'

type PopoverName = string
export interface AppState {
  loading: boolean
  canvas: {
    width: number
    height: number
    scale: number
    scalingSide: number
  }
  selectedObjects: string[]
  editingState: 'none' | 'rectSelecting' | 'dragging' | 'editingText'
  ui: {
    sidebar: 'open' | 'closed'
    popovers: {
      [popoverName: string]: boolean
    }
  }
}

const initialAppState: AppState = {
  canvas: {
    height: 0,
    scale: 0,
    scalingSide: 0,
    width: 0,
  },
  editingState: 'none',
  loading: true,
  selectedObjects: [],
  ui: {
    popovers: {},
    sidebar: 'open',
  },
}

const loadLatoFont = () => {
  return new Promise<void>((active) => {
    WebFont.load({
      active,
      google: {
        families: ['Lato:400,400i,700,700i:latin'],
      },
    })
  })
}

const trackSnapshotViewsOnPage = async ({ document }: { document: Document }) => {
  const snapshotUrns = document.pages.flatMap((page) =>
    page.snapshots.map((snapshot) => snapshot.urn),
  )
  if (snapshotUrns.length === 0) return

  await apolloClient.mutate({
    errorPolicy: 'ignore',
    mutation: TrackContentViewsDocument,
    variables: {
      views: snapshotUrns.map((urn) => generateViewPayload(ContentType.Snapshot, urn, true)),
    },
  })
}

const clearDocumentOfOrphanedSnapshots = ({ document }: { document: Document }): Document => {
  // Step 1) dedupe public ids
  const snapshotPublicIds = new Set(
    document.pages.flatMap((page) => page.snapshots.map((snapshot) => snapshot.id)),
  )

  // Step 2) Create a new document state to modify
  const nextDocument = createNextState(document, (next) => {
    // Step 3) iterate all pages on the document
    next.pages.forEach((page) => {
      // Step 4) iterate all objects on each page
      page.objects.forEach((object) => {
        if (
          // Step 5) if it's a Snapshot
          object.type === 'Snapshot' &&
          // Step 6) and it doesn't exist anymore (i.e the actual Snapshot has been deleted)
          !snapshotPublicIds.has(object.content.id)
        ) {
          // Step 7) delete them from the page we're working with
          const pageIndex = next.pages.findIndex((p) => p.id === page.id)
          const objectIndex = next.pages[pageIndex].objects.findIndex((obj) => obj.id === object.id)
          next.pages[pageIndex].objects.splice(objectIndex, 1)
        }
      })
    })
  })

  // Step 8) Return the new document state
  return nextDocument
}

const fetchSnapshotsInDocument = async (doc: Document): Promise<SnapshotFieldsFragment[]> => {
  const snapshotsOnPageById = doc.pages
    .flatMap((page) =>
      page.objects.map((object) => (object.type === 'Snapshot' ? object.content.id : null)),
    )
    .filter((id) => id !== null)
  const possibleSnapshots = await Promise.allSettled(
    snapshotsOnPageById.map((id) => {
      return apolloClient.query<SnapshotQuery>({
        fetchPolicy: 'network-only',
        query: SnapshotDocument,
        variables: {
          id,
        },
      })
    }),
  )
  const fulfilledSnapshots = possibleSnapshots.filter(
    (p) => p.status === 'fulfilled',
  ) as PromiseFulfilledResult<SnapshotQueryResult>[]
  const snapshots = fulfilledSnapshots.map(
    (p) => p.value.data?.snapshot,
  ) as SnapshotFieldsFragment[]
  return snapshots
}

export const updateSnapshotsInDocument = createAsyncThunk<void, void, AppThunkOptions>(
  'appState/updateSnapshotsInDocument',
  async (_, { dispatch, getState }) => {
    const state = getState()
    const snapshots = await fetchSnapshotsInDocument(state.document)
    dispatch(initializeSnapshots(snapshots))
  },
)

export const initEditorAppThunk = createAsyncThunk<
  boolean,
  {
    documentId: string
  },
  AppThunkOptions
>('appState/init', async ({ documentId }, { dispatch, getState }) => {
  const state = getState()

  if (!state.appState.loading && documentId === state.document.id) {
    return true
  }

  const resp = await apolloClient.query({
    query: PagesEditorQueryDocument,
    variables: {
      documentId,
    },
  })
  const doc = resp.data.document as Document

  const updatedDocument = clearDocumentOfOrphanedSnapshots({
    document: doc,
  })

  const usesLatoFont = updatedDocument.pages
    .flatMap((page) => page.objects)
    .some((obj) => obj.type === 'Text' && obj.content.fontFamily === 'Lato')

  if (usesLatoFont) {
    await loadLatoFont()
  }

  // this is a manually created object so we can avoid a circular dependency
  // i'm only okay with this because this redux code is deprecated anyway, and will keep going away over time
  dispatch({
    payload: updatedDocument,
    type: 'document/initializeDocument',
  })
  documentSync.push(updatedDocument)

  void trackSnapshotViewsOnPage({ document: updatedDocument })

  return true
})

const AppStateSlice = createSlice({
  extraReducers(builder) {
    builder.addCase(initEditorAppThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(initEditorAppThunk.fulfilled, (state, { payload: fullyLoaded }) => {
      if (fullyLoaded) {
        state.loading = false
      }
    })
  },
  initialState: initialAppState,
  name: 'appState',
  reducers: {
    closePopover(state, action: PayloadAction<PopoverName>) {
      delete state.ui.popovers[action.payload]
    },
    closeSidebar(state) {
      state.ui.sidebar = 'closed'
    },
    openPopover(state, action: PayloadAction<PopoverName>) {
      state.ui.popovers[action.payload] = true
    },
    openSidebar(state) {
      state.ui.sidebar = 'open'
    },

    resetAppState() {
      return { ...initialAppState }
    },

    setPopoverStatus(state, action: PayloadAction<[PopoverName, boolean]>) {
      const [popoverName, setOpen] = action.payload

      if (setOpen) {
        state.ui.popovers[popoverName] = true
      } else {
        delete state.ui.popovers[popoverName]
      }
    },

    togglePopover(state, action: PayloadAction<PopoverName>) {
      if (state.ui.popovers[action.payload]) {
        delete state.ui.popovers[action.payload]
      } else {
        state.ui.popovers[action.payload] = true
      }
    },

    toggleSelectedObjects(
      state,
      action: PayloadAction<{ objectIds: string[]; force?: 'on' | 'off' }>,
    ) {
      for (const id of action.payload.objectIds) {
        const idx = state.selectedObjects.indexOf(id)
        if (idx === -1 && action.payload.force !== 'off') {
          state.selectedObjects.push(id)
        } else if (action.payload.force !== 'on') {
          state.selectedObjects.splice(idx, 1)
        }
      }
      state.selectedObjects.sort()
    },

    toggleSidebar(state) {
      if (state.ui.sidebar === 'open') {
        state.ui.sidebar = 'closed'
      } else {
        state.ui.sidebar = 'open'
      }
    },

    updateCanvasSize(
      state,
      action: PayloadAction<{
        base: { width: number; height: number }
        canvas: { width: number; height: number }
      }>,
    ) {
      const scale = action.payload.canvas.width / action.payload.base.width
      state.canvas = {
        height: action.payload.base.height * scale,
        scale,
        scalingSide: Math.max(action.payload.base.width, action.payload.base.height),
        width: action.payload.base.width * scale,
      }
    },

    updateEditingState(state, action: PayloadAction<AppState['editingState']>) {
      state.editingState = action.payload
    },

    updateSelectedObjects(state, action: PayloadAction<string[]>) {
      state.selectedObjects = action.payload.sort()
    },
  },
})

export default AppStateSlice.reducer
export const selectAppState = (state: RootState): AppState => state.appState
export const selectCanvas = (state: RootState): AppState['canvas'] => state.appState.canvas
export const selectSelectedObjects = (state: RootState): string[] => state.appState.selectedObjects
export const selectLoading = (state: RootState): boolean => state.appState.loading
export const selectEditingState = (state: RootState): AppState['editingState'] =>
  state.appState.editingState

export const matchSidebarState =
  (status: AppState['ui']['sidebar']) =>
  (state: RootState): boolean =>
    state.appState.ui.sidebar === status

export const selectIsPopoverOpen =
  (popoverName: PopoverName) =>
  (state: RootState): boolean =>
    Boolean(state.appState.ui.popovers[popoverName])

export const {
  updateCanvasSize,
  updateSelectedObjects,
  toggleSelectedObjects,
  updateEditingState,
  resetAppState,
  openSidebar,
  closeSidebar,
  toggleSidebar,
  openPopover,
  closePopover,
  togglePopover,
  setPopoverStatus,
} = AppStateSlice.actions
