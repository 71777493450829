import * as PopperJS from '@popperjs/core'
import cx from 'clsx'
import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

import { noop } from '@/util/fp'

type Props = {
  leadingIcon?: JSX.Element
  trailingIcon?: JSX.Element
  tooltip?: string
  tooltipPlacement?: PopperJS.Placement | 'icon'
  hasError?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const Input = (props: Props): JSX.Element => {
  const {
    className = '',
    leadingIcon,
    trailingIcon,
    tooltipPlacement = 'top',
    hasError = false,
    ...rest
  } = props

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: tooltipPlacement === 'icon' ? 'top' : tooltipPlacement,
    })

  return (
    <div className="relative flex flex-1 rounded-md">
      {leadingIcon && (
        <div
          className="absolute inset-y-0 left-0 flex items-center pl-2"
          ref={tooltipPlacement === 'icon' ? setTriggerRef : noop}
        >
          {leadingIcon}
        </div>
      )}
      <input
        ref={tooltipPlacement === 'icon' ? noop : setTriggerRef}
        type="text"
        className={cx(
          'border-divider-light-gray block h-8 w-full rounded text-base',
          'hover:bg-interactive-secondary-hover',
          'focus:bg-background-white',
          'focus:border-divider-light-blue',
          'focus:ring-0',
          'focus:ring-offset-0',
          'disabled:opacity-50',
          'disabled:cursor-not-allowed',
          hasError && 'border-copy-alert focus:border-copy-alert',
          className,
          {
            'pl-8': leadingIcon,
            'pr-8': trailingIcon,
          },
        )}
        {...rest}
      />
      {trailingIcon && (
        <div
          className="absolute inset-y-0 right-0 flex items-center pr-2"
          ref={tooltipPlacement === 'icon' ? setTriggerRef : noop}
        >
          {trailingIcon}
        </div>
      )}
      {visible && props.tooltip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.tooltip}
        </div>
      )}
    </div>
  )
}

export default Input
