// THIS IS A GENERATED FILE
/* eslint-disable */
// @ts-nocheck

import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never
}
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Base64String: { input: string; output: string }
  DateTime: { input: string; output: string }
  EmailAddress: { input: string; output: string }
  FQDN: { input: string; output: string }
  IntegerBetween1And100: { input: number; output: number }
  JSON: { input: { readonly [key: string]: any }; output: { readonly [key: string]: any } }
  NonEmptyString: { input: string; output: string }
  NonNegativeInt: { input: number; output: number }
  PositiveInt: { input: number; output: number }
  StringWithMaxLengthOf100: { input: string; output: string }
  StringWithMaxLengthOf1000: { input: string; output: string }
  StringWithMaxLengthOf5000: { input: string; output: string }
  URL: { input: string; output: string }
  URN: { input: string; output: string }
}

export type AcceptInvitationInput = {
  invitationId: Scalars['ID']['input']
  name: Scalars['StringWithMaxLengthOf100']['input']
  type?: InputMaybe<InvitationType>
}

export type AcceptInvitationMutationResult = MutationResult & {
  __typename?: 'AcceptInvitationMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<User>
}

export type ActiveOrgClientState = {
  __typename?: 'ActiveOrgClientState'
  id: Scalars['ID']['output']
  slug: Scalars['String']['output']
}

export type Actor = User

export type BillingPlan = {
  __typename?: 'BillingPlan'
  billedUsersCount: Scalars['Int']['output']
  currencySymbol: Scalars['String']['output']
  frequency?: Maybe<PlanFrequency>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  nextInvoice?: Maybe<Scalars['DateTime']['output']>
  price: Scalars['Int']['output']
  status?: Maybe<PaymentStatus>
  tier: PlanTier
}

export type CanvasSize = {
  __typename?: 'CanvasSize'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type CanvasSizeInput = {
  height: Scalars['Int']['input']
  width: Scalars['Int']['input']
}

export enum CaptureStatus {
  Captured = 'CAPTURED',
  ErrorMissingBrowserData = 'ERROR_MISSING_BROWSER_DATA',
  ErrorNetwork = 'ERROR_NETWORK',
  ErrorSessionExpired = 'ERROR_SESSION_EXPIRED',
  ErrorTimeout = 'ERROR_TIMEOUT',
  ErrorUnknown = 'ERROR_UNKNOWN',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  RefreshRequested = 'REFRESH_REQUESTED',
}

export enum ConnectionStatus {
  Deteriorating = 'DETERIORATING',
  Disconnected = 'DISCONNECTED',
  Good = 'GOOD',
  Reconnecting = 'RECONNECTING',
}

/** An object which represents a type of Content. */
export type Content = {
  /** Datetime when the object was created */
  createdAt: Scalars['DateTime']['output']
  /** The object's id */
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Organization
  properties: ContentProperties
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  /** The Object's URN */
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export enum ContentAccessLevel {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export enum ContentAuthorizationCode {
  Allow = 'ALLOW',
  Forbidden = 'FORBIDDEN',
  Password = 'PASSWORD',
}

/** Describes a content deletion event */
export type ContentDeletion = {
  __typename?: 'ContentDeletion'
  contentType: ContentType
  id: Scalars['ID']['output']
  urn: Scalars['URN']['output']
}

export type ContentItem = Document | File | Folder | Presentation | Slide | Snapshot | Text | Theme

export type ContentMutationResult = MutationResult & {
  __typename?: 'ContentMutationResult'
  code: Scalars['String']['output']
  content?: Maybe<ContentItem>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export enum ContentPrivacy {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED',
}

export type ContentPrivacyEdge = {
  __typename?: 'ContentPrivacyEdge'
  id: Scalars['ID']['output']
  password: Scalars['String']['output']
}

export type ContentPrivacyState = {
  __typename?: 'ContentPrivacyState'
  accessLevel?: Maybe<ContentAccessLevel>
  id?: Maybe<Scalars['ID']['output']>
  password?: Maybe<Scalars['String']['output']>
}

/** Settings and other properties on Content types */
export type ContentProperties = {
  __typename?: 'ContentProperties'
  /** Access code used to restrict access to this content */
  accessCode?: Maybe<Scalars['String']['output']>
  privacy: ContentPrivacy
}

export enum ContentRating {
  ThumbsDown = 'THUMBS_DOWN',
  ThumbsUp = 'THUMBS_UP',
}

export enum ContentType {
  Document = 'DOCUMENT',
  File = 'FILE',
  Folder = 'FOLDER',
  Presentation = 'PRESENTATION',
  Slide = 'SLIDE',
  Snapshot = 'SNAPSHOT',
  Text = 'TEXT',
  Theme = 'THEME',
}

export type ContentViewInput = {
  embedDomain?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  isEmbed?: InputMaybe<Scalars['Boolean']['input']>
  isOnPage?: InputMaybe<Scalars['Boolean']['input']>
  type: ContentType
  urn: Scalars['URN']['input']
}

/** Content fields contextual to the current viewer */
export type ContentViewer = {
  __typename?: 'ContentViewer'
  canDelete: Scalars['Boolean']['output']
  canEdit: Scalars['Boolean']['output']
  isAuthor: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
}

export type CreateDocumentInput = {
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  folder?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  /** The initial page structure */
  pages: Array<PageStructureV1Input>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateFileInput = {
  contentType: UploadContentType
  folder?: InputMaybe<Scalars['ID']['input']>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateFolderInput = {
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateOrganizationInput = {
  joinByDomain?: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['StringWithMaxLengthOf100']['input']
  slug: Scalars['StringWithMaxLengthOf100']['input']
  user: CreateUserInput
}

export type CreateOrganizationMutationResult = MutationResult & {
  __typename?: 'CreateOrganizationMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  organization?: Maybe<Organization>
  success: Scalars['Boolean']['output']
  user?: Maybe<User>
}

export type CreatePresentationFromTextInput = {
  context?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Array<Scalars['ID']['input']>>
  googleSlidesId?: InputMaybe<Scalars['String']['input']>
  isBlueprint?: InputMaybe<Scalars['Boolean']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  numberOfSlides?: InputMaybe<Scalars['PositiveInt']['input']>
  prompt?: InputMaybe<Scalars['String']['input']>
  richTextState?: InputMaybe<Scalars['String']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  templateId?: InputMaybe<Scalars['String']['input']>
  textHandling?: InputMaybe<TextHandling>
  useCustomInstructions?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreatePresentationInput = {
  context?: InputMaybe<Scalars['String']['input']>
  files?: InputMaybe<Array<Scalars['ID']['input']>>
  googleSlidesId?: InputMaybe<Scalars['String']['input']>
  isBlueprint?: InputMaybe<Scalars['Boolean']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  numberOfSlides?: InputMaybe<Scalars['PositiveInt']['input']>
  preset: PresentationPreset
  prompt: Scalars['String']['input']
  richTextState?: InputMaybe<Scalars['String']['input']>
  slides?: InputMaybe<Array<PresentationSlideInput>>
  teamId?: InputMaybe<Scalars['ID']['input']>
  templateId?: InputMaybe<Scalars['String']['input']>
  textHandling?: InputMaybe<TextHandling>
  title?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  useCustomInstructions?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateReferralsMutationResult = MutationResult & {
  __typename?: 'CreateReferralsMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  referrals: Array<Referral>
  success: Scalars['Boolean']['output']
}

export type CreateSlideInput = {
  context?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt: Scalars['String']['input']
  slideType: Scalars['StringWithMaxLengthOf1000']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
  templateId?: InputMaybe<Scalars['ID']['input']>
  textHandling?: InputMaybe<TextHandling>
  themeId?: InputMaybe<Scalars['ID']['input']>
  title?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
}

export type CreateSnapshotInput = {
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  folder?: InputMaybe<Scalars['ID']['input']>
  instructions: SnapshotCaptureInstructionsInput
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  overlay?: InputMaybe<SnapshotOverlayOptionsInput>
  refreshRate?: InputMaybe<SnapshotRefreshRate>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type CreateSnapshotMutationResult = MutationResult & {
  __typename?: 'CreateSnapshotMutationResult'
  browserDataUploadUrl?: Maybe<Scalars['URL']['output']>
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  snapshot?: Maybe<Snapshot>
  success: Scalars['Boolean']['output']
}

export type CreateThemeInput = {
  backgroundColor: Scalars['StringWithMaxLengthOf100']['input']
  bodyFont: Scalars['StringWithMaxLengthOf100']['input']
  colorFour: Scalars['StringWithMaxLengthOf100']['input']
  colorOne: Scalars['StringWithMaxLengthOf100']['input']
  colorThree: Scalars['StringWithMaxLengthOf100']['input']
  colorTwo: Scalars['StringWithMaxLengthOf100']['input']
  fontColor: Scalars['StringWithMaxLengthOf100']['input']
  googleId?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  headerFont: Scalars['StringWithMaxLengthOf100']['input']
  name: Scalars['StringWithMaxLengthOf100']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
  thumbnail?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  useBackgroundImages?: InputMaybe<Scalars['Boolean']['input']>
}

export type CreateUserInput = {
  googleClickId?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  name: Scalars['StringWithMaxLengthOf100']['input']
  source?: InputMaybe<SignupSource>
}

export type CustomInstructions = {
  __typename?: 'CustomInstructions'
  profile?: Maybe<Scalars['String']['output']>
  style?: Maybe<Scalars['String']['output']>
}

export type CustomInstructionsInput = {
  profileInstructions?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  styleInstructions?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
}

export type DeleteInvitationMutationResult = MutationResult & {
  __typename?: 'DeleteInvitationMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type DisconnectSlackAccountMutationResult = MutationResult & {
  __typename?: 'DisconnectSlackAccountMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export enum DismissableUi {
  CaptureFailedNux = 'CAPTURE_FAILED_NUX',
  LogBackInNux = 'LOG_BACK_IN_NUX',
  ReviewNux = 'REVIEW_NUX',
}

export type DismissedUi = {
  __typename?: 'DismissedUi'
  captureFailedNux: Scalars['Boolean']['output']
  loggedOutNux: Scalars['Boolean']['output']
  reviewNux: Scalars['Boolean']['output']
}

export type Document = Content & {
  __typename?: 'Document'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  folder?: Maybe<Folder>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  name: Scalars['String']['output']
  organization: Organization
  pages: Array<PageStructureV1>
  properties: ContentProperties
  team?: Maybe<Team>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export type DocumentImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

/** A Document edge */
export type DocumentEdge = {
  __typename?: 'DocumentEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The user at the end of edge. */
  node: Document
}

export type DocumentMutationResult = MutationResult & {
  __typename?: 'DocumentMutationResult'
  code: Scalars['String']['output']
  document?: Maybe<Document>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

/** A Documents connection */
export type DocumentsConnection = {
  __typename?: 'DocumentsConnection'
  /** A list of node edges */
  edges: Array<DocumentEdge>
  /** A list of nodes */
  nodes: Array<Document>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type DocumentsFilter = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type DomainSettings = {
  __typename?: 'DomainSettings'
  backgroundRefresh: Scalars['Boolean']['output']
  blocked: Scalars['Boolean']['output']
  ebc: Array<EbcRule>
  logout: Array<LogoutRule>
  storage: Array<Scalars['String']['output']>
}

export type EbcRule = {
  __typename?: 'EbcRule'
  captureSelector?: Maybe<Scalars['String']['output']>
  captureVariables?: Maybe<Array<Scalars['String']['output']>>
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  pattern?: Maybe<Scalars['String']['output']>
  selector: Scalars['String']['output']
}

export type ExtensionClientState = {
  __typename?: 'ExtensionClientState'
  extensionRuntimeId?: Maybe<Scalars['String']['output']>
  extensionVersion?: Maybe<Scalars['String']['output']>
  isExtensionInstalled?: Maybe<Scalars['Boolean']['output']>
}

export type FeedClientState = {
  __typename?: 'FeedClientState'
  activeContentType?: Maybe<ContentType>
  selectedContent: Array<SelectedContent>
  sortField?: Maybe<SearchResultOrderField>
}

export type FeedbackInput = {
  feedback?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  presentationUrl?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  rating: ContentRating
}

export type File = Content & {
  __typename?: 'File'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  folder?: Maybe<Folder>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  name: Scalars['String']['output']
  organization: Organization
  properties: ContentProperties
  team?: Maybe<Team>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export type FileImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

export type FileMutationResult = MutationResult & {
  __typename?: 'FileMutationResult'
  code: Scalars['String']['output']
  file?: Maybe<File>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Folder = Content & {
  __typename?: 'Folder'
  /**
   * Number of content items inside the folder
   * @deprecated Use `contentCounts` instead.
   */
  contentCount: Scalars['Int']['output']
  contentCounts: FolderContentCounts
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Organization
  properties: ContentProperties
  team?: Maybe<Team>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export type FolderContentCounts = {
  __typename?: 'FolderContentCounts'
  documents: Scalars['Int']['output']
  snapshots: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

/** A Folder edge */
export type FolderEdge = {
  __typename?: 'FolderEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The user at the end of edge. */
  node: Folder
}

export type FolderMutationResult = MutationResult & {
  __typename?: 'FolderMutationResult'
  code: Scalars['String']['output']
  folder?: Maybe<Folder>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

/** A Folders connection */
export type FoldersConnection = {
  __typename?: 'FoldersConnection'
  /** A list of node edges */
  edges: Array<FolderEdge>
  /** A list of nodes */
  nodes: Array<Folder>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type FoldersFilter = {
  name?: InputMaybe<Scalars['String']['input']>
}

export enum FontStyle {
  Cartoon = 'CARTOON',
  Elegant = 'ELEGANT',
  Formal = 'FORMAL',
  Friendly = 'FRIENDLY',
  Futuristic = 'FUTURISTIC',
  Girly = 'GIRLY',
  Gothic = 'GOTHIC',
  Graphic = 'GRAPHIC',
  Handwritten = 'HANDWRITTEN',
  Luxury = 'LUXURY',
  Minimal = 'MINIMAL',
  Modern = 'MODERN',
  Retro = 'RETRO',
  Traditional = 'TRADITIONAL',
  Vintage = 'VINTAGE',
}

export type GenerateTextInput = {
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt: Scalars['String']['input']
}

export type GenerateTextMutationResult = {
  __typename?: 'GenerateTextMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  text: Text
}

export type GenerateThemeInput = {
  name: Scalars['StringWithMaxLengthOf100']['input']
}

export type GenerateThemeMutationResult = MutationResult & {
  __typename?: 'GenerateThemeMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  theme?: Maybe<GeneratedTheme>
}

export type GeneratedTheme = {
  __typename?: 'GeneratedTheme'
  backgroundColor: Scalars['String']['output']
  bodyFont: Scalars['String']['output']
  colorFour: Scalars['String']['output']
  colorOne: Scalars['String']['output']
  colorThree: Scalars['String']['output']
  colorTwo: Scalars['String']['output']
  fontColor: Scalars['String']['output']
  headerFont: Scalars['String']['output']
  name: Scalars['String']['output']
}

/** An Identities connection */
export type IdentitiesConnection = {
  __typename?: 'IdentitiesConnection'
  /** A list of node edges */
  edges: Array<IdentityEdge>
  /** A list of nodes */
  nodes: Array<Identity>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type IdentitiesFilter = {
  /**
   * Only include identities with the given connection status
   * Undefined = Search for identities with any connection status
   * [...connectionStatuses] = Search only for identities with the specified connection status
   */
  connectionStatus?: InputMaybe<Array<ConnectionStatus>>
  /** Only include results where the identity's domain matches the specified domain */
  domain?: InputMaybe<Scalars['FQDN']['input']>
}

export type Identity = {
  __typename?: 'Identity'
  connectionStatus: ConnectionStatus
  current: Scalars['Boolean']['output']
  domain: Scalars['String']['output']
  id: Scalars['ID']['output']
  snapshots: SnapshotsConnection
  status: IdentityStatus
  updatedAt: Scalars['DateTime']['output']
}

export type IdentitySnapshotsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<SnapshotsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
}

/** An Identity edge */
export type IdentityEdge = {
  __typename?: 'IdentityEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The Identity at the end of edge. */
  node: Identity
}

export enum IdentityStatus {
  Authorized = 'AUTHORIZED',
  Unauthorized = 'UNAUTHORIZED',
}

export enum ImageQualityPreset {
  Original = 'ORIGINAL',
}

export type ImageSuggestion = {
  __typename?: 'ImageSuggestion'
  landscape?: Maybe<Scalars['URL']['output']>
  portrait?: Maybe<Scalars['URL']['output']>
  square?: Maybe<Scalars['URL']['output']>
}

export type Invitation = Node & {
  __typename?: 'Invitation'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy?: Maybe<Actor>
  email: Scalars['EmailAddress']['output']
  id: Scalars['ID']['output']
  organization: Organization
  type: InvitationType
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
}

/** A Invitation edge */
export type InvitationEdge = {
  __typename?: 'InvitationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The invitation at the end of edge. */
  node: Invitation
}

export enum InvitationType {
  Email = 'EMAIL',
  Organization = 'ORGANIZATION',
}

/** A Invitations connection */
export type InvitationsConnection = {
  __typename?: 'InvitationsConnection'
  /** A list of node edges */
  edges: Array<InvitationEdge>
  /** A list of nodes */
  nodes: Array<Invitation>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type InvitationsFilter = {
  email?: InputMaybe<Scalars['String']['input']>
}

export type LinkSlackAccountInput = {
  nonce: Scalars['StringWithMaxLengthOf100']['input']
  slackUserId: Scalars['ID']['input']
}

export type LinkSlackAccountMutationResult = MutationResult & {
  __typename?: 'LinkSlackAccountMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type LogoutRule = {
  __typename?: 'LogoutRule'
  pattern: Scalars['String']['output']
  statuses: Array<Maybe<Scalars['Int']['output']>>
}

export type Mutation = {
  __typename?: 'Mutation'
  _experimental: _ExperimentalMutation
  acceptInvitation: AcceptInvitationMutationResult
  /** Capture a new image on an existing Snapshot */
  captureSnapshot: SnapshotMutationResult
  /** Connect all the snapshots on this domain to the identity of this snapshot */
  connectSnapshotIdentitiesForDomain: SnapshotMutationResult
  createCheckoutLink: Scalars['URL']['output']
  createDocument: DocumentMutationResult
  createFolder: FolderMutationResult
  createOrganization: CreateOrganizationMutationResult
  createPaymentPortalLink: Scalars['URL']['output']
  /**  Creates and sends a referral email to the provided recipients */
  createReferrals: CreateReferralsMutationResult
  /** Create a new Snapshot and trigger an initial capture */
  createSnapshot: CreateSnapshotMutationResult
  createUpload: Scalars['URL']['output']
  deleteDocument: DocumentMutationResult
  deleteFolder: FolderMutationResult
  deleteInvitation: DeleteInvitationMutationResult
  /** Delete a snapshot */
  deleteSnapshot: SnapshotMutationResult
  /** Delete a specific snapshot version */
  deleteSnapshotVersion: SnapshotVersionMutationResult
  deleteUser: UserMutationResult
  disconnectSlackAccount?: Maybe<DisconnectSlackAccountMutationResult>
  /** Disconnect all the snapshots on this domain from the identity of this snapshot */
  disconnectSnapshotIdentitiesForDomain?: Maybe<SnapshotMutationResult>
  /** Dismisses the suggestion repair type for the current snapshot */
  dismissSnapshotRepairSuggestion: SnapshotMutationResult
  /** Links a provided slack account to a users currently logged in Plus account */
  linkSlackAccount?: Maybe<LinkSlackAccountMutationResult>
  /** Moves a piece of content into or out of a folder. Not passing folderId = remove any folder association */
  moveToFolder: ContentMutationResult
  /** Moves a piece of content into or out of a team space. Not passing teamId = place back in personal space */
  moveToTeam: ContentMutationResult
  /** Report an issue with a Snapshot */
  reportSnapshotIssue: SnapshotIssueMutationResult
  sendInvitation: SendInvitationMutationResult
  trackContentViews: TrackingMutationResult
  trackUserActivity: TrackingMutationResult
  /** Updates the properties of a content, including privacy status */
  updateContentProperties: ContentMutationResult
  updateDocument: DocumentMutationResult
  updateFolder: FolderMutationResult
  /** Update an Identity's state data */
  updateIdentityData: Scalars['URL']['output']
  updateOrganization: OrganizationMutationResult
  /** Update a Snapshot's data */
  updateSnapshot: SnapshotMutationResult
  /** Update/refresh a Snapshot's browser and session state data */
  updateSnapshotBrowserData: Scalars['URL']['output']
  updateUser: UserMutationResult
}

export type MutationAcceptInvitationArgs = {
  input: AcceptInvitationInput
}

export type MutationCaptureSnapshotArgs = {
  id: Scalars['ID']['input']
}

export type MutationConnectSnapshotIdentitiesForDomainArgs = {
  id: Scalars['ID']['input']
}

export type MutationCreateCheckoutLinkArgs = {
  couponId?: InputMaybe<Scalars['ID']['input']>
  priceId: Scalars['ID']['input']
  upgradeSource?: InputMaybe<UpgradeSource>
}

export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput
}

export type MutationCreateFolderArgs = {
  input: CreateFolderInput
}

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
}

export type MutationCreateReferralsArgs = {
  recipients: Array<Scalars['EmailAddress']['input']>
}

export type MutationCreateSnapshotArgs = {
  input: CreateSnapshotInput
}

export type MutationCreateUploadArgs = {
  contentType: UploadContentType
  objectId: Scalars['ID']['input']
  purpose: UploadPurpose
}

export type MutationDeleteDocumentArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteFolderArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteInvitationArgs = {
  id: Scalars['ID']['input']
  type?: InputMaybe<InvitationType>
}

export type MutationDeleteSnapshotArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteSnapshotVersionArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input']
}

export type MutationDisconnectSnapshotIdentitiesForDomainArgs = {
  id: Scalars['ID']['input']
}

export type MutationDismissSnapshotRepairSuggestionArgs = {
  id: Scalars['ID']['input']
}

export type MutationLinkSlackAccountArgs = {
  input: LinkSlackAccountInput
}

export type MutationMoveToFolderArgs = {
  folderId?: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
}

export type MutationMoveToTeamArgs = {
  id: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type MutationReportSnapshotIssueArgs = {
  input: SnapshotIssueInput
  urn: Scalars['URN']['input']
}

export type MutationSendInvitationArgs = {
  input: SendInvitationInput
}

export type MutationTrackContentViewsArgs = {
  views: Array<ContentViewInput>
}

export type MutationTrackUserActivityArgs = {
  activity: UserActivityInput
}

export type MutationUpdateContentPropertiesArgs = {
  id: Scalars['ID']['input']
  input: UpdateContentPropertiesInput
}

export type MutationUpdateDocumentArgs = {
  id: Scalars['ID']['input']
  input: UpdateDocumentInput
}

export type MutationUpdateFolderArgs = {
  id: Scalars['ID']['input']
  input: UpdateFolderInput
}

export type MutationUpdateIdentityDataArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateOrganizationArgs = {
  id: Scalars['ID']['input']
  input: UpdateOrganizationInput
}

export type MutationUpdateSnapshotArgs = {
  id: Scalars['ID']['input']
  input: UpdateSnapshotInput
}

export type MutationUpdateSnapshotBrowserDataArgs = {
  id: Scalars['ID']['input']
}

export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input']
  input: UpdateUserInput
}

export type MutationResult = {
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

/** An object with an ID to support global identification. */
export type Node = {
  /** Datetime when the object was created */
  createdAt: Scalars['DateTime']['output']
  /** The object's id */
  id: Scalars['ID']['output']
  /** The Object's URN */
  urn: Scalars['URN']['output']
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Organization = Node & {
  __typename?: 'Organization'
  billingCountryCode?: Maybe<Scalars['String']['output']>
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the organization. */
  createdBy: Actor
  currentPlan?: Maybe<BillingPlan>
  customInstructions: CustomInstructions
  /** Documents belonging to this organization */
  documents: DocumentsConnection
  /** Folders belonging to this organization */
  folders: FoldersConnection
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  invitations: InvitationsConnection
  isStripeCustomer: Scalars['Boolean']['output']
  joinByDomain: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  /** String used to scope external URLs to org */
  slug: Scalars['String']['output']
  /** Snapshots belonging to this organization */
  snapshots: SnapshotsConnection
  /** The viewer's available teams within the organization. */
  teams: TeamsConnection
  urn: Scalars['URN']['output']
  /** Get a list of all users in this organization. */
  users: UsersConnection
  viewer: OrganizationViewer
}

export type OrganizationDocumentsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<DocumentsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type OrganizationFoldersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<FoldersFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type OrganizationImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

export type OrganizationInvitationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<InvitationsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type OrganizationSnapshotsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<SnapshotsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
}

export type OrganizationTeamsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<TeamsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type OrganizationUsersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<UsersFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

/** A Organization edge */
export type OrganizationEdge = {
  __typename?: 'OrganizationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The Organization at the end of edge. */
  node: Organization
}

export type OrganizationMutationResult = MutationResult & {
  __typename?: 'OrganizationMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  organization?: Maybe<Organization>
  success: Scalars['Boolean']['output']
}

export type OrganizationViewer = {
  __typename?: 'OrganizationViewer'
  isAuthor: Scalars['Boolean']['output']
  isOwner: Scalars['Boolean']['output']
}

/** A Organization connection */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection'
  /** A list of node edges */
  edges: Array<OrganizationEdge>
  /** A list of nodes */
  nodes: Array<Organization>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type OrganizationsFilter = {
  name?: InputMaybe<Scalars['String']['input']>
}

/** Pagination information */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** An end cursor for use in pagination. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** Indicates if there are more pages to fetch. */
  hasNextPage: Scalars['Boolean']['output']
  /** Indicates if there are any pages prior to the current page. */
  hasPreviousPage: Scalars['Boolean']['output']
  /** An start cursor for use in pagination. */
  startCursor?: Maybe<Scalars['String']['output']>
}

export type PageStructureV1 = {
  __typename?: 'PageStructureV1'
  /** The size of the page canvas */
  canvasSize: CanvasSize
  /** Datetime when the page was created */
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The order of the page within the document */
  index: Scalars['Int']['output']
  /** The objects on the page to render */
  objects: Array<Scalars['JSON']['output']>
  /** Versions pinned by the user to each snapshot object on the page */
  pinnedVersions: Array<SnapshotVersion>
  /** Snapshot data for snapshots that are on the page */
  snapshots: Array<Snapshot>
  /** Datetime when the page was last updated. */
  updatedAt: Scalars['DateTime']['output']
}

export type PageStructureV1Input = {
  /** The size of the page canvas */
  canvasSize: CanvasSizeInput
  /** Datetime when the page was created */
  createdAt: Scalars['DateTime']['input']
  id: Scalars['ID']['input']
  /** The order of the page within the document */
  index: Scalars['Int']['input']
  /** The objects on the page to render, if any */
  objects: Array<Scalars['JSON']['input']>
  /** Datetime when the page was last updated. */
  updatedAt: Scalars['DateTime']['input']
}

export enum PaymentStatus {
  Failed = 'FAILED',
  Open = 'OPEN',
  Paid = 'PAID',
}

export type Plan = {
  __typename?: 'Plan'
  currencySymbol: Scalars['String']['output']
  frequency: PlanFrequency
  id: Scalars['ID']['output']
  price: Scalars['NonNegativeInt']['output']
  refreshRatesLineItem: Scalars['String']['output']
  snapshotsLineItem: Scalars['String']['output']
  tier: PlanTier
}

export enum PlanFrequency {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY',
}

export enum PlanTier {
  Advanced = 'ADVANCED',
  Custom = 'CUSTOM',
  Free = 'FREE',
  Personal = 'PERSONAL',
  Pro = 'PRO',
  Slides = 'SLIDES',
  Team = 'TEAM',
}

export type Presentation = Content & {
  __typename?: 'Presentation'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  folder?: Maybe<Folder>
  /** Id of the Google Slides pesentation associated with this presentation */
  googleSlidesId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  isBlueprint?: Maybe<Scalars['Boolean']['output']>
  language?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  organization: Organization
  preset: PresentationPreset
  prompt?: Maybe<PresentationPrompt>
  properties: ContentProperties
  slides: Array<PresentationSlide>
  status: PresentationStatus
  team?: Maybe<Team>
  type?: Maybe<PresentationType>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

/** A Presentation edge */
export type PresentationEdge = {
  __typename?: 'PresentationEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The Presentation at the end of edge. */
  node: Presentation
}

export enum PresentationErrorType {
  ConcurentPresentationProcessing = 'CONCURENT_PRESENTATION_PROCESSING',
  InvalidSlideLength = 'INVALID_SLIDE_LENGTH',
  ModerationCheckFailed = 'MODERATION_CHECK_FAILED',
  PresentationQuotaMet = 'PRESENTATION_QUOTA_MET',
  PromptLengthExceeded = 'PROMPT_LENGTH_EXCEEDED',
}

export type PresentationMutationResult = MutationResult & {
  __typename?: 'PresentationMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  presentation?: Maybe<Presentation>
  success: Scalars['Boolean']['output']
}

export enum PresentationPreset {
  Allhands = 'ALLHANDS',
  Boarddeck = 'BOARDDECK',
  Clientproposal = 'CLIENTPROPOSAL',
  Deepdive = 'DEEPDIVE',
  Education = 'EDUCATION',
  Fun = 'FUN',
  Generic = 'GENERIC',
  Onlinecourse = 'ONLINECOURSE',
  Pitchdeck = 'PITCHDECK',
  Productlaunch = 'PRODUCTLAUNCH',
  Qbr = 'QBR',
  Sermon = 'SERMON',
  Training = 'TRAINING',
  Webinar = 'WEBINAR',
}

export type PresentationPrompt = {
  __typename?: 'PresentationPrompt'
  context?: Maybe<Scalars['String']['output']>
  numberOfSlides?: Maybe<Scalars['Int']['output']>
  richTextState?: Maybe<Scalars['String']['output']>
  slides?: Maybe<Array<SlidePrompt>>
  text?: Maybe<Scalars['String']['output']>
  useCustomInstructions?: Maybe<Scalars['Boolean']['output']>
}

export type PresentationSlide =
  | SlideAgendaSimple
  | SlideChartSingle
  | SlideChartText
  | SlideImage2Sections
  | SlideImage3Sections
  | SlideMetricsSimple
  | SlideProcess4Step
  | SlideTeam
  | SlideText1Column
  | SlideText1Paragraph
  | SlideText2Columns
  | SlideText2ColumnsIcon
  | SlideText2Quotes
  | SlideText3Bullets
  | SlideText3BulletsIcon
  | SlideText3Sections
  | SlideText3SectionsIcon
  | SlideText4Bullets
  | SlideText4BulletsIcon
  | SlideText4Sections
  | SlideText4SectionsIcon
  | SlideText6Bullets
  | SlideText6BulletsIcon
  | SlideText6Sections
  | SlideText6SectionsIcon
  | SlideTextImage
  | SlideTextProCon
  | SlideTextStatement
  | SlideVennDiagram

export type PresentationSlideInput = {
  cmsSlideSlug?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  content?: InputMaybe<Scalars['StringWithMaxLengthOf5000']['input']>
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  title?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  type?: InputMaybe<PresentationSlideType>
}

export enum PresentationSlideType {
  SlideAgendaSimple = 'SlideAgendaSimple',
  SlideChartSingle = 'SlideChartSingle',
  SlideChartText = 'SlideChartText',
  SlideImage2Sections = 'SlideImage2Sections',
  SlideImage3Sections = 'SlideImage3Sections',
  SlideMetricsSimple = 'SlideMetricsSimple',
  SlideProcess4Step = 'SlideProcess4Step',
  SlideTeam = 'SlideTeam',
  SlideText1Column = 'SlideText1Column',
  SlideText1Paragraph = 'SlideText1Paragraph',
  SlideText2Columns = 'SlideText2Columns',
  SlideText2ColumnsIcon = 'SlideText2ColumnsIcon',
  SlideText2Quotes = 'SlideText2Quotes',
  SlideText3Bullets = 'SlideText3Bullets',
  SlideText3BulletsIcon = 'SlideText3BulletsIcon',
  SlideText3Sections = 'SlideText3Sections',
  SlideText3SectionsIcon = 'SlideText3SectionsIcon',
  SlideText4Bullets = 'SlideText4Bullets',
  SlideText4BulletsIcon = 'SlideText4BulletsIcon',
  SlideText4Sections = 'SlideText4Sections',
  SlideText4SectionsIcon = 'SlideText4SectionsIcon',
  SlideText6Bullets = 'SlideText6Bullets',
  SlideText6BulletsIcon = 'SlideText6BulletsIcon',
  SlideText6Sections = 'SlideText6Sections',
  SlideText6SectionsIcon = 'SlideText6SectionsIcon',
  SlideTextImage = 'SlideTextImage',
  SlideTextProCon = 'SlideTextProCon',
  SlideTextStatement = 'SlideTextStatement',
  SlideVennDiagram = 'SlideVennDiagram',
}

export enum PresentationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Generated = 'GENERATED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
}

export enum PresentationType {
  OneStep = 'ONE_STEP',
  PerSlide = 'PER_SLIDE',
  TwoStep = 'TWO_STEP',
}

/** An Identities connection */
export type PresentationsConnection = {
  __typename?: 'PresentationsConnection'
  /** A list of node edges */
  edges: Array<PresentationEdge>
  /** A list of nodes */
  nodes: Array<Presentation>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type PresentationsFilter = {
  isBlueprint?: InputMaybe<Scalars['Boolean']['input']>
}

/** An object which represents a type of Content that is accessible publicly. */
export type PublicContent = {
  /** Datetime when the object was created */
  createdAt: Scalars['DateTime']['output']
  /** The object's id */
  id: Scalars['ID']['output']
  name: Scalars['StringWithMaxLengthOf100']['output']
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  /** The Object's URN */
  urn: Scalars['URN']['output']
}

export type PublicDocument = PublicContent & {
  __typename?: 'PublicDocument'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  name: Scalars['StringWithMaxLengthOf100']['output']
  pages: Array<PublicPageStructureV1>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export type PublicDocumentImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

export type PublicDocumentQueryResult = PublicQueryResult & {
  __typename?: 'PublicDocumentQueryResult'
  code: ContentAuthorizationCode
  document?: Maybe<PublicDocument>
  message: Scalars['String']['output']
}

export type PublicPageStructureV1 = {
  __typename?: 'PublicPageStructureV1'
  /** The size of the page canvas */
  canvasSize: CanvasSize
  /** Datetime when the page was created */
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** The order of the page within the document */
  index: Scalars['Int']['output']
  /** The objects on the page to render */
  objects: Array<Scalars['JSON']['output']>
  /** Versions pinned by the user to each snapshot object on the page */
  pinnedVersions: Array<PublicSnapshotVersion>
  /** Snapshot data for snapshots that are on the page */
  snapshots: Array<PublicSnapshot>
  /** Datetime when the page was last updated. */
  updatedAt: Scalars['DateTime']['output']
}

export type PublicQueryResult = {
  code: ContentAuthorizationCode
  message: Scalars['String']['output']
}

export type PublicSnapshot = PublicContent & {
  __typename?: 'PublicSnapshot'
  createdAt: Scalars['DateTime']['output']
  currentVersion?: Maybe<PublicSnapshotVersion>
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  id: Scalars['ID']['output']
  instructions: SnapshotCaptureInstructions
  latestVersion?: Maybe<PublicSnapshotVersion>
  name: Scalars['StringWithMaxLengthOf100']['output']
  overlay: SnapshotOverlayOptions
  /** How frequently the snapshot is refreshed with a new capture */
  refreshRate: SnapshotRefreshRate
  status: SnapshotStatus
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  versions: PublicSnapshotVersionsConnection
  viewer: ContentViewer
}

export type PublicSnapshotVersionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<PublicSnapshotVersionsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type PublicSnapshotQueryResult = PublicQueryResult & {
  __typename?: 'PublicSnapshotQueryResult'
  code: ContentAuthorizationCode
  message: Scalars['String']['output']
  snapshot?: Maybe<PublicSnapshot>
}

export type PublicSnapshotVersion = Node & {
  __typename?: 'PublicSnapshotVersion'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  snapshot: PublicSnapshot
  urn: Scalars['URN']['output']
}

export type PublicSnapshotVersionImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

/** A Public Snapshot Version edge */
export type PublicSnapshotVersionEdge = {
  __typename?: 'PublicSnapshotVersionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The item at the end of edge. */
  node: PublicSnapshotVersion
}

export type PublicSnapshotVersionQueryResult = PublicQueryResult & {
  __typename?: 'PublicSnapshotVersionQueryResult'
  code: ContentAuthorizationCode
  message: Scalars['String']['output']
  snapshotVersion?: Maybe<PublicSnapshotVersion>
}

/** A Public Snapshots connection */
export type PublicSnapshotVersionsConnection = {
  __typename?: 'PublicSnapshotVersionsConnection'
  /** A list of node edges */
  edges: Array<PublicSnapshotVersionEdge>
  /** A list of nodes */
  nodes: Array<PublicSnapshotVersion>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type PublicSnapshotVersionsFilter = {
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>
}

/** Queries */
export type Query = {
  __typename?: 'Query'
  _experimental: _ExperimentalQuery
  activeOrgClientState?: Maybe<ActiveOrgClientState>
  document?: Maybe<Document>
  /** Get the settings for a specific domain */
  domainSettings: DomainSettings
  extensionClientState?: Maybe<ExtensionClientState>
  feedClientState?: Maybe<FeedClientState>
  folder?: Maybe<Folder>
  gallery: DocumentsConnection
  identities: IdentitiesConnection
  identity?: Maybe<Identity>
  /** Check if a potential org slug is valid and available */
  isSlugAvailable: SlugAvailability
  publicDocument?: Maybe<PublicDocumentQueryResult>
  publicSnapshot?: Maybe<PublicSnapshotQueryResult>
  publicSnapshotVersion?: Maybe<PublicSnapshotVersionQueryResult>
  search: SearchResultsConnection
  snapshot?: Maybe<Snapshot>
  snapshotVersion?: Maybe<SnapshotVersion>
  version: Scalars['String']['output']
  viewer: Viewer
}

/** Queries */
export type QueryDocumentArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QueryDomainSettingsArgs = {
  domain: Scalars['FQDN']['input']
}

/** Queries */
export type QueryFolderArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QueryIdentitiesArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<IdentitiesFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

/** Queries */
export type QueryIdentityArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QueryIsSlugAvailableArgs = {
  slug: Scalars['String']['input']
}

/** Queries */
export type QueryPublicDocumentArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QueryPublicSnapshotArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QueryPublicSnapshotVersionArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QuerySearchArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<SearchFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
}

/** Queries */
export type QuerySnapshotArgs = {
  id: Scalars['ID']['input']
}

/** Queries */
export type QuerySnapshotVersionArgs = {
  id: Scalars['ID']['input']
}

export type Quotas = {
  __typename?: 'Quotas'
  presentations: Scalars['NonNegativeInt']['output']
  snapshots: Scalars['NonNegativeInt']['output']
}

export type Referral = Node & {
  __typename?: 'Referral'
  /** Datetime when the team was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the team. */
  createdBy: Actor
  id: Scalars['ID']['output']
  organization: Organization
  recipient: Scalars['EmailAddress']['output']
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
}

export type RemixSlideInput = {
  context?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt?: InputMaybe<Scalars['String']['input']>
  slideText: SlideTextInput
  slideType: Scalars['StringWithMaxLengthOf1000']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
  templateId?: InputMaybe<Scalars['ID']['input']>
  textHandling?: InputMaybe<TextHandling>
  themeId?: InputMaybe<Scalars['ID']['input']>
}

export enum RepairType {
  Login = 'LOGIN',
  None = 'NONE',
  Recapture = 'RECAPTURE',
}

export type RewritePresentationInput = {
  context?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt: Scalars['String']['input']
  slides: Array<SlideTextInput>
}

export type RewritePresentationMutationResult = MutationResult & {
  __typename?: 'RewritePresentationMutationResult'
  code: Scalars['String']['output']
  content?: Maybe<Array<Array<SlideTextSection>>>
  message: Scalars['String']['output']
  presentation?: Maybe<Presentation>
  success: Scalars['Boolean']['output']
}

export type RewriteSlideInput = {
  context?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt: Scalars['String']['input']
  slideText: SlideTextInput
  textHandling?: InputMaybe<TextHandling>
}

export type RewriteSlideMutationResult = MutationResult & {
  __typename?: 'RewriteSlideMutationResult'
  code: Scalars['String']['output']
  content?: Maybe<Array<SlideTextSection>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type RewriteTextInput = {
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  prompt: Scalars['StringWithMaxLengthOf1000']['input']
  text: Scalars['String']['input']
}

export type RewriteTextMutationResult = {
  __typename?: 'RewriteTextMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  text: Text
}

export type SearchClientState = {
  __typename?: 'SearchClientState'
  activeContentType?: Maybe<ContentType>
  searchQuery: Scalars['String']['output']
  sortField: SearchResultOrderField
}

export type SearchFilter = {
  /** Only include results the current user is the owner of */
  contentIOwn?: InputMaybe<Scalars['Boolean']['input']>
  /** Only include results of a specific type */
  contentTypes?: InputMaybe<Array<ContentType>>
  /**
   * Only include results from specific folders
   * Undefined = Search for content in any folder or not in a folder
   * [] = Search only for content not in a folder
   * [...folderIds] = Search only for content in the specified folders
   */
  folders?: InputMaybe<Array<Scalars['ID']['input']>>
  /** Text query string to search for */
  query?: InputMaybe<Scalars['String']['input']>
  /**
   * Only include results from specific teams
   * Undefined = Search for content in any team user is a member of or in users personal space
   * [] = Search only for content in users personal space
   * [...teamIds] = Search only for content in the specified teams
   */
  teams?: InputMaybe<Array<Scalars['ID']['input']>>
}

export type SearchResultCounts = {
  __typename?: 'SearchResultCounts'
  documents: Scalars['Int']['output']
  folders: Scalars['Int']['output']
  snapshots: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

/** A search result edge */
export type SearchResultEdge = {
  __typename?: 'SearchResultEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The search result at the end of edge. */
  node: ContentItem
}

export type SearchResultOrder = {
  direction: OrderDirection
  field: SearchResultOrderField
}

export enum SearchResultOrderField {
  CreatedAt = 'CREATED_AT',
  LastViewed = 'LAST_VIEWED',
  Name = 'NAME',
  UpdatedAt = 'UPDATED_AT',
}

/** A Search Result connection */
export type SearchResultsConnection = {
  __typename?: 'SearchResultsConnection'
  /** The total counts of items in the connection. */
  counts: SearchResultCounts
  /** A list of node edges */
  edges: Array<SearchResultEdge>
  /** A list of nodes */
  nodes: Array<ContentItem>
  /** Information for pagination */
  pageInfo: PageInfo
}

export type SelectedContent = {
  __typename?: 'SelectedContent'
  contentCount?: Maybe<Scalars['Int']['output']>
  contentType: ContentType
  folderId?: Maybe<Scalars['ID']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  slug: Scalars['String']['output']
  teamId?: Maybe<Scalars['ID']['output']>
}

export type SendInvitationInput = {
  email: Scalars['EmailAddress']['input']
}

export type SendInvitationMutationResult = MutationResult & {
  __typename?: 'SendInvitationMutationResult'
  code: Scalars['String']['output']
  invitation?: Maybe<Invitation>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Settings = {
  __typename?: 'Settings'
  customSlides: Scalars['Boolean']['output']
  longPromptEnabled: Scalars['Boolean']['output']
  quotas?: Maybe<Quotas>
  refreshRates: Array<SnapshotRefreshRate>
}

export type SignUpInput = {
  email: Scalars['EmailAddress']['input']
}

export enum SignupSource {
  GoogleSlides = 'GOOGLE_SLIDES',
  Powerpoint = 'POWERPOINT',
  ThemeLibrary = 'THEME_LIBRARY',
  WebApp = 'WEB_APP',
}

export enum SlackAccountStatus {
  Linked = 'LINKED',
  NotLinked = 'NOT_LINKED',
}

export type Slide = Content & {
  __typename?: 'Slide'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  folder?: Maybe<Folder>
  id: Scalars['ID']['output']
  language?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  organization: Organization
  properties: ContentProperties
  slideContent?: Maybe<PresentationSlide>
  status: PresentationStatus
  team?: Maybe<Team>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  viewer: ContentViewer
}

export type SlideAgendaSimple = SlideType & {
  __typename?: 'SlideAgendaSimple'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideChartSingle = SlideType & {
  __typename?: 'SlideChartSingle'
  chapterTitle?: Maybe<Scalars['String']['output']>
  chart: SlideSimpleChartItem
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideChartText = SlideType & {
  __typename?: 'SlideChartText'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  chart: SlideSimpleChartItem
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideIconBulletItem = {
  __typename?: 'SlideIconBulletItem'
  bulletPoint: Scalars['String']['output']
  iconUrl: Scalars['String']['output']
}

export type SlideIconColumnItem = {
  __typename?: 'SlideIconColumnItem'
  bulletPoints: Array<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  iconUrl: Scalars['String']['output']
}

export type SlideIconSectionItem = {
  __typename?: 'SlideIconSectionItem'
  detail: Scalars['String']['output']
  header: Scalars['String']['output']
  iconUrl: Scalars['String']['output']
}

export type SlideImage = {
  __typename?: 'SlideImage'
  url: Scalars['URL']['output']
}

export type SlideImage2Sections = SlideType & {
  __typename?: 'SlideImage2Sections'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideImageSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideImage3Sections = SlideType & {
  __typename?: 'SlideImage3Sections'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideImageSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideImageInput = {
  height: Scalars['Int']['input']
  keywords: Scalars['StringWithMaxLengthOf1000']['input']
  width: Scalars['Int']['input']
}

export type SlideImageMutationResult = MutationResult & {
  __typename?: 'SlideImageMutationResult'
  code: Scalars['String']['output']
  image?: Maybe<SlideImage>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type SlideImageSectionItem = {
  __typename?: 'SlideImageSectionItem'
  detail: Scalars['String']['output']
  header: Scalars['String']['output']
  image: ImageSuggestion
  imageSuggestion: Scalars['String']['output']
}

export type SlideMetricsSimple = SlideType & {
  __typename?: 'SlideMetricsSimple'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  metrics: Array<SlideSimpleMetricItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideMutationResult = MutationResult & {
  __typename?: 'SlideMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  slide?: Maybe<Slide>
  success: Scalars['Boolean']['output']
}

export type SlideProcess4Step = SlideType & {
  __typename?: 'SlideProcess4Step'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  detail: Scalars['String']['output']
  processSteps: Array<SlideProcessStepSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideProcessStepSectionItem = {
  __typename?: 'SlideProcessStepSectionItem'
  deliverable: Scalars['String']['output']
  detail: Scalars['String']['output']
  header: Scalars['String']['output']
}

export type SlidePrompt = {
  __typename?: 'SlidePrompt'
  cmsSlideSlug?: Maybe<Scalars['String']['output']>
  content: Scalars['String']['output']
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  slideType?: Maybe<PresentationSlideType>
  title: Scalars['String']['output']
}

export type SlideQuoteSectionItem = {
  __typename?: 'SlideQuoteSectionItem'
  detail: Scalars['String']['output']
  source: Scalars['String']['output']
}

export type SlideSimpleChartItem = {
  __typename?: 'SlideSimpleChartItem'
  callout?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
}

export type SlideSimpleColumnItem = {
  __typename?: 'SlideSimpleColumnItem'
  bulletPoints: Array<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
}

export type SlideSimpleMetricItem = {
  __typename?: 'SlideSimpleMetricItem'
  label: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type SlideSimpleSectionItem = {
  __typename?: 'SlideSimpleSectionItem'
  detail: Scalars['String']['output']
  header: Scalars['String']['output']
}

export type SlideTeam = SlideType & {
  __typename?: 'SlideTeam'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  detail: Scalars['String']['output']
  teamMembers: Array<SlideTeamMemberSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideTeamMemberSectionItem = {
  __typename?: 'SlideTeamMemberSectionItem'
  background: Scalars['String']['output']
  name: Scalars['String']['output']
  title: Scalars['String']['output']
}

export type SlideText1Column = SlideType & {
  __typename?: 'SlideText1Column'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText1Paragraph = SlideType & {
  __typename?: 'SlideText1Paragraph'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  detail: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText2Columns = SlideType & {
  __typename?: 'SlideText2Columns'
  chapterTitle?: Maybe<Scalars['String']['output']>
  columns: Array<SlideSimpleColumnItem>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText2ColumnsIcon = SlideType & {
  __typename?: 'SlideText2ColumnsIcon'
  chapterTitle?: Maybe<Scalars['String']['output']>
  columns: Array<SlideIconColumnItem>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText2Quotes = SlideType & {
  __typename?: 'SlideText2Quotes'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideQuoteSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText3Bullets = SlideType & {
  __typename?: 'SlideText3Bullets'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText3BulletsIcon = SlideType & {
  __typename?: 'SlideText3BulletsIcon'
  bulletSections: Array<SlideIconBulletItem>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText3Sections = SlideType & {
  __typename?: 'SlideText3Sections'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideSimpleSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText3SectionsIcon = SlideType & {
  __typename?: 'SlideText3SectionsIcon'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideIconSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText4Bullets = SlideType & {
  __typename?: 'SlideText4Bullets'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText4BulletsIcon = SlideType & {
  __typename?: 'SlideText4BulletsIcon'
  bulletSections: Array<SlideIconBulletItem>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText4Sections = SlideType & {
  __typename?: 'SlideText4Sections'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideSimpleSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText4SectionsIcon = SlideType & {
  __typename?: 'SlideText4SectionsIcon'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideIconSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText6Bullets = SlideType & {
  __typename?: 'SlideText6Bullets'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText6BulletsIcon = SlideType & {
  __typename?: 'SlideText6BulletsIcon'
  bulletSections: Array<SlideIconBulletItem>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText6Sections = SlideType & {
  __typename?: 'SlideText6Sections'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideSimpleSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideText6SectionsIcon = SlideType & {
  __typename?: 'SlideText6SectionsIcon'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  sections: Array<SlideIconSectionItem>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideTextImage = SlideType & {
  __typename?: 'SlideTextImage'
  bulletPoints: Array<Scalars['String']['output']>
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  header?: Maybe<Scalars['String']['output']>
  image: ImageSuggestion
  imageSuggestion: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideTextInput = {
  sections: Array<SlideTextSectionInput>
}

export type SlideTextProCon = SlideType & {
  __typename?: 'SlideTextProCon'
  chapterTitle?: Maybe<Scalars['String']['output']>
  conBulletPoints: Array<Scalars['String']['output']>
  conHeader: Scalars['String']['output']
  description?: Maybe<Scalars['String']['output']>
  proBulletPoints: Array<Scalars['String']['output']>
  proHeader: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideTextSection = {
  __typename?: 'SlideTextSection'
  content: Scalars['String']['output']
  order: Scalars['Int']['output']
  type: SlideTextType
}

export type SlideTextSectionInput = {
  content: Scalars['String']['input']
  order: Scalars['Int']['input']
  type: SlideTextType
}

export type SlideTextStatement = SlideType & {
  __typename?: 'SlideTextStatement'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  detail: Scalars['String']['output']
  statement: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export enum SlideTextType {
  Body = 'BODY',
  Subtitle = 'SUBTITLE',
  Title = 'TITLE',
}

export type SlideType = {
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export type SlideVennDiagram = SlideType & {
  __typename?: 'SlideVennDiagram'
  chapterTitle?: Maybe<Scalars['String']['output']>
  description?: Maybe<Scalars['String']['output']>
  groupAAndBCharacteristics: Array<Scalars['String']['output']>
  groupACharacteristics: Array<Scalars['String']['output']>
  groupAHeader: Scalars['String']['output']
  groupBCharacteristics: Array<Scalars['String']['output']>
  groupBHeader: Scalars['String']['output']
  title?: Maybe<Scalars['String']['output']>
  userTip?: Maybe<Scalars['String']['output']>
}

export enum SlugAvailability {
  Available = 'AVAILABLE',
  InvalidFormat = 'INVALID_FORMAT',
  NotAvailable = 'NOT_AVAILABLE',
}

export type Snapshot = Content & {
  __typename?: 'Snapshot'
  /** Change notification sensitivity */
  changeSensitivity: SnapshotChangeSensitivity
  createdAt: Scalars['DateTime']['output']
  createdBy: Actor
  /** The most recent valid snapshot version */
  currentVersion?: Maybe<SnapshotVersion>
  description?: Maybe<Scalars['StringWithMaxLengthOf1000']['output']>
  folder?: Maybe<Folder>
  id: Scalars['ID']['output']
  identity?: Maybe<Identity>
  /** Snapshot capture instructions */
  instructions: SnapshotCaptureInstructions
  /** The most recent snapshot version */
  latestVersion?: Maybe<SnapshotVersion>
  name: Scalars['String']['output']
  organization: Organization
  overlay: SnapshotOverlayOptions
  properties: ContentProperties
  /** How frequently the snapshot is refreshed with a new capture */
  refreshRate: SnapshotRefreshRate
  /** The current status of the snapshot */
  status: SnapshotStatus
  /** Suggestion for what action may need to be taken to fix the Snapshot */
  suggestedRepairType?: Maybe<RepairType>
  team?: Maybe<Team>
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  versions: SnapshotVersionsConnection
  viewer: ContentViewer
}

export type SnapshotVersionsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<SnapshotVersionsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type SnapshotCaptureElement = {
  __typename?: 'SnapshotCaptureElement'
  rule: SnapshotCaptureElementRule
}

export type SnapshotCaptureElementInput = {
  rule: SnapshotCaptureRuleInput
}

export type SnapshotCaptureElementRule = {
  __typename?: 'SnapshotCaptureElementRule'
  id: Scalars['ID']['output']
}

export type SnapshotCaptureFrame = {
  __typename?: 'SnapshotCaptureFrame'
  frameHeight: Scalars['Float']['output']
  frameWidth: Scalars['Float']['output']
  frameX: Scalars['Float']['output']
  frameY: Scalars['Float']['output']
  windowHeight: Scalars['Float']['output']
  windowWidth: Scalars['Float']['output']
}

export type SnapshotCaptureFrameInput = {
  frameHeight: Scalars['Float']['input']
  frameWidth: Scalars['Float']['input']
  frameX: Scalars['Float']['input']
  frameY: Scalars['Float']['input']
  scrollbarWidth?: InputMaybe<Scalars['Float']['input']>
  windowHeight: Scalars['Float']['input']
  windowWidth: Scalars['Float']['input']
}

export type SnapshotCaptureI18n = {
  __typename?: 'SnapshotCaptureI18n'
  locale: Scalars['String']['output']
  timezoneId: Scalars['String']['output']
}

export type SnapshotCaptureI18nInput = {
  locale?: InputMaybe<Scalars['String']['input']>
  timezoneId?: InputMaybe<Scalars['String']['input']>
}

export type SnapshotCaptureInstructions = {
  __typename?: 'SnapshotCaptureInstructions'
  element?: Maybe<SnapshotCaptureElement>
  frame: SnapshotCaptureFrame
  i18n?: Maybe<SnapshotCaptureI18n>
  url: Scalars['URL']['output']
}

export type SnapshotCaptureInstructionsInput = {
  element?: InputMaybe<SnapshotCaptureElementInput>
  frame: SnapshotCaptureFrameInput
  i18n?: InputMaybe<SnapshotCaptureI18nInput>
  url: Scalars['URL']['input']
}

export type SnapshotCaptureRuleInput = {
  id: Scalars['ID']['input']
}

export enum SnapshotChangeSensitivity {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE',
}

/** A Snapshot edge */
export type SnapshotEdge = {
  __typename?: 'SnapshotEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The item at the end of edge. */
  node: Snapshot
}

export type SnapshotIssueInput = {
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  version?: InputMaybe<Scalars['URN']['input']>
}

export type SnapshotIssueMutationResult = MutationResult & {
  __typename?: 'SnapshotIssueMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type SnapshotMutationResult = MutationResult & {
  __typename?: 'SnapshotMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  snapshot?: Maybe<Snapshot>
  success: Scalars['Boolean']['output']
}

export type SnapshotOverlayOptions = {
  __typename?: 'SnapshotOverlayOptions'
  domain: Scalars['Boolean']['output']
  schedule: Scalars['Boolean']['output']
  timestamp: Scalars['Boolean']['output']
  title: Scalars['Boolean']['output']
}

export type SnapshotOverlayOptionsInput = {
  domain?: InputMaybe<Scalars['Boolean']['input']>
  schedule?: InputMaybe<Scalars['Boolean']['input']>
  timestamp?: InputMaybe<Scalars['Boolean']['input']>
  title?: InputMaybe<Scalars['Boolean']['input']>
}

export enum SnapshotRefreshRate {
  Daily = 'DAILY',
  EveryFiveMinutes = 'EVERY_FIVE_MINUTES',
  EverySixHours = 'EVERY_SIX_HOURS',
  Hourly = 'HOURLY',
  Manually = 'MANUALLY',
  QuarterHourly = 'QUARTER_HOURLY',
  Weekly = 'WEEKLY',
}

export enum SnapshotStatus {
  Captured = 'CAPTURED',
  Error = 'ERROR',
  New = 'NEW',
  Processing = 'PROCESSING',
  RefreshRequested = 'REFRESH_REQUESTED',
}

export type SnapshotVersion = Node & {
  __typename?: 'SnapshotVersion'
  createdAt: Scalars['DateTime']['output']
  createdBy: Actor
  /** The text we extracted from the captured image. */
  extractedText?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  /** The URL for the snapshot capture. */
  image?: Maybe<Scalars['URL']['output']>
  /** Certain version status may include a feedback message */
  message?: Maybe<Scalars['String']['output']>
  snapshot: Snapshot
  /** The current status of the version */
  status: CaptureStatus
  urn: Scalars['URN']['output']
}

export type SnapshotVersionImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

/** A Snapshot Version edge */
export type SnapshotVersionEdge = {
  __typename?: 'SnapshotVersionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The item at the end of edge. */
  node: SnapshotVersion
}

export type SnapshotVersionMutationResult = MutationResult & {
  __typename?: 'SnapshotVersionMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  snapshotVersion?: Maybe<SnapshotVersion>
  success: Scalars['Boolean']['output']
}

/** A Snapshots connection */
export type SnapshotVersionsConnection = {
  __typename?: 'SnapshotVersionsConnection'
  /** A list of node edges */
  edges: Array<SnapshotVersionEdge>
  /** A list of nodes */
  nodes: Array<SnapshotVersion>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type SnapshotVersionsFilter = {
  captureStatus?: InputMaybe<Array<CaptureStatus>>
  createdAfter?: InputMaybe<Scalars['DateTime']['input']>
  createdBefore?: InputMaybe<Scalars['DateTime']['input']>
}

/** A Snapshots connection */
export type SnapshotsConnection = {
  __typename?: 'SnapshotsConnection'
  /** A list of node edges */
  edges: Array<SnapshotEdge>
  /** A list of nodes */
  nodes: Array<Snapshot>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type SnapshotsFilter = {
  /** Do not include Snapshots with these IDs */
  exclude?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Only include results from specific folders
   * Undefined = Search for snapshot in any folder or not in a folder
   * [] = Search only for snapshot not in a folder
   * [...folderIds] = Search only for snapshot in the specified folders
   */
  folders?: InputMaybe<Array<Scalars['ID']['input']>>
  /**
   * Only include snapshots with given identity status
   * Undefined = Search for snapshot with any identity status
   * [...identityStatuses] = Search only for snapshot with the specified identity status
   */
  identityStatus?: InputMaybe<Array<IdentityStatus>>
  name?: InputMaybe<Scalars['String']['input']>
  /**
   * Only include results that are in need of repair
   * This falls into one of three categories:
   * 1. We think the snapshot is broken (suggestedRepairType)
   * 2. We encountered a logged out page (identityStatus)
   * 3. Most recent refresh of the snapshot failed (status)
   */
  needsRepair?: InputMaybe<Scalars['Boolean']['input']>
  /** Only include results the current user is the owner of */
  snapshotIOwn?: InputMaybe<Scalars['Boolean']['input']>
  /**
   * Only include results from specific teams
   * Undefined = Search for snapshot in any team user is a member of or in users personal space
   * [] = Search only for snapshot in users personal space
   * [...teamIds] = Search only for snapshot in the specified teams
   */
  teams?: InputMaybe<Array<Scalars['ID']['input']>>
}

/** Subscriptions */
export type Subscription = {
  __typename?: 'Subscription'
  /** Receive a ContentItem when new content is created */
  onContentCreated?: Maybe<SearchResultEdge>
  /** Receive an update when content is deleted */
  onContentDeleted?: Maybe<ContentDeletion>
  /** Receive an updated ContentItem when content is updated */
  onContentUpdated?: Maybe<SearchResultEdge>
  snapshot?: Maybe<Snapshot>
}

/** Subscriptions */
export type SubscriptionOnContentCreatedArgs = {
  filter: SearchFilter
  urn: Scalars['URN']['input']
}

/** Subscriptions */
export type SubscriptionOnContentDeletedArgs = {
  filter: SearchFilter
  urn: Scalars['URN']['input']
}

/** Subscriptions */
export type SubscriptionOnContentUpdatedArgs = {
  filter: SearchFilter
  urn: Scalars['URN']['input']
}

/** Subscriptions */
export type SubscriptionSnapshotArgs = {
  urn: Scalars['URN']['input']
}

export type Team = Node & {
  __typename?: 'Team'
  /** Datetime when the team was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the team. */
  createdBy: Actor
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  organization: Organization
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  /** Get a list of all users in this team. */
  users: UsersConnection
}

export type TeamUsersArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<UsersFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

/** A Team edge */
export type TeamEdge = {
  __typename?: 'TeamEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The team at the end of edge. */
  node: Team
}

/** A Teams connection */
export type TeamsConnection = {
  __typename?: 'TeamsConnection'
  /** A list of node edges */
  edges: Array<TeamEdge>
  /** A list of nodes */
  nodes: Array<Team>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type TeamsFilter = {
  name?: InputMaybe<Scalars['String']['input']>
}

export type Text = Content & {
  __typename?: 'Text'
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the document. */
  createdBy: Actor
  folder?: Maybe<Folder>
  /** Id of the Google Docs document associated with this text */
  googleDocsId?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  language?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  organization: Organization
  prompt?: Maybe<TextPrompt>
  properties: ContentProperties
  status: PresentationStatus
  team?: Maybe<Team>
  textContent?: Maybe<Scalars['String']['output']>
  type?: Maybe<PresentationType>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  userTip?: Maybe<Scalars['String']['output']>
  viewer: ContentViewer
}

export enum TextHandling {
  Default = 'DEFAULT',
  Preserve = 'PRESERVE',
}

export type TextMutationResult = {
  __typename?: 'TextMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  text: Text
}

export type TextPrompt = {
  __typename?: 'TextPrompt'
  /** Text from the document for rewrite flows */
  textInput?: Maybe<Scalars['String']['output']>
  useCustomInstructions?: Maybe<Scalars['Boolean']['output']>
  userPrompt?: Maybe<Scalars['String']['output']>
}

export type Theme = Content & {
  __typename?: 'Theme'
  backgroundColor: Scalars['String']['output']
  bodyFont: Scalars['String']['output']
  colorFour: Scalars['String']['output']
  colorOne: Scalars['String']['output']
  colorThree: Scalars['String']['output']
  colorTwo: Scalars['String']['output']
  /** Datetime when the item was created */
  createdAt: Scalars['DateTime']['output']
  /** The Actor who created the theme. */
  createdBy: Actor
  folder?: Maybe<Folder>
  fontColor: Scalars['String']['output']
  googleId?: Maybe<Scalars['String']['output']>
  headerFont: Scalars['String']['output']
  id: Scalars['ID']['output']
  logo?: Maybe<Scalars['URL']['output']>
  name: Scalars['String']['output']
  organization: Organization
  properties: ContentProperties
  team?: Maybe<Team>
  thumbnail?: Maybe<Scalars['String']['output']>
  /** Datetime when the object was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
  useBackgroundImages?: Maybe<Scalars['Boolean']['output']>
  viewer: ContentViewer
}

export type ThemeMutationResult = MutationResult & {
  __typename?: 'ThemeMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  theme?: Maybe<Theme>
}

export type TrackingMutationResult = MutationResult & {
  __typename?: 'TrackingMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type UpdateContentPropertiesInput = {
  privacy: ContentPrivacy
}

export type UpdateDocumentInput = {
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  /** The updated page structure */
  pages?: InputMaybe<Array<PageStructureV1Input>>
}

export type UpdateFolderInput = {
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
}

export type UpdateOrganizationInput = {
  joinByDomain?: InputMaybe<Scalars['Boolean']['input']>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
}

export type UpdatePresentationInput = {
  context?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  numberOfSlides?: InputMaybe<Scalars['PositiveInt']['input']>
  preset: PresentationPreset
  prompt: Scalars['String']['input']
  richTextState?: InputMaybe<Scalars['String']['input']>
  slides?: InputMaybe<Array<PresentationSlideInput>>
  title?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  useCustomInstructions?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateSnapshotInput = {
  changeSensitivity?: InputMaybe<SnapshotChangeSensitivity>
  connectIdentity?: InputMaybe<Scalars['Boolean']['input']>
  description?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  instructions?: InputMaybe<SnapshotCaptureInstructionsInput>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  overlay?: InputMaybe<SnapshotOverlayOptionsInput>
  refreshRate?: InputMaybe<SnapshotRefreshRate>
  teamId?: InputMaybe<Scalars['ID']['input']>
}

export type UpdateThemeInput = {
  backgroundColor?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  bodyFont?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  colorFour?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  colorOne?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  colorThree?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  colorTwo?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  fontColor?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  googleId?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  headerFont?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  teamId?: InputMaybe<Scalars['ID']['input']>
  thumbnail?: InputMaybe<Scalars['StringWithMaxLengthOf1000']['input']>
  useBackgroundImages?: InputMaybe<Scalars['Boolean']['input']>
}

export type UpdateUserInput = {
  dismissUI?: InputMaybe<DismissableUi>
  name?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
}

export enum UpgradeSource {
  GoogleDocs = 'GOOGLE_DOCS',
  GoogleSlides = 'GOOGLE_SLIDES',
  Powerpoint = 'POWERPOINT',
  Webapp = 'WEBAPP',
  WebappTrial = 'WEBAPP_TRIAL',
}

export enum UploadContentType {
  ApplicationDoc = 'APPLICATION_DOC',
  ApplicationDocx = 'APPLICATION_DOCX',
  ApplicationEpub = 'APPLICATION_EPUB',
  ApplicationPdf = 'APPLICATION_PDF',
  ApplicationPpt = 'APPLICATION_PPT',
  ApplicationPptx = 'APPLICATION_PPTX',
  ApplicationXls = 'APPLICATION_XLS',
  ApplicationXlsx = 'APPLICATION_XLSX',
  ImageJpeg = 'IMAGE_JPEG',
  ImagePng = 'IMAGE_PNG',
  TextCsv = 'TEXT_CSV',
  TextHtml = 'TEXT_HTML',
  TextMarkdown = 'TEXT_MARKDOWN',
  TextPlain = 'TEXT_PLAIN',
  TextRtf = 'TEXT_RTF',
}

export enum UploadPurpose {
  DocumentThumbnail = 'DOCUMENT_THUMBNAIL',
  FileData = 'FILE_DATA',
  OrganizationLogo = 'ORGANIZATION_LOGO',
  PresentationQa = 'PRESENTATION_QA',
  ThemeLogo = 'THEME_LOGO',
  UserAvatar = 'USER_AVATAR',
}

export type Usage = {
  __typename?: 'Usage'
  presentations: Scalars['NonNegativeInt']['output']
  snapshots: Scalars['NonNegativeInt']['output']
}

export type User = Node & {
  __typename?: 'User'
  /** Datetime when the user was created */
  createdAt: Scalars['DateTime']['output']
  dismissedUi: DismissedUi
  email: Scalars['EmailAddress']['output']
  id: Scalars['ID']['output']
  image?: Maybe<Scalars['URL']['output']>
  name: Scalars['String']['output']
  /** Datetime when the user was last updated */
  updatedAt: Scalars['DateTime']['output']
  urn: Scalars['URN']['output']
}

export type UserImageArgs = {
  preset?: InputMaybe<ImageQualityPreset>
}

export enum UserActivity {
  CopyLinkClicked = 'COPY_LINK_CLICKED',
  CopyVersionTextClicked = 'COPY_VERSION_TEXT_CLICKED',
  ExtensionInstalled = 'EXTENSION_INSTALLED',
  GalleryViewed = 'GALLERY_VIEWED',
  GoogleDocsRefreshedAll = 'GOOGLE_DOCS_REFRESHED_ALL',
  GoogleDocsSidebarOpened = 'GOOGLE_DOCS_SIDEBAR_OPENED',
  GoogleDocsSnapshotAdded = 'GOOGLE_DOCS_SNAPSHOT_ADDED',
  GoogleSlidesAiSidebarOpened = 'GOOGLE_SLIDES_AI_SIDEBAR_OPENED',
  GoogleSlidesDialogOpened = 'GOOGLE_SLIDES_DIALOG_OPENED',
  GoogleSlidesPresentationReceived = 'GOOGLE_SLIDES_PRESENTATION_RECEIVED',
  GoogleSlidesPresentationRendered = 'GOOGLE_SLIDES_PRESENTATION_RENDERED',
  GoogleSlidesPresentationTimeout = 'GOOGLE_SLIDES_PRESENTATION_TIMEOUT',
  GoogleSlidesRefreshedAll = 'GOOGLE_SLIDES_REFRESHED_ALL',
  GoogleSlidesSidebarOpened = 'GOOGLE_SLIDES_SIDEBAR_OPENED',
  GoogleSlidesSlideGenerated = 'GOOGLE_SLIDES_SLIDE_GENERATED',
  GoogleSlidesSlideReceived = 'GOOGLE_SLIDES_SLIDE_RECEIVED',
  GoogleSlidesSlideRendered = 'GOOGLE_SLIDES_SLIDE_RENDERED',
  GoogleSlidesSnapshotAdded = 'GOOGLE_SLIDES_SNAPSHOT_ADDED',
  GoogleSlidesThemeApplied = 'GOOGLE_SLIDES_THEME_APPLIED',
  GoogleSlidesUpdatedAll = 'GOOGLE_SLIDES_UPDATED_ALL',
  GoogleSlidesUpgradeClicked = 'GOOGLE_SLIDES_UPGRADE_CLICKED',
  NewTabDisabled = 'NEW_TAB_DISABLED',
  NewTabEnabled = 'NEW_TAB_ENABLED',
  PlatformSelectedGoogleSlides = 'PLATFORM_SELECTED_GOOGLE_SLIDES',
  PlatformSelectedPowerpoint = 'PLATFORM_SELECTED_POWERPOINT',
  PowerpointAiSidebarOpened = 'POWERPOINT_AI_SIDEBAR_OPENED',
  PowerpointDialogOpened = 'POWERPOINT_DIALOG_OPENED',
  PowerpointPresentationReceived = 'POWERPOINT_PRESENTATION_RECEIVED',
  PowerpointPresentationRendered = 'POWERPOINT_PRESENTATION_RENDERED',
  PowerpointPresentationTimeout = 'POWERPOINT_PRESENTATION_TIMEOUT',
  PowerpointSidebarOpened = 'POWERPOINT_SIDEBAR_OPENED',
  PowerpointSlideGenerated = 'POWERPOINT_SLIDE_GENERATED',
  PowerpointSlideReceived = 'POWERPOINT_SLIDE_RECEIVED',
  PowerpointSlideRendered = 'POWERPOINT_SLIDE_RENDERED',
  PowerpointUpgradeClicked = 'POWERPOINT_UPGRADE_CLICKED',
  ShareModalOpened = 'SHARE_MODAL_OPENED',
  SnapshotLoggedBackIn = 'SNAPSHOT_LOGGED_BACK_IN',
  SnapshotRecaptured = 'SNAPSHOT_RECAPTURED',
}

export type UserActivityInput = {
  context?: InputMaybe<Scalars['URN']['input']>
  name: UserActivity
}

/** A User edge */
export type UserEdge = {
  __typename?: 'UserEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['ID']['output']
  /** The user at the end of edge. */
  node: User
}

export type UserMutationResult = MutationResult & {
  __typename?: 'UserMutationResult'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<User>
}

/** A Users connection */
export type UsersConnection = {
  __typename?: 'UsersConnection'
  /** A list of node edges */
  edges: Array<UserEdge>
  /** A list of nodes */
  nodes: Array<User>
  /** Information for pagination */
  pageInfo: PageInfo
  /** The total count of items in the connection. */
  totalCount: Scalars['Int']['output']
}

export type UsersFilter = {
  email?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
}

export type Viewer = {
  __typename?: 'Viewer'
  /** Available plans for user */
  availablePlans: Array<Plan>
  /** The viewer's current organization */
  currentOrganization?: Maybe<Organization>
  /** Email associated with the current actor */
  email: Scalars['String']['output']
  /** User hash for Front support widget */
  frontUserHash: Scalars['String']['output']
  /** Status of whether viewer can join organizations by their associated email */
  hasPublicEmailDomain: Scalars['Boolean']['output']
  /** Pending invitation to join organization */
  invitation?: Maybe<Invitation>
  /** All pending invitations to join organizations */
  invitations: Array<Invitation>
  /** The viewer's available organizations */
  organizations: Array<Maybe<Organization>>
  /** Organization settings */
  settings?: Maybe<Settings>
  /** Check the status of a users slack account (with respect to if it's linked to Plus) */
  slackAccountStatus: SlackAccountStatus
  /** The viewer's available teams */
  teams: TeamsConnection
  /** Organization usage */
  usage?: Maybe<Usage>
  /** The User associated with the viewer */
  user?: Maybe<User>
}

export type ViewerInvitationArgs = {
  id: Scalars['ID']['input']
  type?: InputMaybe<InvitationType>
}

export type ViewerSlackAccountStatusArgs = {
  nonce?: InputMaybe<Scalars['StringWithMaxLengthOf100']['input']>
  slackUserId?: InputMaybe<Scalars['ID']['input']>
}

export type ViewerTeamsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<TeamsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type _ExperimentalMutation = {
  __typename?: '_ExperimentalMutation'
  createFile: FileMutationResult
  createPresentation: PresentationMutationResult
  createPresentationFromText: PresentationMutationResult
  createSlide: SlideMutationResult
  createTheme: ThemeMutationResult
  deletePresentation: PresentationMutationResult
  deleteTheme: ThemeMutationResult
  generateText: GenerateTextMutationResult
  generateTheme: GenerateThemeMutationResult
  getSlideImage: SlideImageMutationResult
  markPresentationRendered: PresentationMutationResult
  regeneratePresentation: PresentationMutationResult
  remixSlide: SlideMutationResult
  rewritePresentation: RewritePresentationMutationResult
  rewriteSlide: RewriteSlideMutationResult
  rewriteText: RewriteTextMutationResult
  saveCustomInstructions: OrganizationMutationResult
  sendPresentationFeedback: PresentationMutationResult
  sendSlideFeedback: SlideMutationResult
  sendTextFeedback: TextMutationResult
  updatePresentation: PresentationMutationResult
  updateTheme: ThemeMutationResult
}

export type _ExperimentalMutationCreateFileArgs = {
  input: CreateFileInput
}

export type _ExperimentalMutationCreatePresentationArgs = {
  input: CreatePresentationInput
}

export type _ExperimentalMutationCreatePresentationFromTextArgs = {
  input: CreatePresentationFromTextInput
}

export type _ExperimentalMutationCreateSlideArgs = {
  input: CreateSlideInput
}

export type _ExperimentalMutationCreateThemeArgs = {
  input: CreateThemeInput
}

export type _ExperimentalMutationDeletePresentationArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalMutationDeleteThemeArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalMutationGenerateTextArgs = {
  input: GenerateTextInput
}

export type _ExperimentalMutationGenerateThemeArgs = {
  input: GenerateThemeInput
}

export type _ExperimentalMutationGetSlideImageArgs = {
  input: SlideImageInput
}

export type _ExperimentalMutationMarkPresentationRenderedArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalMutationRegeneratePresentationArgs = {
  id: Scalars['ID']['input']
  input: CreatePresentationInput
}

export type _ExperimentalMutationRemixSlideArgs = {
  input: RemixSlideInput
}

export type _ExperimentalMutationRewritePresentationArgs = {
  input: RewritePresentationInput
}

export type _ExperimentalMutationRewriteSlideArgs = {
  input: RewriteSlideInput
}

export type _ExperimentalMutationRewriteTextArgs = {
  input: RewriteTextInput
}

export type _ExperimentalMutationSaveCustomInstructionsArgs = {
  input: CustomInstructionsInput
}

export type _ExperimentalMutationSendPresentationFeedbackArgs = {
  id: Scalars['ID']['input']
  input: FeedbackInput
}

export type _ExperimentalMutationSendSlideFeedbackArgs = {
  id: Scalars['ID']['input']
  input: FeedbackInput
}

export type _ExperimentalMutationSendTextFeedbackArgs = {
  id: Scalars['ID']['input']
  input: FeedbackInput
}

export type _ExperimentalMutationUpdatePresentationArgs = {
  id: Scalars['ID']['input']
  input: UpdatePresentationInput
}

export type _ExperimentalMutationUpdateThemeArgs = {
  id: Scalars['ID']['input']
  input: UpdateThemeInput
}

export type _ExperimentalQuery = {
  __typename?: '_ExperimentalQuery'
  pptDownloadUrl: Scalars['String']['output']
  presentation?: Maybe<Presentation>
  presentationGoogleSlidesId: Scalars['String']['output']
  presentations: PresentationsConnection
  slide?: Maybe<Slide>
  text?: Maybe<Text>
  theme?: Maybe<Theme>
  themes: Array<Theme>
}

export type _ExperimentalQueryPptDownloadUrlArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalQueryPresentationArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalQueryPresentationGoogleSlidesIdArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalQueryPresentationsArgs = {
  after?: InputMaybe<Scalars['ID']['input']>
  filter?: InputMaybe<PresentationsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
}

export type _ExperimentalQuerySlideArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalQueryTextArgs = {
  id: Scalars['ID']['input']
}

export type _ExperimentalQueryThemeArgs = {
  id: Scalars['ID']['input']
}

export type CreateSnapMutationVariables = Exact<{
  input: CreateSnapshotInput
}>

export type CreateSnapMutation = {
  __typename?: 'Mutation'
  createSnapshot: {
    __typename?: 'CreateSnapshotMutationResult'
    code: string
    success: boolean
    message: string
    browserDataUploadUrl?: string | null
    snapshot?: {
      __typename?: 'Snapshot'
      id: string
      urn: string
      name: string
      refreshRate: SnapshotRefreshRate
      organization: { __typename?: 'Organization'; id: string; slug: string }
    } | null
  }
}

export type UpdateSnapshotBrowserDataUrlMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UpdateSnapshotBrowserDataUrlMutation = {
  __typename?: 'Mutation'
  updateSnapshotBrowserDataUrl: string
}

export type RepairSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateSnapshotInput
}>

export type RepairSnapshotMutation = {
  __typename?: 'Mutation'
  updateSnapshotBrowserDataUrl: string
  updateSnapshot: {
    __typename?: 'SnapshotMutationResult'
    success: boolean
    message: string
    snapshot?: {
      __typename?: 'Snapshot'
      id: string
      urn: string
      name: string
      refreshRate: SnapshotRefreshRate
      organization: { __typename?: 'Organization'; id: string; slug: string }
    } | null
  }
}

export type IdentitiesForDomainQueryVariables = Exact<{
  domain: Scalars['FQDN']['input']
}>

export type IdentitiesForDomainQuery = {
  __typename?: 'Query'
  identities: {
    __typename?: 'IdentitiesConnection'
    nodes: Array<{ __typename?: 'Identity'; id: string; status: IdentityStatus; updatedAt: string }>
  }
}

export type UpdateIdentityDataMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UpdateIdentityDataMutation = { __typename?: 'Mutation'; updateIdentityData: string }

export type DomainSettingsQueryVariables = Exact<{
  domain: Scalars['FQDN']['input']
}>

export type DomainSettingsQuery = {
  __typename?: 'Query'
  result: {
    __typename?: 'DomainSettings'
    backgroundRefresh: boolean
    blocked: boolean
    storage: Array<string>
    ebc: Array<{
      __typename?: 'EbcRule'
      id: string
      pattern?: string | null
      selector: string
      description: string
      captureSelector?: string | null
      captureVariables?: Array<string> | null
    }>
    logout: Array<{ __typename?: 'LogoutRule'; pattern: string; statuses: Array<number | null> }>
  }
}

export type CreateFileMutationVariables = Exact<{
  input: CreateFileInput
}>

export type CreateFileMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    createFile: {
      __typename?: 'FileMutationResult'
      success: boolean
      file?: { __typename?: 'File'; id: string; name: string } | null
    }
  }
}

export type UploadFileDataMutationVariables = Exact<{
  id: Scalars['ID']['input']
  contentType: UploadContentType
}>

export type UploadFileDataMutation = { __typename?: 'Mutation'; createUpload: string }

export type UploadPresentationDataMutationVariables = Exact<{
  id: Scalars['ID']['input']
  contentType: UploadContentType
}>

export type UploadPresentationDataMutation = { __typename?: 'Mutation'; createUpload: string }

export type FileFieldsFragment = { __typename?: 'File'; id: string; name: string }

export type CreateOrgMutationVariables = Exact<{
  input: CreateOrganizationInput
}>

export type CreateOrgMutation = {
  __typename?: 'Mutation'
  createOrganization: {
    __typename?: 'CreateOrganizationMutationResult'
    code: string
    message: string
    organization?: { __typename?: 'Organization'; id: string; slug: string } | null
  }
}

export type UpdateOrgMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateOrganizationInput
}>

export type UpdateOrgMutation = {
  __typename?: 'Mutation'
  updateOrganization: {
    __typename?: 'OrganizationMutationResult'
    code: string
    message: string
    organization?: {
      __typename: 'Organization'
      id: string
      name: string
      joinByDomain: boolean
    } | null
  }
}

export type DeleteOrgUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOrgUserMutation = {
  __typename?: 'Mutation'
  result: { __typename: 'UserMutationResult'; code: string; message: string }
}

export type CreatePptCheckoutLinkMutationVariables = Exact<{
  priceId: Scalars['ID']['input']
  upgradeSource?: InputMaybe<UpgradeSource>
}>

export type CreatePptCheckoutLinkMutation = { __typename?: 'Mutation'; createCheckoutLink: string }

export type CreatePaymentPortalLinkMutationVariables = Exact<{ [key: string]: never }>

export type CreatePaymentPortalLinkMutation = {
  __typename?: 'Mutation'
  createPaymentPortalLink: string
}

export type CreateOutlineMutationVariables = Exact<{
  input: CreatePresentationInput
}>

export type CreateOutlineMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    createPresentation: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type CreatePresentationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: CreatePresentationInput
}>

export type CreatePresentationMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    regeneratePresentation: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type RewriteFullPresentationMutationVariables = Exact<{
  input: RewritePresentationInput
}>

export type RewriteFullPresentationMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    rewritePresentation: {
      __typename?: 'RewritePresentationMutationResult'
      success: boolean
      content?: Array<
        Array<{ __typename?: 'SlideTextSection'; type: SlideTextType; content: string }>
      > | null
    }
  }
}

export type UpdatePresentationMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdatePresentationInput
}>

export type UpdatePresentationMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    updatePresentation: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type SendPresentationFeedbackMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: FeedbackInput
}>

export type SendPresentationFeedbackMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    sendPresentationFeedback: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type MarkPresentationRenderedMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type MarkPresentationRenderedMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    markPresentationRendered: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type DeletePresentationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeletePresentationMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    deletePresentation: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type CreatePresentationFromTextMutationVariables = Exact<{
  input: CreatePresentationFromTextInput
}>

export type CreatePresentationFromTextMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    createPresentationFromText: {
      __typename?: 'PresentationMutationResult'
      code: string
      presentation?: { __typename?: 'Presentation'; id: string; status: PresentationStatus } | null
    }
  }
}

export type GetPresentationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type GetPresentationsQuery = {
  __typename?: 'Query'
  _experimental: {
    __typename?: '_ExperimentalQuery'
    presentations: {
      __typename?: 'PresentationsConnection'
      totalCount: number
      edges: Array<{
        __typename?: 'PresentationEdge'
        cursor: string
        node: {
          __typename: 'Presentation'
          id: string
          name: string
          status: PresentationStatus
          preset: PresentationPreset
          createdAt: string
          type?: PresentationType | null
          googleSlidesId?: string | null
          prompt?: {
            __typename?: 'PresentationPrompt'
            context?: string | null
            numberOfSlides?: number | null
            richTextState?: string | null
            text?: string | null
            useCustomInstructions?: boolean | null
            slides?: Array<{
              __typename?: 'SlidePrompt'
              cmsSlideSlug?: string | null
              slideType?: PresentationSlideType | null
              title: string
              content: string
            }> | null
          } | null
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
  }
}

export type GetBlueprintPresentationsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type GetBlueprintPresentationsQuery = {
  __typename?: 'Query'
  _experimental: {
    __typename?: '_ExperimentalQuery'
    presentations: {
      __typename?: 'PresentationsConnection'
      totalCount: number
      edges: Array<{
        __typename?: 'PresentationEdge'
        cursor: string
        node: {
          __typename: 'Presentation'
          id: string
          name: string
          status: PresentationStatus
          preset: PresentationPreset
          createdAt: string
          type?: PresentationType | null
          googleSlidesId?: string | null
          prompt?: {
            __typename?: 'PresentationPrompt'
            context?: string | null
            numberOfSlides?: number | null
            richTextState?: string | null
            text?: string | null
            useCustomInstructions?: boolean | null
            slides?: Array<{
              __typename?: 'SlidePrompt'
              cmsSlideSlug?: string | null
              slideType?: PresentationSlideType | null
              title: string
              content: string
            }> | null
          } | null
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
  }
}

export type GetPresentationQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPresentationQuery = {
  __typename?: 'Query'
  _experimental: {
    __typename?: '_ExperimentalQuery'
    presentation?: {
      __typename?: 'Presentation'
      id: string
      name: string
      status: PresentationStatus
      preset: PresentationPreset
      slides: Array<
        | {
            __typename: 'SlideAgendaSimple'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideChartSingle'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            chart: {
              __typename?: 'SlideSimpleChartItem'
              title?: string | null
              callout?: string | null
            }
          }
        | {
            __typename: 'SlideChartText'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            header?: string | null
            bulletPoints: Array<string>
            chart: {
              __typename?: 'SlideSimpleChartItem'
              title?: string | null
              callout?: string | null
            }
          }
        | {
            __typename: 'SlideImage2Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideImageSectionItem'
              detail: string
              header: string
              imageSuggestion: string
              image: {
                __typename?: 'ImageSuggestion'
                landscape?: string | null
                portrait?: string | null
                square?: string | null
              }
            }>
          }
        | {
            __typename: 'SlideImage3Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideImageSectionItem'
              detail: string
              header: string
              imageSuggestion: string
              image: {
                __typename?: 'ImageSuggestion'
                landscape?: string | null
                portrait?: string | null
                square?: string | null
              }
            }>
          }
        | {
            __typename: 'SlideMetricsSimple'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            metrics: Array<{ __typename?: 'SlideSimpleMetricItem'; label: string; value: string }>
          }
        | {
            __typename: 'SlideProcess4Step'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            processSteps: Array<{
              __typename?: 'SlideProcessStepSectionItem'
              deliverable: string
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideTeam'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            teamMembers: Array<{
              __typename?: 'SlideTeamMemberSectionItem'
              background: string
              name: string
              title: string
            }>
          }
        | {
            __typename: 'SlideText1Column'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText1Paragraph'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
          }
        | {
            __typename: 'SlideText2Columns'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            columns: Array<{
              __typename?: 'SlideSimpleColumnItem'
              header?: string | null
              bulletPoints: Array<string>
            }>
          }
        | {
            __typename: 'SlideText2ColumnsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            columns: Array<{
              __typename?: 'SlideIconColumnItem'
              header?: string | null
              bulletPoints: Array<string>
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText2Quotes'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideQuoteSectionItem'
              detail: string
              source: string
            }>
          }
        | {
            __typename: 'SlideText3Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText3BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText3Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText3SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText4Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText4BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText4Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText4SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText6Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText6BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText6Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText6SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideTextImage'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
            imageSuggestion: string
            header?: string | null
            image: {
              __typename?: 'ImageSuggestion'
              landscape?: string | null
              portrait?: string | null
              square?: string | null
            }
          }
        | {
            __typename: 'SlideTextProCon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            conHeader: string
            conBulletPoints: Array<string>
            proHeader: string
            proBulletPoints: Array<string>
          }
        | {
            __typename: 'SlideTextStatement'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            statement: string
          }
        | {
            __typename: 'SlideVennDiagram'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            groupAHeader: string
            groupACharacteristics: Array<string>
            groupBHeader: string
            groupAAndBCharacteristics: Array<string>
            groupBCharacteristics: Array<string>
          }
      >
    } | null
  }
}

export type SlidePromptDataFragment = {
  __typename?: 'SlidePrompt'
  cmsSlideSlug?: string | null
  slideType?: PresentationSlideType | null
  title: string
  content: string
}

export type SaveCustomInstructionsMutationVariables = Exact<{
  input: CustomInstructionsInput
}>

export type SaveCustomInstructionsMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    saveCustomInstructions: {
      __typename?: 'OrganizationMutationResult'
      code: string
      message: string
      organization?: { __typename?: 'Organization'; id: string; slug: string } | null
    }
  }
}

export type RewriteSingleSlideMutationVariables = Exact<{
  input: RewriteSlideInput
}>

export type RewriteSingleSlideMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    rewriteSlide: {
      __typename?: 'RewriteSlideMutationResult'
      success: boolean
      content?: Array<{
        __typename?: 'SlideTextSection'
        type: SlideTextType
        content: string
      }> | null
    }
  }
}

export type CreateSingleSlideMutationVariables = Exact<{
  input: CreateSlideInput
}>

export type CreateSingleSlideMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    createSlide: {
      __typename?: 'SlideMutationResult'
      code: string
      slide?: { __typename?: 'Slide'; id: string; status: PresentationStatus } | null
    }
  }
}

export type RemixSingleSlideMutationVariables = Exact<{
  input: RemixSlideInput
}>

export type RemixSingleSlideMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    remixSlide: {
      __typename?: 'SlideMutationResult'
      code: string
      slide?: { __typename?: 'Slide'; id: string; status: PresentationStatus } | null
    }
  }
}

export type GetSlideImageMutationVariables = Exact<{
  input: SlideImageInput
}>

export type GetSlideImageMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    getSlideImage: {
      __typename?: 'SlideImageMutationResult'
      code: string
      image?: { __typename?: 'SlideImage'; url: string } | null
    }
  }
}

export type SendSlideFeedbackMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: FeedbackInput
}>

export type SendSlideFeedbackMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    sendSlideFeedback: {
      __typename?: 'SlideMutationResult'
      code: string
      slide?: { __typename?: 'Slide'; id: string; status: PresentationStatus } | null
    }
  }
}

export type GetSlideQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetSlideQuery = {
  __typename?: 'Query'
  _experimental: {
    __typename?: '_ExperimentalQuery'
    slide?: {
      __typename?: 'Slide'
      id: string
      name: string
      status: PresentationStatus
      slideContent?:
        | {
            __typename: 'SlideAgendaSimple'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideChartSingle'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            chart: {
              __typename?: 'SlideSimpleChartItem'
              title?: string | null
              callout?: string | null
            }
          }
        | {
            __typename: 'SlideChartText'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            header?: string | null
            bulletPoints: Array<string>
            chart: {
              __typename?: 'SlideSimpleChartItem'
              title?: string | null
              callout?: string | null
            }
          }
        | {
            __typename: 'SlideImage2Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideImageSectionItem'
              detail: string
              header: string
              imageSuggestion: string
              image: {
                __typename?: 'ImageSuggestion'
                landscape?: string | null
                portrait?: string | null
                square?: string | null
              }
            }>
          }
        | {
            __typename: 'SlideImage3Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideImageSectionItem'
              detail: string
              header: string
              imageSuggestion: string
              image: {
                __typename?: 'ImageSuggestion'
                landscape?: string | null
                portrait?: string | null
                square?: string | null
              }
            }>
          }
        | {
            __typename: 'SlideMetricsSimple'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            metrics: Array<{ __typename?: 'SlideSimpleMetricItem'; label: string; value: string }>
          }
        | {
            __typename: 'SlideProcess4Step'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            processSteps: Array<{
              __typename?: 'SlideProcessStepSectionItem'
              deliverable: string
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideTeam'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            teamMembers: Array<{
              __typename?: 'SlideTeamMemberSectionItem'
              background: string
              name: string
              title: string
            }>
          }
        | {
            __typename: 'SlideText1Column'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText1Paragraph'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
          }
        | {
            __typename: 'SlideText2Columns'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            columns: Array<{
              __typename?: 'SlideSimpleColumnItem'
              header?: string | null
              bulletPoints: Array<string>
            }>
          }
        | {
            __typename: 'SlideText2ColumnsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            columns: Array<{
              __typename?: 'SlideIconColumnItem'
              header?: string | null
              bulletPoints: Array<string>
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText2Quotes'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideQuoteSectionItem'
              detail: string
              source: string
            }>
          }
        | {
            __typename: 'SlideText3Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText3BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText3Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText3SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText4Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText4BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText4Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText4SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText6Bullets'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
          }
        | {
            __typename: 'SlideText6BulletsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletSections: Array<{
              __typename?: 'SlideIconBulletItem'
              bulletPoint: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideText6Sections'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideSimpleSectionItem'
              detail: string
              header: string
            }>
          }
        | {
            __typename: 'SlideText6SectionsIcon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            sections: Array<{
              __typename?: 'SlideIconSectionItem'
              detail: string
              header: string
              iconUrl: string
            }>
          }
        | {
            __typename: 'SlideTextImage'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            bulletPoints: Array<string>
            imageSuggestion: string
            header?: string | null
            image: {
              __typename?: 'ImageSuggestion'
              landscape?: string | null
              portrait?: string | null
              square?: string | null
            }
          }
        | {
            __typename: 'SlideTextProCon'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            conHeader: string
            conBulletPoints: Array<string>
            proHeader: string
            proBulletPoints: Array<string>
          }
        | {
            __typename: 'SlideTextStatement'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            detail: string
            statement: string
          }
        | {
            __typename: 'SlideVennDiagram'
            chapterTitle?: string | null
            description?: string | null
            title?: string | null
            userTip?: string | null
            groupAHeader: string
            groupACharacteristics: Array<string>
            groupBHeader: string
            groupAAndBCharacteristics: Array<string>
            groupBCharacteristics: Array<string>
          }
        | null
    } | null
  }
}

export type TrackUserActivityMutationVariables = Exact<{
  activity: UserActivityInput
}>

export type TrackUserActivityMutation = {
  __typename?: 'Mutation'
  trackUserActivity: { __typename?: 'TrackingMutationResult'; success: boolean }
}

export type SendInviteMutationVariables = Exact<{
  input: SendInvitationInput
}>

export type SendInviteMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'SendInvitationMutationResult'; code: string; message: string }
}

export type DeleteOrgInviteMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteOrgInviteMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'DeleteInvitationMutationResult'; code: string; message: string }
}

export type PptDownloadUrlQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PptDownloadUrlQuery = {
  __typename?: 'Query'
  _experimental: { __typename?: '_ExperimentalQuery'; pptDownloadUrl: string }
}

export type IdentitiesQueryVariables = Exact<{
  filter?: InputMaybe<IdentitiesFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type IdentitiesQuery = {
  __typename?: 'Query'
  identities: {
    __typename?: 'IdentitiesConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'IdentityEdge'
      cursor: string
      node: {
        __typename: 'Identity'
        connectionStatus: ConnectionStatus
        current: boolean
        domain: string
        id: string
        status: IdentityStatus
        updatedAt: string
        snapshots: {
          __typename: 'SnapshotsConnection'
          totalCount: number
          edges: Array<{
            __typename?: 'SnapshotEdge'
            cursor: string
            node: {
              __typename: 'Snapshot'
              id: string
              name: string
              createdAt: string
              updatedAt: string
              changeSensitivity: SnapshotChangeSensitivity
              description?: string | null
              refreshRate: SnapshotRefreshRate
              suggestedRepairType?: RepairType | null
              status: SnapshotStatus
              urn: string
              createdBy: {
                __typename?: 'User'
                id: string
                image?: string | null
                name: string
                email: string
              }
              folder?: { __typename?: 'Folder'; id: string; name: string } | null
              properties: {
                __typename?: 'ContentProperties'
                accessCode?: string | null
                privacy: ContentPrivacy
              }
              identity?: {
                __typename?: 'Identity'
                id: string
                connectionStatus: ConnectionStatus
                current: boolean
                domain: string
                status: IdentityStatus
              } | null
              instructions: {
                __typename?: 'SnapshotCaptureInstructions'
                url: string
                frame: {
                  __typename?: 'SnapshotCaptureFrame'
                  frameX: number
                  frameY: number
                  frameWidth: number
                  frameHeight: number
                  windowWidth: number
                  windowHeight: number
                }
              }
              currentVersion?: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              } | null
              latestVersion?: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              } | null
              team?: { __typename?: 'Team'; id: string } | null
              organization: {
                __typename?: 'Organization'
                id: string
                slug: string
                urn: string
                createdAt: string
              }
              versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
              allVersions: {
                __typename?: 'SnapshotVersionsConnection'
                totalCount: number
                edges: Array<{
                  __typename?: 'SnapshotVersionEdge'
                  cursor: string
                  node: {
                    __typename?: 'SnapshotVersion'
                    id: string
                    urn: string
                    image?: string | null
                    extractedText?: string | null
                    createdAt: string
                    status: CaptureStatus
                  }
                }>
                pageInfo: {
                  __typename?: 'PageInfo'
                  endCursor?: string | null
                  hasNextPage: boolean
                  hasPreviousPage: boolean
                  startCursor?: string | null
                }
              }
              viewer: {
                __typename?: 'ContentViewer'
                canDelete: boolean
                canEdit: boolean
                isAuthor: boolean
                isOwner: boolean
              }
            }
          }>
          pageInfo: {
            __typename?: 'PageInfo'
            endCursor?: string | null
            hasNextPage: boolean
            hasPreviousPage: boolean
            startCursor?: string | null
          }
        }
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
}

export type IdentityQueryVariables = Exact<{
  id: Scalars['ID']['input']
  filter?: InputMaybe<SnapshotsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type IdentityQuery = {
  __typename?: 'Query'
  identity?: {
    __typename: 'Identity'
    id: string
    connectionStatus: ConnectionStatus
    current: boolean
    domain: string
    status: IdentityStatus
    updatedAt: string
    snapshots: {
      __typename?: 'SnapshotsConnection'
      totalCount: number
      edges: Array<{
        __typename?: 'SnapshotEdge'
        cursor: string
        node: {
          __typename: 'Snapshot'
          id: string
          name: string
          createdAt: string
          updatedAt: string
          changeSensitivity: SnapshotChangeSensitivity
          description?: string | null
          refreshRate: SnapshotRefreshRate
          suggestedRepairType?: RepairType | null
          status: SnapshotStatus
          urn: string
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
          identity?: {
            __typename?: 'Identity'
            id: string
            connectionStatus: ConnectionStatus
            current: boolean
            domain: string
            status: IdentityStatus
          } | null
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: {
              __typename?: 'SnapshotCaptureFrame'
              frameX: number
              frameY: number
              frameWidth: number
              frameHeight: number
              windowWidth: number
              windowHeight: number
            }
          }
          currentVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          latestVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          team?: { __typename?: 'Team'; id: string } | null
          organization: {
            __typename?: 'Organization'
            id: string
            slug: string
            urn: string
            createdAt: string
          }
          versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
          allVersions: {
            __typename?: 'SnapshotVersionsConnection'
            totalCount: number
            edges: Array<{
              __typename?: 'SnapshotVersionEdge'
              cursor: string
              node: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              }
            }>
            pageInfo: {
              __typename?: 'PageInfo'
              endCursor?: string | null
              hasNextPage: boolean
              hasPreviousPage: boolean
              startCursor?: string | null
            }
          }
          viewer: {
            __typename?: 'ContentViewer'
            canDelete: boolean
            canEdit: boolean
            isAuthor: boolean
            isOwner: boolean
          }
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
  } | null
}

export type SnapshotQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SnapshotQuery = {
  __typename?: 'Query'
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    name: string
    createdAt: string
    updatedAt: string
    changeSensitivity: SnapshotChangeSensitivity
    description?: string | null
    refreshRate: SnapshotRefreshRate
    suggestedRepairType?: RepairType | null
    status: SnapshotStatus
    urn: string
    createdBy: {
      __typename?: 'User'
      id: string
      image?: string | null
      name: string
      email: string
    }
    folder?: { __typename?: 'Folder'; id: string; name: string } | null
    properties: {
      __typename?: 'ContentProperties'
      accessCode?: string | null
      privacy: ContentPrivacy
    }
    identity?: {
      __typename?: 'Identity'
      id: string
      connectionStatus: ConnectionStatus
      current: boolean
      domain: string
      status: IdentityStatus
    } | null
    instructions: {
      __typename?: 'SnapshotCaptureInstructions'
      url: string
      frame: {
        __typename?: 'SnapshotCaptureFrame'
        frameX: number
        frameY: number
        frameWidth: number
        frameHeight: number
        windowWidth: number
        windowHeight: number
      }
    }
    currentVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    latestVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    team?: { __typename?: 'Team'; id: string } | null
    organization: {
      __typename?: 'Organization'
      id: string
      slug: string
      urn: string
      createdAt: string
    }
    versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
    allVersions: {
      __typename?: 'SnapshotVersionsConnection'
      totalCount: number
      edges: Array<{
        __typename?: 'SnapshotVersionEdge'
        cursor: string
        node: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
    viewer: {
      __typename?: 'ContentViewer'
      canDelete: boolean
      canEdit: boolean
      isAuthor: boolean
      isOwner: boolean
    }
  } | null
}

export type OnSnapshotUpdateSubscriptionVariables = Exact<{
  urn: Scalars['URN']['input']
}>

export type OnSnapshotUpdateSubscription = {
  __typename?: 'Subscription'
  snapshot?: {
    __typename: 'Snapshot'
    id: string
    name: string
    createdAt: string
    updatedAt: string
    changeSensitivity: SnapshotChangeSensitivity
    description?: string | null
    refreshRate: SnapshotRefreshRate
    suggestedRepairType?: RepairType | null
    status: SnapshotStatus
    urn: string
    createdBy: {
      __typename?: 'User'
      id: string
      image?: string | null
      name: string
      email: string
    }
    folder?: { __typename?: 'Folder'; id: string; name: string } | null
    properties: {
      __typename?: 'ContentProperties'
      accessCode?: string | null
      privacy: ContentPrivacy
    }
    identity?: {
      __typename?: 'Identity'
      id: string
      connectionStatus: ConnectionStatus
      current: boolean
      domain: string
      status: IdentityStatus
    } | null
    instructions: {
      __typename?: 'SnapshotCaptureInstructions'
      url: string
      frame: {
        __typename?: 'SnapshotCaptureFrame'
        frameX: number
        frameY: number
        frameWidth: number
        frameHeight: number
        windowWidth: number
        windowHeight: number
      }
    }
    currentVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    latestVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    team?: { __typename?: 'Team'; id: string } | null
    organization: {
      __typename?: 'Organization'
      id: string
      slug: string
      urn: string
      createdAt: string
    }
    versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
    allVersions: {
      __typename?: 'SnapshotVersionsConnection'
      totalCount: number
      edges: Array<{
        __typename?: 'SnapshotVersionEdge'
        cursor: string
        node: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
    viewer: {
      __typename?: 'ContentViewer'
      canDelete: boolean
      canEdit: boolean
      isAuthor: boolean
      isOwner: boolean
    }
  } | null
}

export type SnapshotStatusQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SnapshotStatusQuery = {
  __typename?: 'Query'
  snapshot?: {
    __typename?: 'Snapshot'
    id: string
    status: SnapshotStatus
    currentVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
  } | null
}

export type SnapshotCurrentVersionQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SnapshotCurrentVersionQuery = {
  __typename?: 'Query'
  snapshot?: {
    __typename?: 'Snapshot'
    id: string
    currentVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
  } | null
}

export type SnapshotVersionBeforeIdQueryVariables = Exact<{
  snapshotId: Scalars['ID']['input']
  versionId: Scalars['ID']['input']
}>

export type SnapshotVersionBeforeIdQuery = {
  __typename?: 'Query'
  snapshot?: {
    __typename?: 'Snapshot'
    id: string
    versions: {
      __typename?: 'SnapshotVersionsConnection'
      edges: Array<{
        __typename?: 'SnapshotVersionEdge'
        cursor: string
        node: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        }
      }>
    }
  } | null
}

export type PublicSnapshotDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PublicSnapshotDataQuery = {
  __typename?: 'Query'
  publicSnapshot?: {
    __typename?: 'PublicSnapshotQueryResult'
    snapshot?: {
      __typename: 'PublicSnapshot'
      id: string
      name: string
      description?: string | null
      refreshRate: SnapshotRefreshRate
      urn: string
      status: SnapshotStatus
      overlay: {
        __typename?: 'SnapshotOverlayOptions'
        title: boolean
        timestamp: boolean
        schedule: boolean
        domain: boolean
      }
      instructions: {
        __typename?: 'SnapshotCaptureInstructions'
        url: string
        frame: { __typename?: 'SnapshotCaptureFrame'; frameHeight: number; frameWidth: number }
      }
      currentVersion?: {
        __typename?: 'PublicSnapshotVersion'
        id: string
        urn: string
        image?: string | null
        createdAt: string
      } | null
      latestVersion?: {
        __typename?: 'PublicSnapshotVersion'
        id: string
        urn: string
        image?: string | null
        createdAt: string
      } | null
      versions: { __typename?: 'PublicSnapshotVersionsConnection'; totalCount: number }
      allVersions: {
        __typename?: 'PublicSnapshotVersionsConnection'
        totalCount: number
        edges: Array<{
          __typename?: 'PublicSnapshotVersionEdge'
          cursor: string
          node: {
            __typename?: 'PublicSnapshotVersion'
            id: string
            urn: string
            image?: string | null
            createdAt: string
          }
        }>
        pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      }
      viewer: {
        __typename?: 'ContentViewer'
        canDelete: boolean
        canEdit: boolean
        isAuthor: boolean
        isOwner: boolean
      }
    } | null
  } | null
}

export type SnapshotVersionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
}>

export type SnapshotVersionsQuery = {
  __typename?: 'Query'
  snapshot?: {
    __typename?: 'Snapshot'
    id: string
    status: SnapshotStatus
    suggestedRepairType?: RepairType | null
    currentVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    latestVersion?: {
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    } | null
    allVersions: {
      __typename?: 'SnapshotVersionsConnection'
      edges: Array<{
        __typename?: 'SnapshotVersionEdge'
        cursor: string
        node: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        }
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        endCursor?: string | null
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
      }
    }
  } | null
}

export type PublicSnapshotVersionsQueryVariables = Exact<{
  id: Scalars['ID']['input']
  after?: InputMaybe<Scalars['ID']['input']>
  before?: InputMaybe<Scalars['ID']['input']>
}>

export type PublicSnapshotVersionsQuery = {
  __typename?: 'Query'
  publicSnapshot?: {
    __typename?: 'PublicSnapshotQueryResult'
    snapshot?: {
      __typename?: 'PublicSnapshot'
      id: string
      currentVersion?: {
        __typename?: 'PublicSnapshotVersion'
        id: string
        image?: string | null
        createdAt: string
      } | null
      allVersions: {
        __typename?: 'PublicSnapshotVersionsConnection'
        edges: Array<{
          __typename?: 'PublicSnapshotVersionEdge'
          cursor: string
          node: {
            __typename?: 'PublicSnapshotVersion'
            id: string
            urn: string
            image?: string | null
            createdAt: string
          }
        }>
        pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      }
    } | null
  } | null
}

export type TrackContentViewsMutationVariables = Exact<{
  views: Array<ContentViewInput> | ContentViewInput
}>

export type TrackContentViewsMutation = {
  __typename?: 'Mutation'
  trackContentViews: { __typename?: 'TrackingMutationResult'; success: boolean }
}

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteDocumentMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'DocumentMutationResult'; code: string; message: string }
}

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteFolderMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'FolderMutationResult'; code: string; message: string }
}

export type DeleteSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteSnapshotMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'SnapshotMutationResult'; code: string; message: string }
}

export type MoveToFolderMutationVariables = Exact<{
  id: Scalars['ID']['input']
  folderId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveToFolderMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'ContentMutationResult'; code: string; message: string }
}

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateDocumentInput
}>

export type UpdateDocumentMutation = {
  __typename?: 'Mutation'
  result: {
    __typename?: 'DocumentMutationResult'
    code: string
    message: string
    document?: { __typename: 'Document'; id: string; name: string } | null
  }
}

export type UpdateFolderMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateFolderInput
}>

export type UpdateFolderMutation = {
  __typename?: 'Mutation'
  result: {
    __typename?: 'FolderMutationResult'
    code: string
    message: string
    folder?: { __typename: 'Folder'; id: string; name: string } | null
  }
}

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInput
}>

export type CreateFolderMutation = {
  __typename?: 'Mutation'
  result: {
    __typename?: 'FolderMutationResult'
    code: string
    message: string
    folder?: { __typename: 'Folder'; id: string; name: string } | null
  }
}

export type ReferralsCreateMutationVariables = Exact<{
  recipients: Array<Scalars['EmailAddress']['input']> | Scalars['EmailAddress']['input']
}>

export type ReferralsCreateMutation = {
  __typename?: 'Mutation'
  createReferrals: {
    __typename?: 'CreateReferralsMutationResult'
    code: string
    success: boolean
    message: string
    referrals: Array<{ __typename?: 'Referral'; id: string; recipient: string }>
  }
}

export type SnapshotContentAccessQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type SnapshotContentAccessQuery = {
  __typename?: 'Query'
  publicSnapshot?: {
    __typename?: 'PublicSnapshotQueryResult'
    code: ContentAuthorizationCode
    message: string
  } | null
}

export type DocumentContentAccessQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DocumentContentAccessQuery = {
  __typename?: 'Query'
  publicDocument?: {
    __typename?: 'PublicDocumentQueryResult'
    code: ContentAuthorizationCode
    message: string
  } | null
}

export type InOrgSnapshotContentAccessQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type InOrgSnapshotContentAccessQuery = {
  __typename?: 'Query'
  snapshot?: { __typename?: 'Snapshot'; id: string } | null
}

export type InOrgDocumentContentAccessQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type InOrgDocumentContentAccessQuery = {
  __typename?: 'Query'
  document?: { __typename?: 'Document'; id: string } | null
}

export type DeclineInvitationMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeclineInvitationMutation = {
  __typename?: 'Mutation'
  result: { __typename?: 'DeleteInvitationMutationResult'; code: string; message: string }
}

export type DisconnectFromSlackMutationVariables = Exact<{ [key: string]: never }>

export type DisconnectFromSlackMutation = {
  __typename?: 'Mutation'
  disconnectSlackAccount?: {
    __typename?: 'DisconnectSlackAccountMutationResult'
    success: boolean
    message: string
  } | null
}

export type DismissRepairSuggestionMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DismissRepairSuggestionMutation = {
  __typename?: 'Mutation'
  result: {
    __typename: 'SnapshotMutationResult'
    message: string
    code: string
    snapshot?: {
      __typename?: 'Snapshot'
      id: string
      suggestedRepairType?: RepairType | null
    } | null
  }
}

export type GetExtensionStateQueryVariables = Exact<{ [key: string]: never }>

export type GetExtensionStateQuery = {
  __typename?: 'Query'
  extensionClientState?: {
    __typename?: 'ExtensionClientState'
    isExtensionInstalled?: boolean | null
  } | null
}

export type MoveContentToTeamMutationVariables = Exact<{
  id: Scalars['ID']['input']
  teamId?: InputMaybe<Scalars['ID']['input']>
}>

export type MoveContentToTeamMutation = {
  __typename?: 'Mutation'
  result: { __typename: 'ContentMutationResult'; message: string; code: string }
}

export type ReportSnapshotIssueMutationVariables = Exact<{
  urn: Scalars['URN']['input']
  input: SnapshotIssueInput
}>

export type ReportSnapshotIssueMutation = {
  __typename?: 'Mutation'
  reportSnapshotIssue: {
    __typename?: 'SnapshotIssueMutationResult'
    code: string
    success: boolean
    message: string
  }
}

export type UpdateContentPrivacyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateContentPropertiesInput
}>

export type UpdateContentPrivacyMutation = {
  __typename?: 'Mutation'
  result: {
    __typename?: 'ContentMutationResult'
    content?:
      | {
          __typename?: 'Document'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'File'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Folder'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Presentation'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Slide'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Snapshot'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Text'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | {
          __typename?: 'Theme'
          id: string
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
        }
      | null
  }
}

export type SideBarFragment = {
  __typename?: 'Viewer'
  organizations: Array<{
    __typename?: 'Organization'
    id: string
    image?: string | null
    createdBy: { __typename?: 'User'; id: string; image?: string | null; email: string }
    invitations: {
      __typename?: 'InvitationsConnection'
      nodes: Array<{
        __typename?: 'Invitation'
        id: string
        email: string
        createdBy?: { __typename?: 'User'; id: string; image?: string | null; email: string } | null
      }>
    }
    teams: { __typename?: 'TeamsConnection'; nodes: Array<{ __typename?: 'Team'; id: string }> }
    users: {
      __typename?: 'UsersConnection'
      nodes: Array<{
        __typename?: 'User'
        email: string
        id: string
        name: string
        image?: string | null
      }>
    }
  } | null>
  teams: {
    __typename?: 'TeamsConnection'
    nodes: Array<{ __typename?: 'Team'; id: string; name: string }>
  }
}

type ContentCard_Document_Fragment = {
  __typename?: 'Document'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_File_Fragment = {
  __typename?: 'File'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Folder_Fragment = {
  __typename?: 'Folder'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Presentation_Fragment = {
  __typename?: 'Presentation'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Slide_Fragment = {
  __typename?: 'Slide'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Snapshot_Fragment = {
  __typename?: 'Snapshot'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Text_Fragment = {
  __typename?: 'Text'
  organization: { __typename?: 'Organization'; slug: string }
}

type ContentCard_Theme_Fragment = {
  __typename?: 'Theme'
  organization: { __typename?: 'Organization'; slug: string }
}

export type ContentCardFragment =
  | ContentCard_Document_Fragment
  | ContentCard_File_Fragment
  | ContentCard_Folder_Fragment
  | ContentCard_Presentation_Fragment
  | ContentCard_Slide_Fragment
  | ContentCard_Snapshot_Fragment
  | ContentCard_Text_Fragment
  | ContentCard_Theme_Fragment

export type DocumentCardFragment = {
  __typename: 'Document'
  id: string
  image?: string | null
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type FileCardFragment = {
  __typename: 'File'
  id: string
  image?: string | null
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type FolderCardFragment = {
  __typename: 'Folder'
  contentCount: number
  id: string
  name: string
  contentCounts: { __typename?: 'FolderContentCounts'; documents: number; snapshots: number }
  organization: { __typename?: 'Organization'; slug: string }
  team?: { __typename?: 'Team'; id: string } | null
}

export type PresentationCardFragment = {
  __typename: 'Presentation'
  id: string
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type PresentationFieldsFragment = {
  __typename: 'Presentation'
  id: string
  name: string
  createdAt: string
  updatedAt: string
  status: PresentationStatus
  urn: string
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  properties: {
    __typename?: 'ContentProperties'
    accessCode?: string | null
    privacy: ContentPrivacy
  }
  team?: { __typename?: 'Team'; id: string } | null
  organization: {
    __typename?: 'Organization'
    id: string
    slug: string
    urn: string
    createdAt: string
  }
}

export type SlideCardFragment = {
  __typename: 'Slide'
  id: string
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type SnapshotFieldsFragment = {
  __typename: 'Snapshot'
  id: string
  name: string
  createdAt: string
  updatedAt: string
  changeSensitivity: SnapshotChangeSensitivity
  description?: string | null
  refreshRate: SnapshotRefreshRate
  suggestedRepairType?: RepairType | null
  status: SnapshotStatus
  urn: string
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  properties: {
    __typename?: 'ContentProperties'
    accessCode?: string | null
    privacy: ContentPrivacy
  }
  identity?: {
    __typename?: 'Identity'
    id: string
    connectionStatus: ConnectionStatus
    current: boolean
    domain: string
    status: IdentityStatus
  } | null
  instructions: {
    __typename?: 'SnapshotCaptureInstructions'
    url: string
    frame: {
      __typename?: 'SnapshotCaptureFrame'
      frameX: number
      frameY: number
      frameWidth: number
      frameHeight: number
      windowWidth: number
      windowHeight: number
    }
  }
  currentVersion?: {
    __typename?: 'SnapshotVersion'
    id: string
    urn: string
    image?: string | null
    extractedText?: string | null
    createdAt: string
    status: CaptureStatus
  } | null
  latestVersion?: {
    __typename?: 'SnapshotVersion'
    id: string
    urn: string
    image?: string | null
    extractedText?: string | null
    createdAt: string
    status: CaptureStatus
  } | null
  team?: { __typename?: 'Team'; id: string } | null
  organization: {
    __typename?: 'Organization'
    id: string
    slug: string
    urn: string
    createdAt: string
  }
  versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
  allVersions: {
    __typename?: 'SnapshotVersionsConnection'
    totalCount: number
    edges: Array<{
      __typename?: 'SnapshotVersionEdge'
      cursor: string
      node: {
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
  }
  viewer: {
    __typename?: 'ContentViewer'
    canDelete: boolean
    canEdit: boolean
    isAuthor: boolean
    isOwner: boolean
  }
}

export type SnapshotViewerFragment = {
  __typename?: 'Snapshot'
  viewer: {
    __typename?: 'ContentViewer'
    canDelete: boolean
    canEdit: boolean
    isAuthor: boolean
    isOwner: boolean
  }
}

export type PublicSnapshotViewerFragment = {
  __typename?: 'PublicSnapshot'
  viewer: {
    __typename?: 'ContentViewer'
    canDelete: boolean
    canEdit: boolean
    isAuthor: boolean
    isOwner: boolean
  }
}

export type TextCardFragment = {
  __typename: 'Text'
  id: string
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type ThemeCardFragment = {
  __typename: 'Theme'
  id: string
  name: string
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; slug: string }
  createdBy: { __typename?: 'User'; id: string; image?: string | null; name: string; email: string }
  team?: { __typename?: 'Team'; id: string } | null
  properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
}

export type CurrentOrganizationSnapshotsQueryVariables = Exact<{
  filter?: InputMaybe<SnapshotsFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type CurrentOrganizationSnapshotsQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    currentOrganization?: {
      __typename?: 'Organization'
      id: string
      snapshots: {
        __typename?: 'SnapshotsConnection'
        totalCount: number
        edges: Array<{
          __typename?: 'SnapshotEdge'
          cursor: string
          node: {
            __typename: 'Snapshot'
            id: string
            name: string
            createdAt: string
            updatedAt: string
            changeSensitivity: SnapshotChangeSensitivity
            description?: string | null
            refreshRate: SnapshotRefreshRate
            suggestedRepairType?: RepairType | null
            status: SnapshotStatus
            urn: string
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            properties: {
              __typename?: 'ContentProperties'
              accessCode?: string | null
              privacy: ContentPrivacy
            }
            identity?: {
              __typename?: 'Identity'
              id: string
              connectionStatus: ConnectionStatus
              current: boolean
              domain: string
              status: IdentityStatus
            } | null
            instructions: {
              __typename?: 'SnapshotCaptureInstructions'
              url: string
              frame: {
                __typename?: 'SnapshotCaptureFrame'
                frameX: number
                frameY: number
                frameWidth: number
                frameHeight: number
                windowWidth: number
                windowHeight: number
              }
            }
            currentVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            latestVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            team?: { __typename?: 'Team'; id: string } | null
            organization: {
              __typename?: 'Organization'
              id: string
              slug: string
              urn: string
              createdAt: string
            }
            versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
            allVersions: {
              __typename?: 'SnapshotVersionsConnection'
              totalCount: number
              edges: Array<{
                __typename?: 'SnapshotVersionEdge'
                cursor: string
                node: {
                  __typename?: 'SnapshotVersion'
                  id: string
                  urn: string
                  image?: string | null
                  extractedText?: string | null
                  createdAt: string
                  status: CaptureStatus
                }
              }>
              pageInfo: {
                __typename?: 'PageInfo'
                endCursor?: string | null
                hasNextPage: boolean
                hasPreviousPage: boolean
                startCursor?: string | null
              }
            }
            viewer: {
              __typename?: 'ContentViewer'
              canDelete: boolean
              canEdit: boolean
              isAuthor: boolean
              isOwner: boolean
            }
          }
        }>
        pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      }
    } | null
  }
}

export type FolderQueryVariables = Exact<{
  folderId: Scalars['ID']['input']
}>

export type FolderQuery = {
  __typename?: 'Query'
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
}

export type SearchFieldsFragment = {
  __typename?: 'SearchResultsConnection'
  edges: Array<{
    __typename?: 'SearchResultEdge'
    cursor: string
    node:
      | {
          __typename: 'Document'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'File'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Folder'
          contentCount: number
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          contentCounts: {
            __typename?: 'FolderContentCounts'
            documents: number
            snapshots: number
          }
          team?: { __typename?: 'Team'; id: string } | null
        }
      | {
          __typename: 'Presentation'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Slide'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Snapshot'
          id: string
          name: string
          createdAt: string
          updatedAt: string
          changeSensitivity: SnapshotChangeSensitivity
          description?: string | null
          refreshRate: SnapshotRefreshRate
          suggestedRepairType?: RepairType | null
          status: SnapshotStatus
          urn: string
          organization: {
            __typename?: 'Organization'
            slug: string
            id: string
            urn: string
            createdAt: string
          }
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
          identity?: {
            __typename?: 'Identity'
            id: string
            connectionStatus: ConnectionStatus
            current: boolean
            domain: string
            status: IdentityStatus
          } | null
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: {
              __typename?: 'SnapshotCaptureFrame'
              frameX: number
              frameY: number
              frameWidth: number
              frameHeight: number
              windowWidth: number
              windowHeight: number
            }
          }
          currentVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          latestVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          team?: { __typename?: 'Team'; id: string } | null
          versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
          allVersions: {
            __typename?: 'SnapshotVersionsConnection'
            totalCount: number
            edges: Array<{
              __typename?: 'SnapshotVersionEdge'
              cursor: string
              node: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              }
            }>
            pageInfo: {
              __typename?: 'PageInfo'
              endCursor?: string | null
              hasNextPage: boolean
              hasPreviousPage: boolean
              startCursor?: string | null
            }
          }
          viewer: {
            __typename?: 'ContentViewer'
            canDelete: boolean
            canEdit: boolean
            isAuthor: boolean
            isOwner: boolean
          }
        }
      | {
          __typename: 'Text'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Theme'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
  }>
  pageInfo: {
    __typename?: 'PageInfo'
    endCursor?: string | null
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string | null
  }
  counts: {
    __typename?: 'SearchResultCounts'
    documents: number
    folders: number
    snapshots: number
    total: number
  }
}

export type SearchQueryVariables = Exact<{
  filter?: InputMaybe<SearchFilter>
  first?: InputMaybe<Scalars['IntegerBetween1And100']['input']>
  sort?: InputMaybe<SearchResultOrder>
  after?: InputMaybe<Scalars['ID']['input']>
}>

export type SearchQuery = {
  __typename?: 'Query'
  search: {
    __typename?: 'SearchResultsConnection'
    edges: Array<{
      __typename?: 'SearchResultEdge'
      cursor: string
      node:
        | {
            __typename: 'Document'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'File'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Folder'
            contentCount: number
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            contentCounts: {
              __typename?: 'FolderContentCounts'
              documents: number
              snapshots: number
            }
            team?: { __typename?: 'Team'; id: string } | null
          }
        | {
            __typename: 'Presentation'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Slide'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Snapshot'
            id: string
            name: string
            createdAt: string
            updatedAt: string
            changeSensitivity: SnapshotChangeSensitivity
            description?: string | null
            refreshRate: SnapshotRefreshRate
            suggestedRepairType?: RepairType | null
            status: SnapshotStatus
            urn: string
            organization: {
              __typename?: 'Organization'
              slug: string
              id: string
              urn: string
              createdAt: string
            }
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            properties: {
              __typename?: 'ContentProperties'
              accessCode?: string | null
              privacy: ContentPrivacy
            }
            identity?: {
              __typename?: 'Identity'
              id: string
              connectionStatus: ConnectionStatus
              current: boolean
              domain: string
              status: IdentityStatus
            } | null
            instructions: {
              __typename?: 'SnapshotCaptureInstructions'
              url: string
              frame: {
                __typename?: 'SnapshotCaptureFrame'
                frameX: number
                frameY: number
                frameWidth: number
                frameHeight: number
                windowWidth: number
                windowHeight: number
              }
            }
            currentVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            latestVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            team?: { __typename?: 'Team'; id: string } | null
            versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
            allVersions: {
              __typename?: 'SnapshotVersionsConnection'
              totalCount: number
              edges: Array<{
                __typename?: 'SnapshotVersionEdge'
                cursor: string
                node: {
                  __typename?: 'SnapshotVersion'
                  id: string
                  urn: string
                  image?: string | null
                  extractedText?: string | null
                  createdAt: string
                  status: CaptureStatus
                }
              }>
              pageInfo: {
                __typename?: 'PageInfo'
                endCursor?: string | null
                hasNextPage: boolean
                hasPreviousPage: boolean
                startCursor?: string | null
              }
            }
            viewer: {
              __typename?: 'ContentViewer'
              canDelete: boolean
              canEdit: boolean
              isAuthor: boolean
              isOwner: boolean
            }
          }
        | {
            __typename: 'Text'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Theme'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
    counts: {
      __typename?: 'SearchResultCounts'
      documents: number
      folders: number
      snapshots: number
      total: number
    }
  }
}

export type ContentGridSearchQueryVariables = Exact<{
  contentIOwn?: InputMaybe<Scalars['Boolean']['input']>
  folders?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  teams?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>
  sort?: InputMaybe<SearchResultOrder>
}>

export type ContentGridSearchQuery = {
  __typename?: 'Query'
  snapshots: {
    __typename?: 'SearchResultsConnection'
    edges: Array<{
      __typename?: 'SearchResultEdge'
      cursor: string
      node:
        | {
            __typename: 'Document'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'File'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Folder'
            contentCount: number
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            contentCounts: {
              __typename?: 'FolderContentCounts'
              documents: number
              snapshots: number
            }
            team?: { __typename?: 'Team'; id: string } | null
          }
        | {
            __typename: 'Presentation'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Slide'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Snapshot'
            id: string
            name: string
            createdAt: string
            updatedAt: string
            changeSensitivity: SnapshotChangeSensitivity
            description?: string | null
            refreshRate: SnapshotRefreshRate
            suggestedRepairType?: RepairType | null
            status: SnapshotStatus
            urn: string
            organization: {
              __typename?: 'Organization'
              slug: string
              id: string
              urn: string
              createdAt: string
            }
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            properties: {
              __typename?: 'ContentProperties'
              accessCode?: string | null
              privacy: ContentPrivacy
            }
            identity?: {
              __typename?: 'Identity'
              id: string
              connectionStatus: ConnectionStatus
              current: boolean
              domain: string
              status: IdentityStatus
            } | null
            instructions: {
              __typename?: 'SnapshotCaptureInstructions'
              url: string
              frame: {
                __typename?: 'SnapshotCaptureFrame'
                frameX: number
                frameY: number
                frameWidth: number
                frameHeight: number
                windowWidth: number
                windowHeight: number
              }
            }
            currentVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            latestVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            team?: { __typename?: 'Team'; id: string } | null
            versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
            allVersions: {
              __typename?: 'SnapshotVersionsConnection'
              totalCount: number
              edges: Array<{
                __typename?: 'SnapshotVersionEdge'
                cursor: string
                node: {
                  __typename?: 'SnapshotVersion'
                  id: string
                  urn: string
                  image?: string | null
                  extractedText?: string | null
                  createdAt: string
                  status: CaptureStatus
                }
              }>
              pageInfo: {
                __typename?: 'PageInfo'
                endCursor?: string | null
                hasNextPage: boolean
                hasPreviousPage: boolean
                startCursor?: string | null
              }
            }
            viewer: {
              __typename?: 'ContentViewer'
              canDelete: boolean
              canEdit: boolean
              isAuthor: boolean
              isOwner: boolean
            }
          }
        | {
            __typename: 'Text'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Theme'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
    counts: {
      __typename?: 'SearchResultCounts'
      documents: number
      folders: number
      snapshots: number
      total: number
    }
  }
  documents: {
    __typename?: 'SearchResultsConnection'
    edges: Array<{
      __typename?: 'SearchResultEdge'
      cursor: string
      node:
        | {
            __typename: 'Document'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'File'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Folder'
            contentCount: number
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            contentCounts: {
              __typename?: 'FolderContentCounts'
              documents: number
              snapshots: number
            }
            team?: { __typename?: 'Team'; id: string } | null
          }
        | {
            __typename: 'Presentation'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Slide'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Snapshot'
            id: string
            name: string
            createdAt: string
            updatedAt: string
            changeSensitivity: SnapshotChangeSensitivity
            description?: string | null
            refreshRate: SnapshotRefreshRate
            suggestedRepairType?: RepairType | null
            status: SnapshotStatus
            urn: string
            organization: {
              __typename?: 'Organization'
              slug: string
              id: string
              urn: string
              createdAt: string
            }
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            properties: {
              __typename?: 'ContentProperties'
              accessCode?: string | null
              privacy: ContentPrivacy
            }
            identity?: {
              __typename?: 'Identity'
              id: string
              connectionStatus: ConnectionStatus
              current: boolean
              domain: string
              status: IdentityStatus
            } | null
            instructions: {
              __typename?: 'SnapshotCaptureInstructions'
              url: string
              frame: {
                __typename?: 'SnapshotCaptureFrame'
                frameX: number
                frameY: number
                frameWidth: number
                frameHeight: number
                windowWidth: number
                windowHeight: number
              }
            }
            currentVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            latestVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            team?: { __typename?: 'Team'; id: string } | null
            versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
            allVersions: {
              __typename?: 'SnapshotVersionsConnection'
              totalCount: number
              edges: Array<{
                __typename?: 'SnapshotVersionEdge'
                cursor: string
                node: {
                  __typename?: 'SnapshotVersion'
                  id: string
                  urn: string
                  image?: string | null
                  extractedText?: string | null
                  createdAt: string
                  status: CaptureStatus
                }
              }>
              pageInfo: {
                __typename?: 'PageInfo'
                endCursor?: string | null
                hasNextPage: boolean
                hasPreviousPage: boolean
                startCursor?: string | null
              }
            }
            viewer: {
              __typename?: 'ContentViewer'
              canDelete: boolean
              canEdit: boolean
              isAuthor: boolean
              isOwner: boolean
            }
          }
        | {
            __typename: 'Text'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Theme'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
    counts: {
      __typename?: 'SearchResultCounts'
      documents: number
      folders: number
      snapshots: number
      total: number
    }
  }
  folders: {
    __typename?: 'SearchResultsConnection'
    edges: Array<{
      __typename?: 'SearchResultEdge'
      cursor: string
      node:
        | {
            __typename: 'Document'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'File'
            id: string
            image?: string | null
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Folder'
            contentCount: number
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            contentCounts: {
              __typename?: 'FolderContentCounts'
              documents: number
              snapshots: number
            }
            team?: { __typename?: 'Team'; id: string } | null
          }
        | {
            __typename: 'Presentation'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Slide'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Snapshot'
            id: string
            name: string
            createdAt: string
            updatedAt: string
            changeSensitivity: SnapshotChangeSensitivity
            description?: string | null
            refreshRate: SnapshotRefreshRate
            suggestedRepairType?: RepairType | null
            status: SnapshotStatus
            urn: string
            organization: {
              __typename?: 'Organization'
              slug: string
              id: string
              urn: string
              createdAt: string
            }
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            properties: {
              __typename?: 'ContentProperties'
              accessCode?: string | null
              privacy: ContentPrivacy
            }
            identity?: {
              __typename?: 'Identity'
              id: string
              connectionStatus: ConnectionStatus
              current: boolean
              domain: string
              status: IdentityStatus
            } | null
            instructions: {
              __typename?: 'SnapshotCaptureInstructions'
              url: string
              frame: {
                __typename?: 'SnapshotCaptureFrame'
                frameX: number
                frameY: number
                frameWidth: number
                frameHeight: number
                windowWidth: number
                windowHeight: number
              }
            }
            currentVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            latestVersion?: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            } | null
            team?: { __typename?: 'Team'; id: string } | null
            versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
            allVersions: {
              __typename?: 'SnapshotVersionsConnection'
              totalCount: number
              edges: Array<{
                __typename?: 'SnapshotVersionEdge'
                cursor: string
                node: {
                  __typename?: 'SnapshotVersion'
                  id: string
                  urn: string
                  image?: string | null
                  extractedText?: string | null
                  createdAt: string
                  status: CaptureStatus
                }
              }>
              pageInfo: {
                __typename?: 'PageInfo'
                endCursor?: string | null
                hasNextPage: boolean
                hasPreviousPage: boolean
                startCursor?: string | null
              }
            }
            viewer: {
              __typename?: 'ContentViewer'
              canDelete: boolean
              canEdit: boolean
              isAuthor: boolean
              isOwner: boolean
            }
          }
        | {
            __typename: 'Text'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
        | {
            __typename: 'Theme'
            id: string
            name: string
            organization: { __typename?: 'Organization'; slug: string }
            folder?: { __typename?: 'Folder'; id: string; name: string } | null
            createdBy: {
              __typename?: 'User'
              id: string
              image?: string | null
              name: string
              email: string
            }
            team?: { __typename?: 'Team'; id: string } | null
            properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
          }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
      startCursor?: string | null
    }
    counts: {
      __typename?: 'SearchResultCounts'
      documents: number
      folders: number
      snapshots: number
      total: number
    }
  }
}

export type OnContentCreatedSubscriptionVariables = Exact<{
  urn: Scalars['URN']['input']
  filter: SearchFilter
}>

export type OnContentCreatedSubscription = {
  __typename?: 'Subscription'
  onContentCreated?: {
    __typename?: 'SearchResultEdge'
    cursor: string
    node:
      | {
          __typename: 'Document'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'File'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Folder'
          contentCount: number
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          contentCounts: {
            __typename?: 'FolderContentCounts'
            documents: number
            snapshots: number
          }
          team?: { __typename?: 'Team'; id: string } | null
        }
      | {
          __typename: 'Presentation'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Slide'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Snapshot'
          id: string
          name: string
          createdAt: string
          updatedAt: string
          changeSensitivity: SnapshotChangeSensitivity
          description?: string | null
          refreshRate: SnapshotRefreshRate
          suggestedRepairType?: RepairType | null
          status: SnapshotStatus
          urn: string
          organization: {
            __typename?: 'Organization'
            slug: string
            id: string
            urn: string
            createdAt: string
          }
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
          identity?: {
            __typename?: 'Identity'
            id: string
            connectionStatus: ConnectionStatus
            current: boolean
            domain: string
            status: IdentityStatus
          } | null
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: {
              __typename?: 'SnapshotCaptureFrame'
              frameX: number
              frameY: number
              frameWidth: number
              frameHeight: number
              windowWidth: number
              windowHeight: number
            }
          }
          currentVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          latestVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          team?: { __typename?: 'Team'; id: string } | null
          versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
          allVersions: {
            __typename?: 'SnapshotVersionsConnection'
            totalCount: number
            edges: Array<{
              __typename?: 'SnapshotVersionEdge'
              cursor: string
              node: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              }
            }>
            pageInfo: {
              __typename?: 'PageInfo'
              endCursor?: string | null
              hasNextPage: boolean
              hasPreviousPage: boolean
              startCursor?: string | null
            }
          }
          viewer: {
            __typename?: 'ContentViewer'
            canDelete: boolean
            canEdit: boolean
            isAuthor: boolean
            isOwner: boolean
          }
        }
      | {
          __typename: 'Text'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Theme'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
  } | null
}

export type OnContentUpdatedSubscriptionVariables = Exact<{
  urn: Scalars['URN']['input']
  filter: SearchFilter
}>

export type OnContentUpdatedSubscription = {
  __typename?: 'Subscription'
  onContentUpdated?: {
    __typename?: 'SearchResultEdge'
    cursor: string
    node:
      | {
          __typename: 'Document'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'File'
          id: string
          image?: string | null
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Folder'
          contentCount: number
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          contentCounts: {
            __typename?: 'FolderContentCounts'
            documents: number
            snapshots: number
          }
          team?: { __typename?: 'Team'; id: string } | null
        }
      | {
          __typename: 'Presentation'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Slide'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Snapshot'
          id: string
          name: string
          createdAt: string
          updatedAt: string
          changeSensitivity: SnapshotChangeSensitivity
          description?: string | null
          refreshRate: SnapshotRefreshRate
          suggestedRepairType?: RepairType | null
          status: SnapshotStatus
          urn: string
          organization: {
            __typename?: 'Organization'
            slug: string
            id: string
            urn: string
            createdAt: string
          }
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
          identity?: {
            __typename?: 'Identity'
            id: string
            connectionStatus: ConnectionStatus
            current: boolean
            domain: string
            status: IdentityStatus
          } | null
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: {
              __typename?: 'SnapshotCaptureFrame'
              frameX: number
              frameY: number
              frameWidth: number
              frameHeight: number
              windowWidth: number
              windowHeight: number
            }
          }
          currentVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          latestVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          team?: { __typename?: 'Team'; id: string } | null
          versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
          allVersions: {
            __typename?: 'SnapshotVersionsConnection'
            totalCount: number
            edges: Array<{
              __typename?: 'SnapshotVersionEdge'
              cursor: string
              node: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              }
            }>
            pageInfo: {
              __typename?: 'PageInfo'
              endCursor?: string | null
              hasNextPage: boolean
              hasPreviousPage: boolean
              startCursor?: string | null
            }
          }
          viewer: {
            __typename?: 'ContentViewer'
            canDelete: boolean
            canEdit: boolean
            isAuthor: boolean
            isOwner: boolean
          }
        }
      | {
          __typename: 'Text'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
      | {
          __typename: 'Theme'
          id: string
          name: string
          organization: { __typename?: 'Organization'; slug: string }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          team?: { __typename?: 'Team'; id: string } | null
          properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
        }
  } | null
}

export type OnContentDeletedSubscriptionVariables = Exact<{
  urn: Scalars['URN']['input']
  filter: SearchFilter
}>

export type OnContentDeletedSubscription = {
  __typename?: 'Subscription'
  onContentDeleted?: {
    __typename?: 'ContentDeletion'
    id: string
    urn: string
    contentType: ContentType
  } | null
}

export type GetFeedClientStateQueryVariables = Exact<{ [key: string]: never }>

export type GetFeedClientStateQuery = {
  __typename?: 'Query'
  feedClientState?: {
    __typename?: 'FeedClientState'
    activeContentType?: ContentType | null
    sortField?: SearchResultOrderField | null
  } | null
}

export type ViewerNavDataQueryVariables = Exact<{ [key: string]: never }>

export type ViewerNavDataQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    hasPublicEmailDomain: boolean
    email: string
    frontUserHash: string
    slackAccountStatus: SlackAccountStatus
    availablePlans: Array<{
      __typename?: 'Plan'
      id: string
      currencySymbol: string
      price: number
      frequency: PlanFrequency
      snapshotsLineItem: string
      refreshRatesLineItem: string
      tier: PlanTier
    }>
    currentOrganization?: {
      __typename?: 'Organization'
      id: string
      urn: string
      name: string
      image?: string | null
      slug: string
      joinByDomain: boolean
      isStripeCustomer: boolean
      createdBy: { __typename?: 'User'; id: string; image?: string | null; email: string }
      users: {
        __typename?: 'UsersConnection'
        totalCount: number
        nodes: Array<{
          __typename?: 'User'
          email: string
          id: string
          name: string
          image?: string | null
        }>
      }
      viewer: { __typename?: 'OrganizationViewer'; isOwner: boolean; isAuthor: boolean }
      customInstructions: {
        __typename?: 'CustomInstructions'
        profile?: string | null
        style?: string | null
      }
      currentPlan?: {
        __typename?: 'BillingPlan'
        id: string
        currencySymbol: string
        status?: PaymentStatus | null
        frequency?: PlanFrequency | null
        name: string
        tier: PlanTier
        price: number
        nextInvoice?: string | null
        billedUsersCount: number
      } | null
      invitations: {
        __typename?: 'InvitationsConnection'
        nodes: Array<{
          __typename?: 'Invitation'
          id: string
          email: string
          createdBy?: {
            __typename?: 'User'
            id: string
            image?: string | null
            email: string
          } | null
        }>
      }
    } | null
    organizations: Array<{
      __typename?: 'Organization'
      id: string
      urn: string
      name: string
      image?: string | null
      slug: string
      joinByDomain: boolean
      users: {
        __typename?: 'UsersConnection'
        totalCount: number
        nodes: Array<{
          __typename?: 'User'
          email: string
          id: string
          name: string
          image?: string | null
        }>
      }
      viewer: { __typename?: 'OrganizationViewer'; isOwner: boolean; isAuthor: boolean }
      snapshots: { __typename?: 'SnapshotsConnection'; totalCount: number }
      currentPlan?: {
        __typename?: 'BillingPlan'
        id: string
        status?: PaymentStatus | null
        tier: PlanTier
        frequency?: PlanFrequency | null
      } | null
      createdBy: { __typename?: 'User'; id: string; image?: string | null; email: string }
      invitations: {
        __typename?: 'InvitationsConnection'
        nodes: Array<{
          __typename?: 'Invitation'
          id: string
          email: string
          createdBy?: {
            __typename?: 'User'
            id: string
            image?: string | null
            email: string
          } | null
        }>
      }
      teams: { __typename?: 'TeamsConnection'; nodes: Array<{ __typename?: 'Team'; id: string }> }
    } | null>
    invitations: Array<{
      __typename?: 'Invitation'
      id: string
      createdBy?: { __typename?: 'User'; email: string } | null
    }>
    settings?: {
      __typename?: 'Settings'
      customSlides: boolean
      longPromptEnabled: boolean
      refreshRates: Array<SnapshotRefreshRate>
      quotas?: { __typename?: 'Quotas'; presentations: number; snapshots: number } | null
    } | null
    usage?: { __typename?: 'Usage'; presentations: number; snapshots: number } | null
    user?: {
      __typename?: 'User'
      id: string
      name: string
      image?: string | null
      email: string
      urn: string
      dismissedUi: {
        __typename?: 'DismissedUi'
        captureFailedNux: boolean
        loggedOutNux: boolean
        reviewNux: boolean
      }
    } | null
    teams: {
      __typename?: 'TeamsConnection'
      nodes: Array<{ __typename?: 'Team'; id: string; name: string }>
    }
  }
}

export type GalleryQueryVariables = Exact<{ [key: string]: never }>

export type GalleryQuery = {
  __typename?: 'Query'
  gallery: {
    __typename?: 'DocumentsConnection'
    edges: Array<{
      __typename?: 'DocumentEdge'
      node: {
        __typename: 'Document'
        id: string
        image?: string | null
        name: string
        folder?: { __typename?: 'Folder'; id: string; name: string } | null
        organization: { __typename?: 'Organization'; slug: string }
        createdBy: {
          __typename?: 'User'
          id: string
          image?: string | null
          name: string
          email: string
        }
        team?: { __typename?: 'Team'; id: string } | null
        properties: { __typename?: 'ContentProperties'; privacy: ContentPrivacy }
      }
    }>
  }
}

export type GetPublicDocumentQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPublicDocumentQuery = {
  __typename?: 'Query'
  publicDocument?: {
    __typename?: 'PublicDocumentQueryResult'
    document?: {
      __typename?: 'PublicDocument'
      id: string
      description?: string | null
      name: string
      urn: string
      viewer: { __typename?: 'ContentViewer'; canEdit: boolean; isOwner: boolean }
      pages: Array<{
        __typename?: 'PublicPageStructureV1'
        id: string
        index: number
        objects: Array<{ readonly [key: string]: any }>
        createdAt: string
        updatedAt: string
        canvasSize: { __typename?: 'CanvasSize'; height: number; width: number }
        pinnedVersions: Array<{
          __typename?: 'PublicSnapshotVersion'
          id: string
          urn: string
          image?: string | null
          createdAt: string
        }>
        snapshots: Array<{
          __typename?: 'PublicSnapshot'
          id: string
          urn: string
          name: string
          refreshRate: SnapshotRefreshRate
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: { __typename?: 'SnapshotCaptureFrame'; frameHeight: number; frameWidth: number }
          }
          currentVersion?: {
            __typename?: 'PublicSnapshotVersion'
            id: string
            urn: string
            image?: string | null
            createdAt: string
          } | null
        }>
      }>
    } | null
  } | null
}

export type GetSlackAccountStatusQueryVariables = Exact<{
  slackUserId: Scalars['ID']['input']
  nonce: Scalars['StringWithMaxLengthOf100']['input']
}>

export type GetSlackAccountStatusQuery = {
  __typename?: 'Query'
  viewer: { __typename?: 'Viewer'; slackAccountStatus: SlackAccountStatus }
}

export type LinkAccountToSlackMutationVariables = Exact<{
  input: LinkSlackAccountInput
}>

export type LinkAccountToSlackMutation = {
  __typename?: 'Mutation'
  linkSlackAccount?: {
    __typename?: 'LinkSlackAccountMutationResult'
    success: boolean
    message: string
  } | null
}

export type GetSnapshotVersionDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetSnapshotVersionDataQuery = {
  __typename?: 'Query'
  snapshotVersion?: {
    __typename?: 'SnapshotVersion'
    id: string
    urn: string
    image?: string | null
    extractedText?: string | null
    createdAt: string
    status: CaptureStatus
  } | null
}

export type GetPublicSnapshotVersionDataQueryVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type GetPublicSnapshotVersionDataQuery = {
  __typename?: 'Query'
  publicSnapshotVersion?: {
    __typename?: 'PublicSnapshotVersionQueryResult'
    snapshotVersion?: {
      __typename?: 'PublicSnapshotVersion'
      id: string
      urn: string
      image?: string | null
      createdAt: string
    } | null
  } | null
}

export type SnapshotVersionDataFragment = {
  __typename?: 'SnapshotVersion'
  id: string
  urn: string
  image?: string | null
  extractedText?: string | null
  createdAt: string
  status: CaptureStatus
}

export type PublicSnapshotVersionDataFragment = {
  __typename?: 'PublicSnapshotVersion'
  id: string
  urn: string
  image?: string | null
  createdAt: string
}

export type UpdateSnapshotMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateSnapshotInput
}>

export type UpdateSnapshotMutation = {
  __typename?: 'Mutation'
  updateSnapshot: {
    __typename?: 'SnapshotMutationResult'
    success: boolean
    message: string
    snapshot?: {
      __typename: 'Snapshot'
      id: string
      name: string
      createdAt: string
      updatedAt: string
      changeSensitivity: SnapshotChangeSensitivity
      description?: string | null
      refreshRate: SnapshotRefreshRate
      suggestedRepairType?: RepairType | null
      status: SnapshotStatus
      urn: string
      createdBy: {
        __typename?: 'User'
        id: string
        image?: string | null
        name: string
        email: string
      }
      folder?: { __typename?: 'Folder'; id: string; name: string } | null
      properties: {
        __typename?: 'ContentProperties'
        accessCode?: string | null
        privacy: ContentPrivacy
      }
      identity?: {
        __typename?: 'Identity'
        id: string
        connectionStatus: ConnectionStatus
        current: boolean
        domain: string
        status: IdentityStatus
      } | null
      instructions: {
        __typename?: 'SnapshotCaptureInstructions'
        url: string
        frame: {
          __typename?: 'SnapshotCaptureFrame'
          frameX: number
          frameY: number
          frameWidth: number
          frameHeight: number
          windowWidth: number
          windowHeight: number
        }
      }
      currentVersion?: {
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      } | null
      latestVersion?: {
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      } | null
      team?: { __typename?: 'Team'; id: string } | null
      organization: {
        __typename?: 'Organization'
        id: string
        slug: string
        urn: string
        createdAt: string
      }
      versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
      allVersions: {
        __typename?: 'SnapshotVersionsConnection'
        totalCount: number
        edges: Array<{
          __typename?: 'SnapshotVersionEdge'
          cursor: string
          node: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          }
        }>
        pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      }
      viewer: {
        __typename?: 'ContentViewer'
        canDelete: boolean
        canEdit: boolean
        isAuthor: boolean
        isOwner: boolean
      }
    } | null
  }
}

export type CaptureSnapshotMutationVariables = Exact<{
  snapshotId: Scalars['ID']['input']
}>

export type CaptureSnapshotMutation = {
  __typename?: 'Mutation'
  captureSnapshot: {
    __typename?: 'SnapshotMutationResult'
    code: string
    success: boolean
    message: string
    snapshot?: { __typename?: 'Snapshot'; id: string; status: SnapshotStatus } | null
  }
}

export type ConnectSnapshotIdentitiesForDomainMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type ConnectSnapshotIdentitiesForDomainMutation = {
  __typename?: 'Mutation'
  connectSnapshotIdentitiesForDomain: {
    __typename?: 'SnapshotMutationResult'
    code: string
    success: boolean
    message: string
    snapshot?: {
      __typename?: 'Snapshot'
      id: string
      identity?: { __typename?: 'Identity'; id: string; current: boolean } | null
    } | null
  }
}

export type DisconnectSnapshotIdentitiesForDomainMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DisconnectSnapshotIdentitiesForDomainMutation = {
  __typename?: 'Mutation'
  disconnectSnapshotIdentitiesForDomain?: {
    __typename?: 'SnapshotMutationResult'
    code: string
    success: boolean
    message: string
    snapshot?: {
      __typename?: 'Snapshot'
      id: string
      identity?: { __typename?: 'Identity'; id: string; current: boolean } | null
    } | null
  } | null
}

export type CreateThemeMutationVariables = Exact<{
  input: CreateThemeInput
}>

export type CreateThemeMutation = {
  __typename?: 'Mutation'
  _experimental: {
    __typename?: '_ExperimentalMutation'
    createTheme: {
      __typename?: 'ThemeMutationResult'
      success: boolean
      theme?: {
        __typename?: 'Theme'
        id: string
        name: string
        headerFont: string
        bodyFont: string
        backgroundColor: string
        fontColor: string
        colorOne: string
        colorTwo: string
        colorThree: string
        colorFour: string
        logo?: string | null
      } | null
    }
  }
}

export type GetThemesQueryVariables = Exact<{ [key: string]: never }>

export type GetThemesQuery = {
  __typename?: 'Query'
  _experimental: {
    __typename?: '_ExperimentalQuery'
    themes: Array<{
      __typename?: 'Theme'
      id: string
      name: string
      headerFont: string
      bodyFont: string
      backgroundColor: string
      fontColor: string
      colorOne: string
      colorTwo: string
      colorThree: string
      colorFour: string
      logo?: string | null
    }>
  }
}

export type ThemeDataFragment = {
  __typename?: 'Theme'
  id: string
  name: string
  headerFont: string
  bodyFont: string
  backgroundColor: string
  fontColor: string
  colorOne: string
  colorTwo: string
  colorThree: string
  colorFour: string
  logo?: string | null
}

export type AcceptInvitationInputMutationVariables = Exact<{
  input: AcceptInvitationInput
}>

export type AcceptInvitationInputMutation = {
  __typename?: 'Mutation'
  acceptInvitation: {
    __typename?: 'AcceptInvitationMutationResult'
    code: string
    message: string
    user?: { __typename: 'User'; id: string; email: string; name: string } | null
  }
}

export type UploadUserAvatarMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UploadUserAvatarMutation = { __typename?: 'Mutation'; createUpload: string }

export type UploadOrganizationLogoMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type UploadOrganizationLogoMutation = { __typename?: 'Mutation'; createUpload: string }

export type InvitationQueryVariables = Exact<{
  invitationId: Scalars['ID']['input']
  type: InvitationType
}>

export type InvitationQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    invitation?: {
      __typename?: 'Invitation'
      id: string
      type: InvitationType
      createdBy?: { __typename?: 'User'; id: string; email: string } | null
      organization: {
        __typename?: 'Organization'
        id: string
        name: string
        image?: string | null
        slug: string
      }
    } | null
  }
}

export type InvitationsQueryVariables = Exact<{ [key: string]: never }>

export type InvitationsQuery = {
  __typename?: 'Query'
  viewer: {
    __typename?: 'Viewer'
    invitations: Array<{
      __typename?: 'Invitation'
      id: string
      type: InvitationType
      createdBy?: { __typename?: 'User'; id: string; email: string } | null
      organization: {
        __typename?: 'Organization'
        id: string
        image?: string | null
        name: string
        users: { __typename?: 'UsersConnection'; totalCount: number }
      }
    }>
  }
}

export type IsSlugAvailableQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type IsSlugAvailableQuery = { __typename?: 'Query'; isSlugAvailable: SlugAvailability }

export type CreateCheckoutLinkMutationVariables = Exact<{
  priceId: Scalars['ID']['input']
  couponId?: InputMaybe<Scalars['ID']['input']>
  upgradeSource?: InputMaybe<UpgradeSource>
}>

export type CreateCheckoutLinkMutation = { __typename?: 'Mutation'; createCheckoutLink: string }

export type OrganizationImageFragment = { __typename?: 'Organization'; image?: string | null }

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'UserMutationResult'
    code: string
    message: string
    user?: {
      __typename: 'User'
      id: string
      name: string
      dismissedUi: {
        __typename?: 'DismissedUi'
        captureFailedNux: boolean
        loggedOutNux: boolean
        reviewNux: boolean
      }
    } | null
  }
}

export type UserImageFragment = { __typename?: 'User'; image?: string | null }

export type DocumentFieldsFragment = {
  __typename?: 'Document'
  id: string
  description?: string | null
  createdAt: string
  name: string
  updatedAt: string
  urn: string
  createdBy: { __typename?: 'User'; id: string; name: string; image?: string | null; email: string }
  folder?: { __typename?: 'Folder'; id: string; name: string } | null
  organization: { __typename?: 'Organization'; id: string; slug: string }
  pages: Array<{
    __typename?: 'PageStructureV1'
    id: string
    index: number
    objects: Array<{ readonly [key: string]: any }>
    createdAt: string
    updatedAt: string
    canvasSize: { __typename?: 'CanvasSize'; height: number; width: number }
    snapshots: Array<{
      __typename: 'Snapshot'
      id: string
      name: string
      createdAt: string
      updatedAt: string
      changeSensitivity: SnapshotChangeSensitivity
      description?: string | null
      refreshRate: SnapshotRefreshRate
      suggestedRepairType?: RepairType | null
      status: SnapshotStatus
      urn: string
      createdBy: {
        __typename?: 'User'
        id: string
        image?: string | null
        name: string
        email: string
      }
      folder?: { __typename?: 'Folder'; id: string; name: string } | null
      properties: {
        __typename?: 'ContentProperties'
        accessCode?: string | null
        privacy: ContentPrivacy
      }
      identity?: {
        __typename?: 'Identity'
        id: string
        connectionStatus: ConnectionStatus
        current: boolean
        domain: string
        status: IdentityStatus
      } | null
      instructions: {
        __typename?: 'SnapshotCaptureInstructions'
        url: string
        frame: {
          __typename?: 'SnapshotCaptureFrame'
          frameX: number
          frameY: number
          frameWidth: number
          frameHeight: number
          windowWidth: number
          windowHeight: number
        }
      }
      currentVersion?: {
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      } | null
      latestVersion?: {
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      } | null
      team?: { __typename?: 'Team'; id: string } | null
      organization: {
        __typename?: 'Organization'
        id: string
        slug: string
        urn: string
        createdAt: string
      }
      versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
      allVersions: {
        __typename?: 'SnapshotVersionsConnection'
        totalCount: number
        edges: Array<{
          __typename?: 'SnapshotVersionEdge'
          cursor: string
          node: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          }
        }>
        pageInfo: {
          __typename?: 'PageInfo'
          endCursor?: string | null
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor?: string | null
        }
      }
      viewer: {
        __typename?: 'ContentViewer'
        canDelete: boolean
        canEdit: boolean
        isAuthor: boolean
        isOwner: boolean
      }
    }>
    pinnedVersions: Array<{
      __typename?: 'SnapshotVersion'
      id: string
      urn: string
      image?: string | null
      extractedText?: string | null
      createdAt: string
      status: CaptureStatus
    }>
  }>
  properties: {
    __typename?: 'ContentProperties'
    accessCode?: string | null
    privacy: ContentPrivacy
  }
  team?: { __typename?: 'Team'; id: string } | null
  viewer: { __typename?: 'ContentViewer'; canEdit: boolean; isOwner: boolean }
}

export type PagesEditorQueryQueryVariables = Exact<{
  documentId: Scalars['ID']['input']
}>

export type PagesEditorQueryQuery = {
  __typename?: 'Query'
  document?: {
    __typename?: 'Document'
    id: string
    description?: string | null
    createdAt: string
    name: string
    updatedAt: string
    urn: string
    createdBy: {
      __typename?: 'User'
      id: string
      name: string
      image?: string | null
      email: string
    }
    folder?: { __typename?: 'Folder'; id: string; name: string } | null
    organization: { __typename?: 'Organization'; id: string; slug: string }
    pages: Array<{
      __typename?: 'PageStructureV1'
      id: string
      index: number
      objects: Array<{ readonly [key: string]: any }>
      createdAt: string
      updatedAt: string
      canvasSize: { __typename?: 'CanvasSize'; height: number; width: number }
      snapshots: Array<{
        __typename: 'Snapshot'
        id: string
        name: string
        createdAt: string
        updatedAt: string
        changeSensitivity: SnapshotChangeSensitivity
        description?: string | null
        refreshRate: SnapshotRefreshRate
        suggestedRepairType?: RepairType | null
        status: SnapshotStatus
        urn: string
        createdBy: {
          __typename?: 'User'
          id: string
          image?: string | null
          name: string
          email: string
        }
        folder?: { __typename?: 'Folder'; id: string; name: string } | null
        properties: {
          __typename?: 'ContentProperties'
          accessCode?: string | null
          privacy: ContentPrivacy
        }
        identity?: {
          __typename?: 'Identity'
          id: string
          connectionStatus: ConnectionStatus
          current: boolean
          domain: string
          status: IdentityStatus
        } | null
        instructions: {
          __typename?: 'SnapshotCaptureInstructions'
          url: string
          frame: {
            __typename?: 'SnapshotCaptureFrame'
            frameX: number
            frameY: number
            frameWidth: number
            frameHeight: number
            windowWidth: number
            windowHeight: number
          }
        }
        currentVersion?: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        } | null
        latestVersion?: {
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        } | null
        team?: { __typename?: 'Team'; id: string } | null
        organization: {
          __typename?: 'Organization'
          id: string
          slug: string
          urn: string
          createdAt: string
        }
        versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
        allVersions: {
          __typename?: 'SnapshotVersionsConnection'
          totalCount: number
          edges: Array<{
            __typename?: 'SnapshotVersionEdge'
            cursor: string
            node: {
              __typename?: 'SnapshotVersion'
              id: string
              urn: string
              image?: string | null
              extractedText?: string | null
              createdAt: string
              status: CaptureStatus
            }
          }>
          pageInfo: {
            __typename?: 'PageInfo'
            endCursor?: string | null
            hasNextPage: boolean
            hasPreviousPage: boolean
            startCursor?: string | null
          }
        }
        viewer: {
          __typename?: 'ContentViewer'
          canDelete: boolean
          canEdit: boolean
          isAuthor: boolean
          isOwner: boolean
        }
      }>
      pinnedVersions: Array<{
        __typename?: 'SnapshotVersion'
        id: string
        urn: string
        image?: string | null
        extractedText?: string | null
        createdAt: string
        status: CaptureStatus
      }>
    }>
    properties: {
      __typename?: 'ContentProperties'
      accessCode?: string | null
      privacy: ContentPrivacy
    }
    team?: { __typename?: 'Team'; id: string } | null
    viewer: { __typename?: 'ContentViewer'; canEdit: boolean; isOwner: boolean }
  } | null
}

export type PagesEditorCreateMutationVariables = Exact<{
  input: CreateDocumentInput
}>

export type PagesEditorCreateMutation = {
  __typename?: 'Mutation'
  createDocument: {
    __typename?: 'DocumentMutationResult'
    document?: {
      __typename?: 'Document'
      id: string
      description?: string | null
      createdAt: string
      name: string
      updatedAt: string
      urn: string
      createdBy: {
        __typename?: 'User'
        id: string
        name: string
        image?: string | null
        email: string
      }
      folder?: { __typename?: 'Folder'; id: string; name: string } | null
      organization: { __typename?: 'Organization'; id: string; slug: string }
      pages: Array<{
        __typename?: 'PageStructureV1'
        id: string
        index: number
        objects: Array<{ readonly [key: string]: any }>
        createdAt: string
        updatedAt: string
        canvasSize: { __typename?: 'CanvasSize'; height: number; width: number }
        snapshots: Array<{
          __typename: 'Snapshot'
          id: string
          name: string
          createdAt: string
          updatedAt: string
          changeSensitivity: SnapshotChangeSensitivity
          description?: string | null
          refreshRate: SnapshotRefreshRate
          suggestedRepairType?: RepairType | null
          status: SnapshotStatus
          urn: string
          createdBy: {
            __typename?: 'User'
            id: string
            image?: string | null
            name: string
            email: string
          }
          folder?: { __typename?: 'Folder'; id: string; name: string } | null
          properties: {
            __typename?: 'ContentProperties'
            accessCode?: string | null
            privacy: ContentPrivacy
          }
          identity?: {
            __typename?: 'Identity'
            id: string
            connectionStatus: ConnectionStatus
            current: boolean
            domain: string
            status: IdentityStatus
          } | null
          instructions: {
            __typename?: 'SnapshotCaptureInstructions'
            url: string
            frame: {
              __typename?: 'SnapshotCaptureFrame'
              frameX: number
              frameY: number
              frameWidth: number
              frameHeight: number
              windowWidth: number
              windowHeight: number
            }
          }
          currentVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          latestVersion?: {
            __typename?: 'SnapshotVersion'
            id: string
            urn: string
            image?: string | null
            extractedText?: string | null
            createdAt: string
            status: CaptureStatus
          } | null
          team?: { __typename?: 'Team'; id: string } | null
          organization: {
            __typename?: 'Organization'
            id: string
            slug: string
            urn: string
            createdAt: string
          }
          versions: { __typename?: 'SnapshotVersionsConnection'; totalCount: number }
          allVersions: {
            __typename?: 'SnapshotVersionsConnection'
            totalCount: number
            edges: Array<{
              __typename?: 'SnapshotVersionEdge'
              cursor: string
              node: {
                __typename?: 'SnapshotVersion'
                id: string
                urn: string
                image?: string | null
                extractedText?: string | null
                createdAt: string
                status: CaptureStatus
              }
            }>
            pageInfo: {
              __typename?: 'PageInfo'
              endCursor?: string | null
              hasNextPage: boolean
              hasPreviousPage: boolean
              startCursor?: string | null
            }
          }
          viewer: {
            __typename?: 'ContentViewer'
            canDelete: boolean
            canEdit: boolean
            isAuthor: boolean
            isOwner: boolean
          }
        }>
        pinnedVersions: Array<{
          __typename?: 'SnapshotVersion'
          id: string
          urn: string
          image?: string | null
          extractedText?: string | null
          createdAt: string
          status: CaptureStatus
        }>
      }>
      properties: {
        __typename?: 'ContentProperties'
        accessCode?: string | null
        privacy: ContentPrivacy
      }
      team?: { __typename?: 'Team'; id: string } | null
      viewer: { __typename?: 'ContentViewer'; canEdit: boolean; isOwner: boolean }
    } | null
  }
}

export type PagesEditorSyncMutationVariables = Exact<{
  id: Scalars['ID']['input']
  input: UpdateDocumentInput
}>

export type PagesEditorSyncMutation = {
  __typename?: 'Mutation'
  updateDocument: { __typename?: 'DocumentMutationResult'; code: string }
}

export type PagesEditorCreateThumbnailUploadMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type PagesEditorCreateThumbnailUploadMutation = {
  __typename?: 'Mutation'
  createUpload: string
}

export const FileFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SlidePromptDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlidePromptData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SlidePrompt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmsSlideSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slideType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SideBarFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SideBar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Viewer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'User' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const PresentationFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const PublicSnapshotViewerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ContentCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SnapshotVersionDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SnapshotViewerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SnapshotFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const DocumentCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const FolderCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const PresentationCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SlideCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ThemeCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const TextCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const FileCardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const SearchFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'searchFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchResultsConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FolderCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PresentationCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SlideCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileCard' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const PublicSnapshotVersionDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const ThemeDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorOne' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorTwo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorThree' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorFour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const OrganizationImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrganizationImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Organization' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'image' } }],
      },
    },
  ],
} as unknown as DocumentNode
export const UserImageFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserImage' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'image' } }],
      },
    },
  ],
} as unknown as DocumentNode
export const DocumentFieldsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'documentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canvasSize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshots' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pinnedVersions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export const CreateSnapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSnap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSnapshotInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'browserDataUploadUrl' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateSnapMutationFn = Apollo.MutationFunction<
  CreateSnapMutation,
  CreateSnapMutationVariables
>

/**
 * __useCreateSnapMutation__
 *
 * To run a mutation, you first call `useCreateSnapMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSnapMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSnapMutation, { data, loading, error }] = useCreateSnapMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSnapMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSnapMutation, CreateSnapMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSnapMutation, CreateSnapMutationVariables>(
    CreateSnapDocument,
    options,
  )
}
export type CreateSnapMutationHookResult = ReturnType<typeof useCreateSnapMutation>
export type CreateSnapMutationResult = Apollo.MutationResult<CreateSnapMutation>
export type CreateSnapMutationOptions = Apollo.BaseMutationOptions<
  CreateSnapMutation,
  CreateSnapMutationVariables
>
export const UpdateSnapshotBrowserDataUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSnapshotBrowserDataUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateSnapshotBrowserDataUrl' },
            name: { kind: 'Name', value: 'updateSnapshotBrowserData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateSnapshotBrowserDataUrlMutationFn = Apollo.MutationFunction<
  UpdateSnapshotBrowserDataUrlMutation,
  UpdateSnapshotBrowserDataUrlMutationVariables
>

/**
 * __useUpdateSnapshotBrowserDataUrlMutation__
 *
 * To run a mutation, you first call `useUpdateSnapshotBrowserDataUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnapshotBrowserDataUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnapshotBrowserDataUrlMutation, { data, loading, error }] = useUpdateSnapshotBrowserDataUrlMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateSnapshotBrowserDataUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSnapshotBrowserDataUrlMutation,
    UpdateSnapshotBrowserDataUrlMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateSnapshotBrowserDataUrlMutation,
    UpdateSnapshotBrowserDataUrlMutationVariables
  >(UpdateSnapshotBrowserDataUrlDocument, options)
}
export type UpdateSnapshotBrowserDataUrlMutationHookResult = ReturnType<
  typeof useUpdateSnapshotBrowserDataUrlMutation
>
export type UpdateSnapshotBrowserDataUrlMutationResult =
  Apollo.MutationResult<UpdateSnapshotBrowserDataUrlMutation>
export type UpdateSnapshotBrowserDataUrlMutationOptions = Apollo.BaseMutationOptions<
  UpdateSnapshotBrowserDataUrlMutation,
  UpdateSnapshotBrowserDataUrlMutationVariables
>
export const RepairSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RepairSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSnapshotInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'updateSnapshotBrowserDataUrl' },
            name: { kind: 'Name', value: 'updateSnapshotBrowserData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type RepairSnapshotMutationFn = Apollo.MutationFunction<
  RepairSnapshotMutation,
  RepairSnapshotMutationVariables
>

/**
 * __useRepairSnapshotMutation__
 *
 * To run a mutation, you first call `useRepairSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepairSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repairSnapshotMutation, { data, loading, error }] = useRepairSnapshotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepairSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<RepairSnapshotMutation, RepairSnapshotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RepairSnapshotMutation, RepairSnapshotMutationVariables>(
    RepairSnapshotDocument,
    options,
  )
}
export type RepairSnapshotMutationHookResult = ReturnType<typeof useRepairSnapshotMutation>
export type RepairSnapshotMutationResult = Apollo.MutationResult<RepairSnapshotMutation>
export type RepairSnapshotMutationOptions = Apollo.BaseMutationOptions<
  RepairSnapshotMutation,
  RepairSnapshotMutationVariables
>
export const IdentitiesForDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IdentitiesForDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FQDN' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'domain' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useIdentitiesForDomainQuery__
 *
 * To run a query within a React component, call `useIdentitiesForDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentitiesForDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentitiesForDomainQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useIdentitiesForDomainQuery(
  baseOptions: Apollo.QueryHookOptions<
    IdentitiesForDomainQuery,
    IdentitiesForDomainQueryVariables
  > &
    ({ variables: IdentitiesForDomainQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IdentitiesForDomainQuery, IdentitiesForDomainQueryVariables>(
    IdentitiesForDomainDocument,
    options,
  )
}
export function useIdentitiesForDomainLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IdentitiesForDomainQuery,
    IdentitiesForDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IdentitiesForDomainQuery, IdentitiesForDomainQueryVariables>(
    IdentitiesForDomainDocument,
    options,
  )
}
export function useIdentitiesForDomainSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IdentitiesForDomainQuery,
    IdentitiesForDomainQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IdentitiesForDomainQuery, IdentitiesForDomainQueryVariables>(
    IdentitiesForDomainDocument,
    options,
  )
}
export type IdentitiesForDomainQueryHookResult = ReturnType<typeof useIdentitiesForDomainQuery>
export type IdentitiesForDomainLazyQueryHookResult = ReturnType<
  typeof useIdentitiesForDomainLazyQuery
>
export type IdentitiesForDomainSuspenseQueryHookResult = ReturnType<
  typeof useIdentitiesForDomainSuspenseQuery
>
export type IdentitiesForDomainQueryResult = Apollo.QueryResult<
  IdentitiesForDomainQuery,
  IdentitiesForDomainQueryVariables
>
export const UpdateIdentityDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateIdentityData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateIdentityData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateIdentityDataMutationFn = Apollo.MutationFunction<
  UpdateIdentityDataMutation,
  UpdateIdentityDataMutationVariables
>

/**
 * __useUpdateIdentityDataMutation__
 *
 * To run a mutation, you first call `useUpdateIdentityDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIdentityDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIdentityDataMutation, { data, loading, error }] = useUpdateIdentityDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateIdentityDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIdentityDataMutation,
    UpdateIdentityDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateIdentityDataMutation, UpdateIdentityDataMutationVariables>(
    UpdateIdentityDataDocument,
    options,
  )
}
export type UpdateIdentityDataMutationHookResult = ReturnType<typeof useUpdateIdentityDataMutation>
export type UpdateIdentityDataMutationResult = Apollo.MutationResult<UpdateIdentityDataMutation>
export type UpdateIdentityDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateIdentityDataMutation,
  UpdateIdentityDataMutationVariables
>
export const DomainSettingsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'domainSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FQDN' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'domainSettings' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'domain' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'domain' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'backgroundRefresh' } },
                { kind: 'Field', name: { kind: 'Name', value: 'blocked' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'ebc' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'pattern' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'selector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'captureSelector' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'captureVariables' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'logout' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'pattern' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statuses' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useDomainSettingsQuery__
 *
 * To run a query within a React component, call `useDomainSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainSettingsQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useDomainSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<DomainSettingsQuery, DomainSettingsQueryVariables> &
    ({ variables: DomainSettingsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DomainSettingsQuery, DomainSettingsQueryVariables>(
    DomainSettingsDocument,
    options,
  )
}
export function useDomainSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DomainSettingsQuery, DomainSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DomainSettingsQuery, DomainSettingsQueryVariables>(
    DomainSettingsDocument,
    options,
  )
}
export function useDomainSettingsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<DomainSettingsQuery, DomainSettingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DomainSettingsQuery, DomainSettingsQueryVariables>(
    DomainSettingsDocument,
    options,
  )
}
export type DomainSettingsQueryHookResult = ReturnType<typeof useDomainSettingsQuery>
export type DomainSettingsLazyQueryHookResult = ReturnType<typeof useDomainSettingsLazyQuery>
export type DomainSettingsSuspenseQueryHookResult = ReturnType<
  typeof useDomainSettingsSuspenseQuery
>
export type DomainSettingsQueryResult = Apollo.QueryResult<
  DomainSettingsQuery,
  DomainSettingsQueryVariables
>
export const CreateFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateFileInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createFile' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileFields' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateFileMutationFn = Apollo.MutationFunction<
  CreateFileMutation,
  CreateFileMutationVariables
>

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument,
    options,
  )
}
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFileMutation,
  CreateFileMutationVariables
>
export const UploadFileDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadFileData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contentType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadContentType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contentType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purpose' },
                value: { kind: 'EnumValue', value: 'FILE_DATA' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UploadFileDataMutationFn = Apollo.MutationFunction<
  UploadFileDataMutation,
  UploadFileDataMutationVariables
>

/**
 * __useUploadFileDataMutation__
 *
 * To run a mutation, you first call `useUploadFileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileDataMutation, { data, loading, error }] = useUploadFileDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadFileDataMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadFileDataMutation, UploadFileDataMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadFileDataMutation, UploadFileDataMutationVariables>(
    UploadFileDataDocument,
    options,
  )
}
export type UploadFileDataMutationHookResult = ReturnType<typeof useUploadFileDataMutation>
export type UploadFileDataMutationResult = Apollo.MutationResult<UploadFileDataMutation>
export type UploadFileDataMutationOptions = Apollo.BaseMutationOptions<
  UploadFileDataMutation,
  UploadFileDataMutationVariables
>
export const UploadPresentationDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadPresentationData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contentType' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UploadContentType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'contentType' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purpose' },
                value: { kind: 'EnumValue', value: 'PRESENTATION_QA' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UploadPresentationDataMutationFn = Apollo.MutationFunction<
  UploadPresentationDataMutation,
  UploadPresentationDataMutationVariables
>

/**
 * __useUploadPresentationDataMutation__
 *
 * To run a mutation, you first call `useUploadPresentationDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPresentationDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPresentationDataMutation, { data, loading, error }] = useUploadPresentationDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useUploadPresentationDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPresentationDataMutation,
    UploadPresentationDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadPresentationDataMutation,
    UploadPresentationDataMutationVariables
  >(UploadPresentationDataDocument, options)
}
export type UploadPresentationDataMutationHookResult = ReturnType<
  typeof useUploadPresentationDataMutation
>
export type UploadPresentationDataMutationResult =
  Apollo.MutationResult<UploadPresentationDataMutation>
export type UploadPresentationDataMutationOptions = Apollo.BaseMutationOptions<
  UploadPresentationDataMutation,
  UploadPresentationDataMutationVariables
>
export const CreateOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateOrgMutationFn = Apollo.MutationFunction<
  CreateOrgMutation,
  CreateOrgMutationVariables
>

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(
    CreateOrgDocument,
    options,
  )
}
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<
  CreateOrgMutation,
  CreateOrgMutationVariables
>
export const UpdateOrgDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateOrg' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateOrganizationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateOrganization' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'joinByDomain' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateOrgMutationFn = Apollo.MutationFunction<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>

/**
 * __useUpdateOrgMutation__
 *
 * To run a mutation, you first call `useUpdateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrgMutation, { data, loading, error }] = useUpdateOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrgMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrgMutation, UpdateOrgMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrgMutation, UpdateOrgMutationVariables>(
    UpdateOrgDocument,
    options,
  )
}
export type UpdateOrgMutationHookResult = ReturnType<typeof useUpdateOrgMutation>
export type UpdateOrgMutationResult = Apollo.MutationResult<UpdateOrgMutation>
export type UpdateOrgMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrgMutation,
  UpdateOrgMutationVariables
>
export const DeleteOrgUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrgUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeleteOrgUserMutationFn = Apollo.MutationFunction<
  DeleteOrgUserMutation,
  DeleteOrgUserMutationVariables
>

/**
 * __useDeleteOrgUserMutation__
 *
 * To run a mutation, you first call `useDeleteOrgUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgUserMutation, { data, loading, error }] = useDeleteOrgUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrgUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOrgUserMutation, DeleteOrgUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOrgUserMutation, DeleteOrgUserMutationVariables>(
    DeleteOrgUserDocument,
    options,
  )
}
export type DeleteOrgUserMutationHookResult = ReturnType<typeof useDeleteOrgUserMutation>
export type DeleteOrgUserMutationResult = Apollo.MutationResult<DeleteOrgUserMutation>
export type DeleteOrgUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgUserMutation,
  DeleteOrgUserMutationVariables
>
export const CreatePptCheckoutLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePPTCheckoutLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upgradeSource' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpgradeSource' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCheckoutLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'upgradeSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'upgradeSource' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreatePptCheckoutLinkMutationFn = Apollo.MutationFunction<
  CreatePptCheckoutLinkMutation,
  CreatePptCheckoutLinkMutationVariables
>

/**
 * __useCreatePptCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useCreatePptCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePptCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPptCheckoutLinkMutation, { data, loading, error }] = useCreatePptCheckoutLinkMutation({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      upgradeSource: // value for 'upgradeSource'
 *   },
 * });
 */
export function useCreatePptCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePptCheckoutLinkMutation,
    CreatePptCheckoutLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePptCheckoutLinkMutation, CreatePptCheckoutLinkMutationVariables>(
    CreatePptCheckoutLinkDocument,
    options,
  )
}
export type CreatePptCheckoutLinkMutationHookResult = ReturnType<
  typeof useCreatePptCheckoutLinkMutation
>
export type CreatePptCheckoutLinkMutationResult =
  Apollo.MutationResult<CreatePptCheckoutLinkMutation>
export type CreatePptCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  CreatePptCheckoutLinkMutation,
  CreatePptCheckoutLinkMutationVariables
>
export const CreatePaymentPortalLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentPortalLink' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [{ kind: 'Field', name: { kind: 'Name', value: 'createPaymentPortalLink' } }],
      },
    },
  ],
} as unknown as DocumentNode
export type CreatePaymentPortalLinkMutationFn = Apollo.MutationFunction<
  CreatePaymentPortalLinkMutation,
  CreatePaymentPortalLinkMutationVariables
>

/**
 * __useCreatePaymentPortalLinkMutation__
 *
 * To run a mutation, you first call `useCreatePaymentPortalLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentPortalLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentPortalLinkMutation, { data, loading, error }] = useCreatePaymentPortalLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreatePaymentPortalLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePaymentPortalLinkMutation,
    CreatePaymentPortalLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePaymentPortalLinkMutation,
    CreatePaymentPortalLinkMutationVariables
  >(CreatePaymentPortalLinkDocument, options)
}
export type CreatePaymentPortalLinkMutationHookResult = ReturnType<
  typeof useCreatePaymentPortalLinkMutation
>
export type CreatePaymentPortalLinkMutationResult =
  Apollo.MutationResult<CreatePaymentPortalLinkMutation>
export type CreatePaymentPortalLinkMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentPortalLinkMutation,
  CreatePaymentPortalLinkMutationVariables
>
export const CreateOutlineDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateOutline' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePresentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createPresentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateOutlineMutationFn = Apollo.MutationFunction<
  CreateOutlineMutation,
  CreateOutlineMutationVariables
>

/**
 * __useCreateOutlineMutation__
 *
 * To run a mutation, you first call `useCreateOutlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOutlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOutlineMutation, { data, loading, error }] = useCreateOutlineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOutlineMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOutlineMutation, CreateOutlineMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOutlineMutation, CreateOutlineMutationVariables>(
    CreateOutlineDocument,
    options,
  )
}
export type CreateOutlineMutationHookResult = ReturnType<typeof useCreateOutlineMutation>
export type CreateOutlineMutationResult = Apollo.MutationResult<CreateOutlineMutation>
export type CreateOutlineMutationOptions = Apollo.BaseMutationOptions<
  CreateOutlineMutation,
  CreateOutlineMutationVariables
>
export const CreatePresentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreatePresentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'regeneratePresentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreatePresentationMutationFn = Apollo.MutationFunction<
  CreatePresentationMutation,
  CreatePresentationMutationVariables
>

/**
 * __useCreatePresentationMutation__
 *
 * To run a mutation, you first call `useCreatePresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationMutation, { data, loading, error }] = useCreatePresentationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePresentationMutation,
    CreatePresentationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreatePresentationMutation, CreatePresentationMutationVariables>(
    CreatePresentationDocument,
    options,
  )
}
export type CreatePresentationMutationHookResult = ReturnType<typeof useCreatePresentationMutation>
export type CreatePresentationMutationResult = Apollo.MutationResult<CreatePresentationMutation>
export type CreatePresentationMutationOptions = Apollo.BaseMutationOptions<
  CreatePresentationMutation,
  CreatePresentationMutationVariables
>
export const RewriteFullPresentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RewriteFullPresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RewritePresentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewritePresentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type RewriteFullPresentationMutationFn = Apollo.MutationFunction<
  RewriteFullPresentationMutation,
  RewriteFullPresentationMutationVariables
>

/**
 * __useRewriteFullPresentationMutation__
 *
 * To run a mutation, you first call `useRewriteFullPresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewriteFullPresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewriteFullPresentationMutation, { data, loading, error }] = useRewriteFullPresentationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRewriteFullPresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RewriteFullPresentationMutation,
    RewriteFullPresentationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RewriteFullPresentationMutation,
    RewriteFullPresentationMutationVariables
  >(RewriteFullPresentationDocument, options)
}
export type RewriteFullPresentationMutationHookResult = ReturnType<
  typeof useRewriteFullPresentationMutation
>
export type RewriteFullPresentationMutationResult =
  Apollo.MutationResult<RewriteFullPresentationMutation>
export type RewriteFullPresentationMutationOptions = Apollo.BaseMutationOptions<
  RewriteFullPresentationMutation,
  RewriteFullPresentationMutationVariables
>
export const UpdatePresentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdatePresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdatePresentationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatePresentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdatePresentationMutationFn = Apollo.MutationFunction<
  UpdatePresentationMutation,
  UpdatePresentationMutationVariables
>

/**
 * __useUpdatePresentationMutation__
 *
 * To run a mutation, you first call `useUpdatePresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePresentationMutation, { data, loading, error }] = useUpdatePresentationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePresentationMutation,
    UpdatePresentationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdatePresentationMutation, UpdatePresentationMutationVariables>(
    UpdatePresentationDocument,
    options,
  )
}
export type UpdatePresentationMutationHookResult = ReturnType<typeof useUpdatePresentationMutation>
export type UpdatePresentationMutationResult = Apollo.MutationResult<UpdatePresentationMutation>
export type UpdatePresentationMutationOptions = Apollo.BaseMutationOptions<
  UpdatePresentationMutation,
  UpdatePresentationMutationVariables
>
export const SendPresentationFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendPresentationFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FeedbackInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendPresentationFeedback' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type SendPresentationFeedbackMutationFn = Apollo.MutationFunction<
  SendPresentationFeedbackMutation,
  SendPresentationFeedbackMutationVariables
>

/**
 * __useSendPresentationFeedbackMutation__
 *
 * To run a mutation, you first call `useSendPresentationFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPresentationFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPresentationFeedbackMutation, { data, loading, error }] = useSendPresentationFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendPresentationFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPresentationFeedbackMutation,
    SendPresentationFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendPresentationFeedbackMutation,
    SendPresentationFeedbackMutationVariables
  >(SendPresentationFeedbackDocument, options)
}
export type SendPresentationFeedbackMutationHookResult = ReturnType<
  typeof useSendPresentationFeedbackMutation
>
export type SendPresentationFeedbackMutationResult =
  Apollo.MutationResult<SendPresentationFeedbackMutation>
export type SendPresentationFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SendPresentationFeedbackMutation,
  SendPresentationFeedbackMutationVariables
>
export const MarkPresentationRenderedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MarkPresentationRendered' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'markPresentationRendered' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type MarkPresentationRenderedMutationFn = Apollo.MutationFunction<
  MarkPresentationRenderedMutation,
  MarkPresentationRenderedMutationVariables
>

/**
 * __useMarkPresentationRenderedMutation__
 *
 * To run a mutation, you first call `useMarkPresentationRenderedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPresentationRenderedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPresentationRenderedMutation, { data, loading, error }] = useMarkPresentationRenderedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkPresentationRenderedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPresentationRenderedMutation,
    MarkPresentationRenderedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkPresentationRenderedMutation,
    MarkPresentationRenderedMutationVariables
  >(MarkPresentationRenderedDocument, options)
}
export type MarkPresentationRenderedMutationHookResult = ReturnType<
  typeof useMarkPresentationRenderedMutation
>
export type MarkPresentationRenderedMutationResult =
  Apollo.MutationResult<MarkPresentationRenderedMutation>
export type MarkPresentationRenderedMutationOptions = Apollo.BaseMutationOptions<
  MarkPresentationRenderedMutation,
  MarkPresentationRenderedMutationVariables
>
export const DeletePresentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'deletePresentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeletePresentationMutationFn = Apollo.MutationFunction<
  DeletePresentationMutation,
  DeletePresentationMutationVariables
>

/**
 * __useDeletePresentationMutation__
 *
 * To run a mutation, you first call `useDeletePresentationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePresentationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePresentationMutation, { data, loading, error }] = useDeletePresentationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePresentationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePresentationMutation,
    DeletePresentationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePresentationMutation, DeletePresentationMutationVariables>(
    DeletePresentationDocument,
    options,
  )
}
export type DeletePresentationMutationHookResult = ReturnType<typeof useDeletePresentationMutation>
export type DeletePresentationMutationResult = Apollo.MutationResult<DeletePresentationMutation>
export type DeletePresentationMutationOptions = Apollo.BaseMutationOptions<
  DeletePresentationMutation,
  DeletePresentationMutationVariables
>
export const CreatePresentationFromTextDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePresentationFromText' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePresentationFromTextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createPresentationFromText' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'presentation' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreatePresentationFromTextMutationFn = Apollo.MutationFunction<
  CreatePresentationFromTextMutation,
  CreatePresentationFromTextMutationVariables
>

/**
 * __useCreatePresentationFromTextMutation__
 *
 * To run a mutation, you first call `useCreatePresentationFromTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePresentationFromTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPresentationFromTextMutation, { data, loading, error }] = useCreatePresentationFromTextMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePresentationFromTextMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePresentationFromTextMutation,
    CreatePresentationFromTextMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreatePresentationFromTextMutation,
    CreatePresentationFromTextMutationVariables
  >(CreatePresentationFromTextDocument, options)
}
export type CreatePresentationFromTextMutationHookResult = ReturnType<
  typeof useCreatePresentationFromTextMutation
>
export type CreatePresentationFromTextMutationResult =
  Apollo.MutationResult<CreatePresentationFromTextMutation>
export type CreatePresentationFromTextMutationOptions = Apollo.BaseMutationOptions<
  CreatePresentationFromTextMutation,
  CreatePresentationFromTextMutationVariables
>
export const GetPresentationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPresentations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presentations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preset' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'prompt' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'numberOfSlides' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'richTextState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slides' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SlidePromptData' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'useCustomInstructions' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'googleSlidesId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlidePromptData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SlidePrompt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmsSlideSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slideType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetPresentationsQuery__
 *
 * To run a query within a React component, call `useGetPresentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresentationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresentationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetPresentationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPresentationsQuery, GetPresentationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPresentationsQuery, GetPresentationsQueryVariables>(
    GetPresentationsDocument,
    options,
  )
}
export function useGetPresentationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPresentationsQuery, GetPresentationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPresentationsQuery, GetPresentationsQueryVariables>(
    GetPresentationsDocument,
    options,
  )
}
export function useGetPresentationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPresentationsQuery,
    GetPresentationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPresentationsQuery, GetPresentationsQueryVariables>(
    GetPresentationsDocument,
    options,
  )
}
export type GetPresentationsQueryHookResult = ReturnType<typeof useGetPresentationsQuery>
export type GetPresentationsLazyQueryHookResult = ReturnType<typeof useGetPresentationsLazyQuery>
export type GetPresentationsSuspenseQueryHookResult = ReturnType<
  typeof useGetPresentationsSuspenseQuery
>
export type GetPresentationsQueryResult = Apollo.QueryResult<
  GetPresentationsQuery,
  GetPresentationsQueryVariables
>
export const GetBlueprintPresentationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBlueprintPresentations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presentations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'isBlueprint' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'preset' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'prompt' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'context' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'numberOfSlides' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'richTextState' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'slides' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SlidePromptData' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'useCustomInstructions' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'googleSlidesId' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlidePromptData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SlidePrompt' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'cmsSlideSlug' } },
          { kind: 'Field', name: { kind: 'Name', value: 'slideType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'content' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetBlueprintPresentationsQuery__
 *
 * To run a query within a React component, call `useGetBlueprintPresentationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlueprintPresentationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlueprintPresentationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetBlueprintPresentationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBlueprintPresentationsQuery,
    GetBlueprintPresentationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBlueprintPresentationsQuery, GetBlueprintPresentationsQueryVariables>(
    GetBlueprintPresentationsDocument,
    options,
  )
}
export function useGetBlueprintPresentationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBlueprintPresentationsQuery,
    GetBlueprintPresentationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBlueprintPresentationsQuery,
    GetBlueprintPresentationsQueryVariables
  >(GetBlueprintPresentationsDocument, options)
}
export function useGetBlueprintPresentationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBlueprintPresentationsQuery,
    GetBlueprintPresentationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetBlueprintPresentationsQuery,
    GetBlueprintPresentationsQueryVariables
  >(GetBlueprintPresentationsDocument, options)
}
export type GetBlueprintPresentationsQueryHookResult = ReturnType<
  typeof useGetBlueprintPresentationsQuery
>
export type GetBlueprintPresentationsLazyQueryHookResult = ReturnType<
  typeof useGetBlueprintPresentationsLazyQuery
>
export type GetBlueprintPresentationsSuspenseQueryHookResult = ReturnType<
  typeof useGetBlueprintPresentationsSuspenseQuery
>
export type GetBlueprintPresentationsQueryResult = Apollo.QueryResult<
  GetBlueprintPresentationsQuery,
  GetBlueprintPresentationsQueryVariables
>
export const GetPresentationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPresentation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'presentation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'preset' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slides' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideType' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'chapterTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userTip' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideAgendaSimple' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideChartText' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chart' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'callout' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideMetricsSimple' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metrics' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideChartSingle' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chart' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'callout' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2Columns' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'columns' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoints' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2ColumnsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'columns' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoints' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextProCon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'conHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'conBulletPoints' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'proBulletPoints' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextImage' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'landscape' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'portrait' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'square' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageSuggestion' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideImage2Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'landscape' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'portrait' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'square' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageSuggestion' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideImage3Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'landscape' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'portrait' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'square' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageSuggestion' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText1Paragraph' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText1Column' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2Quotes' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextStatement' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statement' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTeam' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teamMembers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'background' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideProcess4Step' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processSteps' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'deliverable' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideVennDiagram' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupAHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupACharacteristics' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupBHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupAAndBCharacteristics' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupBCharacteristics' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetPresentationQuery__
 *
 * To run a query within a React component, call `useGetPresentationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPresentationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPresentationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPresentationQuery(
  baseOptions: Apollo.QueryHookOptions<GetPresentationQuery, GetPresentationQueryVariables> &
    ({ variables: GetPresentationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPresentationQuery, GetPresentationQueryVariables>(
    GetPresentationDocument,
    options,
  )
}
export function useGetPresentationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPresentationQuery, GetPresentationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPresentationQuery, GetPresentationQueryVariables>(
    GetPresentationDocument,
    options,
  )
}
export function useGetPresentationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPresentationQuery,
    GetPresentationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPresentationQuery, GetPresentationQueryVariables>(
    GetPresentationDocument,
    options,
  )
}
export type GetPresentationQueryHookResult = ReturnType<typeof useGetPresentationQuery>
export type GetPresentationLazyQueryHookResult = ReturnType<typeof useGetPresentationLazyQuery>
export type GetPresentationSuspenseQueryHookResult = ReturnType<
  typeof useGetPresentationSuspenseQuery
>
export type GetPresentationQueryResult = Apollo.QueryResult<
  GetPresentationQuery,
  GetPresentationQueryVariables
>
export const SaveCustomInstructionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveCustomInstructions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CustomInstructionsInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'saveCustomInstructions' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type SaveCustomInstructionsMutationFn = Apollo.MutationFunction<
  SaveCustomInstructionsMutation,
  SaveCustomInstructionsMutationVariables
>

/**
 * __useSaveCustomInstructionsMutation__
 *
 * To run a mutation, you first call `useSaveCustomInstructionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveCustomInstructionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveCustomInstructionsMutation, { data, loading, error }] = useSaveCustomInstructionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveCustomInstructionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCustomInstructionsMutation,
    SaveCustomInstructionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SaveCustomInstructionsMutation,
    SaveCustomInstructionsMutationVariables
  >(SaveCustomInstructionsDocument, options)
}
export type SaveCustomInstructionsMutationHookResult = ReturnType<
  typeof useSaveCustomInstructionsMutation
>
export type SaveCustomInstructionsMutationResult =
  Apollo.MutationResult<SaveCustomInstructionsMutation>
export type SaveCustomInstructionsMutationOptions = Apollo.BaseMutationOptions<
  SaveCustomInstructionsMutation,
  SaveCustomInstructionsMutationVariables
>
export const RewriteSingleSlideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RewriteSingleSlide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RewriteSlideInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'rewriteSlide' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type RewriteSingleSlideMutationFn = Apollo.MutationFunction<
  RewriteSingleSlideMutation,
  RewriteSingleSlideMutationVariables
>

/**
 * __useRewriteSingleSlideMutation__
 *
 * To run a mutation, you first call `useRewriteSingleSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRewriteSingleSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rewriteSingleSlideMutation, { data, loading, error }] = useRewriteSingleSlideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRewriteSingleSlideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RewriteSingleSlideMutation,
    RewriteSingleSlideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RewriteSingleSlideMutation, RewriteSingleSlideMutationVariables>(
    RewriteSingleSlideDocument,
    options,
  )
}
export type RewriteSingleSlideMutationHookResult = ReturnType<typeof useRewriteSingleSlideMutation>
export type RewriteSingleSlideMutationResult = Apollo.MutationResult<RewriteSingleSlideMutation>
export type RewriteSingleSlideMutationOptions = Apollo.BaseMutationOptions<
  RewriteSingleSlideMutation,
  RewriteSingleSlideMutationVariables
>
export const CreateSingleSlideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateSingleSlide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateSlideInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createSlide' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slide' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateSingleSlideMutationFn = Apollo.MutationFunction<
  CreateSingleSlideMutation,
  CreateSingleSlideMutationVariables
>

/**
 * __useCreateSingleSlideMutation__
 *
 * To run a mutation, you first call `useCreateSingleSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleSlideMutation, { data, loading, error }] = useCreateSingleSlideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSingleSlideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSingleSlideMutation,
    CreateSingleSlideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSingleSlideMutation, CreateSingleSlideMutationVariables>(
    CreateSingleSlideDocument,
    options,
  )
}
export type CreateSingleSlideMutationHookResult = ReturnType<typeof useCreateSingleSlideMutation>
export type CreateSingleSlideMutationResult = Apollo.MutationResult<CreateSingleSlideMutation>
export type CreateSingleSlideMutationOptions = Apollo.BaseMutationOptions<
  CreateSingleSlideMutation,
  CreateSingleSlideMutationVariables
>
export const RemixSingleSlideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RemixSingleSlide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'RemixSlideInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'remixSlide' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slide' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type RemixSingleSlideMutationFn = Apollo.MutationFunction<
  RemixSingleSlideMutation,
  RemixSingleSlideMutationVariables
>

/**
 * __useRemixSingleSlideMutation__
 *
 * To run a mutation, you first call `useRemixSingleSlideMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemixSingleSlideMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remixSingleSlideMutation, { data, loading, error }] = useRemixSingleSlideMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemixSingleSlideMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemixSingleSlideMutation,
    RemixSingleSlideMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemixSingleSlideMutation, RemixSingleSlideMutationVariables>(
    RemixSingleSlideDocument,
    options,
  )
}
export type RemixSingleSlideMutationHookResult = ReturnType<typeof useRemixSingleSlideMutation>
export type RemixSingleSlideMutationResult = Apollo.MutationResult<RemixSingleSlideMutation>
export type RemixSingleSlideMutationOptions = Apollo.BaseMutationOptions<
  RemixSingleSlideMutation,
  RemixSingleSlideMutationVariables
>
export const GetSlideImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'GetSlideImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SlideImageInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getSlideImage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type GetSlideImageMutationFn = Apollo.MutationFunction<
  GetSlideImageMutation,
  GetSlideImageMutationVariables
>

/**
 * __useGetSlideImageMutation__
 *
 * To run a mutation, you first call `useGetSlideImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSlideImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSlideImageMutation, { data, loading, error }] = useGetSlideImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSlideImageMutation(
  baseOptions?: Apollo.MutationHookOptions<GetSlideImageMutation, GetSlideImageMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetSlideImageMutation, GetSlideImageMutationVariables>(
    GetSlideImageDocument,
    options,
  )
}
export type GetSlideImageMutationHookResult = ReturnType<typeof useGetSlideImageMutation>
export type GetSlideImageMutationResult = Apollo.MutationResult<GetSlideImageMutation>
export type GetSlideImageMutationOptions = Apollo.BaseMutationOptions<
  GetSlideImageMutation,
  GetSlideImageMutationVariables
>
export const SendSlideFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendSlideFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'FeedbackInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendSlideFeedback' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slide' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type SendSlideFeedbackMutationFn = Apollo.MutationFunction<
  SendSlideFeedbackMutation,
  SendSlideFeedbackMutationVariables
>

/**
 * __useSendSlideFeedbackMutation__
 *
 * To run a mutation, you first call `useSendSlideFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSlideFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSlideFeedbackMutation, { data, loading, error }] = useSendSlideFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendSlideFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendSlideFeedbackMutation,
    SendSlideFeedbackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendSlideFeedbackMutation, SendSlideFeedbackMutationVariables>(
    SendSlideFeedbackDocument,
    options,
  )
}
export type SendSlideFeedbackMutationHookResult = ReturnType<typeof useSendSlideFeedbackMutation>
export type SendSlideFeedbackMutationResult = Apollo.MutationResult<SendSlideFeedbackMutation>
export type SendSlideFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SendSlideFeedbackMutation,
  SendSlideFeedbackMutationVariables
>
export const GetSlideDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlide' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slide' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slideContent' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideType' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'chapterTitle' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'userTip' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideAgendaSimple' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideChartText' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chart' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'callout' } },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideMetricsSimple' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'metrics' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideChartSingle' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'chart' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'callout' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2Columns' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'columns' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoints' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2ColumnsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'columns' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoints' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6Bullets' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6BulletsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'bulletSections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'bulletPoint' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText3SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText4SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText6SectionsIcon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'iconUrl' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextProCon' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'conHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'conBulletPoints' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'proHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'proBulletPoints' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextImage' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'landscape' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'portrait' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'square' } },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'imageSuggestion' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideImage2Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'landscape' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'portrait' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'square' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageSuggestion' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideImage3Sections' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'image' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'landscape' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'portrait' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'square' },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'imageSuggestion' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText1Paragraph' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText1Column' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'bulletPoints' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideText2Quotes' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'sections' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'source' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTextStatement' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'statement' } },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideTeam' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'teamMembers' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'background' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideProcess4Step' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'processSteps' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'deliverable' },
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'detail' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'header' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'SlideVennDiagram' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupAHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupACharacteristics' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'groupBHeader' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupAAndBCharacteristics' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'groupBCharacteristics' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetSlideQuery__
 *
 * To run a query within a React component, call `useGetSlideQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSlideQuery(
  baseOptions: Apollo.QueryHookOptions<GetSlideQuery, GetSlideQueryVariables> &
    ({ variables: GetSlideQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSlideQuery, GetSlideQueryVariables>(GetSlideDocument, options)
}
export function useGetSlideLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSlideQuery, GetSlideQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSlideQuery, GetSlideQueryVariables>(GetSlideDocument, options)
}
export function useGetSlideSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetSlideQuery, GetSlideQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSlideQuery, GetSlideQueryVariables>(GetSlideDocument, options)
}
export type GetSlideQueryHookResult = ReturnType<typeof useGetSlideQuery>
export type GetSlideLazyQueryHookResult = ReturnType<typeof useGetSlideLazyQuery>
export type GetSlideSuspenseQueryHookResult = ReturnType<typeof useGetSlideSuspenseQuery>
export type GetSlideQueryResult = Apollo.QueryResult<GetSlideQuery, GetSlideQueryVariables>
export const TrackUserActivityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackUserActivity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'activity' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserActivityInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackUserActivity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activity' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'activity' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type TrackUserActivityMutationFn = Apollo.MutationFunction<
  TrackUserActivityMutation,
  TrackUserActivityMutationVariables
>

/**
 * __useTrackUserActivityMutation__
 *
 * To run a mutation, you first call `useTrackUserActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackUserActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackUserActivityMutation, { data, loading, error }] = useTrackUserActivityMutation({
 *   variables: {
 *      activity: // value for 'activity'
 *   },
 * });
 */
export function useTrackUserActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackUserActivityMutation,
    TrackUserActivityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TrackUserActivityMutation, TrackUserActivityMutationVariables>(
    TrackUserActivityDocument,
    options,
  )
}
export type TrackUserActivityMutationHookResult = ReturnType<typeof useTrackUserActivityMutation>
export type TrackUserActivityMutationResult = Apollo.MutationResult<TrackUserActivityMutation>
export type TrackUserActivityMutationOptions = Apollo.BaseMutationOptions<
  TrackUserActivityMutation,
  TrackUserActivityMutationVariables
>
export const SendInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SendInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'sendInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type SendInviteMutationFn = Apollo.MutationFunction<
  SendInviteMutation,
  SendInviteMutationVariables
>

/**
 * __useSendInviteMutation__
 *
 * To run a mutation, you first call `useSendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendInviteMutation, { data, loading, error }] = useSendInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<SendInviteMutation, SendInviteMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendInviteMutation, SendInviteMutationVariables>(
    SendInviteDocument,
    options,
  )
}
export type SendInviteMutationHookResult = ReturnType<typeof useSendInviteMutation>
export type SendInviteMutationResult = Apollo.MutationResult<SendInviteMutation>
export type SendInviteMutationOptions = Apollo.BaseMutationOptions<
  SendInviteMutation,
  SendInviteMutationVariables
>
export const DeleteOrgInviteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteOrgInvite' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeleteOrgInviteMutationFn = Apollo.MutationFunction<
  DeleteOrgInviteMutation,
  DeleteOrgInviteMutationVariables
>

/**
 * __useDeleteOrgInviteMutation__
 *
 * To run a mutation, you first call `useDeleteOrgInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgInviteMutation, { data, loading, error }] = useDeleteOrgInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrgInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOrgInviteMutation,
    DeleteOrgInviteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteOrgInviteMutation, DeleteOrgInviteMutationVariables>(
    DeleteOrgInviteDocument,
    options,
  )
}
export type DeleteOrgInviteMutationHookResult = ReturnType<typeof useDeleteOrgInviteMutation>
export type DeleteOrgInviteMutationResult = Apollo.MutationResult<DeleteOrgInviteMutation>
export type DeleteOrgInviteMutationOptions = Apollo.BaseMutationOptions<
  DeleteOrgInviteMutation,
  DeleteOrgInviteMutationVariables
>
export const PptDownloadUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pptDownloadUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pptDownloadUrl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __usePptDownloadUrlQuery__
 *
 * To run a query within a React component, call `usePptDownloadUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePptDownloadUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePptDownloadUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePptDownloadUrlQuery(
  baseOptions: Apollo.QueryHookOptions<PptDownloadUrlQuery, PptDownloadUrlQueryVariables> &
    ({ variables: PptDownloadUrlQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PptDownloadUrlQuery, PptDownloadUrlQueryVariables>(
    PptDownloadUrlDocument,
    options,
  )
}
export function usePptDownloadUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PptDownloadUrlQuery, PptDownloadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PptDownloadUrlQuery, PptDownloadUrlQueryVariables>(
    PptDownloadUrlDocument,
    options,
  )
}
export function usePptDownloadUrlSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<PptDownloadUrlQuery, PptDownloadUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PptDownloadUrlQuery, PptDownloadUrlQueryVariables>(
    PptDownloadUrlDocument,
    options,
  )
}
export type PptDownloadUrlQueryHookResult = ReturnType<typeof usePptDownloadUrlQuery>
export type PptDownloadUrlLazyQueryHookResult = ReturnType<typeof usePptDownloadUrlLazyQuery>
export type PptDownloadUrlSuspenseQueryHookResult = ReturnType<
  typeof usePptDownloadUrlSuspenseQuery
>
export type PptDownloadUrlQueryResult = Apollo.QueryResult<
  PptDownloadUrlQuery,
  PptDownloadUrlQueryVariables
>
export const IdentitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Identities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IdentitiesFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snapshots' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'sort' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sort' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: { kind: 'Name', value: 'SnapshotFields' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'endCursor' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hasNextPage' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'hasPreviousPage' },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'startCursor' },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useIdentitiesQuery__
 *
 * To run a query within a React component, call `useIdentitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useIdentitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<IdentitiesQuery, IdentitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IdentitiesQuery, IdentitiesQueryVariables>(IdentitiesDocument, options)
}
export function useIdentitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IdentitiesQuery, IdentitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IdentitiesQuery, IdentitiesQueryVariables>(IdentitiesDocument, options)
}
export function useIdentitiesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IdentitiesQuery, IdentitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IdentitiesQuery, IdentitiesQueryVariables>(
    IdentitiesDocument,
    options,
  )
}
export type IdentitiesQueryHookResult = ReturnType<typeof useIdentitiesQuery>
export type IdentitiesLazyQueryHookResult = ReturnType<typeof useIdentitiesLazyQuery>
export type IdentitiesSuspenseQueryHookResult = ReturnType<typeof useIdentitiesSuspenseQuery>
export type IdentitiesQueryResult = Apollo.QueryResult<IdentitiesQuery, IdentitiesQueryVariables>
export const IdentityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Identity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotsFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshots' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'sort' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SnapshotFields' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useIdentityQuery__
 *
 * To run a query within a React component, call `useIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useIdentityQuery(
  baseOptions: Apollo.QueryHookOptions<IdentityQuery, IdentityQueryVariables> &
    ({ variables: IdentityQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IdentityQuery, IdentityQueryVariables>(IdentityDocument, options)
}
export function useIdentityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IdentityQuery, IdentityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IdentityQuery, IdentityQueryVariables>(IdentityDocument, options)
}
export function useIdentitySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<IdentityQuery, IdentityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IdentityQuery, IdentityQueryVariables>(IdentityDocument, options)
}
export type IdentityQueryHookResult = ReturnType<typeof useIdentityQuery>
export type IdentityLazyQueryHookResult = ReturnType<typeof useIdentityLazyQuery>
export type IdentitySuspenseQueryHookResult = ReturnType<typeof useIdentitySuspenseQuery>
export type IdentityQueryResult = Apollo.QueryResult<IdentityQuery, IdentityQueryVariables>
export const SnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Snapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotQuery__
 *
 * To run a query within a React component, call `useSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnapshotQuery(
  baseOptions: Apollo.QueryHookOptions<SnapshotQuery, SnapshotQueryVariables> &
    ({ variables: SnapshotQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotQuery, SnapshotQueryVariables>(SnapshotDocument, options)
}
export function useSnapshotLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SnapshotQuery, SnapshotQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotQuery, SnapshotQueryVariables>(SnapshotDocument, options)
}
export function useSnapshotSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SnapshotQuery, SnapshotQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SnapshotQuery, SnapshotQueryVariables>(SnapshotDocument, options)
}
export type SnapshotQueryHookResult = ReturnType<typeof useSnapshotQuery>
export type SnapshotLazyQueryHookResult = ReturnType<typeof useSnapshotLazyQuery>
export type SnapshotSuspenseQueryHookResult = ReturnType<typeof useSnapshotSuspenseQuery>
export type SnapshotQueryResult = Apollo.QueryResult<SnapshotQuery, SnapshotQueryVariables>
export const OnSnapshotUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnSnapshotUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URN' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'urn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useOnSnapshotUpdateSubscription__
 *
 * To run a query within a React component, call `useOnSnapshotUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSnapshotUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSnapshotUpdateSubscription({
 *   variables: {
 *      urn: // value for 'urn'
 *   },
 * });
 */
export function useOnSnapshotUpdateSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnSnapshotUpdateSubscription,
    OnSnapshotUpdateSubscriptionVariables
  > &
    ({ variables: OnSnapshotUpdateSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OnSnapshotUpdateSubscription,
    OnSnapshotUpdateSubscriptionVariables
  >(OnSnapshotUpdateDocument, options)
}
export type OnSnapshotUpdateSubscriptionHookResult = ReturnType<
  typeof useOnSnapshotUpdateSubscription
>
export type OnSnapshotUpdateSubscriptionResult =
  Apollo.SubscriptionResult<OnSnapshotUpdateSubscription>
export const SnapshotStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SnapshotStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotStatusQuery__
 *
 * To run a query within a React component, call `useSnapshotStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnapshotStatusQuery(
  baseOptions: Apollo.QueryHookOptions<SnapshotStatusQuery, SnapshotStatusQueryVariables> &
    ({ variables: SnapshotStatusQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotStatusQuery, SnapshotStatusQueryVariables>(
    SnapshotStatusDocument,
    options,
  )
}
export function useSnapshotStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SnapshotStatusQuery, SnapshotStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotStatusQuery, SnapshotStatusQueryVariables>(
    SnapshotStatusDocument,
    options,
  )
}
export function useSnapshotStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SnapshotStatusQuery, SnapshotStatusQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SnapshotStatusQuery, SnapshotStatusQueryVariables>(
    SnapshotStatusDocument,
    options,
  )
}
export type SnapshotStatusQueryHookResult = ReturnType<typeof useSnapshotStatusQuery>
export type SnapshotStatusLazyQueryHookResult = ReturnType<typeof useSnapshotStatusLazyQuery>
export type SnapshotStatusSuspenseQueryHookResult = ReturnType<
  typeof useSnapshotStatusSuspenseQuery
>
export type SnapshotStatusQueryResult = Apollo.QueryResult<
  SnapshotStatusQuery,
  SnapshotStatusQueryVariables
>
export const SnapshotCurrentVersionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SnapshotCurrentVersion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotCurrentVersionQuery__
 *
 * To run a query within a React component, call `useSnapshotCurrentVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotCurrentVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotCurrentVersionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnapshotCurrentVersionQuery(
  baseOptions: Apollo.QueryHookOptions<
    SnapshotCurrentVersionQuery,
    SnapshotCurrentVersionQueryVariables
  > &
    ({ variables: SnapshotCurrentVersionQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotCurrentVersionQuery, SnapshotCurrentVersionQueryVariables>(
    SnapshotCurrentVersionDocument,
    options,
  )
}
export function useSnapshotCurrentVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnapshotCurrentVersionQuery,
    SnapshotCurrentVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotCurrentVersionQuery, SnapshotCurrentVersionQueryVariables>(
    SnapshotCurrentVersionDocument,
    options,
  )
}
export function useSnapshotCurrentVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SnapshotCurrentVersionQuery,
    SnapshotCurrentVersionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SnapshotCurrentVersionQuery, SnapshotCurrentVersionQueryVariables>(
    SnapshotCurrentVersionDocument,
    options,
  )
}
export type SnapshotCurrentVersionQueryHookResult = ReturnType<
  typeof useSnapshotCurrentVersionQuery
>
export type SnapshotCurrentVersionLazyQueryHookResult = ReturnType<
  typeof useSnapshotCurrentVersionLazyQuery
>
export type SnapshotCurrentVersionSuspenseQueryHookResult = ReturnType<
  typeof useSnapshotCurrentVersionSuspenseQuery
>
export type SnapshotCurrentVersionQueryResult = Apollo.QueryResult<
  SnapshotCurrentVersionQuery,
  SnapshotCurrentVersionQueryVariables
>
export const SnapshotVersionBeforeIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SnapshotVersionBeforeId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'versions' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'before' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'versionId' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SnapshotVersionData' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotVersionBeforeIdQuery__
 *
 * To run a query within a React component, call `useSnapshotVersionBeforeIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotVersionBeforeIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotVersionBeforeIdQuery({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *      versionId: // value for 'versionId'
 *   },
 * });
 */
export function useSnapshotVersionBeforeIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SnapshotVersionBeforeIdQuery,
    SnapshotVersionBeforeIdQueryVariables
  > &
    ({ variables: SnapshotVersionBeforeIdQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotVersionBeforeIdQuery, SnapshotVersionBeforeIdQueryVariables>(
    SnapshotVersionBeforeIdDocument,
    options,
  )
}
export function useSnapshotVersionBeforeIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnapshotVersionBeforeIdQuery,
    SnapshotVersionBeforeIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotVersionBeforeIdQuery, SnapshotVersionBeforeIdQueryVariables>(
    SnapshotVersionBeforeIdDocument,
    options,
  )
}
export function useSnapshotVersionBeforeIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SnapshotVersionBeforeIdQuery,
    SnapshotVersionBeforeIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    SnapshotVersionBeforeIdQuery,
    SnapshotVersionBeforeIdQueryVariables
  >(SnapshotVersionBeforeIdDocument, options)
}
export type SnapshotVersionBeforeIdQueryHookResult = ReturnType<
  typeof useSnapshotVersionBeforeIdQuery
>
export type SnapshotVersionBeforeIdLazyQueryHookResult = ReturnType<
  typeof useSnapshotVersionBeforeIdLazyQuery
>
export type SnapshotVersionBeforeIdSuspenseQueryHookResult = ReturnType<
  typeof useSnapshotVersionBeforeIdSuspenseQuery
>
export type SnapshotVersionBeforeIdQueryResult = Apollo.QueryResult<
  SnapshotVersionBeforeIdQuery,
  SnapshotVersionBeforeIdQueryVariables
>
export const PublicSnapshotDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicSnapshotData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'overlay' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'schedule' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instructions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'frame' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'latestVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'versions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'allVersions' },
                        name: { kind: 'Name', value: 'versions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PublicSnapshotVersionData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasPreviousPage' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PublicSnapshotViewer' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __usePublicSnapshotDataQuery__
 *
 * To run a query within a React component, call `usePublicSnapshotDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSnapshotDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSnapshotDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublicSnapshotDataQuery(
  baseOptions: Apollo.QueryHookOptions<PublicSnapshotDataQuery, PublicSnapshotDataQueryVariables> &
    ({ variables: PublicSnapshotDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PublicSnapshotDataQuery, PublicSnapshotDataQueryVariables>(
    PublicSnapshotDataDocument,
    options,
  )
}
export function usePublicSnapshotDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicSnapshotDataQuery,
    PublicSnapshotDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PublicSnapshotDataQuery, PublicSnapshotDataQueryVariables>(
    PublicSnapshotDataDocument,
    options,
  )
}
export function usePublicSnapshotDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PublicSnapshotDataQuery,
    PublicSnapshotDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PublicSnapshotDataQuery, PublicSnapshotDataQueryVariables>(
    PublicSnapshotDataDocument,
    options,
  )
}
export type PublicSnapshotDataQueryHookResult = ReturnType<typeof usePublicSnapshotDataQuery>
export type PublicSnapshotDataLazyQueryHookResult = ReturnType<
  typeof usePublicSnapshotDataLazyQuery
>
export type PublicSnapshotDataSuspenseQueryHookResult = ReturnType<
  typeof usePublicSnapshotDataSuspenseQuery
>
export type PublicSnapshotDataQueryResult = Apollo.QueryResult<
  PublicSnapshotDataQuery,
  PublicSnapshotDataQueryVariables
>
export const SnapshotVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SnapshotVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'latestVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'allVersions' },
                  name: { kind: 'Name', value: 'versions' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'filter' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'captureStatus' },
                            value: {
                              kind: 'ListValue',
                              values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'after' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'before' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'edges' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'node' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'SnapshotVersionData' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pageInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotVersionsQuery__
 *
 * To run a query within a React component, call `useSnapshotVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useSnapshotVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<SnapshotVersionsQuery, SnapshotVersionsQueryVariables> &
    ({ variables: SnapshotVersionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotVersionsQuery, SnapshotVersionsQueryVariables>(
    SnapshotVersionsDocument,
    options,
  )
}
export function useSnapshotVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SnapshotVersionsQuery, SnapshotVersionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotVersionsQuery, SnapshotVersionsQueryVariables>(
    SnapshotVersionsDocument,
    options,
  )
}
export function useSnapshotVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SnapshotVersionsQuery,
    SnapshotVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SnapshotVersionsQuery, SnapshotVersionsQueryVariables>(
    SnapshotVersionsDocument,
    options,
  )
}
export type SnapshotVersionsQueryHookResult = ReturnType<typeof useSnapshotVersionsQuery>
export type SnapshotVersionsLazyQueryHookResult = ReturnType<typeof useSnapshotVersionsLazyQuery>
export type SnapshotVersionsSuspenseQueryHookResult = ReturnType<
  typeof useSnapshotVersionsSuspenseQuery
>
export type SnapshotVersionsQueryResult = Apollo.QueryResult<
  SnapshotVersionsQuery,
  SnapshotVersionsQueryVariables
>
export const PublicSnapshotVersionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PublicSnapshotVersions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentVersion' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'allVersions' },
                        name: { kind: 'Name', value: 'versions' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'before' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'before' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PublicSnapshotVersionData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasPreviousPage' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __usePublicSnapshotVersionsQuery__
 *
 * To run a query within a React component, call `usePublicSnapshotVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSnapshotVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSnapshotVersionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function usePublicSnapshotVersionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PublicSnapshotVersionsQuery,
    PublicSnapshotVersionsQueryVariables
  > &
    ({ variables: PublicSnapshotVersionsQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PublicSnapshotVersionsQuery, PublicSnapshotVersionsQueryVariables>(
    PublicSnapshotVersionsDocument,
    options,
  )
}
export function usePublicSnapshotVersionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PublicSnapshotVersionsQuery,
    PublicSnapshotVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PublicSnapshotVersionsQuery, PublicSnapshotVersionsQueryVariables>(
    PublicSnapshotVersionsDocument,
    options,
  )
}
export function usePublicSnapshotVersionsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PublicSnapshotVersionsQuery,
    PublicSnapshotVersionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PublicSnapshotVersionsQuery, PublicSnapshotVersionsQueryVariables>(
    PublicSnapshotVersionsDocument,
    options,
  )
}
export type PublicSnapshotVersionsQueryHookResult = ReturnType<
  typeof usePublicSnapshotVersionsQuery
>
export type PublicSnapshotVersionsLazyQueryHookResult = ReturnType<
  typeof usePublicSnapshotVersionsLazyQuery
>
export type PublicSnapshotVersionsSuspenseQueryHookResult = ReturnType<
  typeof usePublicSnapshotVersionsSuspenseQuery
>
export type PublicSnapshotVersionsQueryResult = Apollo.QueryResult<
  PublicSnapshotVersionsQuery,
  PublicSnapshotVersionsQueryVariables
>
export const TrackContentViewsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'TrackContentViews' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'views' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentViewInput' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trackContentViews' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'views' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'views' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'success' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type TrackContentViewsMutationFn = Apollo.MutationFunction<
  TrackContentViewsMutation,
  TrackContentViewsMutationVariables
>

/**
 * __useTrackContentViewsMutation__
 *
 * To run a mutation, you first call `useTrackContentViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackContentViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackContentViewsMutation, { data, loading, error }] = useTrackContentViewsMutation({
 *   variables: {
 *      views: // value for 'views'
 *   },
 * });
 */
export function useTrackContentViewsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TrackContentViewsMutation,
    TrackContentViewsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<TrackContentViewsMutation, TrackContentViewsMutationVariables>(
    TrackContentViewsDocument,
    options,
  )
}
export type TrackContentViewsMutationHookResult = ReturnType<typeof useTrackContentViewsMutation>
export type TrackContentViewsMutationResult = Apollo.MutationResult<TrackContentViewsMutation>
export type TrackContentViewsMutationOptions = Apollo.BaseMutationOptions<
  TrackContentViewsMutation,
  TrackContentViewsMutationVariables
>
export const DeleteDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeleteDocumentMutationFn = Apollo.MutationFunction<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(
    DeleteDocumentDocument,
    options,
  )
}
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>
export const DeleteFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeleteFolderMutationFn = Apollo.MutationFunction<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(
    DeleteFolderDocument,
    options,
  )
}
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<
  DeleteFolderMutation,
  DeleteFolderMutationVariables
>
export const DeleteSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeleteSnapshotMutationFn = Apollo.MutationFunction<
  DeleteSnapshotMutation,
  DeleteSnapshotMutationVariables
>

/**
 * __useDeleteSnapshotMutation__
 *
 * To run a mutation, you first call `useDeleteSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSnapshotMutation, { data, loading, error }] = useDeleteSnapshotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSnapshotMutation, DeleteSnapshotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteSnapshotMutation, DeleteSnapshotMutationVariables>(
    DeleteSnapshotDocument,
    options,
  )
}
export type DeleteSnapshotMutationHookResult = ReturnType<typeof useDeleteSnapshotMutation>
export type DeleteSnapshotMutationResult = Apollo.MutationResult<DeleteSnapshotMutation>
export type DeleteSnapshotMutationOptions = Apollo.BaseMutationOptions<
  DeleteSnapshotMutation,
  DeleteSnapshotMutationVariables
>
export const MoveToFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveToFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'moveToFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'folderId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type MoveToFolderMutationFn = Apollo.MutationFunction<
  MoveToFolderMutation,
  MoveToFolderMutationVariables
>

/**
 * __useMoveToFolderMutation__
 *
 * To run a mutation, you first call `useMoveToFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveToFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveToFolderMutation, { data, loading, error }] = useMoveToFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useMoveToFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<MoveToFolderMutation, MoveToFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveToFolderMutation, MoveToFolderMutationVariables>(
    MoveToFolderDocument,
    options,
  )
}
export type MoveToFolderMutationHookResult = ReturnType<typeof useMoveToFolderMutation>
export type MoveToFolderMutationResult = Apollo.MutationResult<MoveToFolderMutation>
export type MoveToFolderMutationOptions = Apollo.BaseMutationOptions<
  MoveToFolderMutation,
  MoveToFolderMutationVariables
>
export const UpdateDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateDocumentMutationFn = Apollo.MutationFunction<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(
    UpdateDocumentDocument,
    options,
  )
}
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>
export const UpdateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateFolderInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'folder' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateFolderMutationFn = Apollo.MutationFunction<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>

/**
 * __useUpdateFolderMutation__
 *
 * To run a mutation, you first call `useUpdateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderMutation, { data, loading, error }] = useUpdateFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFolderMutation, UpdateFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateFolderMutation, UpdateFolderMutationVariables>(
    UpdateFolderDocument,
    options,
  )
}
export type UpdateFolderMutationHookResult = ReturnType<typeof useUpdateFolderMutation>
export type UpdateFolderMutationResult = Apollo.MutationResult<UpdateFolderMutation>
export type UpdateFolderMutationOptions = Apollo.BaseMutationOptions<
  UpdateFolderMutation,
  UpdateFolderMutationVariables
>
export const CreateFolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFolder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateFolderInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'createFolder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'folder' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateFolderMutationFn = Apollo.MutationFunction<
  CreateFolderMutation,
  CreateFolderMutationVariables
>

/**
 * __useCreateFolderMutation__
 *
 * To run a mutation, you first call `useCreateFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFolderMutation, { data, loading, error }] = useCreateFolderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFolderMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFolderMutation, CreateFolderMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFolderMutation, CreateFolderMutationVariables>(
    CreateFolderDocument,
    options,
  )
}
export type CreateFolderMutationHookResult = ReturnType<typeof useCreateFolderMutation>
export type CreateFolderMutationResult = Apollo.MutationResult<CreateFolderMutation>
export type CreateFolderMutationOptions = Apollo.BaseMutationOptions<
  CreateFolderMutation,
  CreateFolderMutationVariables
>
export const ReferralsCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReferralsCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'recipients' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: { kind: 'NamedType', name: { kind: 'Name', value: 'EmailAddress' } },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createReferrals' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'recipients' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'recipients' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'referrals' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'recipient' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type ReferralsCreateMutationFn = Apollo.MutationFunction<
  ReferralsCreateMutation,
  ReferralsCreateMutationVariables
>

/**
 * __useReferralsCreateMutation__
 *
 * To run a mutation, you first call `useReferralsCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferralsCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referralsCreateMutation, { data, loading, error }] = useReferralsCreateMutation({
 *   variables: {
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useReferralsCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReferralsCreateMutation,
    ReferralsCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReferralsCreateMutation, ReferralsCreateMutationVariables>(
    ReferralsCreateDocument,
    options,
  )
}
export type ReferralsCreateMutationHookResult = ReturnType<typeof useReferralsCreateMutation>
export type ReferralsCreateMutationResult = Apollo.MutationResult<ReferralsCreateMutation>
export type ReferralsCreateMutationOptions = Apollo.BaseMutationOptions<
  ReferralsCreateMutation,
  ReferralsCreateMutationVariables
>
export const SnapshotContentAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SnapshotContentAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSnapshotContentAccessQuery__
 *
 * To run a query within a React component, call `useSnapshotContentAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotContentAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotContentAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSnapshotContentAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    SnapshotContentAccessQuery,
    SnapshotContentAccessQueryVariables
  > &
    ({ variables: SnapshotContentAccessQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SnapshotContentAccessQuery, SnapshotContentAccessQueryVariables>(
    SnapshotContentAccessDocument,
    options,
  )
}
export function useSnapshotContentAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SnapshotContentAccessQuery,
    SnapshotContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SnapshotContentAccessQuery, SnapshotContentAccessQueryVariables>(
    SnapshotContentAccessDocument,
    options,
  )
}
export function useSnapshotContentAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SnapshotContentAccessQuery,
    SnapshotContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SnapshotContentAccessQuery, SnapshotContentAccessQueryVariables>(
    SnapshotContentAccessDocument,
    options,
  )
}
export type SnapshotContentAccessQueryHookResult = ReturnType<typeof useSnapshotContentAccessQuery>
export type SnapshotContentAccessLazyQueryHookResult = ReturnType<
  typeof useSnapshotContentAccessLazyQuery
>
export type SnapshotContentAccessSuspenseQueryHookResult = ReturnType<
  typeof useSnapshotContentAccessSuspenseQuery
>
export type SnapshotContentAccessQueryResult = Apollo.QueryResult<
  SnapshotContentAccessQuery,
  SnapshotContentAccessQueryVariables
>
export const DocumentContentAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DocumentContentAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useDocumentContentAccessQuery__
 *
 * To run a query within a React component, call `useDocumentContentAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentContentAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentContentAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentContentAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    DocumentContentAccessQuery,
    DocumentContentAccessQueryVariables
  > &
    ({ variables: DocumentContentAccessQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DocumentContentAccessQuery, DocumentContentAccessQueryVariables>(
    DocumentContentAccessDocument,
    options,
  )
}
export function useDocumentContentAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DocumentContentAccessQuery,
    DocumentContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DocumentContentAccessQuery, DocumentContentAccessQueryVariables>(
    DocumentContentAccessDocument,
    options,
  )
}
export function useDocumentContentAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DocumentContentAccessQuery,
    DocumentContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<DocumentContentAccessQuery, DocumentContentAccessQueryVariables>(
    DocumentContentAccessDocument,
    options,
  )
}
export type DocumentContentAccessQueryHookResult = ReturnType<typeof useDocumentContentAccessQuery>
export type DocumentContentAccessLazyQueryHookResult = ReturnType<
  typeof useDocumentContentAccessLazyQuery
>
export type DocumentContentAccessSuspenseQueryHookResult = ReturnType<
  typeof useDocumentContentAccessSuspenseQuery
>
export type DocumentContentAccessQueryResult = Apollo.QueryResult<
  DocumentContentAccessQuery,
  DocumentContentAccessQueryVariables
>
export const InOrgSnapshotContentAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InOrgSnapshotContentAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useInOrgSnapshotContentAccessQuery__
 *
 * To run a query within a React component, call `useInOrgSnapshotContentAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useInOrgSnapshotContentAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInOrgSnapshotContentAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInOrgSnapshotContentAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    InOrgSnapshotContentAccessQuery,
    InOrgSnapshotContentAccessQueryVariables
  > &
    ({ variables: InOrgSnapshotContentAccessQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InOrgSnapshotContentAccessQuery, InOrgSnapshotContentAccessQueryVariables>(
    InOrgSnapshotContentAccessDocument,
    options,
  )
}
export function useInOrgSnapshotContentAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InOrgSnapshotContentAccessQuery,
    InOrgSnapshotContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InOrgSnapshotContentAccessQuery,
    InOrgSnapshotContentAccessQueryVariables
  >(InOrgSnapshotContentAccessDocument, options)
}
export function useInOrgSnapshotContentAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InOrgSnapshotContentAccessQuery,
    InOrgSnapshotContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    InOrgSnapshotContentAccessQuery,
    InOrgSnapshotContentAccessQueryVariables
  >(InOrgSnapshotContentAccessDocument, options)
}
export type InOrgSnapshotContentAccessQueryHookResult = ReturnType<
  typeof useInOrgSnapshotContentAccessQuery
>
export type InOrgSnapshotContentAccessLazyQueryHookResult = ReturnType<
  typeof useInOrgSnapshotContentAccessLazyQuery
>
export type InOrgSnapshotContentAccessSuspenseQueryHookResult = ReturnType<
  typeof useInOrgSnapshotContentAccessSuspenseQuery
>
export type InOrgSnapshotContentAccessQueryResult = Apollo.QueryResult<
  InOrgSnapshotContentAccessQuery,
  InOrgSnapshotContentAccessQueryVariables
>
export const InOrgDocumentContentAccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'InOrgDocumentContentAccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useInOrgDocumentContentAccessQuery__
 *
 * To run a query within a React component, call `useInOrgDocumentContentAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useInOrgDocumentContentAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInOrgDocumentContentAccessQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInOrgDocumentContentAccessQuery(
  baseOptions: Apollo.QueryHookOptions<
    InOrgDocumentContentAccessQuery,
    InOrgDocumentContentAccessQueryVariables
  > &
    ({ variables: InOrgDocumentContentAccessQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InOrgDocumentContentAccessQuery, InOrgDocumentContentAccessQueryVariables>(
    InOrgDocumentContentAccessDocument,
    options,
  )
}
export function useInOrgDocumentContentAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    InOrgDocumentContentAccessQuery,
    InOrgDocumentContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    InOrgDocumentContentAccessQuery,
    InOrgDocumentContentAccessQueryVariables
  >(InOrgDocumentContentAccessDocument, options)
}
export function useInOrgDocumentContentAccessSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    InOrgDocumentContentAccessQuery,
    InOrgDocumentContentAccessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    InOrgDocumentContentAccessQuery,
    InOrgDocumentContentAccessQueryVariables
  >(InOrgDocumentContentAccessDocument, options)
}
export type InOrgDocumentContentAccessQueryHookResult = ReturnType<
  typeof useInOrgDocumentContentAccessQuery
>
export type InOrgDocumentContentAccessLazyQueryHookResult = ReturnType<
  typeof useInOrgDocumentContentAccessLazyQuery
>
export type InOrgDocumentContentAccessSuspenseQueryHookResult = ReturnType<
  typeof useInOrgDocumentContentAccessSuspenseQuery
>
export type InOrgDocumentContentAccessQueryResult = Apollo.QueryResult<
  InOrgDocumentContentAccessQuery,
  InOrgDocumentContentAccessQueryVariables
>
export const DeclineInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeclineInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'deleteInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DeclineInvitationMutationFn = Apollo.MutationFunction<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>

/**
 * __useDeclineInvitationMutation__
 *
 * To run a mutation, you first call `useDeclineInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineInvitationMutation, { data, loading, error }] = useDeclineInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineInvitationMutation,
    DeclineInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeclineInvitationMutation, DeclineInvitationMutationVariables>(
    DeclineInvitationDocument,
    options,
  )
}
export type DeclineInvitationMutationHookResult = ReturnType<typeof useDeclineInvitationMutation>
export type DeclineInvitationMutationResult = Apollo.MutationResult<DeclineInvitationMutation>
export type DeclineInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeclineInvitationMutation,
  DeclineInvitationMutationVariables
>
export const DisconnectFromSlackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisconnectFromSlack' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectSlackAccount' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DisconnectFromSlackMutationFn = Apollo.MutationFunction<
  DisconnectFromSlackMutation,
  DisconnectFromSlackMutationVariables
>

/**
 * __useDisconnectFromSlackMutation__
 *
 * To run a mutation, you first call `useDisconnectFromSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectFromSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectFromSlackMutation, { data, loading, error }] = useDisconnectFromSlackMutation({
 *   variables: {
 *   },
 * });
 */
export function useDisconnectFromSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectFromSlackMutation,
    DisconnectFromSlackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DisconnectFromSlackMutation, DisconnectFromSlackMutationVariables>(
    DisconnectFromSlackDocument,
    options,
  )
}
export type DisconnectFromSlackMutationHookResult = ReturnType<
  typeof useDisconnectFromSlackMutation
>
export type DisconnectFromSlackMutationResult = Apollo.MutationResult<DisconnectFromSlackMutation>
export type DisconnectFromSlackMutationOptions = Apollo.BaseMutationOptions<
  DisconnectFromSlackMutation,
  DisconnectFromSlackMutationVariables
>
export const DismissRepairSuggestionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DismissRepairSuggestion' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'dismissSnapshotRepairSuggestion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DismissRepairSuggestionMutationFn = Apollo.MutationFunction<
  DismissRepairSuggestionMutation,
  DismissRepairSuggestionMutationVariables
>

/**
 * __useDismissRepairSuggestionMutation__
 *
 * To run a mutation, you first call `useDismissRepairSuggestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissRepairSuggestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissRepairSuggestionMutation, { data, loading, error }] = useDismissRepairSuggestionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissRepairSuggestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DismissRepairSuggestionMutation,
    DismissRepairSuggestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DismissRepairSuggestionMutation,
    DismissRepairSuggestionMutationVariables
  >(DismissRepairSuggestionDocument, options)
}
export type DismissRepairSuggestionMutationHookResult = ReturnType<
  typeof useDismissRepairSuggestionMutation
>
export type DismissRepairSuggestionMutationResult =
  Apollo.MutationResult<DismissRepairSuggestionMutation>
export type DismissRepairSuggestionMutationOptions = Apollo.BaseMutationOptions<
  DismissRepairSuggestionMutation,
  DismissRepairSuggestionMutationVariables
>
export const GetExtensionStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExtensionState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'extensionClientState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'isExtensionInstalled' },
                  directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetExtensionStateQuery__
 *
 * To run a query within a React component, call `useGetExtensionStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtensionStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtensionStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExtensionStateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetExtensionStateQuery, GetExtensionStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetExtensionStateQuery, GetExtensionStateQueryVariables>(
    GetExtensionStateDocument,
    options,
  )
}
export function useGetExtensionStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExtensionStateQuery,
    GetExtensionStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetExtensionStateQuery, GetExtensionStateQueryVariables>(
    GetExtensionStateDocument,
    options,
  )
}
export function useGetExtensionStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetExtensionStateQuery,
    GetExtensionStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetExtensionStateQuery, GetExtensionStateQueryVariables>(
    GetExtensionStateDocument,
    options,
  )
}
export type GetExtensionStateQueryHookResult = ReturnType<typeof useGetExtensionStateQuery>
export type GetExtensionStateLazyQueryHookResult = ReturnType<typeof useGetExtensionStateLazyQuery>
export type GetExtensionStateSuspenseQueryHookResult = ReturnType<
  typeof useGetExtensionStateSuspenseQuery
>
export type GetExtensionStateQueryResult = Apollo.QueryResult<
  GetExtensionStateQuery,
  GetExtensionStateQueryVariables
>
export const MoveContentToTeamDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveContentToTeam' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'moveToTeam' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'teamId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type MoveContentToTeamMutationFn = Apollo.MutationFunction<
  MoveContentToTeamMutation,
  MoveContentToTeamMutationVariables
>

/**
 * __useMoveContentToTeamMutation__
 *
 * To run a mutation, you first call `useMoveContentToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveContentToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveContentToTeamMutation, { data, loading, error }] = useMoveContentToTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useMoveContentToTeamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MoveContentToTeamMutation,
    MoveContentToTeamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MoveContentToTeamMutation, MoveContentToTeamMutationVariables>(
    MoveContentToTeamDocument,
    options,
  )
}
export type MoveContentToTeamMutationHookResult = ReturnType<typeof useMoveContentToTeamMutation>
export type MoveContentToTeamMutationResult = Apollo.MutationResult<MoveContentToTeamMutation>
export type MoveContentToTeamMutationOptions = Apollo.BaseMutationOptions<
  MoveContentToTeamMutation,
  MoveContentToTeamMutationVariables
>
export const ReportSnapshotIssueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReportSnapshotIssue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotIssueInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reportSnapshotIssue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'urn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type ReportSnapshotIssueMutationFn = Apollo.MutationFunction<
  ReportSnapshotIssueMutation,
  ReportSnapshotIssueMutationVariables
>

/**
 * __useReportSnapshotIssueMutation__
 *
 * To run a mutation, you first call `useReportSnapshotIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportSnapshotIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSnapshotIssueMutation, { data, loading, error }] = useReportSnapshotIssueMutation({
 *   variables: {
 *      urn: // value for 'urn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSnapshotIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReportSnapshotIssueMutation,
    ReportSnapshotIssueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReportSnapshotIssueMutation, ReportSnapshotIssueMutationVariables>(
    ReportSnapshotIssueDocument,
    options,
  )
}
export type ReportSnapshotIssueMutationHookResult = ReturnType<
  typeof useReportSnapshotIssueMutation
>
export type ReportSnapshotIssueMutationResult = Apollo.MutationResult<ReportSnapshotIssueMutation>
export type ReportSnapshotIssueMutationOptions = Apollo.BaseMutationOptions<
  ReportSnapshotIssueMutation,
  ReportSnapshotIssueMutationVariables
>
export const UpdateContentPrivacyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateContentPrivacy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateContentPropertiesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'result' },
            name: { kind: 'Name', value: 'updateContentProperties' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'Content' },
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'properties' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateContentPrivacyMutationFn = Apollo.MutationFunction<
  UpdateContentPrivacyMutation,
  UpdateContentPrivacyMutationVariables
>

/**
 * __useUpdateContentPrivacyMutation__
 *
 * To run a mutation, you first call `useUpdateContentPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentPrivacyMutation, { data, loading, error }] = useUpdateContentPrivacyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContentPrivacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateContentPrivacyMutation,
    UpdateContentPrivacyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateContentPrivacyMutation, UpdateContentPrivacyMutationVariables>(
    UpdateContentPrivacyDocument,
    options,
  )
}
export type UpdateContentPrivacyMutationHookResult = ReturnType<
  typeof useUpdateContentPrivacyMutation
>
export type UpdateContentPrivacyMutationResult = Apollo.MutationResult<UpdateContentPrivacyMutation>
export type UpdateContentPrivacyMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentPrivacyMutation,
  UpdateContentPrivacyMutationVariables
>
export const CurrentOrganizationSnapshotsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentOrganizationSnapshots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotsFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'snapshots' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'filter' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'sort' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'after' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'edges' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'node' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                        },
                                        {
                                          kind: 'FragmentSpread',
                                          name: { kind: 'Name', value: 'SnapshotFields' },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pageInfo' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasPreviousPage' },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useCurrentOrganizationSnapshotsQuery__
 *
 * To run a query within a React component, call `useCurrentOrganizationSnapshotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentOrganizationSnapshotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentOrganizationSnapshotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useCurrentOrganizationSnapshotsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >(CurrentOrganizationSnapshotsDocument, options)
}
export function useCurrentOrganizationSnapshotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >(CurrentOrganizationSnapshotsDocument, options)
}
export function useCurrentOrganizationSnapshotsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    CurrentOrganizationSnapshotsQuery,
    CurrentOrganizationSnapshotsQueryVariables
  >(CurrentOrganizationSnapshotsDocument, options)
}
export type CurrentOrganizationSnapshotsQueryHookResult = ReturnType<
  typeof useCurrentOrganizationSnapshotsQuery
>
export type CurrentOrganizationSnapshotsLazyQueryHookResult = ReturnType<
  typeof useCurrentOrganizationSnapshotsLazyQuery
>
export type CurrentOrganizationSnapshotsSuspenseQueryHookResult = ReturnType<
  typeof useCurrentOrganizationSnapshotsSuspenseQuery
>
export type CurrentOrganizationSnapshotsQueryResult = Apollo.QueryResult<
  CurrentOrganizationSnapshotsQuery,
  CurrentOrganizationSnapshotsQueryVariables
>
export const FolderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'folder' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'folderId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useFolderQuery__
 *
 * To run a query within a React component, call `useFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderQuery({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useFolderQuery(
  baseOptions: Apollo.QueryHookOptions<FolderQuery, FolderQueryVariables> &
    ({ variables: FolderQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options)
}
export function useFolderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FolderQuery, FolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options)
}
export function useFolderSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<FolderQuery, FolderQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<FolderQuery, FolderQueryVariables>(FolderDocument, options)
}
export type FolderQueryHookResult = ReturnType<typeof useFolderQuery>
export type FolderLazyQueryHookResult = ReturnType<typeof useFolderLazyQuery>
export type FolderSuspenseQueryHookResult = ReturnType<typeof useFolderSuspenseQuery>
export type FolderQueryResult = Apollo.QueryResult<FolderQuery, FolderQueryVariables>
export const SearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Search' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilter' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'IntegerBetween1And100' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultOrder' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'first' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'after' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'after' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'searchFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'searchFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchResultsConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FolderCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PresentationCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SlideCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileCard' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useSearchQuery__
 *
 * To run a query within a React component, call `useSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      sort: // value for 'sort'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export function useSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export function useSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchQuery, SearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<SearchQuery, SearchQueryVariables>(SearchDocument, options)
}
export type SearchQueryHookResult = ReturnType<typeof useSearchQuery>
export type SearchLazyQueryHookResult = ReturnType<typeof useSearchLazyQuery>
export type SearchSuspenseQueryHookResult = ReturnType<typeof useSearchSuspenseQuery>
export type SearchQueryResult = Apollo.QueryResult<SearchQuery, SearchQueryVariables>
export const ContentGridSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ContentGridSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'contentIOwn' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'folders' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'teams' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchResultOrder' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'snapshots' },
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentIOwn' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contentIOwn' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'SNAPSHOT' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folders' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'folders' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'teams' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teams' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '14' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'searchFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'documents' },
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentIOwn' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contentIOwn' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'DOCUMENT' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folders' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'folders' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'teams' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teams' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '9' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'searchFields' } },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'folders' },
            name: { kind: 'Name', value: 'search' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentIOwn' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'contentIOwn' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'contentTypes' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'FOLDER' }],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'folders' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'folders' } },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'teams' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'teams' } },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sort' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sort' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '9' },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'searchFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'searchFields' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'SearchResultsConnection' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'edges' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FolderCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PresentationCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SlideCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileCard' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pageInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'folders' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useContentGridSearchQuery__
 *
 * To run a query within a React component, call `useContentGridSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useContentGridSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContentGridSearchQuery({
 *   variables: {
 *      contentIOwn: // value for 'contentIOwn'
 *      folders: // value for 'folders'
 *      teams: // value for 'teams'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useContentGridSearchQuery(
  baseOptions?: Apollo.QueryHookOptions<ContentGridSearchQuery, ContentGridSearchQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContentGridSearchQuery, ContentGridSearchQueryVariables>(
    ContentGridSearchDocument,
    options,
  )
}
export function useContentGridSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ContentGridSearchQuery,
    ContentGridSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContentGridSearchQuery, ContentGridSearchQueryVariables>(
    ContentGridSearchDocument,
    options,
  )
}
export function useContentGridSearchSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ContentGridSearchQuery,
    ContentGridSearchQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ContentGridSearchQuery, ContentGridSearchQueryVariables>(
    ContentGridSearchDocument,
    options,
  )
}
export type ContentGridSearchQueryHookResult = ReturnType<typeof useContentGridSearchQuery>
export type ContentGridSearchLazyQueryHookResult = ReturnType<typeof useContentGridSearchLazyQuery>
export type ContentGridSearchSuspenseQueryHookResult = ReturnType<
  typeof useContentGridSearchSuspenseQuery
>
export type ContentGridSearchQueryResult = Apollo.QueryResult<
  ContentGridSearchQuery,
  ContentGridSearchQueryVariables
>
export const OnContentCreatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnContentCreated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onContentCreated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'urn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FolderCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PresentationCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SlideCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileCard' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useOnContentCreatedSubscription__
 *
 * To run a query within a React component, call `useOnContentCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnContentCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnContentCreatedSubscription({
 *   variables: {
 *      urn: // value for 'urn'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnContentCreatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnContentCreatedSubscription,
    OnContentCreatedSubscriptionVariables
  > &
    ({ variables: OnContentCreatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OnContentCreatedSubscription,
    OnContentCreatedSubscriptionVariables
  >(OnContentCreatedDocument, options)
}
export type OnContentCreatedSubscriptionHookResult = ReturnType<
  typeof useOnContentCreatedSubscription
>
export type OnContentCreatedSubscriptionResult =
  Apollo.SubscriptionResult<OnContentCreatedSubscription>
export const OnContentUpdatedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnContentUpdated' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onContentUpdated' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'urn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'node' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'DocumentCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FolderCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PresentationCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SlideCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'TextCard' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'FileCard' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Content' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FileCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'File' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'FolderCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Folder' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'contentCount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentCounts' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'documents' } },
                { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PresentationCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Presentation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SlideCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Slide' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'TextCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Text' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useOnContentUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnContentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnContentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnContentUpdatedSubscription({
 *   variables: {
 *      urn: // value for 'urn'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnContentUpdatedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnContentUpdatedSubscription,
    OnContentUpdatedSubscriptionVariables
  > &
    ({ variables: OnContentUpdatedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OnContentUpdatedSubscription,
    OnContentUpdatedSubscriptionVariables
  >(OnContentUpdatedDocument, options)
}
export type OnContentUpdatedSubscriptionHookResult = ReturnType<
  typeof useOnContentUpdatedSubscription
>
export type OnContentUpdatedSubscriptionResult =
  Apollo.SubscriptionResult<OnContentUpdatedSubscription>
export const OnContentDeletedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'OnContentDeleted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'URN' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'SearchFilter' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'onContentDeleted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'urn' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'urn' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filter' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useOnContentDeletedSubscription__
 *
 * To run a query within a React component, call `useOnContentDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnContentDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnContentDeletedSubscription({
 *   variables: {
 *      urn: // value for 'urn'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOnContentDeletedSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    OnContentDeletedSubscription,
    OnContentDeletedSubscriptionVariables
  > &
    ({ variables: OnContentDeletedSubscriptionVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    OnContentDeletedSubscription,
    OnContentDeletedSubscriptionVariables
  >(OnContentDeletedDocument, options)
}
export type OnContentDeletedSubscriptionHookResult = ReturnType<
  typeof useOnContentDeletedSubscription
>
export type OnContentDeletedSubscriptionResult =
  Apollo.SubscriptionResult<OnContentDeletedSubscription>
export const GetFeedClientStateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetFeedClientState' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'feedClientState' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activeContentType' },
                  directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sortField' },
                  directives: [{ kind: 'Directive', name: { kind: 'Name', value: 'client' } }],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetFeedClientStateQuery__
 *
 * To run a query within a React component, call `useGetFeedClientStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedClientStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedClientStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeedClientStateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFeedClientStateQuery, GetFeedClientStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFeedClientStateQuery, GetFeedClientStateQueryVariables>(
    GetFeedClientStateDocument,
    options,
  )
}
export function useGetFeedClientStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeedClientStateQuery,
    GetFeedClientStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFeedClientStateQuery, GetFeedClientStateQueryVariables>(
    GetFeedClientStateDocument,
    options,
  )
}
export function useGetFeedClientStateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetFeedClientStateQuery,
    GetFeedClientStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetFeedClientStateQuery, GetFeedClientStateQueryVariables>(
    GetFeedClientStateDocument,
    options,
  )
}
export type GetFeedClientStateQueryHookResult = ReturnType<typeof useGetFeedClientStateQuery>
export type GetFeedClientStateLazyQueryHookResult = ReturnType<
  typeof useGetFeedClientStateLazyQuery
>
export type GetFeedClientStateSuspenseQueryHookResult = ReturnType<
  typeof useGetFeedClientStateSuspenseQuery
>
export type GetFeedClientStateQueryResult = Apollo.QueryResult<
  GetFeedClientStateQuery,
  GetFeedClientStateQueryVariables
>
export const ViewerNavDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ViewerNavData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'availablePlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'currencySymbol' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snapshotsLineItem' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refreshRatesLineItem' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'hasPublicEmailDomain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currentOrganization' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'User' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'joinByDomain' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '50' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'customInstructions' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'profile' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'style' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'currencySymbol' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'nextInvoice' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'billedUsersCount' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'isStripeCustomer' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'invitations' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '50' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'nodes' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdBy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'User' },
                                          },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'id' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'image' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'email' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'frontUserHash' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'joinByDomain' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'users' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'first' },
                            value: { kind: 'IntValue', value: '50' },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'snapshots' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currentPlan' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'tier' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'frequency' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'User' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'customSlides' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'quotas' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'presentations' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'longPromptEnabled' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'refreshRates' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'slackAccountStatus' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'usage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'presentations' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'snapshots' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedUi' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'captureFailedNux' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loggedOutNux' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reviewNux' } },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                    ],
                  },
                },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SideBar' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SideBar' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Viewer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createdBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'InlineFragment',
                        typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'createdBy' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'InlineFragment',
                                    typeCondition: {
                                      kind: 'NamedType',
                                      name: { kind: 'Name', value: 'User' },
                                    },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'teams' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'users' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'first' },
                      value: { kind: 'IntValue', value: '50' },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nodes' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teams' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'nodes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useViewerNavDataQuery__
 *
 * To run a query within a React component, call `useViewerNavDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerNavDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerNavDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerNavDataQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerNavDataQuery, ViewerNavDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ViewerNavDataQuery, ViewerNavDataQueryVariables>(
    ViewerNavDataDocument,
    options,
  )
}
export function useViewerNavDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerNavDataQuery, ViewerNavDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ViewerNavDataQuery, ViewerNavDataQueryVariables>(
    ViewerNavDataDocument,
    options,
  )
}
export function useViewerNavDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ViewerNavDataQuery, ViewerNavDataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<ViewerNavDataQuery, ViewerNavDataQueryVariables>(
    ViewerNavDataDocument,
    options,
  )
}
export type ViewerNavDataQueryHookResult = ReturnType<typeof useViewerNavDataQuery>
export type ViewerNavDataLazyQueryHookResult = ReturnType<typeof useViewerNavDataLazyQuery>
export type ViewerNavDataSuspenseQueryHookResult = ReturnType<typeof useViewerNavDataSuspenseQuery>
export type ViewerNavDataQueryResult = Apollo.QueryResult<
  ViewerNavDataQuery,
  ViewerNavDataQueryVariables
>
export const GalleryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Gallery' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gallery' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'DocumentCard' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DocumentCard' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'privacy' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGalleryQuery__
 *
 * To run a query within a React component, call `useGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGalleryQuery(
  baseOptions?: Apollo.QueryHookOptions<GalleryQuery, GalleryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options)
}
export function useGalleryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GalleryQuery, GalleryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options)
}
export function useGallerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GalleryQuery, GalleryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, options)
}
export type GalleryQueryHookResult = ReturnType<typeof useGalleryQuery>
export type GalleryLazyQueryHookResult = ReturnType<typeof useGalleryLazyQuery>
export type GallerySuspenseQueryHookResult = ReturnType<typeof useGallerySuspenseQuery>
export type GalleryQueryResult = Apollo.QueryResult<GalleryQuery, GalleryQueryVariables>
export const GetPublicDocumentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicDocument' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewer' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pages' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'canvasSize' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                ],
                              },
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'objects' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'pinnedVersions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'snapshots' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'instructions' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'frame' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'frameHeight' },
                                              },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'frameWidth' },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'currentVersion' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PublicSnapshotVersionData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetPublicDocumentQuery__
 *
 * To run a query within a React component, call `useGetPublicDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicDocumentQuery(
  baseOptions: Apollo.QueryHookOptions<GetPublicDocumentQuery, GetPublicDocumentQueryVariables> &
    ({ variables: GetPublicDocumentQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPublicDocumentQuery, GetPublicDocumentQueryVariables>(
    GetPublicDocumentDocument,
    options,
  )
}
export function useGetPublicDocumentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicDocumentQuery,
    GetPublicDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPublicDocumentQuery, GetPublicDocumentQueryVariables>(
    GetPublicDocumentDocument,
    options,
  )
}
export function useGetPublicDocumentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPublicDocumentQuery,
    GetPublicDocumentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetPublicDocumentQuery, GetPublicDocumentQueryVariables>(
    GetPublicDocumentDocument,
    options,
  )
}
export type GetPublicDocumentQueryHookResult = ReturnType<typeof useGetPublicDocumentQuery>
export type GetPublicDocumentLazyQueryHookResult = ReturnType<typeof useGetPublicDocumentLazyQuery>
export type GetPublicDocumentSuspenseQueryHookResult = ReturnType<
  typeof useGetPublicDocumentSuspenseQuery
>
export type GetPublicDocumentQueryResult = Apollo.QueryResult<
  GetPublicDocumentQuery,
  GetPublicDocumentQueryVariables
>
export const GetSlackAccountStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSlackAccountStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'StringWithMaxLengthOf100' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slackAccountStatus' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'slackUserId' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'slackUserId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'nonce' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'nonce' } },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetSlackAccountStatusQuery__
 *
 * To run a query within a React component, call `useGetSlackAccountStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlackAccountStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlackAccountStatusQuery({
 *   variables: {
 *      slackUserId: // value for 'slackUserId'
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useGetSlackAccountStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSlackAccountStatusQuery,
    GetSlackAccountStatusQueryVariables
  > &
    ({ variables: GetSlackAccountStatusQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSlackAccountStatusQuery, GetSlackAccountStatusQueryVariables>(
    GetSlackAccountStatusDocument,
    options,
  )
}
export function useGetSlackAccountStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSlackAccountStatusQuery,
    GetSlackAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSlackAccountStatusQuery, GetSlackAccountStatusQueryVariables>(
    GetSlackAccountStatusDocument,
    options,
  )
}
export function useGetSlackAccountStatusSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSlackAccountStatusQuery,
    GetSlackAccountStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSlackAccountStatusQuery, GetSlackAccountStatusQueryVariables>(
    GetSlackAccountStatusDocument,
    options,
  )
}
export type GetSlackAccountStatusQueryHookResult = ReturnType<typeof useGetSlackAccountStatusQuery>
export type GetSlackAccountStatusLazyQueryHookResult = ReturnType<
  typeof useGetSlackAccountStatusLazyQuery
>
export type GetSlackAccountStatusSuspenseQueryHookResult = ReturnType<
  typeof useGetSlackAccountStatusSuspenseQuery
>
export type GetSlackAccountStatusQueryResult = Apollo.QueryResult<
  GetSlackAccountStatusQuery,
  GetSlackAccountStatusQueryVariables
>
export const LinkAccountToSlackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'LinkAccountToSlack' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'LinkSlackAccountInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkSlackAccount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type LinkAccountToSlackMutationFn = Apollo.MutationFunction<
  LinkAccountToSlackMutation,
  LinkAccountToSlackMutationVariables
>

/**
 * __useLinkAccountToSlackMutation__
 *
 * To run a mutation, you first call `useLinkAccountToSlackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkAccountToSlackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkAccountToSlackMutation, { data, loading, error }] = useLinkAccountToSlackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLinkAccountToSlackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LinkAccountToSlackMutation,
    LinkAccountToSlackMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LinkAccountToSlackMutation, LinkAccountToSlackMutationVariables>(
    LinkAccountToSlackDocument,
    options,
  )
}
export type LinkAccountToSlackMutationHookResult = ReturnType<typeof useLinkAccountToSlackMutation>
export type LinkAccountToSlackMutationResult = Apollo.MutationResult<LinkAccountToSlackMutation>
export type LinkAccountToSlackMutationOptions = Apollo.BaseMutationOptions<
  LinkAccountToSlackMutation,
  LinkAccountToSlackMutationVariables
>
export const GetSnapshotVersionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSnapshotVersionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'snapshotVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetSnapshotVersionDataQuery__
 *
 * To run a query within a React component, call `useGetSnapshotVersionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnapshotVersionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnapshotVersionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSnapshotVersionDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetSnapshotVersionDataQuery,
    GetSnapshotVersionDataQueryVariables
  > &
    ({ variables: GetSnapshotVersionDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSnapshotVersionDataQuery, GetSnapshotVersionDataQueryVariables>(
    GetSnapshotVersionDataDocument,
    options,
  )
}
export function useGetSnapshotVersionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSnapshotVersionDataQuery,
    GetSnapshotVersionDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSnapshotVersionDataQuery, GetSnapshotVersionDataQueryVariables>(
    GetSnapshotVersionDataDocument,
    options,
  )
}
export function useGetSnapshotVersionDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetSnapshotVersionDataQuery,
    GetSnapshotVersionDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetSnapshotVersionDataQuery, GetSnapshotVersionDataQueryVariables>(
    GetSnapshotVersionDataDocument,
    options,
  )
}
export type GetSnapshotVersionDataQueryHookResult = ReturnType<
  typeof useGetSnapshotVersionDataQuery
>
export type GetSnapshotVersionDataLazyQueryHookResult = ReturnType<
  typeof useGetSnapshotVersionDataLazyQuery
>
export type GetSnapshotVersionDataSuspenseQueryHookResult = ReturnType<
  typeof useGetSnapshotVersionDataSuspenseQuery
>
export type GetSnapshotVersionDataQueryResult = Apollo.QueryResult<
  GetSnapshotVersionDataQuery,
  GetSnapshotVersionDataQueryVariables
>
export const GetPublicSnapshotVersionDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicSnapshotVersionData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'publicSnapshotVersion' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshotVersion' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicSnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PublicSnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetPublicSnapshotVersionDataQuery__
 *
 * To run a query within a React component, call `useGetPublicSnapshotVersionDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicSnapshotVersionDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicSnapshotVersionDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPublicSnapshotVersionDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  > &
    ({ variables: GetPublicSnapshotVersionDataQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  >(GetPublicSnapshotVersionDataDocument, options)
}
export function useGetPublicSnapshotVersionDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  >(GetPublicSnapshotVersionDataDocument, options)
}
export function useGetPublicSnapshotVersionDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GetPublicSnapshotVersionDataQuery,
    GetPublicSnapshotVersionDataQueryVariables
  >(GetPublicSnapshotVersionDataDocument, options)
}
export type GetPublicSnapshotVersionDataQueryHookResult = ReturnType<
  typeof useGetPublicSnapshotVersionDataQuery
>
export type GetPublicSnapshotVersionDataLazyQueryHookResult = ReturnType<
  typeof useGetPublicSnapshotVersionDataLazyQuery
>
export type GetPublicSnapshotVersionDataSuspenseQueryHookResult = ReturnType<
  typeof useGetPublicSnapshotVersionDataSuspenseQuery
>
export type GetPublicSnapshotVersionDataQueryResult = Apollo.QueryResult<
  GetPublicSnapshotVersionDataQuery,
  GetPublicSnapshotVersionDataQueryVariables
>
export const UpdateSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateSnapshotInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateSnapshotMutationFn = Apollo.MutationFunction<
  UpdateSnapshotMutation,
  UpdateSnapshotMutationVariables
>

/**
 * __useUpdateSnapshotMutation__
 *
 * To run a mutation, you first call `useUpdateSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSnapshotMutation, { data, loading, error }] = useUpdateSnapshotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSnapshotMutation, UpdateSnapshotMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateSnapshotMutation, UpdateSnapshotMutationVariables>(
    UpdateSnapshotDocument,
    options,
  )
}
export type UpdateSnapshotMutationHookResult = ReturnType<typeof useUpdateSnapshotMutation>
export type UpdateSnapshotMutationResult = Apollo.MutationResult<UpdateSnapshotMutation>
export type UpdateSnapshotMutationOptions = Apollo.BaseMutationOptions<
  UpdateSnapshotMutation,
  UpdateSnapshotMutationVariables
>
export const CaptureSnapshotDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CaptureSnapshot' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'captureSnapshot' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'snapshotId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CaptureSnapshotMutationFn = Apollo.MutationFunction<
  CaptureSnapshotMutation,
  CaptureSnapshotMutationVariables
>

/**
 * __useCaptureSnapshotMutation__
 *
 * To run a mutation, you first call `useCaptureSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCaptureSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [captureSnapshotMutation, { data, loading, error }] = useCaptureSnapshotMutation({
 *   variables: {
 *      snapshotId: // value for 'snapshotId'
 *   },
 * });
 */
export function useCaptureSnapshotMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CaptureSnapshotMutation,
    CaptureSnapshotMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CaptureSnapshotMutation, CaptureSnapshotMutationVariables>(
    CaptureSnapshotDocument,
    options,
  )
}
export type CaptureSnapshotMutationHookResult = ReturnType<typeof useCaptureSnapshotMutation>
export type CaptureSnapshotMutationResult = Apollo.MutationResult<CaptureSnapshotMutation>
export type CaptureSnapshotMutationOptions = Apollo.BaseMutationOptions<
  CaptureSnapshotMutation,
  CaptureSnapshotMutationVariables
>
export const ConnectSnapshotIdentitiesForDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ConnectSnapshotIdentitiesForDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'connectSnapshotIdentitiesForDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'identity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type ConnectSnapshotIdentitiesForDomainMutationFn = Apollo.MutationFunction<
  ConnectSnapshotIdentitiesForDomainMutation,
  ConnectSnapshotIdentitiesForDomainMutationVariables
>

/**
 * __useConnectSnapshotIdentitiesForDomainMutation__
 *
 * To run a mutation, you first call `useConnectSnapshotIdentitiesForDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectSnapshotIdentitiesForDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectSnapshotIdentitiesForDomainMutation, { data, loading, error }] = useConnectSnapshotIdentitiesForDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConnectSnapshotIdentitiesForDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectSnapshotIdentitiesForDomainMutation,
    ConnectSnapshotIdentitiesForDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConnectSnapshotIdentitiesForDomainMutation,
    ConnectSnapshotIdentitiesForDomainMutationVariables
  >(ConnectSnapshotIdentitiesForDomainDocument, options)
}
export type ConnectSnapshotIdentitiesForDomainMutationHookResult = ReturnType<
  typeof useConnectSnapshotIdentitiesForDomainMutation
>
export type ConnectSnapshotIdentitiesForDomainMutationResult =
  Apollo.MutationResult<ConnectSnapshotIdentitiesForDomainMutation>
export type ConnectSnapshotIdentitiesForDomainMutationOptions = Apollo.BaseMutationOptions<
  ConnectSnapshotIdentitiesForDomainMutation,
  ConnectSnapshotIdentitiesForDomainMutationVariables
>
export const DisconnectSnapshotIdentitiesForDomainDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DisconnectSnapshotIdentitiesForDomain' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'disconnectSnapshotIdentitiesForDomain' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshot' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'identity' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type DisconnectSnapshotIdentitiesForDomainMutationFn = Apollo.MutationFunction<
  DisconnectSnapshotIdentitiesForDomainMutation,
  DisconnectSnapshotIdentitiesForDomainMutationVariables
>

/**
 * __useDisconnectSnapshotIdentitiesForDomainMutation__
 *
 * To run a mutation, you first call `useDisconnectSnapshotIdentitiesForDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectSnapshotIdentitiesForDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectSnapshotIdentitiesForDomainMutation, { data, loading, error }] = useDisconnectSnapshotIdentitiesForDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectSnapshotIdentitiesForDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectSnapshotIdentitiesForDomainMutation,
    DisconnectSnapshotIdentitiesForDomainMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisconnectSnapshotIdentitiesForDomainMutation,
    DisconnectSnapshotIdentitiesForDomainMutationVariables
  >(DisconnectSnapshotIdentitiesForDomainDocument, options)
}
export type DisconnectSnapshotIdentitiesForDomainMutationHookResult = ReturnType<
  typeof useDisconnectSnapshotIdentitiesForDomainMutation
>
export type DisconnectSnapshotIdentitiesForDomainMutationResult =
  Apollo.MutationResult<DisconnectSnapshotIdentitiesForDomainMutation>
export type DisconnectSnapshotIdentitiesForDomainMutationOptions = Apollo.BaseMutationOptions<
  DisconnectSnapshotIdentitiesForDomainMutation,
  DisconnectSnapshotIdentitiesForDomainMutationVariables
>
export const CreateThemeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateThemeInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createTheme' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeData' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorOne' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorTwo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorThree' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorFour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateThemeMutationFn = Apollo.MutationFunction<
  CreateThemeMutation,
  CreateThemeMutationVariables
>

/**
 * __useCreateThemeMutation__
 *
 * To run a mutation, you first call `useCreateThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createThemeMutation, { data, loading, error }] = useCreateThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateThemeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateThemeMutation, CreateThemeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateThemeMutation, CreateThemeMutationVariables>(
    CreateThemeDocument,
    options,
  )
}
export type CreateThemeMutationHookResult = ReturnType<typeof useCreateThemeMutation>
export type CreateThemeMutationResult = Apollo.MutationResult<CreateThemeMutation>
export type CreateThemeMutationOptions = Apollo.BaseMutationOptions<
  CreateThemeMutation,
  CreateThemeMutationVariables
>
export const GetThemesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetThemes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: '_experimental' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ThemeData' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Theme' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'headerFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bodyFont' } },
          { kind: 'Field', name: { kind: 'Name', value: 'backgroundColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fontColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorOne' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorTwo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorThree' } },
          { kind: 'Field', name: { kind: 'Name', value: 'colorFour' } },
          { kind: 'Field', name: { kind: 'Name', value: 'logo' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetThemesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options)
}
export function useGetThemesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, options)
}
export function useGetThemesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<GetThemesQuery, GetThemesQueryVariables>(
    GetThemesDocument,
    options,
  )
}
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>
export type GetThemesSuspenseQueryHookResult = ReturnType<typeof useGetThemesSuspenseQuery>
export type GetThemesQueryResult = Apollo.QueryResult<GetThemesQuery, GetThemesQueryVariables>
export const AcceptInvitationInputDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AcceptInvitationInput' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'AcceptInvitationInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'acceptInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type AcceptInvitationInputMutationFn = Apollo.MutationFunction<
  AcceptInvitationInputMutation,
  AcceptInvitationInputMutationVariables
>

/**
 * __useAcceptInvitationInputMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationInputMutation, { data, loading, error }] = useAcceptInvitationInputMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationInputMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInvitationInputMutation,
    AcceptInvitationInputMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AcceptInvitationInputMutation, AcceptInvitationInputMutationVariables>(
    AcceptInvitationInputDocument,
    options,
  )
}
export type AcceptInvitationInputMutationHookResult = ReturnType<
  typeof useAcceptInvitationInputMutation
>
export type AcceptInvitationInputMutationResult =
  Apollo.MutationResult<AcceptInvitationInputMutation>
export type AcceptInvitationInputMutationOptions = Apollo.BaseMutationOptions<
  AcceptInvitationInputMutation,
  AcceptInvitationInputMutationVariables
>
export const UploadUserAvatarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadUserAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'EnumValue', value: 'IMAGE_JPEG' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purpose' },
                value: { kind: 'EnumValue', value: 'USER_AVATAR' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UploadUserAvatarMutationFn = Apollo.MutationFunction<
  UploadUserAvatarMutation,
  UploadUserAvatarMutationVariables
>

/**
 * __useUploadUserAvatarMutation__
 *
 * To run a mutation, you first call `useUploadUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserAvatarMutation, { data, loading, error }] = useUploadUserAvatarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadUserAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadUserAvatarMutation,
    UploadUserAvatarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadUserAvatarMutation, UploadUserAvatarMutationVariables>(
    UploadUserAvatarDocument,
    options,
  )
}
export type UploadUserAvatarMutationHookResult = ReturnType<typeof useUploadUserAvatarMutation>
export type UploadUserAvatarMutationResult = Apollo.MutationResult<UploadUserAvatarMutation>
export type UploadUserAvatarMutationOptions = Apollo.BaseMutationOptions<
  UploadUserAvatarMutation,
  UploadUserAvatarMutationVariables
>
export const UploadOrganizationLogoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadOrganizationLogo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'EnumValue', value: 'IMAGE_JPEG' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purpose' },
                value: { kind: 'EnumValue', value: 'ORGANIZATION_LOGO' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UploadOrganizationLogoMutationFn = Apollo.MutationFunction<
  UploadOrganizationLogoMutation,
  UploadOrganizationLogoMutationVariables
>

/**
 * __useUploadOrganizationLogoMutation__
 *
 * To run a mutation, you first call `useUploadOrganizationLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOrganizationLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOrganizationLogoMutation, { data, loading, error }] = useUploadOrganizationLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadOrganizationLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadOrganizationLogoMutation,
    UploadOrganizationLogoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UploadOrganizationLogoMutation,
    UploadOrganizationLogoMutationVariables
  >(UploadOrganizationLogoDocument, options)
}
export type UploadOrganizationLogoMutationHookResult = ReturnType<
  typeof useUploadOrganizationLogoMutation
>
export type UploadOrganizationLogoMutationResult =
  Apollo.MutationResult<UploadOrganizationLogoMutation>
export type UploadOrganizationLogoMutationOptions = Apollo.BaseMutationOptions<
  UploadOrganizationLogoMutation,
  UploadOrganizationLogoMutationVariables
>
export const InvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'invitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'InvitationType' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'type' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'type' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'User' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables> &
    ({ variables: InvitationQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options)
}
export function useInvitationSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationQuery, InvitationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InvitationQuery, InvitationQueryVariables>(
    InvitationDocument,
    options,
  )
}
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>
export type InvitationSuspenseQueryHookResult = ReturnType<typeof useInvitationSuspenseQuery>
export type InvitationQueryResult = Apollo.QueryResult<InvitationQuery, InvitationQueryVariables>
export const InvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Invitations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'invitations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'type' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdBy' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'User' },
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'organization' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'users' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useInvitationsQuery__
 *
 * To run a query within a React component, call `useInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<InvitationsQuery, InvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<InvitationsQuery, InvitationsQueryVariables>(InvitationsDocument, options)
}
export function useInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<InvitationsQuery, InvitationsQueryVariables>(
    InvitationsDocument,
    options,
  )
}
export function useInvitationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<InvitationsQuery, InvitationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<InvitationsQuery, InvitationsQueryVariables>(
    InvitationsDocument,
    options,
  )
}
export type InvitationsQueryHookResult = ReturnType<typeof useInvitationsQuery>
export type InvitationsLazyQueryHookResult = ReturnType<typeof useInvitationsLazyQuery>
export type InvitationsSuspenseQueryHookResult = ReturnType<typeof useInvitationsSuspenseQuery>
export type InvitationsQueryResult = Apollo.QueryResult<InvitationsQuery, InvitationsQueryVariables>
export const IsSlugAvailableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'isSlugAvailable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isSlugAvailable' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __useIsSlugAvailableQuery__
 *
 * To run a query within a React component, call `useIsSlugAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsSlugAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsSlugAvailableQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useIsSlugAvailableQuery(
  baseOptions: Apollo.QueryHookOptions<IsSlugAvailableQuery, IsSlugAvailableQueryVariables> &
    ({ variables: IsSlugAvailableQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsSlugAvailableQuery, IsSlugAvailableQueryVariables>(
    IsSlugAvailableDocument,
    options,
  )
}
export function useIsSlugAvailableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsSlugAvailableQuery, IsSlugAvailableQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsSlugAvailableQuery, IsSlugAvailableQueryVariables>(
    IsSlugAvailableDocument,
    options,
  )
}
export function useIsSlugAvailableSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    IsSlugAvailableQuery,
    IsSlugAvailableQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<IsSlugAvailableQuery, IsSlugAvailableQueryVariables>(
    IsSlugAvailableDocument,
    options,
  )
}
export type IsSlugAvailableQueryHookResult = ReturnType<typeof useIsSlugAvailableQuery>
export type IsSlugAvailableLazyQueryHookResult = ReturnType<typeof useIsSlugAvailableLazyQuery>
export type IsSlugAvailableSuspenseQueryHookResult = ReturnType<
  typeof useIsSlugAvailableSuspenseQuery
>
export type IsSlugAvailableQueryResult = Apollo.QueryResult<
  IsSlugAvailableQuery,
  IsSlugAvailableQueryVariables
>
export const CreateCheckoutLinkDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateCheckoutLink' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'priceId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'couponId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'upgradeSource' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpgradeSource' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createCheckoutLink' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'priceId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'priceId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'couponId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'couponId' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'upgradeSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'upgradeSource' } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type CreateCheckoutLinkMutationFn = Apollo.MutationFunction<
  CreateCheckoutLinkMutation,
  CreateCheckoutLinkMutationVariables
>

/**
 * __useCreateCheckoutLinkMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCheckoutLinkMutation, { data, loading, error }] = useCreateCheckoutLinkMutation({
 *   variables: {
 *      priceId: // value for 'priceId'
 *      couponId: // value for 'couponId'
 *      upgradeSource: // value for 'upgradeSource'
 *   },
 * });
 */
export function useCreateCheckoutLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCheckoutLinkMutation,
    CreateCheckoutLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCheckoutLinkMutation, CreateCheckoutLinkMutationVariables>(
    CreateCheckoutLinkDocument,
    options,
  )
}
export type CreateCheckoutLinkMutationHookResult = ReturnType<typeof useCreateCheckoutLinkMutation>
export type CreateCheckoutLinkMutationResult = Apollo.MutationResult<CreateCheckoutLinkMutation>
export type CreateCheckoutLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateCheckoutLinkMutation,
  CreateCheckoutLinkMutationVariables
>
export const UpdateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateUserInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dismissedUi' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'captureFailedNux' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'loggedOutNux' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'reviewNux' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const PagesEditorQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PagesEditorQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'document' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'documentId' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'documentFields' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'documentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canvasSize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshots' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pinnedVersions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode

/**
 * __usePagesEditorQueryQuery__
 *
 * To run a query within a React component, call `usePagesEditorQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePagesEditorQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePagesEditorQueryQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function usePagesEditorQueryQuery(
  baseOptions: Apollo.QueryHookOptions<PagesEditorQueryQuery, PagesEditorQueryQueryVariables> &
    ({ variables: PagesEditorQueryQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PagesEditorQueryQuery, PagesEditorQueryQueryVariables>(
    PagesEditorQueryDocument,
    options,
  )
}
export function usePagesEditorQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PagesEditorQueryQuery, PagesEditorQueryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PagesEditorQueryQuery, PagesEditorQueryQueryVariables>(
    PagesEditorQueryDocument,
    options,
  )
}
export function usePagesEditorQuerySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    PagesEditorQueryQuery,
    PagesEditorQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<PagesEditorQueryQuery, PagesEditorQueryQueryVariables>(
    PagesEditorQueryDocument,
    options,
  )
}
export type PagesEditorQueryQueryHookResult = ReturnType<typeof usePagesEditorQueryQuery>
export type PagesEditorQueryLazyQueryHookResult = ReturnType<typeof usePagesEditorQueryLazyQuery>
export type PagesEditorQuerySuspenseQueryHookResult = ReturnType<
  typeof usePagesEditorQuerySuspenseQuery
>
export type PagesEditorQueryQueryResult = Apollo.QueryResult<
  PagesEditorQueryQuery,
  PagesEditorQueryQueryVariables
>
export const PagesEditorCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PagesEditorCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'document' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'documentFields' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'changeSensitivity' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'refreshRate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'suggestedRepairType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'identity' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'connectionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'current' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'instructions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'frame' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'frameX' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameY' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'frameHeight' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowWidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'windowHeight' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'latestVersion' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotVersionData' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotViewer' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'versions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'totalCount' } }],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'allVersions' },
            name: { kind: 'Name', value: 'versions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'captureStatus' },
                      value: {
                        kind: 'ListValue',
                        values: [{ kind: 'EnumValue', value: 'CAPTURED' }],
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'edges' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'cursor' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'node' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'SnapshotVersionData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pageInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'endCursor' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNextPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasPreviousPage' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'startCursor' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotViewer' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Snapshot' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canDelete' } },
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isAuthor' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'SnapshotVersionData' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SnapshotVersion' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
          { kind: 'Field', name: { kind: 'Name', value: 'image' } },
          { kind: 'Field', name: { kind: 'Name', value: 'extractedText' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'documentFields' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Document' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createdBy' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'User' } },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'image' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'folder' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pages' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'canvasSize' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'index' } },
                { kind: 'Field', name: { kind: 'Name', value: 'objects' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'snapshots' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'SnapshotFields' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pinnedVersions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'SnapshotVersionData' },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'properties' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'team' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'viewer' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'canEdit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isOwner' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'urn' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type PagesEditorCreateMutationFn = Apollo.MutationFunction<
  PagesEditorCreateMutation,
  PagesEditorCreateMutationVariables
>

/**
 * __usePagesEditorCreateMutation__
 *
 * To run a mutation, you first call `usePagesEditorCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagesEditorCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagesEditorCreateMutation, { data, loading, error }] = usePagesEditorCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePagesEditorCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PagesEditorCreateMutation,
    PagesEditorCreateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PagesEditorCreateMutation, PagesEditorCreateMutationVariables>(
    PagesEditorCreateDocument,
    options,
  )
}
export type PagesEditorCreateMutationHookResult = ReturnType<typeof usePagesEditorCreateMutation>
export type PagesEditorCreateMutationResult = Apollo.MutationResult<PagesEditorCreateMutation>
export type PagesEditorCreateMutationOptions = Apollo.BaseMutationOptions<
  PagesEditorCreateMutation,
  PagesEditorCreateMutationVariables
>
export const PagesEditorSyncDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PagesEditorSync' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'UpdateDocumentInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateDocument' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'code' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type PagesEditorSyncMutationFn = Apollo.MutationFunction<
  PagesEditorSyncMutation,
  PagesEditorSyncMutationVariables
>

/**
 * __usePagesEditorSyncMutation__
 *
 * To run a mutation, you first call `usePagesEditorSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagesEditorSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagesEditorSyncMutation, { data, loading, error }] = usePagesEditorSyncMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePagesEditorSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PagesEditorSyncMutation,
    PagesEditorSyncMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<PagesEditorSyncMutation, PagesEditorSyncMutationVariables>(
    PagesEditorSyncDocument,
    options,
  )
}
export type PagesEditorSyncMutationHookResult = ReturnType<typeof usePagesEditorSyncMutation>
export type PagesEditorSyncMutationResult = Apollo.MutationResult<PagesEditorSyncMutation>
export type PagesEditorSyncMutationOptions = Apollo.BaseMutationOptions<
  PagesEditorSyncMutation,
  PagesEditorSyncMutationVariables
>
export const PagesEditorCreateThumbnailUploadDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'PagesEditorCreateThumbnailUpload' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUpload' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'contentType' },
                value: { kind: 'EnumValue', value: 'IMAGE_JPEG' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objectId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'purpose' },
                value: { kind: 'EnumValue', value: 'DOCUMENT_THUMBNAIL' },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode
export type PagesEditorCreateThumbnailUploadMutationFn = Apollo.MutationFunction<
  PagesEditorCreateThumbnailUploadMutation,
  PagesEditorCreateThumbnailUploadMutationVariables
>

/**
 * __usePagesEditorCreateThumbnailUploadMutation__
 *
 * To run a mutation, you first call `usePagesEditorCreateThumbnailUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePagesEditorCreateThumbnailUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pagesEditorCreateThumbnailUploadMutation, { data, loading, error }] = usePagesEditorCreateThumbnailUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePagesEditorCreateThumbnailUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PagesEditorCreateThumbnailUploadMutation,
    PagesEditorCreateThumbnailUploadMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    PagesEditorCreateThumbnailUploadMutation,
    PagesEditorCreateThumbnailUploadMutationVariables
  >(PagesEditorCreateThumbnailUploadDocument, options)
}
export type PagesEditorCreateThumbnailUploadMutationHookResult = ReturnType<
  typeof usePagesEditorCreateThumbnailUploadMutation
>
export type PagesEditorCreateThumbnailUploadMutationResult =
  Apollo.MutationResult<PagesEditorCreateThumbnailUploadMutation>
export type PagesEditorCreateThumbnailUploadMutationOptions = Apollo.BaseMutationOptions<
  PagesEditorCreateThumbnailUploadMutation,
  PagesEditorCreateThumbnailUploadMutationVariables
>
