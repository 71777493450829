import { SnapshotRefreshRate } from '@/generated/graphql'

export const REFRESH_RATES = [
  { label: 'Manual', value: SnapshotRefreshRate.Manually },
  { label: '15 minutes', value: SnapshotRefreshRate.QuarterHourly },
  { label: '1 hour', value: SnapshotRefreshRate.Hourly },
  { label: '6 hours', value: SnapshotRefreshRate.EverySixHours },
  { label: '1 day', value: SnapshotRefreshRate.Daily },
]

export const IDENTITY_SETTINGS = [
  { label: 'I use one login', value: 'connected' },
  { label: 'I use multiple logins', value: 'disconnected' },
]
