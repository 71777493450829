import cx from 'clsx'
import { nanoid } from 'nanoid'
import { useMemo } from 'react'
import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

type Props = {
  tooltip?: string
  indeterminate?: boolean
} & React.InputHTMLAttributes<HTMLInputElement>

const Checkbox = (props: Props): JSX.Element => {
  const { className = '', children, indeterminate, id, ...rest } = props
  const generatedId = useMemo(() => `checkbox-${id || nanoid()}`, [id])

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible, triggerRef } =
    usePopperTooltip({
      delayShow: 300,
      placement: 'top',
    })

  React.useEffect(() => {
    if (triggerRef) {
      ;(triggerRef as HTMLInputElement).indeterminate = Boolean(indeterminate)
    }
  }, [triggerRef, indeterminate])

  return (
    <div className="relative flex items-center">
      <div className="flex h-5 items-center">
        <input
          ref={setTriggerRef}
          id={generatedId}
          type="checkbox"
          className={cx(
            'h-4 w-4',
            'text-interactive-primary',
            'border-divider-light-gray',
            'rounded-sm',
            'hover:bg-interactive-secondary-hover',
            'focus:ring-0',
            'focus:ring-offset-0',
            'focus:border-interactive-primary',
            'checked:bg-checkbox-check bg-center',
            'disabled:opacity-50',
            'disabled:cursor-not-allowed',
            className,
          )}
          {...rest}
        />
      </div>
      <div className="ml-2 text-base">
        <label
          htmlFor={generatedId}
          className={cx('select-none font-normal', {
            'cursor-pointer': !props.disabled,
          })}
        >
          {children}
        </label>
      </div>
      {visible && props.tooltip && (
        <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {props.tooltip}
        </div>
      )}
    </div>
  )
}

export default Checkbox
