/**
 * This wraps our Cognito usage
 */

import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js'

import { logError } from '../../util/log'
import LocalStorageHelper from './localStorageHelper'

let _userPool: CognitoUserPool | undefined
let _user: CognitoUser | null

export async function getUserPool(): Promise<CognitoUserPool> {
  if (!_userPool) {
    const storage = (await new LocalStorageHelper().init()).getStorage()

    const poolData = {
      ClientId: import.meta.env.WEB_COGNITO_CLIENT_ID,
      Storage: storage,
      UserPoolId: import.meta.env.WEB_COGNITO_USER_POOL_ID as string,
    }

    _userPool = new CognitoUserPool(poolData)
  }

  return _userPool
}

export async function getUser(): Promise<CognitoUser | null> {
  if (!_user) {
    const pool = await getUserPool()
    const user = pool.getCurrentUser()
    _user = user
  }

  return _user
}

export async function refreshCognitoState(newState: {
  readonly [key: string]: string
}): Promise<void> {
  const storageHelper = await new LocalStorageHelper().init()
  await storageHelper.refreshCognitoState(newState)
  _userPool = undefined
  _user = null
  await getUserPool()
  await getUser()
}

export async function cognitoRefreshToken(
  user: CognitoUser,
  session: CognitoUserSession,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const token = session.getRefreshToken()

    user.refreshSession(token, (error: Error, _session: CognitoUserSession) => {
      if (error) {
        return reject(error)
      }

      return resolve(_session.getIdToken().getJwtToken())
    })
  })
}

export async function getIdToken(): Promise<string | null> {
  const cognitoUser = await getUser()
  if (cognitoUser == null) return null

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        logError(error.message || JSON.stringify(error))
        return reject(error)
      }

      return resolve(session.getIdToken().getJwtToken())
    })
  })
}

export async function cognitoRegister({
  email,
  password,
}: {
  readonly email: string
  readonly password: string
}): Promise<ISignUpResult> {
  const attributes = [new CognitoUserAttribute({ Name: 'email', Value: email })]

  const userPool = await getUserPool()

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, attributes, [], (error, result) => {
      if (error || !result) {
        return reject(error)
      }

      return resolve(result)
    })
  })
}

export async function cognitoLogin({
  email,
  password,
  onSuccess,
}: {
  readonly email: string
  readonly password: string
  readonly onSuccess?: (newCognitoUser: CognitoUser) => void
}): Promise<CognitoUserSession> {
  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()

  return new Promise((resolve, reject) => {
    const newCognitoUser = new CognitoUser({
      Pool: userPool,
      Storage: storage,
      Username: email,
    })

    const authenticationDetails = new AuthenticationDetails({
      ClientMetadata: {
        what: 'where does this end up?', // TODO
      },
      Password: password,
      Username: email,
    })

    newCognitoUser.authenticateUser(authenticationDetails, {
      onFailure: function (error) {
        if (error.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the
          // email verification step when signing up
          return reject({
            code: error.code,
            message: 'Please verify your account before attempting to log in.',
          })
        }

        return reject({
          code: error.code,
          message: 'Incorrect email or password.',
        })
      },
      onSuccess: function (result) {
        _user = newCognitoUser
        onSuccess && onSuccess(newCognitoUser)

        return resolve(result)
      },
    })
  })
}

export async function sendForgotPasswordMail(email: string): Promise<void> {
  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()
  const newCognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: email,
  })

  return new Promise((resolve, reject) => {
    newCognitoUser.forgotPassword({
      onFailure: function (err) {
        return reject(err)
      },
      onSuccess: function () {
        return resolve()
      },
    })
  })
}

export async function confirmForgotPassword(
  email: string,
  newPassword: string,
  verificationCode: string,
): Promise<void> {
  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()
  const newCognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: email,
  })

  return new Promise((resolve, reject) => {
    newCognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure: function (err) {
        return reject(err)
      },
      onSuccess: function () {
        return resolve()
      },
    })
  })
}

export async function cognitoResendConfirmationEmail(email: string): Promise<void> {
  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()
  const newCognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: email,
  })

  return new Promise((resolve, reject) => {
    newCognitoUser.resendConfirmationCode(function (err, result) {
      if (err) {
        return reject(err)
      }
      return resolve(result)
    })
  })
}

export async function cognitoConfirmRegistration(
  email: string,
  verificationCode: string,
): Promise<void> {
  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()
  const newCognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: email,
  })

  return new Promise((resolve, reject) => {
    newCognitoUser.confirmRegistration(verificationCode, false, function (err, result) {
      if (err) {
        return reject(err)
      }
      return resolve(result)
    })
  })
}

export async function cognitoLoginWithOAuth(code: string): Promise<CognitoUser> {
  const response = await fetch(
    `https://${import.meta.env.WEB_COGNITO_OAUTH_HOSTNAME}/oauth2/token`,
    {
      body: new URLSearchParams({
        // eslint-disable-next-line camelcase
        client_id: import.meta.env.WEB_COGNITO_CLIENT_ID,

        code,

        // eslint-disable-next-line camelcase
        grant_type: 'authorization_code',
        // eslint-disable-next-line camelcase
        redirect_uri: window.location.href.split('?')[0],
        // Future TODO: PKCE
        // PKCE ref: https://github.com/aws-amplify/amplify-js/blob/a047ce73abe98c3bf82e888c3afb4d2f911805f3/packages/auth/src/OAuth/OAuth.ts#L138
      }).toString(),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      method: 'POST',
    },
  )

  const {
    error,
    access_token: AccessToken,
    id_token: IdToken,
    refresh_token: RefreshToken,
  } = await response.json()

  if (error) {
    throw new Error('We were unable to authorize you with the 3rd-party provider.')
  }

  const session = new CognitoUserSession({
    AccessToken: new CognitoAccessToken({ AccessToken }),
    IdToken: new CognitoIdToken({ IdToken }),
    RefreshToken: new CognitoRefreshToken({ RefreshToken }),
  })

  const userPool = await getUserPool()
  const storage = (await new LocalStorageHelper().init()).getStorage()

  const username = session.getIdToken().decodePayload()['cognito:username']
  const cognitoUser = new CognitoUser({
    Pool: userPool,
    Storage: storage,
    Username: username,
  })

  cognitoUser.setSignInUserSession(session)
  _user = cognitoUser

  return cognitoUser
}

export async function getCognitoSession({
  onNoSession,
  onLoading,
  onSuccess,
}: {
  readonly onNoSession: () => void
  readonly onLoading: () => void
  readonly onSuccess: (cognitoUser: CognitoUser) => void
}): Promise<void> {
  const cognitoUser = await getUser()

  if (!cognitoUser) {
    onNoSession && onNoSession()
    return
  }

  onLoading && onLoading()

  return new Promise((resolve, reject) => {
    cognitoUser.getSession(async function (error: Error | null) {
      if (error) {
        // Resource likely not found because cognito pool id changed
        const resourceNotFound = error?.name === 'ResourceNotFoundException'
        const refreshTokenRevoked =
          error?.name === 'NotAuthorizedException' &&
          error?.message === 'Refresh Token has been revoked'

        if (resourceNotFound || refreshTokenRevoked) {
          const storage = (await new LocalStorageHelper().init()).getStorage()
          // Likely the user no longer exists (eg: locally when resetting the
          // database)
          storage.clear()
        }
        // TODO What are we supposed to do here? Something user-friendly, presumably. But...what?
        logError(error.message || JSON.stringify(error))
        onNoSession && onNoSession()
        return reject(error)
      }

      onSuccess && onSuccess(cognitoUser)
      return resolve()
    })
  })
}

export async function cognitoLogout(): Promise<void> {
  const cognitoUser = await getUser()
  if (!cognitoUser) return

  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore the callback type is wrong, the callback gets an error
    // https://github.com/aws-amplify/amplify-js/blob/3f8c0008ea46fb144e5a312a9d09c88bc65b592e/packages/amazon-cognito-identity-js/src/CognitoUser.js#L1985
    cognitoUser.signOut((err) => {
      if (err) {
        return reject(err)
      }

      resolve()
    })
  })
}
