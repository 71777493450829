import {
  ApolloCache,
  ApolloClient,
  FieldReadFunction,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities'
import { createClient } from 'graphql-ws'

import { logError } from '../../util/log'
import { getClientId } from '../auth/clientId'
import { getIdToken } from '../auth/cognitoHelper'
import {
  activeOrganizationState,
  contentPrivacyStateVar,
  extensionClientStateVar,
  feedClientStateVar,
} from './apolloLocalState'

const httpLink = new HttpLink({
  uri: import.meta.env.WEB_GRAPHQL_API_HOST,
})
const wsLink = new GraphQLWsLink(
  createClient({
    connectionParams: async () => {
      const token = await getIdToken()
      return {
        token,
      }
    },
    keepAlive: 1000 * 60 * 3, // ping server every 3 minutes
    url: import.meta.env.WEB_GRAPHQL_WEBSOCKET_API_URL,
  }),
)
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const authLink = setContext(async ({ query }, { headers }) => {
  const definition = getMainDefinition(query)
  // don't add auth headers to subscriptions, since we do that in graphql-ws client
  if (definition.kind === 'OperationDefinition' && definition.operation === 'subscription') {
    return
  }
  try {
    const token = await getIdToken()
    const orgState = activeOrganizationState()
    const { id, password } = contentPrivacyStateVar()

    const authValues = [
      id && `Basic ${btoa(`${id}:${password || '-'}`)}`,
      token && `Bearer ${token}`,
    ]

    return {
      headers: {
        ...headers,
        'authorization': authValues.filter(Boolean).join(','),
        'organization-id': orgState ? orgState.id : '-',
      },
    }
  } catch (err) {
    const tokenError = new Error('Unable to get ID token')
    if (err instanceof Error) {
      tokenError.message = `${tokenError.message}: ${err.message || JSON.stringify(err)}`
    }
    throw tokenError
  }
})

const clientIdLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      'client-id': await getClientId(),
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      logError(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        message,
        locations,
        path,
      )
    })
  }

  if (networkError) {
    logError(`[Network error]: ${networkError}`)

    if ([401, 403].includes((networkError as ServerError).statusCode)) {
      // the session expired
      // NOTE: ApolloClient is used in web app and extension react and the extension background page.
      // window && window.location.reload(); // TODO handle this better e.g. with a user-friendly dialog or something
    }
  }
})

const generateReadByIdForType = (__typename: string): { read: FieldReadFunction } => ({
  read(_, { args, toReference }) {
    return toReference({ __typename, id: args?.id })
  },
})

export const deleteFromCacheDuringMutation =
  (variables: { id: string; __typename: string }) =>
  (cache: ApolloCache<NormalizedCacheObject>): void => {
    cache.evict({ id: cache.identify(variables) })
    cache.gc()
  }

export const apolloCache = new InMemoryCache({
  typePolicies: {
    Organization: {
      fields: {
        snapshots: relayStylePagination(['filter', 'sort']),
      },
    },
    PublicSnapshot: {
      fields: {
        versions: relayStylePagination(['filter', 'sort']),
      },
    },
    Query: {
      fields: {
        document: generateReadByIdForType('Document'),
        extensionClientState: {
          read() {
            return extensionClientStateVar()
          },
        },
        feedClientState: {
          read() {
            return feedClientStateVar()
          },
        },
        folder: generateReadByIdForType('Folder'),
        publicDocument: {
          keyArgs: ['id'],
          merge: true,
        },
        publicSnapshot: {
          keyArgs: ['id'],
          merge: true,
        },
        search: relayStylePagination(['filter', 'sort']),
        snapshot: generateReadByIdForType('Snapshot'),
        snapshotVersion: generateReadByIdForType('SnapshotVersion'),
      },
    },
    SearchResultEdge: {
      keyFields: ['cursor'],
    },
    Snapshot: {
      fields: {
        versions: relayStylePagination(['filter', 'sort']),
        viewer: {
          merge: true,
        },
      },
    },
    SnapshotEdge: {
      keyFields: ['cursor'],
    },
    SnapshotVersionEdge: {
      keyFields: ['cursor'],
    },
    Viewer: {
      merge: true,
    },
  },
})

export const apolloClient = new ApolloClient({
  cache: apolloCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first',
    },
  },
  link: from([errorLink, clientIdLink, authLink, splitLink]),
})
