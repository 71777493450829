const EXTENSION_NAMESPACE = 'com.plusdocs.extension:' as const

export type NamespacedEventName = `${typeof EXTENSION_NAMESPACE}${string}`
export type RuntimeMessage = {
  name: NamespacedEventName
  data?: Record<string, unknown>
}

export const generateNamespacedName = (name: string): NamespacedEventName =>
  `${EXTENSION_NAMESPACE}${name}`

export const generateNamespacedMessage = (
  name: string,
  data?: RuntimeMessage['data'],
): RuntimeMessage => ({
  data,
  name: generateNamespacedName(name),
})

export const isValidMessage = (message: RuntimeMessage): boolean => {
  if (!message || !message.name || !message.name.startsWith(EXTENSION_NAMESPACE)) {
    return false
  } else {
    return true
  }
}

export const extractNameFromMessage = ({ name }: RuntimeMessage): string =>
  name.replace(EXTENSION_NAMESPACE, '')
