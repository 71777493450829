import cx from 'clsx'
import * as PropTypes from 'prop-types'
import * as React from 'react'

const Badge: React.FC<{ children?: React.ReactNode }> = (props): JSX.Element => {
  const { children } = props

  return <span className={cx(badgeClassNames)}>{children}</span>
}

const badgeClassNames = [
  'p-1',
  'rounded',
  'bg-background-overlay',
  'text-copy-active',
  'text-base',
  'focus:border',
  'focus:!border-divider-dark-gray',
]

export default Badge

Badge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
