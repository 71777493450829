import { format } from 'date-fns'

import { SnapshotFieldsFragment, SnapshotRefreshRate } from '@/generated/graphql'
import { Label, SelectMenu } from '@/shared/components'
import { REFRESH_RATES } from '@/shared/constants'
import useUpdateSnapshot from '@/shared/hooks/useUpdateSnapshot'
import { useViewer } from '@/web/hooks'

export const RefreshSettings = ({
  snapshot,
}: {
  snapshot: SnapshotFieldsFragment
}): JSX.Element => {
  const updateSnapshotMutation = useUpdateSnapshot(snapshot)

  const { viewer } = useViewer()
  const refreshRates = REFRESH_RATES.filter((element) =>
    viewer?.settings?.refreshRates?.includes(element.value),
  )

  if (!snapshot.viewer.canEdit) {
    return <></>
  }

  return (
    <div data-star-tours-id="refresh-rate">
      <Label className="text-base font-semibold">Refresh rate</Label>
      <div className="w-full pb-1">
        <SelectMenu
          options={refreshRates}
          onChange={(option) => {
            updateSnapshotMutation({
              refreshRate: option.value as SnapshotRefreshRate,
            })
          }}
          value={
            refreshRates.find((rate) => rate.value === snapshot.refreshRate) || refreshRates[0]
          }
          modal
          isDropdown
          disabled={!snapshot.viewer.canEdit}
        />
      </div>
      {snapshot?.currentVersion?.createdAt && (
        <span className="text-copy-secondary text-xs">
          Last updated {format(new Date(snapshot.currentVersion.createdAt), 'P')} at{' '}
          {format(new Date(snapshot.currentVersion.createdAt), 'p')}
        </span>
      )}
    </div>
  )
}
