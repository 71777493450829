import { useCallback, useMemo, useState } from 'react'
import * as React from 'react'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import { Input, Label, TextArea } from '@/shared/components'
import useUpdateSnapshot from '@/shared/hooks/useUpdateSnapshot'

type PropertiesWithTextInputs = 'name' | 'description'

export const TitleAndDescriptionSettings = ({
  snapshot,
  labels = { description: 'Snapshot description', title: 'Snapshot title' },
}: {
  snapshot: SnapshotFieldsFragment
  labels?: { title?: string; description: string }
}): JSX.Element => {
  const updateSnapshotMutation = useUpdateSnapshot(snapshot)
  const pendingChangeRef = React.useRef({ description: '', name: '' })
  const [inputVals, setInputVals] = useState({
    description: snapshot.description || '',
    name: snapshot.name || '',
  })
  const [hasChanged, setHasChanged] = useState(false)

  React.useEffect(() => {
    setInputVals({
      description: snapshot.description || '',
      name: snapshot.name || '',
    })
  }, [snapshot])

  React.useEffect(() => {
    return () => {
      // There's a case where you're editing a name/description and then click
      // in the canvas changing the selected item. This sidebar unmounts before
      // onBlur can fire. We want to keep any pending changes in a ref so we can
      // actually sync them on unmount.
      if (pendingChangeRef.current.name) {
        updateSnapshotMutation({ name: pendingChangeRef.current.name })
      }
      if (pendingChangeRef.current.description) {
        updateSnapshotMutation({
          description: pendingChangeRef.current.description,
        })
      }
    }
  }, [updateSnapshotMutation])
  // Update the input vals if the outer snapshot changes (e.g. changing selection between two snapshots in the pages editor)
  React.useEffect(() => {
    setInputVals({
      description: snapshot.description ?? '',
      name: snapshot.name ?? '',
    })
  }, [snapshot.name, snapshot.description])
  const inputOnChange = useCallback(
    (property: PropertiesWithTextInputs) =>
      ({ target: { value } }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInputVals({ ...inputVals, [property]: value })
        pendingChangeRef.current[property] = value
        setHasChanged(true)
      },
    [inputVals],
  )

  const inputOnBlur = useCallback(
    (property: PropertiesWithTextInputs) => () => {
      if (hasChanged) {
        updateSnapshotMutation({
          [property]: pendingChangeRef.current[property],
        })
        pendingChangeRef.current[property] = ''
        setHasChanged(false)
      }
    },
    [hasChanged, updateSnapshotMutation],
  )

  const onChangeName = useMemo(() => inputOnChange('name'), [inputOnChange])
  const onChangeDescription = useMemo(() => inputOnChange('description'), [inputOnChange])
  const onBlurName = useMemo(() => inputOnBlur('name'), [inputOnBlur])
  const onBlurDescription = useMemo(() => inputOnBlur('description'), [inputOnBlur])

  if (!snapshot.viewer.canEdit) {
    return <></>
  }

  return (
    <div className="flex flex-col space-y-3">
      <div>
        <Label htmlFor="sidebar-title-input" className="text-base font-semibold">
          {labels.title}
        </Label>
        <Input
          id="sidebar-title-input"
          placeholder="Untitled snapshot"
          value={inputVals.name}
          onChange={onChangeName}
          onBlur={onBlurName}
          className="pl-2 pr-0"
          maxLength={100}
          disabled={!snapshot.viewer.canEdit}
        />
      </div>
      <div>
        <Label htmlFor="sidebar-description-input" className="mb-1 text-base font-semibold">
          {labels.description}
        </Label>
        <TextArea
          id="sidebar-description-input"
          placeholder="Add a description"
          rows={2}
          value={inputVals.description}
          onChange={onChangeDescription}
          onBlur={onBlurDescription}
          className="min-h-[48px] p-2"
          disabled={!snapshot.viewer.canEdit}
        />
      </div>
    </div>
  )
}
