import * as React from 'react'

export function Loader(props: React.HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <div className="loader-bounding-box" {...props}>
      <div className="loader-wrapper">
        <svg
          viewBox="0 0 128 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="loader"
        >
          <path
            className="loader-br"
            d="M96 72L96 64L64 64L64 96L72 96L72 92C72 79 79 72 92 72L96 72Z"
          />
          <path
            className="loader-tl"
            d="M32 56L32 64L64 64L64 32L56 32L56 36C56 49 49 56 36 56L32 56Z"
          />
          <path
            className="loader-tr"
            d="M48 93L56 93L56 72L35 72L35 80L43 80C46 80 48 82 48 85L48 93Z"
          />
          <path
            className="loader-bl"
            d="M80 35L72 35L72 56L93 56L93 48L85 48C82 48 80 46 80 43L80 35Z"
          />
        </svg>
      </div>
    </div>
  )
}
