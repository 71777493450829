/**
 * this naive document sync is really only good for single player, and should be replaced entirely when we do multi player.
 * basic flow:
 * 1. exports a class instance of the sync engine
 * 2. when engine.push() is called, we store the state given and start an internal timer of 500 milliseconds
 * 3. if push is called again before the 500 milliseconds, we discard the previous state and restart the timer
 * 4. if the timer finishes (i.e. no changes within 500 milliseconds), we make the network call to persist the stored state
 * 5. if there are changes while the network request is in flight, we simply store them and start the timer once the in flight request finishes
 */

import { captureException } from '@sentry/react'
import { isEqual } from 'lodash-es'

import {
  Document,
  PagesEditorCreateThumbnailUploadDocument,
  PagesEditorSyncDocument,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'

class DocumentSync {
  timer: ReturnType<typeof setTimeout> | null = null
  state: Document | null = null
  changes: Document | null = null
  syncing = false
  orgId: string | null = null

  onUnload(ev: BeforeUnloadEvent) {
    if (this.timer || this.syncing) {
      ev.preventDefault()
      // if someone tries to leave, immediately flush the timer and start the sync so they can leave ASAP
      if (typeof this.timer === 'number') {
        clearTimeout(this.timer)
        this.#sync()
      }
      ev.returnValue =
        'You may have unsaved changes. Please wait a few seconds for syncing to finish.'
    }
  }

  onSaveShortcut() {
    if (typeof this.timer === 'number') {
      clearTimeout(this.timer)
      this.#sync()
    }
  }

  constructor() {
    window.addEventListener('beforeunload', this.onUnload.bind(this))
    window.addEventListener('pages:save', this.onSaveShortcut.bind(this))
  }

  setSyncing(syncing: boolean) {
    this.syncing = syncing
    window.dispatchEvent(
      new CustomEvent<{
        syncing: boolean
      }>('pages:sync-change', { detail: { syncing } }),
    )
  }

  push(doc: Document): void {
    if (!this.syncing) {
      this.state = doc
    }
    if (this.syncing) {
      this.changes = doc
      return
    }
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(this.#sync.bind(this), 500)
  }

  clear(): void {
    if (typeof this.timer === 'number') {
      clearTimeout(this.timer)
    }
  }

  async #sync() {
    if (!this.state) {
      return
    }

    let pendingImage

    while (this.state) {
      const initialState = { ...this.state }
      this.timer = null
      if (!isEqual(this.state, this.changes)) {
        this.state = this.changes
        this.changes = null
      } else {
        this.state = null
        this.changes = null
      }
      this.setSyncing(true)

      // remove extraneous typenames and temp data
      const stateToSync = {
        ...initialState,
        __typename: undefined,
        pages: initialState.pages.map((page) => ({
          ...page,
          __typename: undefined,
          canvasSize: {
            ...page.canvasSize,
            __typename: undefined,
          },
          objects: page.objects.map((object) => ({
            ...object,
            temp: {},
          })),
          pinnedVersions: undefined,
          snapshots: undefined,
        })),
      }

      const [{ data }] = await Promise.all([
        apolloClient.mutate<{ createUpload: string }>({
          mutation: PagesEditorCreateThumbnailUploadDocument,
          variables: {
            id: stateToSync.id,
          },
        }),
        apolloClient.mutate({
          mutation: PagesEditorSyncDocument,
          variables: {
            id: stateToSync.id,
            input: {
              description: stateToSync.description,
              name: stateToSync.name,
              pages: stateToSync.pages,
            },
          },
        }),
      ])

      pendingImage = data?.createUpload
    }

    if (pendingImage) {
      try {
        const thumbnailData = await getPageCanvasDataURL()
        await fetch(pendingImage, {
          body: thumbnailData,
          method: 'PUT',
        })
      } catch (e) {
        // if uploading the thumbnail fails, we send to sentry behind the scenes and continue
        if (import.meta.env.MODE === 'production') {
          captureException(e, {
            level: 'debug', // we set debug so that it doesn't trigger the crash dialogue
          })
        }
      }
    }

    this.setSyncing(false)
  }
}

const instance = new DocumentSync()
export default instance

/**
 * Method to grab the page Canvas directly off the DOM and then pull the blob of binary data
 * @returns {Promise<Blob>} – the blob of binary data
 */
const getPageCanvasDataURL = async (): Promise<Blob> => {
  const height = 540
  const width = 960

  try {
    const primaryCanvas = document.getElementsByTagName('canvas')[0]
    const primaryCanvasDataUrl = primaryCanvas.toDataURL('image/webp', 1.0)

    // create an off-screen canvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      throw new Error('Could not create canvas context')
    }

    canvas.height = height
    canvas.width = width
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, width, height)

    const img = new Image()

    const finalBlob = await new Promise<Blob>((resolve) => {
      img.onload = () => {
        ctx?.drawImage(img, 0, 0, width, height)
        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob)
            }
          },
          'image/jpeg',
          0.9,
        )
      }
      img.src = primaryCanvasDataUrl
    })

    return finalBlob
  } catch (e) {
    return Promise.reject(e)
  }
}
