// KEEP THESE IN ALPHABETICAL ORDER!
export enum ButtonTestId {
  ConfirmBrokenSnapshot = 'Button:ConfirmBrokenSnapshot',
  ConfirmNotBrokenSnapshot = 'Button:ConfirmNotBrokenSnapshot',
  ConnectionStatus = 'Button:ConnectionStatus',
  DeleteSnapshot = 'Button:DeleteSnapshot',
  HideThisMessage = 'Button:HideThisMessage',
  MoreOptionsInCaptureApp = 'Button:MoreOptionsInCaptureApp',
  Reconnect = 'Button:Reconnect',
  RefreshSnapshot = 'Button:RefreshSnapshot',
  RepairSnapshot = 'Button:RepairSnapshot',
  RetakeSnapshot = 'Button:RetakeSnapshot',
  ShowRepairNux = 'Button:ShowRepairNux',
}

// KEEP THESE IN ALPHABETICAL ORDER!
export enum MenuItemTestId {
  ActionRecording = 'MenuItem:ActionRecording',
  HideThisMessage = 'MenuItem:HideThisMessage',
  LogBackIn = 'MenuItem:LogBackIn',
  ReportSnapshotIssue = 'MenuItem:ReportSnapshotIssue',
  RestartCapture = 'MenuItem:RestartCapture',
  RetakeSnapshot = 'MenuItem:RetakeSnapshot',
  SnapshotNotBroken = 'MenuItem:SnapshotNotBroken',
}

export type TestId = ButtonTestId | MenuItemTestId
