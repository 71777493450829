import { captureException } from '@sentry/react'

import {
  ContentType,
  Document,
  DocumentCardFragment,
  Maybe,
  PublicDocument,
  PublicSnapshot,
  Snapshot,
  SnapshotFieldsFragment,
} from '../generated/graphql'
import { useSearchParams } from '../shared/hooks/useSearchParams'
import { ContentV2, EncodableString, ScreenSize } from '../shared/types'
import { generateNamespacedMessage, generateNamespacedName } from './extension'

type Target = '_blank' | '_self'

export const isEmbed = (): boolean => {
  return window && window !== window.parent
}

export const useIsLinkPreview = (): boolean => {
  const params = useSearchParams()
  return params.get('isNotionLinkPreview')?.toLowerCase() === 'true'
}

export const openLink = (link: string, target: Target = '_blank'): void => {
  window.open(link, target)
}

export const copyTextToClipboard = async (data: string): Promise<void> => {
  try {
    await navigator.clipboard.writeText(data)
    return
  } catch {
    const copyCallback = (e: ClipboardEvent) => {
      if (e.clipboardData) {
        e.clipboardData.setData('text/plain', data)
        e.preventDefault()
        document.removeEventListener('copy', copyCallback)
      }
    }

    document.addEventListener('copy', copyCallback)
    document.execCommand('copy')

    return Promise.resolve()
  }
}

const reportPortError = (extensionRuntimeId?: string) => {
  if (chrome.runtime.lastError) {
    captureException({
      extensionRuntimeId,
      message: chrome.runtime.lastError.message,
      name: 'ExtensionRuntimeError',
    })
  }
}

const createExtensionHandler = (
  extensionRuntimeId: Maybe<string> | undefined,
  handlerName: string,
  handlerPayload: Record<string, unknown>,
) => {
  if (!extensionRuntimeId) return
  const port = chrome.runtime.connect(extensionRuntimeId, {
    name: generateNamespacedName('external-port'),
  })
  port.onDisconnect.addListener(() => {
    reportPortError(extensionRuntimeId)
  })
  port.postMessage(generateNamespacedMessage(handlerName, handlerPayload))
  port.disconnect()
}

export const createReauthHandler =
  ({
    snapshotId,
    snapshotImageUrl,
    snapshotUrl,
    extensionRuntimeId,
    orgId,
    orgSlug,
  }: {
    readonly snapshotId: string
    readonly snapshotImageUrl: string
    readonly snapshotUrl: string
    readonly extensionRuntimeId: Maybe<string> | undefined
    readonly orgId: string
    readonly orgSlug: string
  }): (() => void) =>
  () => {
    createExtensionHandler(
      extensionRuntimeId,
      'start-reauth', // Note: We can delete orgId/orgSlug when move to M2 ReAuth
      {
        imageUrl: snapshotImageUrl,
        orgId,
        orgSlug,
        organizationId: orgId,
        organizationSlug: orgSlug,
        screenHeight: screen.height,
        screenWidth: screen.width,
        snapshotId,
        url: snapshotUrl,
        // Note: we might not need these if we get x/y from frame
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      },
    )
  }

export const createRepairHandler =
  ({
    snapshotId,
    snapshotUrl,
    snapshotImageUrl,
    extensionRuntimeId,
    orgId,
    orgSlug,
  }: {
    readonly snapshotId: string
    readonly snapshotUrl: string
    readonly snapshotImageUrl: string
    readonly extensionRuntimeId: Maybe<string> | undefined
    readonly orgId: string
    readonly orgSlug: string
  }): (() => void) =>
  () => {
    createExtensionHandler(extensionRuntimeId, 'start-repair', {
      imageUrl: snapshotImageUrl,
      organizationId: orgId,
      organizationSlug: orgSlug,
      screenHeight: screen.height,
      screenWidth: screen.width,
      snapshotId,
      url: snapshotUrl,
      // Note: we might not need these if we get x/y from frame
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    })
  }

export const syncCognitoTokens = (extensionRuntimeId: string): void => {
  // early return if the runtime id is an empty string, since that means the extension is not installed
  if (extensionRuntimeId === '') return

  const cognitoState = Object.fromEntries(
    Object.entries(localStorage).filter(([k]) => k.startsWith('CognitoIdentityServiceProvider')),
  )

  const port = chrome.runtime.connect(extensionRuntimeId, {
    name: generateNamespacedName('external-port'),
  })
  port.onDisconnect.addListener(() => {
    reportPortError(extensionRuntimeId)
  })

  port.postMessage(
    generateNamespacedMessage('sync-cognito-state', {
      cognitoState: JSON.stringify(cognitoState),
    }),
  )
  port.disconnect()
}

export const syncLogout = (extensionRuntimeId: string): void => {
  // early return if the runtime id is an empty string, since that means the extension is not installed
  if (extensionRuntimeId === '') return

  const port = chrome.runtime.connect(extensionRuntimeId, {
    name: generateNamespacedName('external-port'),
  })
  port.onDisconnect.addListener(() => {
    reportPortError(extensionRuntimeId)
  })

  port.postMessage(generateNamespacedMessage('sync-cognito-logout'))
  port.disconnect()
}

export const stringifyThumbnailData = (thumbnailData: EncodableString): string => {
  if (!thumbnailData) {
    return ''
  }

  if (typeof thumbnailData === 'string') {
    return thumbnailData
  }

  const enc = new TextDecoder('utf-8')
  return enc.decode(new Uint8Array(thumbnailData.data))
}

/**
 * Method to get the publicly available link for a contentItem
 * This is the full shareUrl for snapshots/pages
 */
export const getPublicLink = (
  content:
    | SnapshotFieldsFragment
    | DocumentCardFragment
    | Snapshot
    | Document
    | PublicDocument
    | PublicSnapshot
    | null,
  organizationSlug: string,
  isPublic: boolean,
  versionId?: string | null | undefined,
): string => {
  if (!content) return ''
  if (isPublic) return window.location.href

  switch (content.__typename) {
    case 'Snapshot':
      return (
        `${import.meta.env.WEB_APP_HOST}/${organizationSlug}/snapshot/${content.id}` +
        (versionId ? `/version/${versionId}` : '') +
        (versionId ? window.location.search : '')
      )
    case 'Document': {
      return `${import.meta.env.WEB_APP_HOST}/${organizationSlug}/page/${content.id}`
    }
    default:
      return ''
  }
}

/**
 * Method to get the internal link for a contentItem
 * This is the path only editURL for snapshots/pages
 * @param contentItem
 * @returns
 */
export const getContentLinkUrl = (
  contentItem: ContentV2,
  teamId?: string,
  gallery?: boolean,
): string => {
  const teamPath = teamId || contentItem.team?.id ? `/team/${teamId || contentItem.team?.id}` : ''

  if (gallery) {
    return `gallery/${contentItem.id}`
  }

  switch (contentItem.__typename) {
    case 'Document':
      return `/${contentItem.organization.slug}/page/${contentItem.id}`
    case 'Snapshot':
      return `/${contentItem.organization.slug}/snapshot/${contentItem.id}`
    case 'Folder':
      return `/${contentItem.organization.slug}${teamPath}/folders/${contentItem.id}`
    default:
      return ''
  }
}

export const getContentFriendlyText = (contentItem: ContentV2): string => {
  switch (contentItem.__typename) {
    case 'Document':
      return 'Page'
    case 'Snapshot':
      return 'Snapshot'
    case 'Folder':
      return 'Folder'
    default:
      return 'Content'
  }
}

export const getContentTypeFriendlyText = (contentType: ContentType): string => {
  switch (contentType) {
    case ContentType.Document:
      return 'Page'
    case ContentType.Snapshot:
      return 'Snapshot'
    case ContentType.Folder:
      return 'Folder'
    default:
      return 'Content'
  }
}

export const getScreenSize = (): ScreenSize => {
  const width = window.innerWidth
  if (width < ScreenSize.sm) {
    return ScreenSize.xs
  } else if (width < ScreenSize.md) {
    return ScreenSize.sm
  } else if (width < ScreenSize.lg) {
    return ScreenSize.md
  } else if (width < ScreenSize.xl) {
    return ScreenSize.lg
  } else if (width < ScreenSize.xxl) {
    return ScreenSize.xl
  } else {
    return ScreenSize.xxl
  }
}

/**
 * Captialized the first character in a string
 * @param inputString String to capitalize
 * @returns String with first character captialized
 */
export const capitalize = (inputString: string): string => {
  return inputString[0].toUpperCase() + inputString.slice(1)
}

export const generateNewPageUrl = (
  orgSlug?: string,
  teamId?: string,
  folderId?: string,
): string => {
  const searchParams = new URLSearchParams()
  if (teamId) searchParams.append('teamId', teamId)
  if (folderId) searchParams.append('folderId', folderId)

  return `/${orgSlug}/page/new${teamId || folderId ? `?${searchParams.toString()}` : ''}`
}
