import * as React from 'react'

import { authContext, useAuthProvider } from './useAuth'

interface AuthProviderProps {
  readonly children: React.ReactNode
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const auth = useAuthProvider()

  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}
