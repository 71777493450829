import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '@radix-ui/react-dropdown-menu'
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import { useRef, useState } from 'react'
import { Check, ChevronDown, Lock, X } from 'react-feather'

import Button from './Button/Button'

export type SelectMenuOption = {
  label: string
  value: string | number | boolean | null
  disabled?: boolean
  tooltip?: string
  locked?: boolean
}

type Props = {
  defaultValue?: SelectMenuOption
  icon?: JSX.Element
  tooltip?: string
  options: SelectMenuOption[]
  value: SelectMenuOption
  onChange: (option: SelectMenuOption) => void
  selectedValueLabel?: string | ((option: SelectMenuOption) => string)
  modal?: boolean
  isDropdown?: boolean
  disabled?: boolean
}

const SelectMenu = (props: Props): JSX.Element => {
  const { defaultValue, disabled, icon, isDropdown, options, value, onChange, modal } = props
  const [open, setOpen] = useState(false)
  const isNonDefaultValueSelected = defaultValue && value.value !== defaultValue.value
  const buttonRef = useRef<HTMLButtonElement>(null)

  const hasSelectedValueLabel =
    typeof props.selectedValueLabel === 'function' || typeof props.selectedValueLabel === 'string'

  return (
    <DropdownMenu open={open} onOpenChange={setOpen} modal={modal}>
      <Button
        align="left"
        className="h-8 w-full !p-0"
        size="standard"
        variant="secondary"
        active={open || isNonDefaultValueSelected}
        ref={buttonRef}
        disabled={disabled}
      >
        <DropdownMenuTrigger asChild disabled={disabled}>
          <div
            className={clsx(
              'flex w-full items-center justify-between p-2',
              isDropdown ? 'font-normal' : 'font-semibold',
            )}
          >
            {icon && <div className="mr-1">{icon}</div>}
            {hasSelectedValueLabel
              ? typeof props.selectedValueLabel === 'function'
                ? props.selectedValueLabel(value)
                : props.selectedValueLabel
              : value.label}
            {isDropdown && (
              <ChevronDown className="text-copy-secondary h-4 w-4" aria-hidden="true" />
            )}
          </div>
        </DropdownMenuTrigger>

        {defaultValue && isNonDefaultValueSelected && (
          <X className="mr-1" size={16} onClick={() => onChange(defaultValue)} />
        )}
      </Button>
      <DropdownMenuPortal>
        <DropdownMenuContent
          align="start"
          className={clsx(
            'font-base border-divider-light-gray bg-background-white shadow-soft rounded-md border p-2 text-base outline-none',
            modal && 'z-50',
          )}
          style={{ minWidth: buttonRef.current?.offsetWidth ?? '100%' }}
        >
          <TooltipProvider>
            {options.map((item) => (
              <Tooltip delayDuration={300} key={item.label}>
                <TooltipTrigger asChild>
                  <DropdownMenuItem
                    className={clsx(
                      'flex flex-row items-center justify-between gap-2',
                      'hover:bg-interactive-secondary-hover focus-visible:bg-interactive-secondary-hover w-full cursor-pointer rounded-md p-2 outline-none',
                      item.disabled && 'text-copy cursor-not-allowed opacity-50',
                      item.value === value.value &&
                        '!border-divider-light-blue !bg-interactive-secondary-active !text-interactive-primary hover:bg-interactive-secondary-hover',
                    )}
                    onSelect={() => onChange(item)}
                    disabled={item.disabled}
                  >
                    {item.label}
                    <div className="flex flex-row gap-2">
                      <Check
                        size={16}
                        className={clsx(['mr-0 ml-auto', item.value !== value.value && 'hidden'])}
                      />
                      {item.locked && <Lock size={16} />}
                    </div>
                  </DropdownMenuItem>
                </TooltipTrigger>
                {item.tooltip && (
                  <TooltipPortal>
                    <TooltipContent
                      side="top"
                      className="bg-background-dark text-copy-active shadow-dark-hard z-50 flex w-[200px] max-w-xs flex-row items-center gap-2 rounded p-2 text-center text-xs font-normal"
                    >
                      {item.tooltip}
                      <TooltipArrow className="fill-background-dark" />
                    </TooltipContent>
                  </TooltipPortal>
                )}
              </Tooltip>
            ))}
          </TooltipProvider>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  )
}

export default SelectMenu
