/* eslint-disable complexity */
import basePalette from '@plusdocs/styles/palette.json'
import { formatRelative } from 'date-fns'
import Konva from 'konva'
import { useMemo, useRef } from 'react'
import * as React from 'react'
import { Image, Label, Tag, Text } from 'react-konva'
import useImage from 'use-image'

import {
  PublicSnapshotVersionDataFragment,
  PublicSnapshotVersionDataFragmentDoc,
  Snapshot as ISnapshot,
  SnapshotFieldsFragmentDoc,
  SnapshotRefreshRate,
  SnapshotVersionDataFragment,
  SnapshotVersionDataFragmentDoc,
  SnapshotViewerFragment,
  SnapshotViewerFragmentDoc,
  useSnapshotQuery,
} from '@/generated/graphql'
import { apolloCache } from '@/shared/apollo/apolloClient'
import { OwnerAttribution } from '@/shared/components'
import SidebarDivider from '@/shared/components/SidebarDivider'
import type {
  SettingsProps,
  SnapshotObjectContent,
  SnapshotPageObject,
} from '@/shared/types/document'
import { openLink } from '@/util'
import { useAppDispatch, useAppSelector } from '@/web/hooks/__DEPRECATED_reduxHooks'
import { selectEditingState, selectSelectedObjects } from '@/web/store/appState'
import { selectCurrentPage } from '@/web/store/document'
import { refreshSnapshot, selectAllStatuses } from '@/web/store/snapshots'

import {
  IdentitySettings,
  OverlaySettings,
  RefreshButton,
  RefreshSettings,
  SnapshotBooleanProperties,
  TitleAndDescriptionSettings,
} from '../../SnapshotSidebarSettings'
import type { RendererConfig, RenderProps } from './types'

const refreshRateToFriendly = {
  [SnapshotRefreshRate.Daily]: 'every day',
  [SnapshotRefreshRate.EveryFiveMinutes]: 'every 5 minutes',
  [SnapshotRefreshRate.Hourly]: 'every hour',
  [SnapshotRefreshRate.Manually]: 'manually',
  [SnapshotRefreshRate.QuarterHourly]: 'every 15 minutes',
  [SnapshotRefreshRate.Weekly]: 'every week',
  [SnapshotRefreshRate.EverySixHours]: 'every 6 hours',
}

const Snapshot = (props: RenderProps): JSX.Element => {
  const object = props.object as SnapshotPageObject
  const { renderProps, selected, mode, slug } = props
  const selectedObjects = useAppSelector(selectSelectedObjects)
  const editingState = useAppSelector(selectEditingState)
  const imageRef = useRef<Konva.Image>(null)
  const labelRef = useRef<Konva.Label>(null)
  const isShare = mode === 'share'

  // Note: even though the snapshot is passed down, we want to use
  // useSnapshotQuery to subscribe to apollo cache updates for it
  // It'll actually return null data until you click on a specific snapshot
  // which fires a get versions query that then populates it. Will need to
  // revisit why the docuement query doesn't populate it in the first place
  const snapshotResult = useSnapshotQuery({
    fetchPolicy: 'cache-only',
    variables: { id: object.content.id },
  })

  const data = isShare ? null : snapshotResult.data

  // NOTE: it should be impossible for data.snapshot to be undefined here, but
  // we can fall back to the passed down non-subscribed snapshot if it is
  const snapshot = data?.snapshot || props.snapshots[object.content.id]

  const displayVersion =
    // user pinned version
    object.content.versionId ||
    // most recently captured successfully
    snapshot.currentVersion?.id

  // The snapshotversion will always be in the cache, either loaded from the
  // page editor query or via the get versions query for showing the version
  // timeline
  const snapshotVersion = isShare
    ? (apolloCache.readFragment({
        fragment: PublicSnapshotVersionDataFragmentDoc,
        id: 'PublicSnapshotVersion:' + displayVersion,
      }) as PublicSnapshotVersionDataFragment)
    : (apolloCache.readFragment({
        fragment: SnapshotVersionDataFragmentDoc,
        id: 'SnapshotVersion:' + displayVersion,
      }) as SnapshotVersionDataFragment)

  const [image] = useImage(snapshotVersion?.image ?? '', 'anonymous')

  const aspectRatio = image
    ? image?.height / image?.width
    : snapshot.instructions.frame.frameHeight / snapshot.instructions.frame.frameWidth
  const objectHeight = aspectRatio * renderProps.width
  renderProps.height = objectHeight

  const overlayPosition = useMemo(() => {
    return new Konva.Transform()
      .translate(renderProps.x, renderProps.y) // start with the initial x and y position unrotated
      .rotate((renderProps.rotation * Math.PI) / 180) // rotate the points on the given angle (doing deg -> rad)
      .translate(0, objectHeight) // move the y down to the to the bottom of the image by adding the height
      .getTranslation() // get the new x and y
  }, [renderProps.x, renderProps.y, renderProps.rotation, objectHeight])

  const hasOverlay =
    object.content.schedule ||
    object.content.domain ||
    object.content.timestamp ||
    object.content.title

  const overlayText =
    snapshot && snapshotVersion && hasOverlay
      ? [
          object.content.title ? snapshot.name : null,
          object.content.timestamp && snapshotVersion?.createdAt
            ? `Updated ${formatRelative(new Date(snapshotVersion?.createdAt), new Date())}`
            : null,
          object.content.schedule && snapshot.refreshRate
            ? `Updates ${refreshRateToFriendly[snapshot.refreshRate]}`
            : null,
          object.content.domain ? new URL(snapshot.instructions.url).hostname : null,
        ]
          .filter((val) => val !== null)
          .join(' • ')
      : ''

  const onClick = isShare
    ? () => {
        openLink(`${import.meta.env.WEB_APP_HOST}/${slug ?? ''}/snapshot/${object.content.id}`)
      }
    : renderProps.onClick
  const onMouseEnter = isShare
    ? () => {
        document.body.classList.add('cursor-pointer')
      }
    : renderProps.onMouseEnter
  const onMouseLeave = isShare
    ? () => {
        document.body.classList.remove('cursor-pointer')
      }
    : renderProps.onMouseLeave

  return (
    <>
      <Label
        ref={labelRef}
        x={overlayPosition.x}
        y={overlayPosition.y}
        rotation={renderProps.rotation}
        visible={hasOverlay && !(editingState === 'dragging' && selected)}
      >
        <Tag fill="#fff" />
        <Text
          text={overlayText}
          fontFamily="Inter"
          fill={basePalette.black}
          width={renderProps.width}
          padding={5}
          fontSize={16}
        />
      </Label>
      <Image
        perfectDrawEnabled={false}
        ref={imageRef}
        {...renderProps}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        image={image}
        stroke={selected && selectedObjects.length > 1 ? basePalette.primaryBlue : undefined}
        strokeWidth={2}
        strokeScaleEnabled={false}
      />
    </>
  )
}

const SingleSnapshotSettings = (
  props: SettingsProps<SnapshotObjectContent> & { snapshotObject: SnapshotPageObject },
): JSX.Element => {
  const { getObjectContentProperty } = props
  const [selectedSnapshotId] = getObjectContentProperty('id')
  // Note: We need to use cache-first instead of cache-only for snapshots added
  // from the picker. Other snapshots are already in the cache from the document
  // query
  const { data } = useSnapshotQuery({
    fetchPolicy: 'cache-first',
    variables: { id: selectedSnapshotId },
  })

  const snapshot = data?.snapshot

  if (!snapshot) return <></>

  return (
    <>
      <div className="flex flex-col space-y-3">
        <OwnerAttribution
          userName={snapshot.createdBy.name}
          userImageUrl={snapshot.createdBy.image}
          userEmail={snapshot.createdBy.email}
        />
        <TitleAndDescriptionSettings snapshot={snapshot} />
        <RefreshSettings snapshot={snapshot} />
        <IdentitySettings snapshot={snapshot} isOwner={snapshot.viewer?.isOwner} />
      </div>
      <SidebarDivider />
    </>
  )
}

const createCheckboxGetter =
  (getFunction: (property: SnapshotBooleanProperties) => readonly boolean[]) =>
  (property: SnapshotBooleanProperties) => {
    const values = getFunction(property)
    return values.reduce<boolean | 'indeterminate'>((acc, curr) => {
      return acc === curr ? acc : 'indeterminate'
    }, values[0])
  }

const createCheckboxSetter =
  (setFunction: (property: SnapshotBooleanProperties, value: boolean) => void) =>
  (property: SnapshotBooleanProperties) =>
  (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFunction(property, ev.target.checked)
  }

const SnapshotSettings = (props: SettingsProps<SnapshotObjectContent>): JSX.Element => {
  const dispatch = useAppDispatch()
  const currentPage = useAppSelector(selectCurrentPage)
  const selectedSnapshotIds = useAppSelector(selectSelectedObjects)
  const selectedSnapshotObjects = useMemo(
    () =>
      selectedSnapshotIds.map((id) =>
        currentPage.objects.find((o) => o.id === id),
      ) as SnapshotPageObject[],
    [selectedSnapshotIds, currentPage.objects],
  )
  const selectedSnapshots = selectedSnapshotObjects.map((object) => {
    return apolloCache.readFragment({
      fragment: SnapshotFieldsFragmentDoc,
      fragmentName: 'SnapshotFields',
      id: 'Snapshot:' + object.content.id,
    }) as ISnapshot
  })

  const onClickRefreshSnapshots = React.useCallback(() => {
    selectedSnapshotObjects.forEach((object) => {
      dispatch(refreshSnapshot(object.content.id))
    })
  }, [dispatch, selectedSnapshotObjects])

  // check if selected snapshots are editable, by looking in the cache for the snapshots
  // this covers the case where you select a snapshot that is owned by a personal user, but is on a team page
  // since in that case, the cache would not be primed with the snapshots, due to them being loaded by the document
  const editableSnapshots = selectedSnapshotObjects.filter((object) => {
    const data = apolloCache.readFragment<SnapshotViewerFragment>({
      fragment: SnapshotViewerFragmentDoc,
      id: `Snapshot:${object.content.id}`,
    })

    return data?.viewer?.canEdit
  })
  const isSingleSnapshotSelected = selectedSnapshotObjects.length === 1
  const statuses = useAppSelector(selectAllStatuses)

  const selectedSnapshotsRefreshing = !selectedSnapshots.length
    ? statuses.includes('refreshing')
    : selectedSnapshots.some(
        (snapshot) => snapshot?.status === 'REFRESH_REQUESTED' || snapshot?.status === 'PROCESSING',
      )

  const allSelectedSnapshotsLoggedOut = selectedSnapshots.every(
    (snapshot) => snapshot?.identity?.status === 'UNAUTHORIZED',
  )

  const selectedEditableSnapshotIds = selectedSnapshotIds.filter((id) =>
    editableSnapshots.find((snapshot) => snapshot.id === id),
  )

  const refreshLabel =
    selectedEditableSnapshotIds.length > 1
      ? `Refresh selected (${selectedEditableSnapshotIds.length})`
      : 'Refresh Snapshot'

  const getCheckboxValue = createCheckboxGetter(props.getObjectContentProperty)
  const setCheckboxValue = createCheckboxSetter(props.setObjectContentProperty)

  return (
    <>
      <div className="space-y-3">
        {editableSnapshots.length === 0 && (
          <>
            <div className="space-y-1">
              <div className="text-xs font-semibold">{`You can't edit this Snapshot`}</div>
              <div className="text-xs">
                This Snapshot is owned by another user. Properties of this Snapshot can not be
                changed.
              </div>
            </div>
            <SidebarDivider />
          </>
        )}
        {editableSnapshots.length > 0 && (
          <>
            <RefreshButton
              isLoggedOut={allSelectedSnapshotsLoggedOut}
              label={refreshLabel}
              onClickRefreshSnapshot={onClickRefreshSnapshots}
              refreshOrCaptureState={selectedSnapshotsRefreshing ? 'refreshing' : 'none'}
              snapshot={selectedSnapshots.length === 1 ? selectedSnapshots[0] : undefined}
            />
            <SidebarDivider />
          </>
        )}
        {isSingleSnapshotSelected ? (
          <SingleSnapshotSettings {...props} snapshotObject={selectedSnapshotObjects[0]} />
        ) : null}
        {editableSnapshots.length > 0 &&
          editableSnapshots.length !== selectedSnapshotIds.length && (
            <>
              <div className="text-copy-secondary text-xs">
                {"You don't have permission to edit one or more of the selected Snapshots."}
              </div>
              <SidebarDivider />
            </>
          )}
        {editableSnapshots.length > 0 && (
          <OverlaySettings
            getCheckboxValue={getCheckboxValue}
            setCheckboxValue={setCheckboxValue}
          />
        )}
      </div>
      {editableSnapshots.length > 0 && <SidebarDivider />}
    </>
  )
}

const config: RendererConfig = {
  name: 'Snapshot',
  renderObject: Snapshot,
  renderSettings: SnapshotSettings,
  transformOptions: {
    keepRatio: true,
  },
}
export default config
