import { X } from 'react-feather'

import Button, { ButtonProps } from './Button/Button'

const Pill = (props: ButtonProps): JSX.Element => {
  const { children, ...rest } = props

  return (
    <Button {...rest} size="small" variant="secondary">
      {children}
      <X size={14} className="text-copy-secondary ml-1 -mr-1" />
    </Button>
  )
}

Pill.defaultProps = Button.defaultProps

export default Pill
