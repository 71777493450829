interface ExtensionIdProperties {
  // Note: These are all the same thing, but used for different environments to
  // prevent collisions when you have extensions from multiple environments
  // installed
  __PLUSDOCS_EXTENSION_DEVELOPMENT_ID__?: string
  __PLUSDOCS_EXTENSION_STAGING_ID__?: string
  __PLUSDOCS_EXTENSION_PRODUCTION_ID__?: string
}
interface ExtensionRegisterProperties {
  __PLUS_REGISTER_EXTENSION_DEVELOPMENT__?: (id: string) => void
  __PLUS_REGISTER_EXTENSION_STAGING__?: (id: string) => void
  __PLUS_REGISTER_EXTENSION_PRODUCTION__?: (id: string) => void
}
interface ExtensionVersionProperties {
  __PLUS_EXTENSION_VERSION_DEVELOPMENT__?: string
  __PLUS_EXTENSION_VERSION_STAGING__?: string
  __PLUS_EXTENSION_VERSION_PRODUCTION__?: string
}

declare global {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends ExtensionIdProperties {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends ExtensionRegisterProperties {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Window extends ExtensionVersionProperties {}
}

type ExtensionIdVar = keyof ExtensionIdProperties
type ExtensionRegisterVar = keyof ExtensionRegisterProperties
type ExtensionVersionVar = keyof ExtensionVersionProperties

// When the extension loads first it'll inject/expose its id at
// window[EXTENSION_ID_VARIABLE_NAME] for the web app to consume.
// This lets us match/connect a given build of the extension to the matching web app
export const EXTENSION_ID_VARIABLE_NAME = import.meta.env
  .WEB_APP_EXTENSION_ID_VARIABLE_NAME as ExtensionIdVar

// In cases where the web app loads first the web app will create a function at
// window[EXTENSION_ID_REGISTRATION_NAME] that the extension can call
// when it loads to give the web app the extension id.
export const EXTENSION_ID_REGISTRATION_NAME = import.meta.env
  .WEB_APP_EXTENSION_ID_REGISTRATION_NAME as ExtensionRegisterVar

export const EXTENSION_VERSION_VARIABLE_NAME = import.meta.env
  .WEB_APP_EXTENSION_VERSION_VARIABLE_NAME as ExtensionVersionVar

if (typeof EXTENSION_ID_VARIABLE_NAME !== 'string') {
  // eslint-disable-next-line no-console
  console.error('The variable name for the extension id is not defined.')
}

export const extensionRuntimeId =
  (typeof chrome !== 'undefined' && chrome.runtime?.id) || window[EXTENSION_ID_VARIABLE_NAME] || ''
export const isExtensionInstalled = Boolean(extensionRuntimeId)
