import {
  ContentCardFragment,
  ContentType,
  DocumentCardFragment,
  FileCardFragment,
  FolderCardFragment,
  PresentationCardFragment,
  SlideCardFragment,
  SnapshotFieldsFragment,
  TextCardFragment,
  ThemeCardFragment,
} from '../../generated/graphql'

export type ContentV2 = ContentCardFragment &
  (
    | DocumentCardFragment
    | FileCardFragment
    | FolderCardFragment
    | SnapshotFieldsFragment
    | PresentationCardFragment
    | SlideCardFragment
    | TextCardFragment
    | ThemeCardFragment
  )

/**
 * Object map used to convert __typename based content types to enum based content types
 */
export const ContentV2ObjectMap: { [key: string]: ContentType } = {
  Document: ContentType.Document,
  File: ContentType.File,
  Folder: ContentType.Folder,
  Presentation: ContentType.Presentation,
  Slide: ContentType.Slide,
  Snapshot: ContentType.Snapshot,
  Text: ContentType.Text,
  Theme: ContentType.Theme,
}
