import { useReactiveVar } from '@apollo/client'
import { useEffect, useState } from 'react'
import * as React from 'react'
import { ExternalLink } from 'react-feather'

import { SnapshotFieldsFragment } from '@/generated/graphql'
import { extensionClientStateVar } from '@/shared/apollo/apolloLocalState'
import { Input, Label } from '@/shared/components'
import { createReauthHandler, openLink } from '@/util'

export const IdentitySettings = ({
  snapshot,
  isOwner,
  showLogBackInModal = false,
}: {
  snapshot: SnapshotFieldsFragment
  isOwner: boolean
  showLogBackInModal?: boolean
}): JSX.Element => {
  const { extensionRuntimeId } = useReactiveVar(extensionClientStateVar)

  const snapshotUrl = snapshot?.instructions?.url.toString()
  const orgId = snapshot.organization?.id
  const orgSlug = snapshot.organization?.slug

  const focusInput = ({
    currentTarget,
  }: React.MouseEvent<HTMLInputElement> & React.MouseEvent<HTMLTextAreaElement>) => {
    currentTarget.select()
  }

  const openSourceURL = React.useCallback(() => {
    openLink(snapshot.instructions.url.toString())
  }, [snapshot.instructions.url])

  const onLogBackInClick = React.useMemo(
    () =>
      createReauthHandler({
        extensionRuntimeId,
        orgId,
        orgSlug,
        snapshotId: snapshot.id,
        snapshotImageUrl: snapshot.currentVersion?.image ?? '',
        snapshotUrl,
      }),
    [snapshot.id, snapshot.currentVersion?.image, snapshotUrl, extensionRuntimeId, orgId, orgSlug],
  )

  // Don't allow re-auth or connect/disconnect if this is a deleted
  // user's snapshot transferred to this org owner. User should
  // connect/disconnect identities for this domain to associate (from
  // a snapshot that already has an identity).
  const canManageIdentity = isOwner && Boolean(snapshot.identity)

  const isIdentityLoggedOut = snapshot.identity?.status === 'UNAUTHORIZED'

  const [hasModalBeenOpened, setHasModalBeenOpened] = useState(false)
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    if (
      !hasModalBeenOpened &&
      !isOpen &&
      showLogBackInModal &&
      snapshot &&
      isIdentityLoggedOut &&
      canManageIdentity
    ) {
      setOpen(true)
      setHasModalBeenOpened(true)
    }

    if (!isIdentityLoggedOut || !canManageIdentity || !showLogBackInModal || !snapshot) {
      setOpen(false)
      setHasModalBeenOpened(false)
    }
  }, [
    snapshot,
    showLogBackInModal,
    onLogBackInClick,
    canManageIdentity,
    isIdentityLoggedOut,
    isOpen,
    hasModalBeenOpened,
  ])

  if (!snapshot.viewer.canEdit) {
    return <></>
  }

  return (
    <>
      <div className="w-full">
        <Label htmlFor="sidebar-snapshot-source-input" className="text-base font-semibold">
          Source
        </Label>
        <Input
          id="sidebar-snapshot-source-input"
          onClick={focusInput}
          readOnly
          value={snapshot.instructions.url.toString()}
          className="text-interactive-primary text-base"
          tooltip="Open in new tab"
          tooltipPlacement="icon"
          trailingIcon={
            <ExternalLink
              className="text-copy-secondary cursor-pointer"
              aria-hidden="true"
              onClick={openSourceURL}
              size={16}
            />
          }
        />
      </div>
    </>
  )
}
