interface IPlusLogoProps {
  height?: string
  width?: string
  fillColor?: string
  className?: string
}

function PlusLogo(props: IPlusLogoProps): JSX.Element {
  const { height, width, fillColor, className } = props
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="14" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32 16C32 26.1699 26.1699 32 16 32C5.83011 32 0 26.1699 0 16C0 5.83011 5.83011 0 16 0C26.1699 0 32 5.83011 32 16ZM26.6667 18.6667V16L16 16L16 5.33333L13.3333 5.33333V6.66667C13.3333 11 11 13.3333 6.66667 13.3333L5.33333 13.3333L5.33333 16L16 16L16 26.6667H18.6667L18.6667 25.3333C18.6667 21 21 18.6667 25.3333 18.6667H26.6667ZM10.6667 25.6667H13.3333L13.3333 18.6667H6.33333V21.3333H9C10 21.3333 10.6667 22 10.6667 23V25.6667ZM18.6667 6.33333H21.3333V9C21.3333 10 22 10.6667 23 10.6667H25.6667V13.3333L18.6667 13.3333V6.33333Z"
        fill={fillColor}
      />
    </svg>
  )
}

PlusLogo.defaultProps = {
  fillColor: '#100F19',
  height: '32',
  width: '32',
}
export default PlusLogo
