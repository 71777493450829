import * as PopperJS from '@popperjs/core'
import cx from 'clsx'
import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'

type LabelProps = {
  tooltip?: string
  tooltipPlacement?: PopperJS.Placement
} & React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>

const Label = (props: LabelProps): JSX.Element => {
  const { children, className, htmlFor, tooltip, tooltipPlacement = 'top' } = props

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      delayShow: 300,
      placement: tooltipPlacement,
    })

  const LabelComponent = (
    <label
      ref={setTriggerRef}
      htmlFor={htmlFor}
      className={cx(['mb-1', 'inline-block', tooltip && 'cursor-help', className])}
    >
      {children}
      {visible && tooltip && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container text-center max-w-[224px]',
          })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {tooltip}
        </div>
      )}
    </label>
  )

  return LabelComponent
}

export default React.forwardRef<HTMLLabelElement, LabelProps>(Label)
