import clsx from 'clsx'

import { ConnectionStatus } from '@/generated/graphql'

export interface IConnectionStatusPillProps {
  readonly className?: string
  readonly status: ConnectionStatus
}

const connectionStatusColors = {
  [ConnectionStatus.Good]: { backgroundColor: 'bg-[#15B85A33]', textColor: 'text-[#0FA64F]' },
  [ConnectionStatus.Deteriorating]: {
    backgroundColor: 'bg-[#FFC7384D]',
    textColor: 'text-[#B6860B]',
  },
  [ConnectionStatus.Disconnected]: {
    backgroundColor: 'bg-[#FFDEE1]',
    textColor: 'text-copy-alert',
  },
  [ConnectionStatus.Reconnecting]: {
    backgroundColor: 'bg-background-canvas',
    textColor: 'text-copy-secondary',
  },
} as const

const connectionStatusText = {
  [ConnectionStatus.Good]: 'Good',
  [ConnectionStatus.Deteriorating]: 'Deteriorating',
  [ConnectionStatus.Disconnected]: 'Disconnected',
  [ConnectionStatus.Reconnecting]: 'Reconnecting',
} as const

export const getConnectionStatusColors = (connectionStatus: ConnectionStatus) =>
  connectionStatusColors[connectionStatus]

export const getConnectionStatusColorClassName = (connectionStatus: ConnectionStatus) =>
  `${connectionStatusColors[connectionStatus].textColor} ${connectionStatusColors[connectionStatus].backgroundColor}`

const ConnectionStatusPill = ({ className, status }: IConnectionStatusPillProps): JSX.Element => {
  return (
    <span
      className={clsx(
        'rounded-xl py-0.5 px-2 text-xs font-normal',
        getConnectionStatusColorClassName(status),
        className,
      )}
    >
      {connectionStatusText[status]}
    </span>
  )
}

export default ConnectionStatusPill
