import { UserImage } from '@/shared/components'

const OwnerAttribution = ({
  userName,
  userImageUrl,
  userEmail,
}: {
  userName: string
  userImageUrl?: string | null
  userEmail: string
}): JSX.Element => {
  return (
    <div className="flex flex-col space-y-1">
      <span className="text-base font-bold">Owner</span>
      <div className="flex flex-row items-center space-x-1">
        <UserImage
          size="tiny"
          userName={userName}
          userImageUrl={userImageUrl}
          userEmail={userEmail}
          className="mb-0 mt-0"
        />
        <div className="truncate text-base">{userName}</div>
      </div>
    </div>
  )
}

export default OwnerAttribution
