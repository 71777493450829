import * as React from 'react'

import { useViewerProvider, viewerContext } from '../hooks/useViewer'

export const ViewerProvider = ({
  children,
  waitForOrganizationToBeSet,
}: {
  children: React.ReactNode
  waitForOrganizationToBeSet?: boolean
}) => {
  const viewer = useViewerProvider(waitForOrganizationToBeSet)
  return <viewerContext.Provider value={viewer}>{children}</viewerContext.Provider>
}
