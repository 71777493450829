import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AppThunkOptions } from './types'

type InverseAction = () => void

export type HistoryState = {
  undoStack: Array<InverseAction>
  redoStack: Array<InverseAction>
}

const initialHistoryState: HistoryState = {
  redoStack: [],
  undoStack: [],
} as HistoryState

export const executeUndo = createAsyncThunk<void, void, AppThunkOptions>(
  'history/executeUndo',
  (_, { dispatch, getState }) => {
    const rootState = getState()

    const latestUndoAction = Array.from(rootState.history.undoStack).pop()

    if (!latestUndoAction) {
      return
    }

    dispatch(removeLatestUndoAction())

    latestUndoAction()
  },
)
export const executeRedo = createAsyncThunk<void, void, AppThunkOptions>(
  'history/executeRedo',
  (_, { dispatch, getState }) => {
    const rootState = getState()

    const latestRedoAction = Array.from(rootState.history.redoStack).pop()

    if (!latestRedoAction) {
      return
    }

    dispatch(removeLatestRedoAction())

    latestRedoAction()
  },
)

export const addToHistoryStack = ({
  dispatch,
  undoAction,
  redoAction,
  isUndoAction = false,
  isRedoAction = false,
}: {
  dispatch: AppThunkOptions['dispatch']
  undoAction: InverseAction
  redoAction: InverseAction
  isUndoAction?: boolean
  isRedoAction?: boolean
}): void => {
  if (!isUndoAction) {
    dispatch(
      addUndoAction(() => {
        dispatch(addRedoAction(redoAction))
        undoAction()
      }),
    )
  }

  if (!isUndoAction && !isRedoAction) {
    dispatch(resetRedoStack())
  }
}

const HistorySlice = createSlice({
  initialState: initialHistoryState,
  name: 'history',
  reducers: {
    addRedoAction(state, action: PayloadAction<InverseAction>) {
      state.redoStack.push(action.payload)
    },
    addUndoAction(state, action: PayloadAction<InverseAction>) {
      state.undoStack.push(action.payload)
    },
    removeLatestRedoAction(state) {
      state.redoStack.pop()
    },
    removeLatestUndoAction(state) {
      state.undoStack.pop()
    },
    resetRedoStack(state) {
      state.redoStack = []
    },
  },
})

export default HistorySlice.reducer

export const {
  addUndoAction,
  removeLatestUndoAction,
  addRedoAction,
  removeLatestRedoAction,
  resetRedoStack,
} = HistorySlice.actions
