import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  CaptureSnapshotDocument,
  Snapshot,
  SnapshotDocument,
  SnapshotFieldsFragment,
  SnapshotStatus,
} from '@/generated/graphql'
import { apolloClient } from '@/shared/apollo/apolloClient'
import type { ContentStatus } from '@/shared/types'

import { AppThunkOptions, RootState } from './types'

type SnapshotsByPublicId = {
  [id: string]: SnapshotFieldsFragment | Snapshot
}

export type SnapshotState = {
  snapshots: SnapshotsByPublicId
  statuses: {
    [id: string]: ContentStatus
  }
}

const initialSnapshotState: SnapshotState = {
  snapshots: {},
  statuses: {},
} as SnapshotState

export const refreshSnapshot = createAsyncThunk<
  SnapshotFieldsFragment,
  SnapshotFieldsFragment['id'],
  AppThunkOptions
>('appState/refreshSnapshot', async (snapshotPublicId: Snapshot['id']) => {
  await apolloClient.mutate({
    mutation: CaptureSnapshotDocument,
    variables: {
      snapshotId: snapshotPublicId,
    },
  })

  return new Promise((resolve, reject) => {
    const intervalId = setInterval(async () => {
      const resp = await apolloClient.query<{ snapshot: SnapshotFieldsFragment }>({
        fetchPolicy: 'network-only',
        query: SnapshotDocument,
        variables: {
          id: snapshotPublicId,
        },
      })

      switch (resp.data.snapshot.status) {
        case SnapshotStatus.Captured:
          resolve(resp.data.snapshot)
          clearInterval(intervalId)
          break
        case SnapshotStatus.Error:
          reject(resp)
          clearInterval(intervalId)
          break
      }
    }, 1000)
  })
})

const SnapshotSlice = createSlice({
  extraReducers(builder) {
    /**
     * Manage async states for snapshot refreshing
     */
    builder.addCase(refreshSnapshot.pending, (state, action) => {
      state.statuses[action.meta.arg] = 'refreshing'
    })

    builder.addCase(refreshSnapshot.fulfilled, (state, action) => {
      const snapshot = action.payload
      state.snapshots[snapshot.id] = snapshot
    })

    builder.addMatcher(
      (action) => {
        return [refreshSnapshot]
          .flatMap((thunk) => [thunk.fulfilled.type, thunk.rejected.type])
          .includes(action.type)
      },
      (state, action) => {
        if (typeof action.meta.arg === 'string') {
          // if id is the arg
          state.statuses[action.meta.arg] = 'idle'
        } else if (typeof action.meta.arg.id === 'string') {
          // if snapshot is the arg
          state.statuses[action.meta.arg.id] = 'idle'
        }
      },
    )
  },
  initialState: initialSnapshotState,
  name: 'snapshots',
  reducers: {
    initializeSnapshots(state, action: PayloadAction<SnapshotFieldsFragment[]>) {
      state.snapshots = action.payload.reduce((snapshotsByPublicId, snapshot) => {
        snapshotsByPublicId[snapshot.id] = snapshot
        return snapshotsByPublicId
      }, state.snapshots)
    },
    updateSnapshot(state, action: PayloadAction<SnapshotFieldsFragment>) {
      state.snapshots[action.payload.id] = action.payload
    },
  },
})

export default SnapshotSlice.reducer
export const selectSnapshots = (state: RootState): SnapshotsByPublicId =>
  state.snapshotsState.snapshots

export const selectSnapshotByPublicId =
  (id: Snapshot['id']) =>
  (state: RootState): SnapshotFieldsFragment | Snapshot =>
    selectSnapshots(state)[id]

export const selectSnapshotsByStatus = (state: RootState): SnapshotState['statuses'] =>
  state.snapshotsState.statuses
export const selectAllStatuses = (state: RootState): ContentStatus[] =>
  Object.values(state.snapshotsState.statuses)

export const selectSnapshotStatus =
  (id: Snapshot['id']) =>
  (state: RootState): ContentStatus =>
    state.snapshotsState.statuses[id] || 'idle'

export const matchSnapshotStatus =
  (id: Snapshot['id'], status: ContentStatus) =>
  (state: RootState): boolean =>
    selectSnapshotStatus(id)(state) === status

export const { initializeSnapshots, updateSnapshot } = SnapshotSlice.actions
