import * as React from 'react'

import { Checkbox, Label } from '@/shared/components'

export type SnapshotBooleanProperties = 'title' | 'timestamp' | 'schedule' | 'domain'

export type GetCheckboxValue = (property: SnapshotBooleanProperties) => boolean | 'indeterminate'
export type SetCheckboxValue = (
  property: SnapshotBooleanProperties,
) => (event: React.ChangeEvent<HTMLInputElement>) => void

export const OverlaySettings = ({
  getCheckboxValue,
  setCheckboxValue,
  disabled = false,
  label = 'Snapshot overlays',
}: {
  getCheckboxValue: GetCheckboxValue
  setCheckboxValue: SetCheckboxValue
  disabled?: boolean
  label?: string
}): JSX.Element => {
  const titleCheckboxValue = getCheckboxValue('title')
  const timestampCheckboxValue = getCheckboxValue('timestamp')
  const scheduleCheckboxValue = getCheckboxValue('schedule')
  const domainCheckboxValue = getCheckboxValue('domain')

  return (
    <div>
      <Label className="mb-3 text-base font-semibold">{label}</Label>
      <div data-automation-id="snapshot-overlay-settings" className="space-y-2 pb-4">
        <Checkbox
          className="cursor-pointer"
          checked={Boolean(titleCheckboxValue)}
          onChange={setCheckboxValue('title')}
          indeterminate={titleCheckboxValue === 'indeterminate'}
          disabled={disabled}
        >
          Show title
        </Checkbox>
        <Checkbox
          className="cursor-pointer"
          checked={Boolean(timestampCheckboxValue)}
          onChange={setCheckboxValue('timestamp')}
          indeterminate={timestampCheckboxValue === 'indeterminate'}
          disabled={disabled}
        >
          Show update timestamp
        </Checkbox>
        <Checkbox
          className="cursor-pointer"
          checked={Boolean(scheduleCheckboxValue)}
          onChange={setCheckboxValue('schedule')}
          indeterminate={scheduleCheckboxValue === 'indeterminate'}
          disabled={disabled}
        >
          Show update schedule
        </Checkbox>
        <Checkbox
          className="cursor-pointer"
          checked={Boolean(domainCheckboxValue)}
          onChange={setCheckboxValue('domain')}
          indeterminate={domainCheckboxValue === 'indeterminate'}
          disabled={disabled}
        >
          Show source domain
        </Checkbox>
      </div>
    </div>
  )
}
