import { useCallback } from 'react'

import {
  Snapshot,
  SnapshotFieldsFragment,
  UpdateSnapshotInput,
  useUpdateSnapshotMutation,
} from '@/generated/graphql'

type SnapshotWithTypename = SnapshotFieldsFragment & { __typename: 'Snapshot' }

const useUpdateSnapshot = (
  snapshot: SnapshotFieldsFragment,
): ((updates: UpdateSnapshotInput) => void) => {
  const [updateSnapshotMutation] = useUpdateSnapshotMutation()

  return useCallback(
    (updates: UpdateSnapshotInput) => {
      void updateSnapshotMutation({
        optimisticResponse: {
          updateSnapshot: {
            message: 'Snapshot updated.',
            snapshot: {
              ...(snapshot as SnapshotWithTypename),
              ...(updates as Partial<Snapshot>),
            },
            success: true,
          },
        },
        variables: { id: snapshot.id, input: updates },
      })
    },
    [updateSnapshotMutation, snapshot],
  )
}

export default useUpdateSnapshot
