import clsx from 'clsx'
import { Camera } from 'react-feather'

const hashString = (str: string): number => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = Math.imul(31, hash) + str.charCodeAt(i)
  }
  return hash | 0
}

const getBackgroundColor = (userName?: string): string => {
  const n = hashString(userName || '') % 3
  switch (n) {
    case 0:
      return `bg-base-highlightA`
    case 1:
      return `bg-base-highlightC`
    case 2:
      return `bg-base-highlightB`
    default:
      return `bg-base-highlightA`
  }
}

const sizeClasses = {
  large: 'w-[48px] h-[48px] text-3xl my-1',
  small: 'w-[32px] h-[32px] text-l my-1',
  tiny: 'w-[16px] h-[16px] text-xxs',
}

const UserImage = ({
  size,
  userName,
  userImageUrl,
  userEmail,
  editable,
  className,
}: {
  className?: string
  size: 'tiny' | 'small' | 'large'
  userName?: string
  userImageUrl?: string | null
  userEmail?: string
  editable?: boolean
}): JSX.Element => {
  return (
    <div
      className={clsx(
        'text-copy-active group relative flex select-none items-center justify-center justify-items-center rounded-full border border-white bg-contain transition-colors',
        userImageUrl ? 'bg-background-canvas' : getBackgroundColor(userEmail),
        sizeClasses[size],
        className,
      )}
      style={userImageUrl ? { backgroundImage: `url(${userImageUrl})` } : {}}
    >
      {!userImageUrl ? (
        <span className="font-bold">{userName ? userName[0].toLocaleUpperCase() : '?'}</span>
      ) : null}
      {editable ? (
        <div className="bg-background-buttonOverlay group-active:bg-background-activeButtonOverlay absolute top-0 bottom-0 left-0 right-0 hidden cursor-pointer items-center justify-center rounded-full group-hover:flex">
          <Camera color="white" width={16} />
        </div>
      ) : null}
    </div>
  )
}

export default UserImage
