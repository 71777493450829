import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { extensionRuntimeId } from '../../util/extensionId'
import { syncCognitoTokens, syncLogout } from '../../util/helpers'
import { apolloClient } from '../apollo/apolloClient'
import {
  cognitoConfirmRegistration,
  cognitoLogin,
  cognitoLoginWithOAuth,
  cognitoLogout,
  cognitoRefreshToken,
  cognitoRegister,
  cognitoResendConfirmationEmail,
  confirmForgotPassword,
  getCognitoSession,
  getUser,
  sendForgotPasswordMail,
} from './cognitoHelper'

interface IAuthAccount {
  readonly id: string
  readonly email: string
  readonly name: string
  readonly verified: boolean
  readonly provider: 'cognito' | 'google'
}

interface IdTokenPayload {
  readonly sub: string
  readonly email: string
  readonly email_verified: boolean
  readonly name: string
  readonly identities?: ReadonlyArray<{
    readonly providerType: 'cognito' | 'google'
  }>
}

export function useAuthProvider() {
  const [account, setAccount] = useState<IAuthAccount | null>(null)
  const [loading, setLoading] = useState(true)

  const setAccountData = useCallback(async (cognitoUser: CognitoUser | null) => {
    if (!cognitoUser) {
      setAccount(null)
      return setLoading(false)
    }

    const data = await new Promise<IdTokenPayload>((resolve) =>
      cognitoUser.getSession((_: unknown, session: CognitoUserSession) => {
        resolve(session.getIdToken().decodePayload() as IdTokenPayload)
      }),
    )

    setAccount({
      email: data.email,
      id: data.sub,
      name: data.name,
      provider: data.identities ? data.identities[0].providerType : 'cognito',
      verified: data.email_verified,
    })

    return setLoading(false)
  }, [])

  const register = useCallback(async ({ email, password }: { email: string; password: string }) => {
    return cognitoRegister({ email, password })
  }, [])

  const startResetPassword = useCallback(async ({ email }: { email: string }) => {
    return sendForgotPasswordMail(email)
  }, [])

  const resetPassword = useCallback(
    async ({
      email,
      newPassword,
      verificationCode,
    }: {
      email: string
      newPassword: string
      verificationCode: string
    }) => {
      return confirmForgotPassword(email, newPassword, verificationCode)
    },
    [],
  )

  const confirmRegistration = useCallback(
    async ({ email, verificationCode }: { email: string; verificationCode: string }) => {
      return cognitoConfirmRegistration(email, verificationCode)
    },
    [],
  )

  const resendConfirmationEmail = useCallback(async ({ email }: { email: string }) => {
    return cognitoResendConfirmationEmail(email)
  }, [])

  const login = useCallback(
    async ({ email, password }: { email: string; password: string }) => {
      return cognitoLogin({
        email,
        onSuccess: async (cognitoUser) => {
          await setAccountData(cognitoUser)

          syncCognitoTokens(extensionRuntimeId)
        },
        password,
      })
    },
    [setAccountData],
  )

  const startPPTOAuthLogin = useCallback((provider: 'Google' | 'Microsoft' = 'Google') => {
    const url = new URL(`https://${import.meta.env.WEB_COGNITO_OAUTH_HOSTNAME}/oauth2/authorize`)

    url.searchParams.append('response_type', 'code')
    url.searchParams.append('client_id', import.meta.env.WEB_COGNITO_CLIENT_ID)
    url.searchParams.append(
      'redirect_uri',
      `${import.meta.env.WEB_APP_HOST}/ppt-add-in/login-dialog/dialog.html`,
    )
    url.searchParams.append('scope', 'openid profile email')
    url.searchParams.append('identity_provider', provider)

    window.location.href = url.toString()
  }, [])

  const startLoginWithOAuth = useCallback(
    (useInstallRoute = false, provider: 'Google' | 'Microsoft' = 'Google') => {
      const url = new URL(`https://${import.meta.env.WEB_COGNITO_OAUTH_HOSTNAME}/oauth2/authorize`)

      url.searchParams.append('response_type', 'code')
      url.searchParams.append('client_id', import.meta.env.WEB_COGNITO_CLIENT_ID)
      url.searchParams.append(
        'redirect_uri',
        useInstallRoute
          ? `${import.meta.env.WEB_APP_HOST}/install`
          : `${import.meta.env.WEB_APP_HOST}/login`,
      )
      url.searchParams.append('scope', 'openid profile email')
      url.searchParams.append('identity_provider', provider)

      window.location.href = url.toString()
    },
    [],
  )

  const finishLoginWithOAuth = useCallback(
    async (code: string) => {
      const cognitoUser = await cognitoLoginWithOAuth(code)

      await setAccountData(cognitoUser)
      syncCognitoTokens(extensionRuntimeId)
    },
    [setAccountData],
  )

  const logout = useCallback(async (reload = true) => {
    await cognitoLogout()
    setAccount(null)

    if (apolloClient) {
      await apolloClient.clearStore()
    }

    localStorage.removeItem('organizationState')

    syncLogout(extensionRuntimeId)

    if (reload) {
      location.reload()
    }
  }, [])

  const isLoggedIn = useCallback(() => {
    return !!account
  }, [account])

  const refreshToken = useCallback(async () => {
    const cognitoUser = await getUser()
    if (!cognitoUser) return

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
        if (err) {
          return reject(err)
        }

        cognitoRefreshToken(cognitoUser, session).then((token) => {
          syncCognitoTokens(extensionRuntimeId)
          resolve(token)
        })
      })
    })
  }, [])

  const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
    const cognitoUser = await getUser()
    if (!cognitoUser) return

    return new Promise((resolve, reject) => {
      cognitoUser.changePassword(oldPassword, newPassword, (err) => {
        if (err) {
          return reject(err)
        }
        resolve(true)
      })
    })
  }, [])

  const refreshSessionData = () => {
    getCognitoSession({
      onLoading: () => {
        setLoading(true)
      },
      onNoSession: () => {
        setLoading(false)
        setAccount(null)
      },
      onSuccess: setAccountData,
    })
  }

  useEffect(() => {
    // Convention is that useEffect gets a syncronous function so we nest an
    // async one in here
    getCognitoSession({
      onLoading: () => {
        setLoading(true)
      },
      onNoSession: () => {
        setLoading(false)
        setAccount(null)
      },
      onSuccess: setAccountData,
    })
  }, [setAccountData])

  return {
    account,
    changePassword,
    confirmRegistration,
    finishLoginWithOAuth,
    isLoggedIn,
    loading,
    login,
    logout,
    refreshSessionData,
    refreshToken,
    register,
    resendConfirmationEmail,
    resetPassword,
    startLoginWithOAuth,
    startPPTOAuthLogin,
    startResetPassword,
  }
}

export const authContext = createContext({} as ReturnType<typeof useAuthProvider>)

export const useAuth = (): ReturnType<typeof useAuthProvider> => useContext(authContext)
