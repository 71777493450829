/*
	"buffer" npm package assumes "global" to be defined
	but Vite doesn't add this behavior because it's somewhat archaic
	so, we define it here ourselves
	ref: https://github.com/feross/buffer/issues/235
	ref: https://github.com/vitejs/vite/issues/2618
*/

if (typeof globalThis !== 'undefined' && typeof globalThis.global === 'undefined') {
  globalThis.global = globalThis
}

if (typeof window !== 'undefined' && typeof window.global === 'undefined') {
  window.global = window
}
