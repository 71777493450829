import { ApolloQueryResult, useReactiveVar } from '@apollo/client'
import { createContext, useContext, useEffect, useMemo } from 'react'

import {
  PaymentStatus,
  PlanTier,
  useViewerNavDataQuery,
  ViewerNavDataQuery,
} from '@/generated/graphql'
import { activeOrganizationState } from '@/shared/apollo/apolloLocalState'

export interface IUseViewer {
  readonly activeOrganization: undefined | ViewerNavDataQuery['viewer']['organizations'][0]
  readonly isPaidUser: boolean
  readonly isPaymentPastDue: boolean
  readonly isProUser: boolean
  readonly loading: boolean
  readonly organizationCount: number
  readonly viewer?: ViewerNavDataQuery['viewer']
  readonly reloadViewer: () => Promise<ApolloQueryResult<ViewerNavDataQuery> | void>
}

export const useViewerProvider = (waitForOrganizationToBeSet?: boolean): IUseViewer => {
  const activeOrgState = useReactiveVar(activeOrganizationState)
  const { data, loading, refetch } = useViewerNavDataQuery({
    errorPolicy: 'all',
    skip: waitForOrganizationToBeSet ? !activeOrgState : false,
  })
  useEffect(() => {
    if (activeOrgState?.id && activeOrgState?.id !== data?.viewer.currentOrganization?.id) {
      void refetch()
    }
  }, [refetch, activeOrgState?.id, data?.viewer.currentOrganization?.id])
  const activeOrganization = useMemo(
    () => data?.viewer.organizations.find((x) => x?.id === activeOrgState?.id),
    [activeOrgState?.id, data?.viewer.organizations],
  )

  return {
    activeOrganization,
    isPaidUser: (data?.viewer?.settings?.quotas?.presentations || 3) > 3,
    isPaymentPastDue:
      data?.viewer.currentOrganization?.currentPlan?.status === PaymentStatus.Failed,
    isProUser:
      data?.viewer?.currentOrganization?.currentPlan?.tier === PlanTier.Pro ||
      data?.viewer?.currentOrganization?.currentPlan?.tier === PlanTier.Team,
    loading,
    organizationCount: data?.viewer.organizations.length ?? 0,
    reloadViewer: refetch,
    viewer: data?.viewer,
  }
}

export const viewerContext = createContext<IUseViewer>({
  activeOrganization: undefined,
  isPaidUser: false,
  isPaymentPastDue: false,
  isProUser: false,
  loading: true,
  organizationCount: 0,
  reloadViewer: () => Promise.resolve(),
  viewer: undefined,
})
export const useViewer = () => useContext<IUseViewer>(viewerContext)
export default useViewer
