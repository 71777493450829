import cuid from 'cuid'

let localClientId: string | null = null
export const getClientId = async () => {
  if (localClientId) return localClientId

  if (typeof chrome !== 'undefined' && chrome.storage) {
    const { clientId } = await chrome.storage.local.get(['clientId'])
    if (typeof clientId === 'string') {
      localClientId = clientId
    } else {
      localClientId = cuid()
      await chrome.storage.local.set({ clientId: localClientId })
    }
    return localClientId
  }

  try {
    localClientId = localStorage.getItem('plus-client-id')
    if (!localClientId) {
      localClientId = cuid()
      localStorage.setItem('plus-client-id', localClientId)
    }
  } catch {
    localClientId = cuid()
  }

  return localClientId
}
