/**
 *
 * Primarily needed by Cognito to persist sessions this decides whether to use
 * localStorage (browser) or chrome.storage (extension) and wraps chrome.storage
 * with a syncronous api so Cognito can use it.
 *
 * Cognito comes with an inmemory localstorage fallback,
 * https://github.com/aws-amplify/amplify-js/blob/main/packages/amazon-cognito-identity-js/src/StorageHelper.js
 * They also have an async wrapper for ReactNative
 * https://github.com/aws-amplify/amplify-js/blob/main/packages/amazon-cognito-identity-js/src/StorageHelper-rn.js
 *
 * While chrome.storage (and in memory storage) both support objects,
 * localstorage and postMessage only support strings. Cognito assumes it needs
 * to work with localstorage by default and so it only tries to store strings
 * guarding by JSON.stringifying args it passes in and then parses as needed
 * after getting. We also don't care to support batch operations here.
 *
 * Since we're only using chrome.storage for persisting we only read from it at
 * startup and then just blindly do async writes.
 */

import { ICognitoStorage } from 'amazon-cognito-identity-js'

import { noop } from '../../util/fp'

interface DataMemory {
  [key: string]: string
}

let dataMemory: DataMemory = {}
let inited = false

class MemoryStorage {
  /**
   * This is used to set a specific item in storage
   * @param {string} key - the key for the item
   * @param {object} value - the value
   * @returns {string} value that was set
   */
  static setItem(key: string, value: string): string {
    dataMemory[key] = value
    return dataMemory[key]
  }

  /**
   * This is used to get a specific key from storage
   * @param {string} key - the key for the item
   * This is used to clear the storage
   * @returns {string} the data item
   */
  static getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : null
  }

  /**
   * This is used to remove an item from storage
   * @param {string} key - the key being set
   * @returns {boolean} return true
   */
  static removeItem(key: string) {
    return delete dataMemory[key]
  }

  /**
   * This is used to clear the storage
   * @returns {string} nothing
   */
  static clear() {
    dataMemory = {}
    return dataMemory
  }

  /**
   * Noop cache function, to appease types
   */
  static cache = noop
}

export class SyncChromeStorageWrapper {
  // NOTE: The Chrome.storage calls are async, but we don't care too much about
  // knowing when they finished. Trusting it to manage its own queue and
  // guarantee order of operations

  static async setItem(key: string, value: string): Promise<string> {
    await chrome.storage.local.set({ [key]: value })
    dataMemory[key] = value
    return value
  }

  static getItem(key: string): string | null {
    const value = Object.prototype.hasOwnProperty.call(dataMemory, key) ? dataMemory[key] : null

    return value
  }

  static async removeItem(key: string): Promise<boolean> {
    await chrome.storage.local.remove(key)
    return delete dataMemory[key]
  }

  static async clear(): Promise<DataMemory> {
    const data = await chrome.storage.local.get(null)
    const cognitoKeys = Object.keys(data).filter((key) =>
      key.startsWith('CognitoIdentityServiceProvider'),
    )
    await chrome.storage.local.remove(cognitoKeys)
    dataMemory = {}
    return dataMemory
  }

  static cache(): Promise<boolean> {
    return new Promise((resolve) => {
      chrome.storage.local.get(null, (items) => {
        dataMemory = {
          ...items,
        }
        resolve(true)
      })
    })
  }
}
export default class StorageHelper {
  readonly storage
  isChromeExtension = false

  constructor() {
    try {
      if (typeof chrome !== 'undefined' && chrome.storage) {
        this.storage = SyncChromeStorageWrapper
        this.isChromeExtension = true
      } else if (typeof window !== 'undefined' && window.localStorage) {
        window.localStorage.setItem('plus:ls', '1')
        window.localStorage.removeItem('plus:ls')
        this.storage = window.localStorage
      } else {
        this.storage = MemoryStorage
      }
    } catch (err) {
      this.storage = MemoryStorage
    }
  }

  async init(): Promise<this> {
    if (!inited) {
      inited = true
      await this.refreshCache()
    }

    return this
  }

  getStorage(): ICognitoStorage {
    return this.storage
  }

  async refreshCache(): Promise<this> {
    if (this.isChromeExtension) {
      await this.storage?.cache()
    }

    return this
  }

  // Used when receiving cognito state over postMessage. eg: web app sending it
  // to extension
  async refreshCognitoState(newState: { readonly [key: string]: string }): Promise<void> {
    await this.storage.clear()

    for (const key in newState) {
      await this.storage.setItem(key, newState[key])
    }
  }
}
