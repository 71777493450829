import { findOrThrow } from '@plusdocs/utils/common/arrays'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  nanoid,
  PayloadAction,
  unwrapResult,
} from '@reduxjs/toolkit'
import arrayMove from 'array-move'

import type { ContentPrivacy, Document, PageStructureV1 } from '@/generated/graphql'
import type { ObjectPosition, ObjectTypeNames, PageObject } from '@/shared/types/document'
import documentSync from '@/util/documentSync'
import { scaledToRelativeObject } from '@/util/transformPositions'

import { toggleSelectedObjects, updateSelectedObjects } from './appState'
import { addToHistoryStack } from './history'
import { AppThunkOptions, RootState } from './types'

const applyDataMigrations = (documentFromServer: Document) => {
  const migrations = [
    // Convert page object property "source" to "domain" to match Snapshot model
    (data: Document) => {
      return {
        ...data,
        pages: data.pages.map((page) => {
          return {
            ...page,
            objects: page.objects.map((object) => {
              if (object.type !== 'Snapshot') {
                return object
              }

              const { source, domain, ...content } = object.content
              const needsMigration = source === true || typeof domain === 'undefined'

              if (!needsMigration) {
                return object
              }

              return {
                ...object,
                content: {
                  ...content,
                  domain: source || domain || false,
                },
              }
            }),
          }
        }),
      }
    },
  ]

  const migratedDocument = migrations.reduce((document, patch) => {
    return patch(document)
  }, documentFromServer)

  return migratedDocument
}

// casting "as Document" so that we can start with an incomplete object
// while still maintaining that all the properties are required
// this allows us to avoid null checks throughout the app, which would happen
// if we used Partial<Document> instead
const initialDocumentState: Document = {} as Document

const DocumentSlice = createSlice({
  initialState: initialDocumentState,
  name: 'document',
  reducers: {
    addObject(
      state,
      {
        payload: { pageId, object },
      }: PayloadAction<{
        pageId: string
        object: PageObject
      }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      state.pages[pageIndex].objects.push(object)
    },
    initializeDocument(state, action: PayloadAction<Document>) {
      return { ...applyDataMigrations(action.payload) }
    },
    removeMultiObject(
      state,
      { payload: { pageId, objectIds } }: PayloadAction<{ pageId: string; objectIds: string[] }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      for (const id of objectIds) {
        const objectIndex = state.pages[pageIndex].objects.findIndex((obj) => obj.id === id)
        state.pages[pageIndex].objects.splice(objectIndex, 1)
      }
    },
    resetDocument() {
      return { ...initialDocumentState }
    },
    updateDescription(state, action: PayloadAction<string>) {
      state.description = action.payload
    },
    updateObjectContent(
      state,
      {
        payload: { pageId, objectId, content },
      }: PayloadAction<{
        pageId: string
        objectId: string
        content: Record<string, unknown>
      }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      const objectIndex = state.pages[pageIndex].objects.findIndex((obj) => obj.id === objectId)
      state.pages[pageIndex].objects[objectIndex].content = {
        ...state.pages[pageIndex].objects[objectIndex].content,
        ...content,
      }
    },
    updateObjectPosition(
      state,
      {
        payload: { pageId, objectId, position },
      }: PayloadAction<{
        pageId: string
        objectId: string
        position: Partial<ObjectPosition>
      }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      const objectIndex = state.pages[pageIndex].objects.findIndex((obj) => obj.id === objectId)
      state.pages[pageIndex].objects[objectIndex].position = {
        ...state.pages[pageIndex].objects[objectIndex].position,
        ...position,
      }
    },
    updateObjectSorting(
      state,
      {
        payload: { pageId, objectsInOrder },
      }: PayloadAction<{
        pageId: string
        objectsInOrder: PageObject[]
      }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      const date = new Date().toJSON()
      state.pages[pageIndex].updatedAt = date
      objectsInOrder.forEach((obj, index) => {
        const objectIndex = state.pages[pageIndex].objects.findIndex((_obj) => _obj.id === obj.id)
        state.pages[pageIndex].objects[objectIndex].position.z = index
        state.pages[pageIndex].objects[objectIndex].updatedAt = date
      })
    },
    updateObjectTemp(
      state,
      {
        payload: { pageId, objectId, temp },
      }: PayloadAction<{
        pageId: string
        objectId: string
        temp: Record<string, unknown>
      }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      const objectIndex = state.pages[pageIndex].objects.findIndex((obj) => obj.id === objectId)
      state.pages[pageIndex].objects[objectIndex].temp = {
        ...state.pages[pageIndex].objects[objectIndex].temp,
        ...temp,
      }
    },
    updateObjectTimestamp(
      state,
      { payload: { pageId, objectId } }: PayloadAction<{ pageId: string; objectId: string }>,
    ) {
      const pageIndex = state.pages.findIndex((p) => p.id === pageId)
      const objectIndex = state.pages[pageIndex].objects.findIndex((obj) => obj.id === objectId)
      const date = new Date().toJSON()
      state.pages[pageIndex].updatedAt = date
      state.pages[pageIndex].objects[objectIndex].updatedAt = date
    },
    updateProperties(
      state,
      action: PayloadAction<{
        accessCode?: string | null
        privacy: ContentPrivacy
      }>,
    ) {
      state.properties = action.payload
    },
    updateTitle(state, action: PayloadAction<string>) {
      state.name = action.payload
    },
  },
})

export const removeMultiObjects = createAsyncThunk<
  void,
  {
    pageId: string
    objectIds: string[]
    isUndoAction?: boolean
    isRedoAction?: boolean
  },
  AppThunkOptions
>('document/removeMultiObjectThunk', async (action, { dispatch, getState }) => {
  const startingState = getState()
  addToHistoryStack({
    dispatch,
    isRedoAction: action.isRedoAction,
    isUndoAction: action.isUndoAction,
    redoAction: () => {
      dispatch(
        removeMultiObjects({
          isRedoAction: true,
          objectIds: action.objectIds,
          pageId: action.pageId,
        }),
      )
    },
    undoAction: () => {
      for (const id of action.objectIds) {
        const pageIndex = startingState.document.pages.findIndex((p) => p.id === action.pageId)
        const object = startingState.document.pages[pageIndex].objects.find((o) => o.id === id)
        if (!object) return
        dispatch(
          createObject({
            content: object.content,
            id: object.id,
            pageId: action.pageId,
            position: object.position,
            selectAfterCreation: false,
            shouldSkipScaling: true,
            type: object.type,
          }),
        )
      }
      dispatch(updateSelectedObjects([...action.objectIds]))
    },
  })

  dispatch(toggleSelectedObjects({ force: 'off', objectIds: action.objectIds }))
  dispatch(DocumentSlice.actions.removeMultiObject(action))
  const newState = getState()
  documentSync.push(newState.document)
})

export const updateObjectContent = createAsyncThunk<
  void,
  {
    pageId: string
    objectId: string
    content: Record<string, unknown>
    isUndoAction?: boolean
    isRedoAction?: boolean
  },
  AppThunkOptions
>('document/updateObjectContentThunk', async (action, { dispatch, getState }) => {
  const { pageId, objectId } = action
  const state = getState()
  const pageIndex = state.document.pages.findIndex((p) => p.id === pageId)
  const { content: currentContent } = findOrThrow(
    state.document.pages[pageIndex].objects as PageObject[],
    (o) => o.id === objectId,
  )

  addToHistoryStack({
    dispatch,
    isRedoAction: action.isRedoAction,
    isUndoAction: action.isUndoAction,
    redoAction: () => {
      dispatch(
        updateObjectContent({
          content: action.content,
          isRedoAction: true,
          objectId,
          pageId,
        }),
      )
    },
    undoAction: () => {
      dispatch(
        updateObjectContent({
          content: currentContent,
          isUndoAction: true,
          objectId,
          pageId,
        }),
      )
    },
  })
  dispatch(DocumentSlice.actions.updateObjectContent(action))
  dispatch(DocumentSlice.actions.updateObjectTimestamp(action))
  const newState = getState()
  documentSync.push(newState.document)
})

export const updateObjectPosition = createAsyncThunk<
  void,
  {
    pageId: string
    objectId: string
    position: Partial<ObjectPosition>
    isUndoAction?: boolean
    isRedoAction?: boolean
  },
  AppThunkOptions
>('document/updateObjectPositionThunk', async (action, { dispatch, getState }) => {
  const { pageId, objectId } = action
  const state = getState()
  const pageIndex = state.document.pages.findIndex((p) => p.id === pageId)
  const { position: currentPosition } = findOrThrow(
    state.document.pages[pageIndex].objects as PageObject[],
    (o) => o.id === objectId,
  )
  addToHistoryStack({
    dispatch,
    isRedoAction: action.isRedoAction,
    isUndoAction: action.isUndoAction,
    redoAction: () => {
      dispatch(
        updateObjectPosition({
          isRedoAction: true,
          objectId,
          pageId,
          position: action.position,
        }),
      )
    },
    undoAction: () => {
      dispatch(
        updateObjectPosition({
          isUndoAction: true,
          objectId,
          pageId,
          position: currentPosition,
        }),
      )
    },
  })

  dispatch(DocumentSlice.actions.updateObjectPosition(action))
  dispatch(DocumentSlice.actions.updateObjectTimestamp(action))
  const newState = getState()
  documentSync.push(newState.document)
})

export const updateObjectOrder = createAsyncThunk<
  void,
  {
    pageId: string
    objectIds?: string[]
    sortedObjects?: PageObject[]
    command: 'override' | 'back' | 'backward' | 'forward' | 'front'
    isUndoAction?: boolean
    isRedoAction?: boolean
  },
  AppThunkOptions
>('document/updateObjectOrder', async (action, { dispatch, getState }) => {
  const state = getState()
  const pageIndex = state.document.pages.findIndex((p) => p.id === action.pageId)
  const objects = state.document.pages[pageIndex].objects as PageObject[]
  const sortedObjectsArray = [...objects].sort((a, b) => a.position.z - b.position.z)

  let shouldCommit = false
  // sort incoming object ids by their starting position lowest to highest
  const sortedObjectIds = (action.objectIds ? [...action.objectIds] : []).sort((a, b) => {
    const firstObject = findOrThrow(objects, (o) => o.id === a)
    const secondObject = findOrThrow(objects, (o) => o.id === b)
    return firstObject.position.z - secondObject.position.z
  })
  let newSortedObjectsArray: PageObject[] = []
  switch (action.command) {
    case 'override':
      if (action.sortedObjects) {
        newSortedObjectsArray = action.sortedObjects
        shouldCommit = true
      }
      break
    case 'back':
      sortedObjectIds.forEach((id, localIndex) => {
        const objectIndex = sortedObjectsArray.findIndex((obj) => obj.id === id)
        if (objectIndex !== 0 + localIndex) {
          newSortedObjectsArray = arrayMove(sortedObjectsArray, objectIndex, 0 + localIndex) // 0 + localIndex = start of array, plus the relative ordering of this group
          shouldCommit = true
        }
      })
      break
    case 'backward': {
      const startingLowestIndex = sortedObjectsArray.findIndex(
        (obj) => obj.id === sortedObjectIds[0],
      )
      sortedObjectIds.forEach((id, localIndex) => {
        const objectIndex = sortedObjectsArray.findIndex((obj) => obj.id === id)
        if (objectIndex > 0 + localIndex) {
          newSortedObjectsArray = arrayMove(
            sortedObjectsArray,
            objectIndex,
            startingLowestIndex - 1 + localIndex, // go one index below the lowest object's start index, plus the relative ordering of this group
          )
          shouldCommit = true
        }
      })
      break
    }
    case 'forward': {
      const startingHighestIndex = sortedObjectsArray.findIndex(
        (obj) => obj.id === sortedObjectIds[sortedObjectIds.length - 1],
      )
      sortedObjectIds.forEach((id, localIndex) => {
        const objectIndex = sortedObjectsArray.findIndex((obj) => obj.id === id)
        if (objectIndex < sortedObjectsArray.length - 1) {
          newSortedObjectsArray = arrayMove(
            sortedObjectsArray,
            objectIndex,
            startingHighestIndex + 1 + localIndex, // go one index above the highest object's start index, plus the relative ordering of this group
          )
          shouldCommit = true
        }
      })
      break
    }
    case 'front':
      sortedObjectIds.forEach((id) => {
        const objectIndex = sortedObjectsArray.findIndex((obj) => obj.id === id)
        if (objectIndex !== sortedObjectsArray.length - 1) {
          newSortedObjectsArray = arrayMove(sortedObjectsArray, objectIndex, -1) // -1 = end of array, therefore highest position
          shouldCommit = true
        }
      })
      break
  }

  if (shouldCommit) {
    addToHistoryStack({
      dispatch,
      isRedoAction: action.isRedoAction,
      isUndoAction: action.isUndoAction,
      redoAction: () => {
        dispatch(
          updateObjectOrder({
            command: 'override',
            isRedoAction: true,
            pageId: action.pageId,
            sortedObjects: newSortedObjectsArray,
          }),
        )
      },
      undoAction: () => {
        dispatch(
          updateObjectOrder({
            command: 'override',
            isUndoAction: true,
            pageId: action.pageId,
            sortedObjects: sortedObjectsArray,
          }),
        )
      },
    })
    dispatch(
      DocumentSlice.actions.updateObjectSorting({
        objectsInOrder: newSortedObjectsArray,
        pageId: action.pageId,
      }),
    )
    const newState = getState()
    documentSync.push(newState.document)
  }
})

export const updateTitle = createAsyncThunk<void, string, AppThunkOptions>(
  'document/updateTitleThunk',
  (title, { dispatch, getState }) => {
    dispatch(DocumentSlice.actions.updateTitle(title))
    const newState = getState()
    documentSync.push(newState.document)
  },
)

export const updateDescription = createAsyncThunk<void, string, AppThunkOptions>(
  'document/updateDescriptionThunk',
  (description, { dispatch, getState }) => {
    dispatch(DocumentSlice.actions.updateDescription(description))
    const newState = getState()
    documentSync.push(newState.document)
  },
)

export const updateProperties = createAsyncThunk<
  void,
  { accessCode?: string | null; privacy: ContentPrivacy },
  AppThunkOptions
>('document/updatePropertiesThunk', (contentPrivacy, { dispatch, getState }) => {
  dispatch(DocumentSlice.actions.updateProperties(contentPrivacy))
  const newState = getState()
  documentSync.push(newState.document)
})

export default DocumentSlice.reducer
export const selectDocument = (state: RootState): Document => state.document
export const selectDocumentPages = (state: RootState): PageStructureV1[] => state.document.pages
const selectFirstPage = createSelector(
  selectDocumentPages,
  (pages: PageStructureV1[] | null): PageStructureV1 =>
    // we can force the cast to Page here, as every document will always have at least one page
    // when we have multiple pages, we'll ensure that the user can't delete the page if they only have one
    pages
      ? (pages.find((p: PageStructureV1) => p.index === 1) as PageStructureV1)
      : ({} as PageStructureV1),
)

// todo: make this actually the current page at some point, but for now, the first page works
export const selectCurrentPage = selectFirstPage

export const selectObjectsOfTypeOnPage = createSelector(
  selectCurrentPage,
  (_: RootState, type: ObjectTypeNames) => type,
  (page: PageStructureV1, type: ObjectTypeNames) => {
    return (page?.objects ?? []).filter((object) => object.type === type)
  },
)

export const { initializeDocument, resetDocument, updateObjectTemp } = DocumentSlice.actions

const getHighestZIndex = (objects: PageObject[]) => {
  return Math.max(...[...objects].map((obj) => obj.position.z), -1)
}

export type CreateObjectPayload = {
  pageId: string
  id?: string
  type: ObjectTypeNames
  position: {
    x: number | 'center'
    y: number | 'center'
    w: number
    h: number
    r: number
    z?: number
  }
  content: Record<string, unknown>
  selectAfterCreation?: boolean
  shouldSkipScaling?: boolean
  isUndoAction?: boolean
  isRedoAction?: boolean
}

const createObject = createAsyncThunk<
  { pageId: string; objectId: string },
  CreateObjectPayload,
  AppThunkOptions
>(
  'document/createObject',
  async (
    {
      pageId,
      id,
      type,
      position: initialPosition,
      content,
      selectAfterCreation = true,
      shouldSkipScaling = false,
    },
    { dispatch, getState },
  ) => {
    const {
      appState: {
        canvas: { scalingSide },
      },
      document,
    } = getState()

    const pageIndex = document.pages.findIndex((p) => p.id === pageId)
    const baseSize = document.pages[pageIndex].canvasSize
    const objects = document.pages[pageIndex].objects as PageObject[]
    const position = { ...initialPosition }
    if (position.w > baseSize.width - 20) {
      // 20 = 10px of padding each side
      position.w = baseSize.width - 20
      position.h = position.h * (position.w / initialPosition.w)
    }
    if (position.h > baseSize.height - 20) {
      position.h = baseSize.height - 20
      position.w = position.w * (position.h / initialPosition.h)
    }
    if (position.x === 'center') {
      position.x = baseSize.width / 2 - position.w / 2
    }
    if (position.y === 'center') {
      position.y = baseSize.height / 2 - position.h / 2
    }
    const objectPos = shouldSkipScaling
      ? position
      : (scaledToRelativeObject(position as ObjectPosition, scalingSide) as ObjectPosition)
    const object = {
      content: content,
      createdAt: new Date().toJSON(),
      id: id ?? nanoid(),
      position: {
        ...objectPos,
        z: position.z ?? getHighestZIndex(objects) + 1,
      },
      temp: {},
      type,
      updatedAt: new Date().toJSON(),
    } as PageObject

    dispatch(
      DocumentSlice.actions.addObject({
        object: object,
        pageId,
      }),
    )
    if (selectAfterCreation) {
      dispatch(updateSelectedObjects([object.id]))
    }
    const newState = getState()
    documentSync.push(newState.document)

    return { objectId: object.id, pageId }
  },
)

export const createObjects = createAsyncThunk<
  void,
  {
    objects: Array<CreateObjectPayload>
    isUndoAction?: boolean
    isRedoAction?: boolean
  },
  AppThunkOptions
>('document/createObject', async (payload, { dispatch }) => {
  const { objects } = payload

  const createdObjects = await Promise.all(
    objects.map((object) => {
      return dispatch(createObject(object)).then(unwrapResult)
    }),
  )

  addToHistoryStack({
    dispatch,
    isRedoAction: payload.isRedoAction,
    isUndoAction: payload.isUndoAction,
    redoAction: () => {
      dispatch(
        createObjects({
          ...payload,
          isRedoAction: true,
        }),
      )
    },
    undoAction: () => {
      dispatch(
        removeMultiObjects({
          isUndoAction: true,
          objectIds: createdObjects.map((object) => object.objectId),
          pageId: createdObjects[0].pageId,
        }),
      )
    },
  })
})
