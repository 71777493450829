/**
 * List of breakpoints by screen width
 * which are equivalent to our tailwind configuration
 */
export const enum ScreenSize {
  xs = 320,
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  xxl = 1536,
}
