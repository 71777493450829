import { useCallback, useEffect } from 'react'

import { ContentType, ContentViewInput, useTrackContentViewsMutation } from '@/generated/graphql'
import { isEmbed } from '@/util/helpers'

const safeParseAncestorOriginToHostname = () => {
  try {
    return new URL(window.location.ancestorOrigins[0]).hostname
  } catch {
    return undefined
  }
}

export const generateViewPayload = (
  type: ContentType,
  urn: string,
  isOnPage = false,
  isNewTab = false,
) => {
  const isEmbedded = isEmbed()
  return {
    embedDomain: isNewTab
      ? 'plus-new-tab'
      : isEmbedded
      ? safeParseAncestorOriginToHostname()
      : undefined,
    isEmbed: isNewTab || isEmbedded,
    isOnPage,
    type,
    urn,
  }
}

export const useTrackContentView = (
  type: ContentType,
  urn?: string,
  isOnPage?: boolean,
  isNewTab?: boolean,
) => {
  const [trackView] = useTrackContentViewsMutation({
    errorPolicy: 'ignore',
  })
  useEffect(() => {
    if (urn) {
      void trackView({
        variables: {
          views: [generateViewPayload(type, urn, isOnPage, isNewTab)],
        },
      })
    }
  }, [urn, trackView, type, isOnPage, isNewTab])
}

export const useLazyTrackContentView = () => {
  const [trackView] = useTrackContentViewsMutation({
    errorPolicy: 'ignore',
  })

  return useCallback(
    (type: ContentType, urn: string, isOnPage?: boolean) => {
      void trackView({
        variables: {
          views: [generateViewPayload(type, urn, isOnPage)],
        },
      })
    },
    [trackView],
  )
}

export const useLazyTrackContentViews = () => {
  const [trackView] = useTrackContentViewsMutation({
    errorPolicy: 'ignore',
  })

  return useCallback(
    (views: ContentViewInput[]) => {
      void trackView({
        variables: {
          views,
        },
      })
    },
    [trackView],
  )
}
